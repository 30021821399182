import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { X, Lightbulb } from 'lucide-react';

const BigBroTooltip = ({ text, target, onClose }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);
  
  // Calculate position relative to target element
  useEffect(() => {
    if (!target) {
      // Set a default position in the center of the viewport
      setPosition({
        top: window.innerHeight / 3,
        left: window.innerWidth / 2
      });
      setIsVisible(true);
      return;
    }
    
    const calculatePosition = () => {
      const targetRect = target.getBoundingClientRect();
      const viewportWidth = window.innerWidth; // Define viewportWidth here so it's available in all scopes
      
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
      // Check if this is a default target (body or main) for special positioning
      const isDefaultTarget = target === document.body || 
                            target.tagName === 'MAIN' || 
                            target.classList.contains('query-result-card');
      
      let newPosition;
      
      if (isDefaultTarget) {
        // For default targets, position in the center-top of the viewport
        
        // When tooltip is rendered, use its width for positioning
        if (tooltipRef.current) {
          const tooltipRect = tooltipRef.current.getBoundingClientRect();
          
          // Position at the top center of the viewport
          newPosition = {
            top: scrollTop + 60, // 60px from top of viewport
            left: scrollLeft + (viewportWidth / 2) - (tooltipRect.width / 2)
          };
          
          // Make sure it's not off-screen
          if (newPosition.left < 10) {
            newPosition.left = 10;
          } else if (newPosition.left + tooltipRect.width > viewportWidth - 10) {
            newPosition.left = viewportWidth - tooltipRect.width - 10;
          }
        } else {
          // Initial positioning before tooltip is rendered
          newPosition = {
            top: scrollTop + 60,
            left: scrollLeft + (viewportWidth / 2) - 150 // Assume ~300px width
          };
        }
      } else {
        // For specific targets, position relative to the element
        newPosition = {
          top: targetRect.top + scrollTop - 10, // 10px above target
          left: targetRect.left + scrollLeft + (targetRect.width / 2)
        };
        
        // When tooltip is rendered, adjust position based on its size
        if (tooltipRef.current) {
          const tooltipRect = tooltipRef.current.getBoundingClientRect();
          
          newPosition.left -= (tooltipRect.width / 2); // Center tooltip horizontally
          newPosition.top -= tooltipRect.height; // Position above target
          
          // Check if tooltip would go off-screen
          if (newPosition.left < 10) {
            newPosition.left = 10; // Keep 10px from left edge
          } else if (newPosition.left + tooltipRect.width > viewportWidth - 10) {
            newPosition.left = viewportWidth - tooltipRect.width - 10; // Keep 10px from right edge
          }
          
          // If tooltip would go above viewport, position it below target instead
          if (newPosition.top < 10) {
            newPosition.top = targetRect.bottom + scrollTop + 10;
          }
        }
      }
      
      setPosition(newPosition);
    };
    
    // Calculate initial position
    calculatePosition();
    
    // Make tooltip visible after position is calculated
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    
    // Update position on scroll and resize
    window.addEventListener('scroll', calculatePosition);
    window.addEventListener('resize', calculatePosition);
    
    return () => {
      window.removeEventListener('scroll', calculatePosition);
      window.removeEventListener('resize', calculatePosition);
      clearTimeout(timer);
    };
  }, [target]);
  
  return createPortal(
    <div 
      ref={tooltipRef}
      className={`fixed z-[9999] max-w-md bg-blue-600 text-white p-4 rounded-lg shadow-2xl transition-all duration-300 border border-blue-400 ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      {/* Tooltip content */}
      <div className="flex items-start gap-3">
        <Lightbulb className="h-5 w-5 flex-shrink-0 mt-0.5 text-yellow-300" />
        
        <div className="flex-grow">
          <div className="text-sm font-medium">{text}</div>
        </div>
        
        <button 
          onClick={onClose}
          className="text-white opacity-70 hover:opacity-100 transition-opacity duration-200 rounded-full p-1 -mt-1 -mr-1"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
      
      {/* Arrow pointing to target - only show for specific targets, not default ones */}
      {target !== document.body && 
       target?.tagName !== 'MAIN' && 
       !target?.classList?.contains('query-result-card') && (
        <div 
          className="absolute w-6 h-6 bg-blue-600 transform rotate-45"
          style={{ 
            bottom: position.top > (target?.getBoundingClientRect().top + window.pageYOffset) ? 'auto' : '-12px',
            top: position.top > (target?.getBoundingClientRect().top + window.pageYOffset) ? '-12px' : 'auto',
            left: '50%', 
            marginLeft: '-12px'
          }}
        />
      )}
    </div>,
    document.body
  );
};

export default BigBroTooltip;