// src/hooks/useDocuments.js
import { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, collection, query, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

export const useDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [currentConfig, setCurrentConfig] = useState(null);
  const { currentUser } = useAuth();

  const functions = getFunctions();
  const firestore = getFirestore();

  // Initialize document listener
  useEffect(() => {
    const q = query(
      collection(firestore, 'docs'),
      orderBy('uploadedAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        uploadedAt: doc.data().uploadedAt?.toDate()
      }));
      setDocuments(docs);
    }, (err) => {
      console.error('Error fetching documents:', err);
      setError('Failed to fetch documents');
    });

    return () => unsubscribe();
  }, []);

  // Initialize config listener
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        // Fetch Models
        const modelsDoc = await getDoc(doc(firestore, 'DocsConfig', 'Models'));
        if (modelsDoc.exists()) {
          setAvailableModels(modelsDoc.data().models || []);
        }

        // Fetch Config
        const configDoc = await getDoc(doc(firestore, 'DocsConfig', 'Config'));
        if (configDoc.exists()) {
          setCurrentConfig(configDoc.data());
        }
      } catch (err) {
        console.error('Error fetching docs configuration:', err);
        setError('Failed to fetch configuration');
      }
    };

    fetchConfig();

    // Set up real-time listener for Config changes
    const unsubscribe = onSnapshot(
      doc(firestore, 'DocsConfig', 'Config'),
      (doc) => {
        if (doc.exists()) {
          setCurrentConfig(doc.data());
        }
      },
      (err) => {
        console.error('Error in config listener:', err);
      }
    );

    return () => unsubscribe();
  }, [firestore]);

  const handleUpload = async (files) => {
    setIsUploading(true);
    setError(null);

    try {
      const uploadDocument = httpsCallable(functions, 'uploadDocument');

      for (const file of files) {
        const reader = new FileReader();
        const base64Promise = new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result.split(',')[1]);
          reader.onerror = (e) => reject(e);
          reader.readAsDataURL(file);
        });

        const base64Content = await base64Promise;

        await uploadDocument({
          name: file.name,
          type: file.type,
          size: file.size,
          base64Content,
          uploadedBy: currentUser.email
        });
      }

      setSaveStatus({
        type: 'success',
        message: 'Documents uploaded successfully!'
      });
    } catch (err) {
      console.error('Error uploading documents:', err);
      setError(err.message || 'Failed to upload documents');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to upload documents'
      });
    } finally {
      setIsUploading(false);
      setTimeout(() => setSaveStatus(null), 3000);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      const deleteDocumentFn = httpsCallable(functions, 'deleteDocument');
      await deleteDocumentFn({ documentId });
      
      setSaveStatus({
        type: 'success',
        message: 'Document deleted successfully!'
      });
    } catch (err) {
      console.error('Error deleting document:', err);
      setError(err.message || 'Failed to delete document');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to delete document'
      });
    }
  };

  const updateLabels = async (documentId, labels) => {
    try {
      const updateDocumentLabels = httpsCallable(functions, 'updateDocumentLabels');
      await updateDocumentLabels({ documentId, labels });

      setSaveStatus({
        type: 'success',
        message: 'Labels updated successfully!'
      });
    } catch (err) {
      console.error('Error updating labels:', err);
      setError(err.message || 'Failed to update labels');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to update labels'
      });
    }
  };

  const updateCategories = async (documentId, categories) => {
    try {
      const updateDocumentCategories = httpsCallable(functions, 'updateDocumentCategories');
      // Send the full category objects to preserve quotes
      const formattedCategories = categories.map(cat => 
        typeof cat === 'string' ? { category: cat, quote: '' } : cat
      );
      
      await updateDocumentCategories({ documentId, categories: formattedCategories });
      
      // After successful API call, update local state with full objects
      setDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        const docIndex = updatedDocuments.findIndex(d => d.id === documentId);
        if (docIndex !== -1) {
          updatedDocuments[docIndex] = {
            ...updatedDocuments[docIndex],
            categories: formattedCategories
          };
        }
        return updatedDocuments;
      });
  
      setSaveStatus({
        type: 'success',
        message: 'Categories updated successfully!'
      });
    } catch (err) {
      console.error('Error updating categories:', err);
      setError(err.message || 'Failed to update categories');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to update categories'
      });
    }
  };

  const updateDocumentId = async (documentId, newId) => {
    try {
      const updateDocumentIdFn = httpsCallable(functions, 'updateDocumentId');
      await updateDocumentIdFn({ documentId, newId });
      
      setSaveStatus({
        type: 'success',
        message: 'Document ID updated successfully!'
      });
    } catch (err) {
      console.error('Error updating document ID:', err);
      setError(err.message || 'Failed to update document ID');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to update document ID'
      });
    }
  };

  const batchDeleteDocuments = async (documentIds) => {
    try {
      const batchDelete = httpsCallable(functions, 'batchDeleteDocuments');
      await batchDelete({ documentIds });
      
      setSaveStatus({
        type: 'success',
        message: 'Documents deleted successfully!'
      });
    } catch (err) {
      console.error('Error deleting documents:', err);
      setError(err.message || 'Failed to delete documents');
      setSaveStatus({
        type: 'error',
        message: err.message || 'Failed to delete documents'
      });
    }
  };

  return {
    documents,
    isUploading,
    error,
    saveStatus,
    handleUpload,
    deleteDocument,
    batchDeleteDocuments,
    updateLabels,
    updateCategories,
    setSaveStatus,
    availableModels,
    currentConfig,
  };
};