import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  orderBy, 
  limit, 
  getDocs
} from 'firebase/firestore';
import { Loader2, AlertCircle, Database, RefreshCw, FileText, Info, Trash2, AlertTriangle } from 'lucide-react';
import Card from '../Card';
import StatusMessage from './StatusMessage';
import BatchEmbeddingProgress from './BatchEmbeddingProgress';
import { useEmbeddings } from '../../hooks/useEmbeddings';

const EmbeddingsManagement = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clearingVectorData, setClearingVectorData] = useState(false);
  
  const functions = getFunctions();
  const firestore = getFirestore();

  // State for filtering documents
  const [filter, setFilter] = useState('all'); // 'all', 'embedded', 'pending', 'error'
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [documentToProcess, setDocumentToProcess] = useState(null);
  
  // State for clear vector search confirmation
  const [showClearVectorDialog, setShowClearVectorDialog] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(''); 

  // Setup embeddings hook
  const { startProcessing, stopProcessing, forceReset } = useEmbeddings({
    isProcessing,
    setIsProcessing,
    setError
  });

  // Fetch documents with embedding status
  const fetchDocuments = async () => {
    try {
      setLoading(true);
      
      const docsRef = collection(firestore, 'docs');
      let q;
      
      // Apply filter
      if (filter === 'embedded') {
        q = query(
          docsRef,
          where('embeddingStatus', '==', 'completed'),
          orderBy('uploadedAt', 'desc'),
          limit(50)
        );
      } else if (filter === 'pending') {
        q = query(
          docsRef,
          where('embeddingStatus', 'in', ['pending', 'processing']),
          orderBy('uploadedAt', 'desc'),
          limit(50)
        );
      } else if (filter === 'error') {
        q = query(
          docsRef,
          where('embeddingStatus', '==', 'error'),
          orderBy('uploadedAt', 'desc'),
          limit(50)
        );
      } else { // 'all'
        q = query(
          docsRef,
          orderBy('uploadedAt', 'desc'),
          limit(50)
        );
      }
      
      const querySnapshot = await getDocs(q);
      const docsData = [];
      
      querySnapshot.forEach((doc) => {
        docsData.push({
          id: doc.id,
          ...doc.data(),
          uploadedAt: doc.data().uploadedAt?.toDate() || null
        });
      });
      
      setDocuments(docsData);
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError('Failed to load documents. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Clear vector search index and GCS bucket
  const clearVectorSearch = async () => {
    try {
      setClearingVectorData(true);
      setShowClearVectorDialog(false);
      setDeleteConfirmation('');
      setStatusMessage({ type: 'loading', message: 'Clearing Vector Search data...' });
      
      const clearVectorSearchFunction = httpsCallable(functions, 'clearVectorSearchData');
      const result = await clearVectorSearchFunction();
      
      if (result.data.success) {
        setStatusMessage({ 
          type: 'success',
          message: result.data.message || 'Vector Search data has been cleared successfully'
        });
        
        // Refresh the document list to show updated status
        fetchDocuments();
      } else {
        throw new Error(result.data.message || 'Unknown error');
      }
    } catch (err) {
      console.error('Error clearing Vector Search data:', err);
      setError(err.message || 'Failed to clear Vector Search data');
      setStatusMessage({ 
        type: 'error', 
        message: `Failed to clear Vector Search data: ${err.message}` 
      });
    } finally {
      setClearingVectorData(false);
      setTimeout(() => {
        setStatusMessage(null);
      }, 5000);
    }
  };

  // Show confirmation dialog before processing
  const confirmProcessDocument = (documentId, documentName) => {
    setDocumentToProcess({ id: documentId, name: documentName });
    setShowConfirmDialog(true);
  };
  
  // Close confirmation dialog
  const cancelProcessDocument = () => {
    setShowConfirmDialog(false);
    setDocumentToProcess(null);
  };
  
  // Process a single document
  const processDocument = async (documentId) => {
    setShowConfirmDialog(false);
    try {
      setStatusMessage({ type: 'loading', message: `Processing document ${documentId}...` });
      startProcessing();
      
      const createEmbeddingsFunction = httpsCallable(functions, 'createDocumentEmbeddings');
      const result = await createEmbeddingsFunction({ documentId });
      
      if (result.data.success) {
        setStatusMessage({ 
          type: 'success', 
          message: `Document processed successfully. Created ${result.data.chunkCount} chunks.` 
        });
      } else {
        throw new Error(result.data.message || 'Unknown error');
      }
      
      // Refresh document list
      fetchDocuments();
    } catch (err) {
      console.error('Error processing document:', err);
      
      // Handle specific error messages
      let errorMessage = err.message || 'Unknown error';
      
      if (errorMessage.includes('No processable text content found')) {
        errorMessage = 'Document contains no extractable text. It might be an image, a scanned document without OCR, or an unsupported format.';
      } else if (errorMessage.includes('unsupported file type')) {
        errorMessage = 'Unsupported file type for text extraction. Only PDF, TXT, and DOCX formats are supported.';
      }
      
      setStatusMessage({ 
        type: 'error', 
        message: `Failed to process document: ${errorMessage}` 
      });
      
      fetchDocuments(); // Refresh to see updated status
    } finally {
      setTimeout(() => {
        setStatusMessage(null);
        stopProcessing();
      }, 3000); // Give a short delay to ensure UI updates are complete
    }
  };

  // Load documents on initial render and when filter changes
  useEffect(() => {
    fetchDocuments();
  }, [filter]);

  // Get embedding status badge
  const getStatusBadge = (doc) => {
    const status = doc.embeddingStatus;
    
    switch (status) {
      case 'completed':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Completed
          </span>
        );
      case 'processing':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            <Loader2 className="w-3 h-3 mr-1 animate-spin" />
            Processing
          </span>
        );
      case 'pending':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            Pending
          </span>
        );
      case 'error':
        return (
          <div>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              Error
            </span>
            {doc.embeddingError && (
              <div className="mt-1 text-xs text-red-600 max-w-xs truncate" title={doc.embeddingError}>
                {doc.embeddingError}
              </div>
            )}
          </div>
        );
      default:
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Not Processed
          </span>
        );
    }
  };

  return (
    <Card>
      {/* Clear Vector Search Confirmation Dialog */}
      {showClearVectorDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-lg">
            <div className="flex items-center mb-4 text-amber-600">
              <AlertTriangle className="h-6 w-6 mr-2" />
              <h3 className="text-lg font-medium">Reset Vector Search Index?</h3>
            </div>
            <p className="text-gray-600 mb-3">
              This will clear all vector search data and reset the index. All documents will need to be re-embedded.
            </p>
            <p className="text-gray-600 mb-4 p-2 bg-amber-50 border border-amber-100 rounded-md text-sm">
              <strong>Warning:</strong> This action cannot be undone. Search functionality will be unavailable until documents are re-embedded.
            </p>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type DELETE to confirm:
              </label>
              <input
                type="text"
                value={deleteConfirmation}
                onChange={(e) => setDeleteConfirmation(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="DELETE"
              />
            </div>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => {
                  setShowClearVectorDialog(false);
                  setDeleteConfirmation('');
                }}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded"
              >
                Cancel
              </button>
              <button
                onClick={clearVectorSearch}
                disabled={deleteConfirmation !== 'DELETE'}
                className={`px-4 py-2 rounded flex items-center ${
                  deleteConfirmation === 'DELETE'
                    ? 'bg-red-600 hover:bg-red-700 text-white'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                <Trash2 className="h-4 w-4 mr-1" />
                Reset Index
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Document Process Confirmation Dialog */}
      {showConfirmDialog && documentToProcess && (
        <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-lg">
            <h3 className="text-lg font-medium mb-2">Process Document?</h3>
            <p className="text-gray-600 mb-4">
              Are you sure you want to process "<span className="font-medium">{documentToProcess.name}</span>"?
              This will extract text and create embeddings for vector search.
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={cancelProcessDocument}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => processDocument(documentToProcess.id)}
                className="px-4 py-2 bg-primary hover:bg-primary/90 text-white rounded"
              >
                Process
              </button>
            </div>
          </div>
        </div>
      )}
      
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Document Embeddings</h3>
            <div className="flex items-center gap-3">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={isProcessing || clearingVectorData}
              >
                <option value="all">All Documents</option>
                <option value="embedded">Embedded</option>
                <option value="pending">In Progress</option>
                <option value="error">Failed</option>
              </select>
              
              <button
                onClick={() => setShowClearVectorDialog(true)}
                disabled={isProcessing || clearingVectorData}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors text-sm ${
                  isProcessing || clearingVectorData
                    ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200 border border-gray-300'
                }`}
              >
                {clearingVectorData ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Clearing...
                  </>
                ) : (
                  <>
                    <Trash2 className="h-4 w-4" />
                    Reset Vector Index
                  </>
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md flex items-center gap-2">
              <AlertCircle className="h-5 w-5" />
              <span>{error}</span>
            </div>
          )}

          {statusMessage && (
            <StatusMessage
              type={statusMessage.type}
              message={statusMessage.message}
              isLoading={statusMessage.type === 'loading'}
            />
          )}

          {isProcessing && <BatchEmbeddingProgress />}

          <div className="bg-gray-50 p-4 rounded-md">
            <div className="flex items-start gap-2 mb-3">
              <Info className="h-5 w-5 text-gray-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-gray-600">
                <p className="mb-2">
                  Document embeddings are used for semantic search and AI-powered question answering.
                  Each document is processed and stored in a vector database for efficient similarity search.
                </p>
                <div className="mt-2 text-xs bg-white p-2 rounded border border-gray-200">
                  <strong className="block mb-1">Supported formats:</strong>
                  <ul className="list-disc pl-5 space-y-1">
                    <li>PDF documents (with text content, not just scanned images)</li>
                    <li>Text files (.txt)</li>
                    <li>Microsoft Word documents (.docx)</li>
                  </ul>
                  <div className="mt-2">
                    <strong className="text-amber-600">Note:</strong> Scanned documents without OCR, image-based PDFs, or files with very little text content may not be processed successfully.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">Document Name</th>
                  <th scope="col" className="px-6 py-3">Status</th>
                  <th scope="col" className="px-6 py-3">Chunks</th>
                  <th scope="col" className="px-6 py-3">Last Updated</th>
                  <th scope="col" className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" className="px-6 py-4 text-center">
                      <div className="flex justify-center items-center">
                        <Loader2 className="h-5 w-5 animate-spin mr-2" />
                        Loading documents...
                      </div>
                    </td>
                  </tr>
                ) : documents.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="px-6 py-4 text-center">
                      No documents found.
                    </td>
                  </tr>
                ) : (
                  documents.map((doc) => (
                    <tr key={doc.id} className="bg-white border-b hover:bg-gray-50">
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        <div className="flex items-center gap-2">
                          <FileText className="h-4 w-4 text-gray-500" />
                          <span className="truncate max-w-xs">{doc.name}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        {getStatusBadge(doc)}
                      </td>
                      <td className="px-6 py-4">
                        {doc.embeddingData?.chunkCount || '-'}
                      </td>
                      <td className="px-6 py-4">
                        {doc.embeddingStatus === 'completed' && doc.embeddingCompletedAt?.toDate
                          ? doc.embeddingCompletedAt.toDate().toLocaleString()
                          : '-'}
                      </td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => confirmProcessDocument(doc.id, doc.name)}
                          disabled={isProcessing || clearingVectorData || doc.embeddingStatus === 'processing' || doc.embeddingStatus === 'pending'}
                          className={`text-xs px-2.5 py-1.5 rounded ${
                            isProcessing || clearingVectorData || doc.embeddingStatus === 'processing' || doc.embeddingStatus === 'pending'
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-primary text-white hover:bg-primary/90'
                          }`}
                        >
                          {doc.embeddingStatus === 'completed' ? 'Reprocess' : 'Process'}
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EmbeddingsManagement;