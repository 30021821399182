// src/hooks/useEmbeddings.js
import { useEffect, useRef } from 'react';

export const useEmbeddings = ({
  isProcessing,
  setIsProcessing,
  setError,
}) => {
  const timeoutRef = useRef(null);

  // Clear any stuck state on initial load
  useEffect(() => {
    // Clear any leftover state from previous sessions
    localStorage.removeItem('currentEmbeddingBatchId');
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Main effect to handle document processing state
  useEffect(() => {
    // If we're no longer processing, clean up
    if (!isProcessing) {
      return;
    }

    // Set a timeout to reset any stuck processing state
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      console.log('Processing timeout reached, resetting state');
      setIsProcessing(false);
      setError('Document processing timed out. Please try again.');
      localStorage.removeItem('currentEmbeddingBatchId');
    }, 60000); // 1 minute timeout should be sufficient for single document

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isProcessing, setIsProcessing, setError]);

  return {
    startProcessing: () => {
      // Clear any pending timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsProcessing(true);
    },
    stopProcessing: () => {
      // Clean up completely
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      
      localStorage.removeItem('currentEmbeddingBatchId');
      setIsProcessing(false);
    },
    forceReset: () => {
      // Force a complete reset of all states
      setIsProcessing(false);
      localStorage.removeItem('currentEmbeddingBatchId');
      
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }
  };
};