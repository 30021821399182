import useQueryLibraryCore from './useQueryLibraryCore';
import useQueryLibraryUI from './useQueryLibraryUI';

/**
 * Original hook that now combines the core and UI hooks for backward compatibility
 * @returns {Object} Combined hook functionality
 */
export const useQueryLibrary = () => {
  // Just return the complete UI hook which already includes all core functionality
  return useQueryLibraryUI();
};

export default useQueryLibrary;