import React, { useState, useEffect, useRef } from 'react';
import { Search, Loader2 } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

const ValueSearchList = ({
  tableId,
  columnName,
  onSelect,
  className = '',
  selectedValue = '',
  autoFocus = false,
  isSearching = false,
  selectedIndex = -1,
  onItemsUpdate
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [values, setValues] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const inputRef = useRef(null);
  const { allowedTables } = useAuth();
  const selectionInProgressRef = useRef(false);

  // Effect for initial data fetch
  useEffect(() => {
    const fetchValues = async () => {
      if (!tableId || !columnName) return;
      
      try {
        setLoading(true);
        setError(null);

        if (!allowedTables || !allowedTables[tableId]) {
          console.log('[ValueSearch] No access to table:', tableId);
          setError('Unauthorized access');
          setLoading(false);
          return;
        }
        
        const safeTableId = tableId.replace(/\./g, '_');
        const docId = `${safeTableId}_${columnName}`;
        const docRef = doc(db, 'index', docId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && Array.isArray(data.values)) {
            const uniqueValues = [...new Set(data.values.map(item => item.value))];
            const formattedValues = uniqueValues.map(value => ({ value }));
            setValues(formattedValues);
            setFilteredValues(formattedValues);
            if (onItemsUpdate) {
              onItemsUpdate(uniqueValues);
            }
          } else {
            setError('No values found');
          }
        } else {
          setError('No values found');
        }
      } catch (err) {
        console.error('Error fetching values:', err);
        setError('Failed to load values');
      } finally {
        setLoading(false);
      }
    };

    fetchValues();
    
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
      setIsOpen(true);
    }
  }, [tableId, columnName, autoFocus, allowedTables]);

  // Effect for filtering values
  useEffect(() => {
    if (!selectionInProgressRef.current) {
      if (searchTerm) {
        const filtered = values.filter(item =>
          item.value.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredValues(filtered);
        if (onItemsUpdate) {
          onItemsUpdate(filtered.map(item => item.value));
        }
      } else {
        setFilteredValues(values);
        if (onItemsUpdate) {
          onItemsUpdate(values.map(item => item.value));
        }
      }
    }
  }, [searchTerm, values]);

  // Effect for selected index from parent
  useEffect(() => {
    if (!selectionInProgressRef.current && selectedIndex !== highlightedIndex) {
      setHighlightedIndex(selectedIndex);
    }
  }, [selectedIndex]);

  // Effect for scroll into view
  useEffect(() => {
    if (highlightedIndex >= 0 && listRef.current) {
      const highlightedElement = listRef.current.children[highlightedIndex];
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, [highlightedIndex]);

  const handleSelect = (value) => {
    selectionInProgressRef.current = true;
    setSearchTerm('');
    setIsOpen(false);
    setHighlightedIndex(-1);
    onSelect(value);
    setTimeout(() => {
      selectionInProgressRef.current = false;
    }, 100);
  };

  const handleKeyDown = (e) => {
    if (loading) return;

    if (!isOpen) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        setIsOpen(true);
        e.preventDefault();
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredValues.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev > 0 ? prev - 1 : prev
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredValues.length) {
          handleSelect(filteredValues[highlightedIndex].value);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    if (!selectionInProgressRef.current) {
      const newValue = e.target.value;
      setSearchTerm(newValue);
      setIsOpen(true);
      if (!newValue) {
        onSelect('');
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={containerRef}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={selectedValue || searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          className="w-full bg-gray-700 text-white rounded-md px-4 py-2.5 focus:ring-2 focus:ring-secondary focus:outline-none"
          placeholder="Type to search values..."
        />
        <div className="absolute right-3 top-3">
          {loading ? (
            <Loader2 className="h-5 w-5 animate-spin text-gray-400" />
          ) : (
            <Search className="h-5 w-5 text-secondary" />
          )}
        </div>
      </div>

      {isOpen && !loading && !error && (
        <div className="absolute z-50 w-full mt-1 bg-gray-700 border border-gray-600 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredValues.length > 0 ? (
            <ul className="py-1" ref={listRef}>
              {filteredValues.map((item, index) => (
                <li
                  key={index}
                  className={`px-4 py-2 cursor-pointer text-white ${
                    index === highlightedIndex ? 'bg-gray-800' : 'hover:bg-gray-800'
                  }`}
                  onClick={() => handleSelect(item.value)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {item.value}
                </li>
              ))}
            </ul>
          ) : (
            <div className="px-4 py-2 text-gray-400">No matching values found</div>
          )}
        </div>
      )}

      {error && (
        <div className="mt-1 text-sm text-red-500">
          {error}
        </div>
      )}
    </div>
  );
};

export default ValueSearchList;