// src/components/Docs/DocumentID.js
import React, { useState } from 'react';
import { Edit, Check, X } from 'lucide-react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const DocumentID = ({ document }) => {
  const db = getFirestore();
  const [isEditing, setIsEditing] = useState(false);
  const [editedID, setEditedID] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  // Extract the ID from document name based on existing logic
  const extractDocumentID = (docName) => {
    const matchStandard = docName.match(/\b(\d{2,4}-\d{3,4})\b/);
    const matchAlt = docName.match(/\b(\d+[.-]\d{4})\b/);
    
    if (matchStandard) return matchStandard[1];
    return matchAlt ? `${matchAlt[1].split(/[.-]/)[0]}-${matchAlt[1].split(/[.-]/)[1]}` : 'N/A';
  };

  // Get the document ID (either from the id field if it exists, or extract from name)
  const getDocumentID = () => {
    if (document.id_field) return document.id_field;
    return extractDocumentID(document.name);
  };

  // Truncate ID for display
  const getTruncatedID = (id) => {
    if (id.length <= 18) return id; // Reduced from 20 to 18 to ensure space for edit button
    return `${id.substring(0, 18)}...`;
  };

  const handleEditClick = () => {
    setEditedID(getDocumentID());
    setIsEditing(true);
    setError(null);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setError(null);
  };

  const handleSave = async () => {
    if (!editedID.trim()) {
      setError("ID cannot be empty");
      return;
    }

    try {
      setIsSaving(true);
      setError(null);
      
      // Update the document in Firestore with the id field
      await updateDoc(doc(db, 'docs', document.id), {
        id_field: editedID.trim()
      });
      
      // Update the local document object for immediate UI feedback
      document.id_field = editedID.trim();
      
      setIsEditing(false);
    } catch (err) {
      setError("Failed to save: " + err.message);
      console.error("Error updating document ID:", err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isEditing) {
    return (
      <div className="relative w-full">
        <div className="flex items-center space-x-1">
          <input
            type="text"
            value={editedID}
            onChange={(e) => setEditedID(e.target.value)}
            className={`w-24 px-2 py-1 text-sm border ${error ? 'border-red-500' : 'border-gray-300'} rounded`}
            disabled={isSaving}
          />
          <div className="flex-shrink-0 flex items-center">
            <button 
              onClick={handleSave}
              disabled={isSaving}
              className="text-green-600 hover:text-green-800 p-1"
              title="Save"
            >
              {isSaving ? (
                <span className="animate-spin">⌛</span>
              ) : (
                <Check className="h-4 w-4" />
              )}
            </button>
            <button 
              onClick={handleCancel}
              disabled={isSaving}
              className="text-red-600 hover:text-red-800 p-1"
              title="Cancel"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>
        {error && (
          <div className="absolute left-0 top-full mt-1 z-10">
            <span className="text-xs text-red-500 bg-white shadow-sm px-1">{error}</span>
          </div>
        )}
      </div>
    );
  }

  const fullID = getDocumentID();
  const displayID = getTruncatedID(fullID);

  return (
    <div className="flex items-center space-x-1 w-full">
      <span className="text-sm text-gray-900 truncate max-w-[80%]" title={fullID}>{displayID}</span>
      <button
        onClick={handleEditClick}
        className="text-gray-400 hover:text-blue-600 ml-1 p-1 flex-shrink-0"
        title="Edit ID"
      >
        <Edit className="h-3.5 w-3.5" />
      </button>
    </div>
  );
};

export default DocumentID;