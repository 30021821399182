import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import AnalysisResultsContainer from '../components/Review/AnalysisResultsContainer';
import ReviewQueryInput from '../components/Review/ReviewQueryInput';
import SavedAnalysisList from '../components/Review/SavedAnalysisList';
import documentAnalysisService from '../components/Review/DocumentAnalysisService';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FolderOpen, FolderClosed } from 'lucide-react';

const USER_NAME_CACHE_KEY = 'user_name_cache';

const Review = ({ mainContentRef }) => {
  const { currentUser } = useAuth();
  const { config, isLoading: isConfigLoading } = useConfig();
  const [query, setQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState(() => {
    try {
      const cached = localStorage.getItem(USER_NAME_CACHE_KEY);
      if (cached) {
        const { name, email } = JSON.parse(cached);
        if (email === currentUser?.email) {
          return name;
        }
      }
    } catch (error) {
      console.error('Error reading from localStorage:', error);
    }
    return '';
  });
  const [isNameLoading, setIsNameLoading] = useState(!userName);
  const [showSavedList, setShowSavedList] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);

  // Effect to fetch user's name
  useEffect(() => {
    const fetchUserName = async () => {
      if (!currentUser?.email) return;
      
      try {
        setIsNameLoading(true);
        const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
        const docSnap = await getDoc(userPrefsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const firstName = data.englishName?.split(' ')[0] || '';
          
          try {
            localStorage.setItem(USER_NAME_CACHE_KEY, JSON.stringify({
              name: firstName,
              email: currentUser.email
            }));
          } catch (error) {
            console.error('Error caching to localStorage:', error);
          }
  
          setUserName(firstName);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      } finally {
        setIsNameLoading(false);
      }
    };
  
    // Only fetch from DB if this is a fresh page load (not a navigation)
    if (performance.navigation.type === 1 || !userName) {
      fetchUserName();
    }
  }, [currentUser]);

  // Effect to handle mutual exclusivity between filters and document selection
  useEffect(() => {
    // If documents are selected, clear filters
    if (selectedDocuments.length > 0) {
      setSelectedCategories([]);
      setSelectedLabels([]);
    }
  }, [selectedDocuments]);

  useEffect(() => {
    // If filters are selected, clear documents
    if (selectedCategories.length > 0 || selectedLabels.length > 0) {
      setSelectedDocuments([]);
    }
  }, [selectedCategories, selectedLabels]);

  // Effect to scroll to top when results change
  useEffect(() => {
    if (analysisResult && mainContentRef?.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [analysisResult, mainContentRef]);

  // Effect to handle loading a saved analysis
  useEffect(() => {
    if (selectedAnalysis) {
      setQuery(selectedAnalysis.query || '');
      
      // Set categories if available
      if (selectedAnalysis.selectedCategories && Array.isArray(selectedAnalysis.selectedCategories)) {
        setSelectedCategories(selectedAnalysis.selectedCategories);
      } else {
        setSelectedCategories([]);
      }
      
      // Set labels if available
      if (selectedAnalysis.selectedLabels && Array.isArray(selectedAnalysis.selectedLabels)) {
        setSelectedLabels(selectedAnalysis.selectedLabels);
      } else {
        setSelectedLabels([]);
      }
      
      // Set documents if available
      if (selectedAnalysis.selectedDocumentIds && Array.isArray(selectedAnalysis.selectedDocumentIds)) {
        if (selectedAnalysis.selectedDocuments && Array.isArray(selectedAnalysis.selectedDocuments)) {
          setSelectedDocuments(selectedAnalysis.selectedDocuments);
        } else {
          // If we only have IDs but not the full documents, set empty for now
          // In a real implementation, you might want to fetch the documents by IDs
          setSelectedDocuments([]);
        }
      } else {
        setSelectedDocuments([]);
      }
      
      // Create an analysis result object based on the selected analysis
      setAnalysisResult({
        success: true,
        response: selectedAnalysis.content,
        title: selectedAnalysis.title || 'Document Analysis',
        metadata: selectedAnalysis.metadata || {},
        documentLinks: selectedAnalysis.documentLinks || []
      });
    }
  }, [selectedAnalysis]);

  const handleSubmit = useCallback(async (e) => {
    if (e) e.preventDefault();
    
    if (!query?.trim()) {
      setError({ message: 'Please enter a query for analysis' });
      return;
    }
  
    setIsLoading(true);
    setError(null);
    setSelectedAnalysis(null); // Clear any selected analysis when submitting new query
    
    try {
      // Pass selectedDocuments to the analysis service
      const result = await documentAnalysisService.analyzeQuery(
        query.trim(), 
        selectedCategories, 
        selectedLabels,
        selectedDocuments
      );
      
      setAnalysisResult(result);
      
      if (!result.success) {
        setError({
          message: result.message || 'Analysis failed. Please try again.',
          status: 'error',
        });
      }
    } catch (error) {
      console.error('Error analyzing query:', error);
      setError({
        message: error.message || 'An unexpected error occurred. Please try again.',
        status: 'error',
      });
      
      setAnalysisResult({
        success: false,
        message: error.message || 'An unexpected error occurred. Please try again.',
        response: null
      });
    } finally {
      setIsLoading(false);
    }
  }, [query, selectedCategories, selectedLabels, selectedDocuments]);

  // Handle closing the analysis result
  const handleAnalysisClose = useCallback(() => {
    setAnalysisResult(null);
    setSelectedAnalysis(null);
  }, []);

  // Handle selecting an analysis from the saved list
  const handleSelectAnalysis = useCallback((analysis) => {
    setSelectedAnalysis(analysis);
    
    // On mobile, auto-close the sidebar after selection
    if (window.innerWidth < 768) {
      setShowSavedList(false);
    }
  }, []);

  if (!currentUser) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold mb-4">Please log in to view this page</h2>
        <p>You need to be logged in to access the document review features.</p>
      </div>
    );
  }

  // Main container with flexible layout
  return (
    <div className="flex h-[calc(100vh-64px)] overflow-hidden">
      {/* Saved Analysis List Panel with smooth transition */}
      <div 
        className={`
          h-full flex-shrink-0 bg-white overflow-hidden
          transition-all duration-500 ease-in-out
          ${showSavedList ? 'w-52' : 'w-0'}
        `}
      >
        {showSavedList && (
          <div className="h-full flex flex-col">
            <div className="p-2.5 border-b border-gray-100 flex items-center justify-between">
              <h3 className="font-medium text-gray-700 text-xs uppercase tracking-wider">Saved Analyses</h3>
              <button 
                onClick={() => setShowSavedList(false)}
                className="text-gray-400 hover:text-gray-600 transition-colors"
                aria-label="Close saved analyses"
              >
                <FolderClosed className="w-4 h-4" />
              </button>
            </div>
            <div className="flex-1 overflow-hidden">
              <SavedAnalysisList 
                onSelectAnalysis={handleSelectAnalysis}
                selectedAnalysisId={selectedAnalysis?.id}
              />
            </div>
          </div>
        )}
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col h-full overflow-auto relative">
        {/* Toggle Button - Only visible when list is closed */}
        {!showSavedList && (
          <button 
            onClick={() => setShowSavedList(true)}
            className="absolute top-4 left-4 z-10 bg-white shadow-md rounded-md p-1.5 
                       hover:bg-gray-50 transition-all duration-300 flex items-center justify-center"
            aria-label="Show saved analyses"
          >
            <FolderOpen className="w-4 h-4 text-gray-500" />
          </button>
        )}

        {/* Content container with nice transitions and consistent width */}
        <div className={`container mx-auto px-4 sm:px-6 flex flex-col items-center
                       min-h-full ${!analysisResult ? 'justify-center' : 'justify-start pt-8'}
                       transition-all duration-500 ease-in-out`}>
          <div className={`relative transition-all duration-500 ease-in-out 
                         ${analysisResult 
                           ? 'w-full max-w-4xl' 
                           : showSavedList 
                             ? 'w-full md:w-[660px]' 
                             : 'w-full md:w-[768px]'
                         }`}>
            {/* Title - hidden when analysis is shown */}
            <h1 className={`text-2xl md:text-3xl font-bold mb-8 text-center text-gray-700
                          transition-all duration-500 ease-in-out transform
                          ${analysisResult 
                            ? 'scale-90 opacity-0 absolute top-0 left-1/2 -translate-x-1/2' 
                            : 'scale-100 opacity-100 relative'
                          }`}>
              {userName ? (
                <>
                  <span className={`inline-block transition-all duration-300 ease-in-out
                                   ${isNameLoading ? 'opacity-0 translate-y-1' : 'opacity-100 translate-y-0'}`}>
                    {userName}
                  </span>
                  <span>, review your documents</span>
                </>
              ) : (
                <span>Document Review</span>
              )}
            </h1>
            
            {/* QueryInput component with transition */}
            <div className="mb-8 w-full">
              <ReviewQueryInput
                query={query}
                setQuery={setQuery}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedLabels={selectedLabels}
                setSelectedLabels={setSelectedLabels}
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                error={error}
                isLoading={isLoading || isConfigLoading}
                onSubmit={handleSubmit}
              />
            </div>

            {/* Analysis Results */}
            {analysisResult && (
              <div className="w-full transition-all duration-500 ease-in-out">
                <AnalysisResultsContainer 
                  analysisResult={analysisResult} 
                  query={query} 
                  onClose={handleAnalysisClose}
                  selectedCategories={selectedCategories}
                  selectedLabels={selectedLabels}
                  selectedDocuments={selectedDocuments}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;