// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import PromptManagement from '../components/PromptManagement';
import SchemaManagement from '../components/SchemaManagement';
import UserManagement from '../components/UserManagement';
import AgentManagement from '../components/AgentManagement';
import PolicyManagement from '../components/PolicyManagement';
import RoleManagement from '../components/RoleManagement';
import UserRoleAssignment from '../components/UserRoleAssignment';
import DocsManagement from '../components/Docs/DocsManagement';
import DBIConfigManagement from '../components/DBIConfigManagement';
import ProposedQuestionsManagement from '../components/ProposedQuestionsManagement';
import { Database, FileText, Users, Zap, Shield, BookOpen } from 'lucide-react';

const AdminDashboard = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeAccessControl, setActiveAccessControl] = useState('policies');
  const [activeShootTab, setActiveShootTab] = useState('schema');

  // Get the current section from the URL
  const currentPath = location.pathname.split('/').pop();

  useEffect(() => {
    console.log('AdminDashboard auth state:', { isAdmin });
  }, [isAdmin]);
  
  useEffect(() => {
    // If user is not admin, redirect to home
    if (!isAdmin) {
      navigate('/');
      return;
    }
    
    // If no section is specified, redirect to shoot/schema
    if (location.pathname === '/admin') {
      navigate('/admin/shoot/schema');
    }
  }, [location, navigate, isAdmin]);

  // If not admin, don't render anything
  if (!isAdmin) {
    return null;
  }

  const renderAccessControlContent = () => {
    switch (activeAccessControl) {
      case 'policies':
        return <PolicyManagement />;
      case 'roles':
        return <RoleManagement />;
      case 'user-roles':
        return <UserRoleAssignment />;
      default:
        return <PolicyManagement />;
    }
  };

  const renderShootContent = () => {
    switch (activeShootTab) {
      case 'schema':
        return <SchemaManagement />;
      case 'prompts':
        return <PromptManagement />;
      case 'shoot-config':
        return <DBIConfigManagement />;
      case 'proposed-questions':
        return <ProposedQuestionsManagement />;
      default:
        return <SchemaManagement />;
    }
  };

  const getTabClass = (tabName) => {
    // For main tabs
    if (!tabName.includes('/')) {
      return `pb-4 px-2 -mb-px ${
        currentPath === tabName || (tabName === 'shoot' && ['schema', 'prompts', 'shoot-config', 'proposed-questions'].includes(currentPath))
          ? 'border-b-2 border-primary text-primary font-medium'
          : 'text-gray-500 hover:text-gray-700'
      }`;
    }
  };

  const getShootTabClass = (tabName) => {
    return `relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
      activeShootTab === tabName ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
    }`;
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="container mx-auto px-2 sm:px-0 py-2 sm:py-4">
        <div className="mb-6">
          <nav className="flex space-x-6 border-b border-gray-200">
            <button 
              className={getTabClass('shoot')}
              onClick={() => {
                navigate('/admin/shoot/schema');
                setActiveShootTab('schema');
              }}
            >
              Shoot
            </button>
            <button 
              className={getTabClass('docs')}
              onClick={() => navigate('/admin/docs')}
            >
              Docs
            </button>
            <button 
              className={getTabClass('users')}
              onClick={() => navigate('/admin/users')}
            >
              Users
            </button>
            <button 
              className={getTabClass('agents')}
              onClick={() => navigate('/admin/agents')}
            >
              Agents
            </button>
            <button 
              className={getTabClass('access-control')}
              onClick={() => navigate('/admin/access-control')}
            >
              Access Control
            </button>
          </nav>
        </div>
        
        {/* Main content area */}
        <div className="w-full">
          <Routes>
            {/* New shoot subpages routes */}
            <Route 
              path="shoot/*" 
              element={
                <div>
                  {/* Shoot Tabs Navigation */}
                  <div className="border-b border-gray-200 mb-6">
                    <div className="flex items-end gap-6">
                      <button
                        onClick={() => {
                          setActiveShootTab('schema');
                          navigate('/admin/shoot/schema');
                        }}
                        className={getShootTabClass('schema')}
                      >
                        <Database className="h-4 w-4" />
                        Data
                        {activeShootTab === 'schema' && (
                          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
                        )}
                      </button>
                      
                      <button
                        onClick={() => {
                          setActiveShootTab('prompts');
                          navigate('/admin/shoot/prompts');
                        }}
                        className={getShootTabClass('prompts')}
                      >
                        <FileText className="h-4 w-4" />
                        Prompt
                        {activeShootTab === 'prompts' && (
                          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
                        )}
                      </button>
                      
                      <button
                        onClick={() => {
                          setActiveShootTab('shoot-config');
                          navigate('/admin/shoot/shoot-config');
                        }}
                        className={getShootTabClass('shoot-config')}
                      >
                        <BookOpen className="h-4 w-4" />
                        Shoot Config
                        {activeShootTab === 'shoot-config' && (
                          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
                        )}
                      </button>
                      
                      <button
                        onClick={() => {
                          setActiveShootTab('proposed-questions');
                          navigate('/admin/shoot/proposed-questions');
                        }}
                        className={getShootTabClass('proposed-questions')}
                      >
                        <Zap className="h-4 w-4" />
                        Proposed Questions
                        {activeShootTab === 'proposed-questions' && (
                          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
                        )}
                      </button>
                    </div>
                  </div>
                  
                  {renderShootContent()}
                </div>
              } 
            />
            
            {/* Route to go to the default shoot tab */}
            <Route path="shoot" element={<Navigate to="/admin/shoot/schema" replace />} />
            
            {/* Preserving old direct routes for compatibility */}
            <Route path="prompts" element={<Navigate to="/admin/shoot/prompts" replace />} />
            <Route path="schema" element={<Navigate to="/admin/shoot/schema" replace />} />
            <Route path="shoot-config" element={<Navigate to="/admin/shoot/shoot-config" replace />} />
            <Route path="proposed-questions" element={<Navigate to="/admin/shoot/proposed-questions" replace />} />
            
            {/* Other existing routes */}
            <Route path="docs" element={<DocsManagement />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="agents" element={<AgentManagement />} />
            <Route 
              path="access-control" 
              element={
                <div className="space-y-6">
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="flex space-x-4 border-b border-gray-200">
                      <button 
                        className={`pb-4 px-2 -mb-px ${
                          activeAccessControl === 'policies' 
                            ? 'border-b-2 border-primary text-primary font-medium'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveAccessControl('policies')}
                      >
                        Policies
                      </button>
                      <button 
                        className={`pb-4 px-2 -mb-px ${
                          activeAccessControl === 'roles' 
                            ? 'border-b-2 border-primary text-primary font-medium'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveAccessControl('roles')}
                      >
                        Roles
                      </button>
                      <button 
                        className={`pb-4 px-2 -mb-px ${
                          activeAccessControl === 'user-roles' 
                            ? 'border-b-2 border-primary text-primary font-medium'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveAccessControl('user-roles')}
                      >
                        User Roles
                      </button>
                    </div>
                    
                    <div className="mt-6">
                      {renderAccessControlContent()}
                    </div>
                  </div>
                </div>
              } 
            />
            <Route path="/" element={<Navigate to="/admin/shoot/schema" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;