import React, { useState, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Bookmark, Search, X, Table, BarChart, BarChart2, PieChart, LineChart, FileText, Pin, PinOff, Trash2 } from 'lucide-react';
import useQueryLibrary from '../../hooks/useQueryLibrary';

const QueryLibrary = forwardRef(({ onQuerySelect, isVisible, onClose }, ref) => {
  const {
    queries,
    isLoading,
    error,
    lastFetchTime,
    fetchSavedQueries,
    filteredQueries: getFilteredQueries,
    togglePinQuery,
    pinnedQueries,
    getSortedQueries,
    MAX_PINNED_QUERIES,
    hideQuery,
    getQuerySettings // Add this to import visualization settings
  } = useQueryLibrary();
  
  const [searchQuery, setSearchQuery] = useState('');
  const [hoverCardId, setHoverCardId] = useState(null);
  const [newQueryId, setNewQueryId] = useState(null);
  const [previousQueriesIds, setPreviousQueriesIds] = useState([]);
  
  // Add refresh function
  const refreshLibrary = useCallback(() => {
    console.log("Explicitly refreshing library");
    // Save current query IDs before refreshing
    setPreviousQueriesIds(queries.map(q => q.id));
    fetchSavedQueries(true);
  }, [fetchSavedQueries, queries]);
  
  // Expose the refresh function through the ref
  useImperativeHandle(ref, () => ({
    refresh: refreshLibrary
  }));
  
  // When queries change after a refresh, detect new query
  useEffect(() => {
    if (previousQueriesIds.length > 0 && queries.length > 0) {
      // Find any new query IDs that weren't in the previous set
      const newIds = queries
        .map(q => q.id)
        .filter(id => !previousQueriesIds.includes(id));
      
      if (newIds.length > 0) {
        // Get the most recently added query (usually the first one in sorted order)
        setNewQueryId(newIds[0]);
        
        // Clear the animation after a few seconds
        const timer = setTimeout(() => {
          setNewQueryId(null);
          setPreviousQueriesIds([]);
        }, 3000);
        
        return () => clearTimeout(timer);
      }
    }
  }, [queries, previousQueriesIds]);
  
  // Refresh data when component mounts or becomes visible
  useEffect(() => {
    if (isVisible) {
      const now = Date.now();
      const shouldRefresh = now - lastFetchTime > 5 * 60 * 1000; // 5 minutes in milliseconds
      fetchSavedQueries(shouldRefresh);
    }
  }, [fetchSavedQueries, isVisible, lastFetchTime]);

  const handleRunQuery = (query) => {
    if (onQuerySelect) {
      onQuerySelect(query);
    }
  };

  const handlePinToggle = (e, queryId) => {
    e.stopPropagation(); // Prevent triggering the parent button click
    togglePinQuery(queryId);
  };

  const handleHideQuery = (e, query) => {
    e.stopPropagation(); // Prevent triggering the parent button click
    if (window.confirm(`Hide "${query.name || 'this query'}" from your library?`)) {
      hideQuery(query.id);
    }
  };

  const getQueryIcon = (query) => {
    // Get saved visualization settings for this query
    const querySettings = getQuerySettings()[query.id] || {};
    
    // Use either the saved settings from localStorage or the settings stored with the query
    const effectiveSettings = {
      ...query.cardSettings,  // Settings saved in Firestore with the query
      ...querySettings        // Override with settings from localStorage (has higher priority)
    };
    
    const viewMode = effectiveSettings.viewMode || (query.cardSettings ? query.cardSettings.viewMode : 'table');
    const chartType = effectiveSettings.chartType || (query.cardSettings ? query.cardSettings.chartType : 'bar');
    
    // Check if it's a single value result
    const isSingleValue = query.result && query.result.length === 1 && 
                         Object.keys(query.result[0]).length === 1;
    
    if (isSingleValue) {
      return <FileText className="h-4 w-4 text-gray-500" />;
    }
    
    if (viewMode === 'table') {
      return <Table className="h-4 w-4 text-gray-500" />;
    }
    
    // Chart icons based on chart type or view mode
    switch (chartType || viewMode) {
      case 'bar':
        return <BarChart className="h-4 w-4 text-gray-500" />;
      case 'line':
        return <LineChart className="h-4 w-4 text-gray-500" />;
      case 'pie':
        return <PieChart className="h-4 w-4 text-gray-500" />;
      case 'scatter':
        return <BarChart2 className="h-4 w-4 text-gray-500" />;
      default:
        return <BarChart className="h-4 w-4 text-gray-500" />;
    }
  };

  // Get filtered queries using the hook's filter function
  const filteredQueries = useMemo(() => {
    const filtered = getFilteredQueries(searchQuery);
    return getSortedQueries(filtered);
  }, [getFilteredQueries, getSortedQueries, searchQuery]);

  // Skip rendering if not visible
  if (!isVisible) {
    return null;
  }

  // Render placeholder items that match the real queries in style
  const renderPlaceholders = (count = 5, lowOpacity = false) => {
    // Return array of placeholder cards with white background
    return Array.from({ length: count }).map((_, index) => {
      return (
        <div 
          key={`placeholder-${lowOpacity ? 'subtle-' : ''}${index}`}
          className={`w-full rounded-lg p-2.5 border border-gray-100 
                     bg-white
                     ${lowOpacity ? 'opacity-30' : ''}`}
        >
          <div className="flex items-center gap-2 mb-1.5">
            <div className="bg-gray-200 p-1 rounded-full h-6 w-6"></div>
            <div className="h-4 bg-gray-200 rounded w-16"></div>
          </div>
          <div className="h-4 bg-gray-200 rounded mb-2 w-full"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
      );
    });
  };

  return (
    <div className="h-full flex flex-col overflow-hidden max-w-[150px] xl:max-w-[220px]">
      <div className="absolute top-2 right-2 z-10 md:hidden">
        <button 
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700"
          aria-label="Close library"
        >
          <X size={16} />
        </button>
      </div>
      
      {/* Add "Saved Queries" text at the top with left alignment and bookmark icon */}
      {filteredQueries.length === 0 && !searchQuery && (
        <div className="p-4 text-left text-gray-500 text-sm flex flex-col items-start">
          <Bookmark className="text-gray-400 h-5 w-5 mb-2 flex-shrink-0" />
          <span>Saved Queries</span>
        </div>
      )}
      
      {/* Search box with darker placeholder and simplified text - FIXED CONDITION */}
      {getFilteredQueries('').length > 5 && (
        <div className="px-4 py-2">
          <div className="relative flex items-center">
            <Search className="absolute left-0 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-8 pr-3 py-2 text-gray-500 placeholder-gray-500 bg-transparent 
                        border-0 border-b border-purple-200 focus:border-purple-300
                        focus:outline-none text-sm"
            />
          </div>
        </div>
      )}
      
      <div className="flex-1 overflow-y-auto p-2 scroll-container">
        {isLoading && queries.length === 0 ? (
          <div className="flex justify-center items-center h-20">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary"></div>
          </div>
        ) : error && queries.length === 0 ? (
          <div className="text-red-500 p-2 text-center text-sm">{error}</div>
        ) : filteredQueries.length === 0 ? (
          <div className="grid gap-3">
            {searchQuery ? (
              <div className="text-gray-500 p-2 text-center text-sm">No matching queries found</div>
            ) : (
              // Show placeholder cards without redundant bookmark icon
              renderPlaceholders()
            )}
          </div>
        ) : (
          <div className="grid gap-2">
            {/* Actual queries */}
            {filteredQueries.map(query => {
              const isPinned = pinnedQueries.includes(query.id);
              const isNew = query.id === newQueryId;
              
              return (
                <button
                  key={query.id}
                  onClick={() => handleRunQuery(query)}
                  onMouseEnter={() => setHoverCardId(query.id)}
                  onMouseLeave={() => setHoverCardId(null)}
                  className={`w-full text-left transition-all rounded-lg p-2.5 
                             border bg-white relative
                             ${isNew 
                                ? 'animate-pulse border-secondary shadow-md' 
                                : 'border-gray-100 hover:translate-y-[-1px] hover:border-l-4 hover:border-l-secondary'}
                             ${isPinned ? 'bg-primary/5' : ''}`}
                  style={{
                    boxShadow: isNew ? '0 0 10px rgba(255, 173, 255, 0.5)' : ''
                  }}
                >
                  <div className="flex items-center justify-between mb-1.5">
                    <div className="flex items-center gap-2">
                      <div className={`p-1 rounded-full ${isNew ? 'bg-secondary/20' : 'bg-primary/10'}`}>
                        {getQueryIcon(query)}
                      </div>
                      {!query.isPrivate && (
                        <span className="px-1.5 py-0.5 text-[10px] font-normal rounded-full bg-blue-100/70 text-blue-700">
                          Shared
                        </span>
                      )}
                      {isNew && (
                        <span className="px-1.5 py-0.5 text-[10px] font-semibold rounded-full bg-secondary/20 text-secondary animate-pulse">
                          New
                        </span>
                      )}
                    </div>
                    <div className="flex items-center gap-1.5">
                      <div 
                        className={`transition-opacity duration-150 
                                  ${hoverCardId === query.id ? 'opacity-100' : 'opacity-0'}`}
                      >
                        <button 
                          onClick={(e) => handleHideQuery(e, query)}
                          className="text-gray-400 hover:text-red-500"
                          title="Hide query from library"
                          aria-label="Hide query from library"
                        >
                          <Trash2 size={14} />
                        </button>
                      </div>
                      <div 
                        className={`transition-opacity duration-150 
                                  ${(hoverCardId === query.id || isPinned) ? 'opacity-100' : 'opacity-0'}`}
                      >
                        {isPinned ? (
                          <button 
                            onClick={(e) => handlePinToggle(e, query.id)}
                            className="text-primary hover:text-primary/80 relative group"
                            title="Unpin query"
                            aria-label="Unpin query"
                          >
                            <Pin size={14} className="group-hover:opacity-0" />
                            <PinOff size={14} className="absolute top-0 left-0 opacity-0 group-hover:opacity-100" />
                          </button>
                        ) : (
                          <button 
                            onClick={(e) => handlePinToggle(e, query.id)}
                            className="text-gray-400 hover:text-primary"
                            title={pinnedQueries.length >= MAX_PINNED_QUERIES 
                              ? `Maximum ${MAX_PINNED_QUERIES} pins reached. Unpin others first.` 
                              : "Pin query to top"}
                            aria-label="Pin query to top"
                            disabled={pinnedQueries.length >= MAX_PINNED_QUERIES}
                          >
                            <Pin size={14} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className="text-sm font-normal text-gray-700 line-clamp-2">
                    {(query.explanation && query.explanation.title) || 
                    query.name || 
                    (query.userQuery ? query.userQuery.substring(0, 40) + (query.userQuery.length > 40 ? '...' : '') : 'Unnamed Query')}
                  </h3>

                </button>
              );
            })}
            
            {/* Add subtle placeholders if there are few queries */}
            {filteredQueries.length > 0 && filteredQueries.length < 6 && !searchQuery && (
              <>
                {renderPlaceholders(6 - filteredQueries.length, true)}
              </>
            )}
          </div>
        )}
        
        {/* Add a subtle loading indicator while refreshing in background */}
        {isLoading && queries.length > 0 && (
          <div className="text-center py-2">
            <div className="inline-block w-4 h-4 border-b-2 border-primary rounded-full animate-spin"></div>
          </div>
        )}

      </div>
    </div>
  );
});

export default QueryLibrary;