// src/components/Docs/CategoryLabel.js
import React, { useState, useEffect, useRef } from 'react';
import { FolderTree, X } from 'lucide-react';

const CategoryLabel = ({ 
  category, 
  quote = '', 
  isManualTag = false,
  onRemove,
  disabled = false 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const labelRef = useRef(null);
  const categoryText = typeof category === 'string' ? category : category.category;

  // Handle clicking outside to close the expanded view
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (labelRef.current && !labelRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded]);

  const handleClick = () => {
    if (!disabled) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleRemove = (e) => {
    e.stopPropagation(); // Prevent expanding when clicking remove
    if (!disabled) {
      onRemove();
      setIsExpanded(false);
    }
  };

  return (
    <div ref={labelRef} className="relative inline-block">
      <span
        onClick={handleClick}
        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium whitespace-nowrap cursor-pointer transition-all duration-200 
          ${isManualTag 
            ? 'bg-primary/25 text-purple-800 hover:bg-primary/40' 
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}
          ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <FolderTree className={`flex-shrink-0 w-3 h-3 mr-1 ${
            isManualTag ? 'text-purple-600' : 'text-gray-500'
          }`} />
        <span className="truncate max-w-[300px]">{categoryText}</span>
      </span>

      {isExpanded && (
        <div className="absolute z-50 top-full left-0 mt-2 w-64 bg-white rounded-lg shadow-xl border border-gray-200 animate-fadeIn">
          <div className="p-3">
            <div className="flex items-center justify-between mb-2 pb-2 border-b border-gray-200">
              <div className="flex items-center">
                <FolderTree className={`h-4 w-4 mr-2 ${
                  isManualTag ? 'text-purple-600' : 'text-gray-500'
                }`} />
                <span className="font-medium text-gray-900">{categoryText}</span>
              </div>
              <button
                onClick={handleRemove}
                disabled={disabled}
                className="text-xs px-2 py-0.5 text-red-500 hover:text-red-600 hover:underline transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                title="Remove category"
              >
                remove
              </button>
            </div>
              {quote && (
                <div className="text-sm text-gray-700 whitespace-pre-wrap">
                  "{quote}"
                </div>
              )}
          </div>
          <div className="absolute -top-2 left-4 w-4 h-4 rotate-45 bg-white border-t border-l border-gray-200"></div>
        </div>
      )}
    </div>
  );
};

export default CategoryLabel;