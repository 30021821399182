// src/components/Docs/DocumentList.js
import React, { useState, useEffect } from 'react';
import { File, FileText, FileSpreadsheet, Image, FileJson, FileType, Tag, Download, Trash2, AlertCircle, Plus, FolderTree, X, Search } from 'lucide-react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Modal from '../Modal';
import RestoreDocuments from './RestoreDocuments';
import DocumentLabel from './DocumentLabel';
import CategoryLabel from './CategoryLabel';
import DocumentsFilter from './DocumentsFilter';
import CSVDownload from '../CSVDownload';
import { useAuth } from '../../contexts/AuthContext';
import DocumentID from './DocumentID';
import DocumentComments from './DocumentComments';



const DocumentList = ({ documents, updateCategories, batchDeleteDocuments }) => {
  const functions = getFunctions();
  const db = getFirestore();
  const [downloadingId, setDownloadingId] = useState(null);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [labelSearchTerm, setLabelSearchTerm] = useState('');
  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [deletingId, setDeletingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [availableLabels, setAvailableLabels] = useState([]);
  const [showLabelsModal, setShowLabelsModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [tempLabels, setTempLabels] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { currentUser, isAdmin } = useAuth();
  const [updatingLabels, setUpdatingLabels] = useState(false);
  const [labelUpdateError, setLabelUpdateError] = useState(null);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [tempCategories, setTempCategories] = useState([]);
  const [updatingCategories, setUpdatingCategories] = useState(false);
  const [categoryUpdateError, setCategoryUpdateError] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);


  // Fetch available labels from DocsLabels collection
  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const configDoc = await getDoc(doc(db, 'DocsLabels', 'Config'));
        if (configDoc.exists()) {
          const configData = configDoc.data();
          const versionRef = doc(db, 'DocsLabels', 'Config', 'versions', configData.currentVersion);
          const versionDoc = await getDoc(versionRef);
          if (versionDoc.exists()) {
            setAvailableLabels(versionDoc.data().labels || []);
          }
        }
      } catch (error) {
        console.error('Error fetching labels:', error);
      }
    };

    fetchLabels();
  }, [db]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const configDoc = await getDoc(doc(db, 'DocsCategories', 'Config'));
        if (configDoc.exists()) {
          const configData = configDoc.data();
          const versionRef = doc(db, 'DocsCategories', 'Config', 'versions', configData.currentVersion);
          const versionDoc = await getDoc(versionRef);
          if (versionDoc.exists()) {
            setAvailableCategories(versionDoc.data().categories || []);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchCategories();
  }, [db]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDocs(filteredDocuments.map(doc => doc.id));
    } else {
      setSelectedDocs([]);
    }
  };

  const handleSelectDoc = (docId) => {
    setSelectedDocs(prev => {
      if (prev.includes(docId)) {
        return prev.filter(id => id !== docId);
      } else {
        return [...prev, docId];
      }
    });
  };
  
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return timestamp instanceof Date ? timestamp.toLocaleString() : timestamp.toDate().toLocaleString();
  };

  const calculateTotalSize = () => {
    return documents.reduce((acc, doc) => acc + doc.size, 0);
  };

  const formatTotalSize = (bytes) => {
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
  };

  const getSimpleFileType = (mimeType) => {
    const mimeTypeMap = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'application/msword': 'DOC',
      'application/pdf': 'PDF',
      'text/plain': 'TXT',
      'image/jpeg': 'JPG',
      'image/png': 'PNG'
    };

    return mimeTypeMap[mimeType] || mimeType.split('/').pop().toUpperCase();
  };

  const getFileIcon = (mimeType) => {
    const iconMap = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': FileText,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileSpreadsheet,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': FileText,
      'application/msword': FileText,
      'application/pdf': FileType,
      'text/plain': FileText,
      'image/jpeg': Image,
      'image/png': Image,
      'image/gif': Image,
      'text/csv': FileSpreadsheet,
      'application/json': FileJson,
      'text/html': FileText,
      'text/javascript': FileText,
      'application/xml': FileText
    };
  
    return iconMap[mimeType] || File;
  };

  const handleDownload = async (doc) => {
    try {
      setDownloadingId(doc.id);
      const generateSignedUrl = httpsCallable(functions, 'generateSignedUrl');
      const result = await generateSignedUrl({ documentId: doc.id });
      
      if (result.data.success) {
        const link = document.createElement('a');
        link.href = result.data.url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Download error:', error);
      alert('Error downloading file. Please ensure you are logged in and try again.');
    } finally {
      setDownloadingId(null);
    }
  };

  const handleDeleteClick = (doc) => {
    if (!isAdmin) return;
    setDeletingId(doc.id);
    setShowDeleteModal(true);
    setDeleteError(null);
  };

  const handleDeleteConfirm = async () => {
    if (!isAdmin) return;
    try {
      if (deletingId === 'batch') {
        await batchDeleteDocuments(selectedDocs);
        setSelectedDocs([]);
      } else {
        const deleteDocument = httpsCallable(functions, 'deleteDocument');
        await deleteDocument({ documentId: deletingId });
      }
      setShowDeleteModal(false);
      setDeletingId(null);
      setDeleteError(null);
    } catch (error) {
      console.error('Delete error:', error);
      setDeleteError(error.message || 'Failed to delete document');
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setDeletingId(null);
    setDeleteError(null);
  };

  const handleLabelClick = (doc) => {
    setSelectedDocument(doc);
    setTempLabels(doc.labels || []);
    setShowLabelsModal(true);
  };

  const handleLabelToggle = (label) => {
    const isCurrentlySelected = tempLabels.some(l => 
      typeof l === 'string' ? l === label : l.label === label
    );
    const updatedLabels = isCurrentlySelected
      ? tempLabels.filter(l => (typeof l === 'string' ? l !== label : l.label !== label))
      : [...tempLabels, { label, quote: '' }];
    setTempLabels(updatedLabels);
  };

  const handleRemoveLabel = async (doc, labelToRemove) => {
    setUpdatingLabels(true);
    setLabelUpdateError(null);
    
    try {
      const updatedLabels = doc.labels.filter(labelObj => {
        const currentLabel = typeof labelObj === 'string' ? labelObj : labelObj.label;
        return currentLabel !== (typeof labelToRemove === 'string' ? labelToRemove : labelToRemove.label);
      });
      
      const updateLabels = httpsCallable(functions, 'updateDocumentLabels');
      await updateLabels({ 
        documentId: doc.id, 
        labels: updatedLabels 
      });
      
      // Update the local state with the new labels
      doc.labels = updatedLabels;
      
    } catch (error) {
      console.error('Error removing label:', error);
      setLabelUpdateError('Failed to remove label. Please try again.');
    } finally {
      setUpdatingLabels(false);
    }
  };

  const handleSaveLabels = async () => {
    if (!selectedDocument) return;
    
    setUpdatingLabels(true);
    setLabelUpdateError(null);
    
    try {
      const updateLabels = httpsCallable(functions, 'updateDocumentLabels');
      await updateLabels({ 
        documentId: selectedDocument.id, 
        labels: tempLabels 
      });
      
      setSelectedDocument(prev => ({
        ...prev,
        labels: tempLabels
      }));
      
      setShowLabelsModal(false);
    } catch (error) {
      console.error('Error updating labels:', error);
      setLabelUpdateError('Failed to update labels. Please try again.');
    } finally {
      setUpdatingLabels(false);
    }
  };

  // When setting initial temp categories
  const handleCategoryClick = (doc) => {
    setSelectedDocument(doc);
    // Ensure categories are in object format
    const formattedCategories = (doc.categories || []).map(cat => 
      typeof cat === 'string' ? { category: cat, quote: '' } : cat
    );
    setTempCategories(formattedCategories);
    setShowCategoriesModal(true);
  };
  
  const handleCategoryToggle = (category) => {
    setTempCategories(prev => {
      const isCurrentlySelected = prev.some(c => 
        (typeof c === 'string' ? c : c.category) === category
      );
      
      if (isCurrentlySelected) {
        return prev.filter(c => 
          (typeof c === 'string' ? c : c.category) !== category
        );
      } else {
        // Always add as an object with empty quote
        return [...prev, { category, quote: '' }];
      }
    });
  };
  
  const handleSaveCategories = async () => {
    if (!selectedDocument) return;
    
    setUpdatingCategories(true);
    setCategoryUpdateError(null);
    
    try {
      // Ensure all categories are in object format before saving
      const formattedCategories = tempCategories.map(cat => 
        typeof cat === 'string' ? { category: cat, quote: '' } : cat
      );
      
      await updateCategories(selectedDocument.id, formattedCategories);
      
      // Update local state with the formatted categories
      setSelectedDocument(prev => ({
        ...prev,
        categories: formattedCategories
      }));
      
      setShowCategoriesModal(false);
    } catch (error) {
      console.error('Error updating categories:', error);
      setCategoryUpdateError('Failed to update categories. Please try again.');
    } finally {
      setUpdatingCategories(false);
    }
  };
  
  const handleRemoveCategory = async (doc, categoryToRemove) => {
    setUpdatingCategories(true);
    setCategoryUpdateError(null);
    
    try {
      // Ensure we're working with the full category objects
      const currentCategories = doc.categories || [];
      const updatedCategories = currentCategories.filter(c => {
        const currentCat = typeof c === 'string' ? c : c.category;
        const removeCat = typeof categoryToRemove === 'string' ? 
          categoryToRemove : categoryToRemove.category;
        return currentCat !== removeCat;
      });
      
      // Ensure all categories are in object format
      const formattedCategories = updatedCategories.map(cat => 
        typeof cat === 'string' ? { category: cat, quote: '' } : cat
      );
      
      await updateCategories(doc.id, formattedCategories);
      
      // Update the local document state
      doc.categories = formattedCategories;
    } catch (error) {
      console.error('Error removing category:', error);
      setCategoryUpdateError('Failed to remove category. Please try again.');
    } finally {
      setUpdatingCategories(false);
    }
  };
  

  const filteredDocuments = documents.filter(doc => {
    const searchMatch = searchTerm === '' || 
    doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doc.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (doc.id_field && String(doc.id_field).toLowerCase().includes(searchTerm.toLowerCase()));

    const filterMatch = selectedFilters.length === 0 || 
      selectedFilters.every(filter => 
        doc.labels?.some(label => filter === (typeof label === 'string' ? label : label.label)) ||
        doc.categories?.some(category => filter === (typeof category === 'string' ? category : category.category))
      );

    return searchMatch && filterMatch;
  });

  return (
    <>
      <div className="bg-white rounded-lg shadow mt-4">
        <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
          <div className="flex items-center gap-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Documents ({filteredDocuments.length})
            </h3>
            {selectedDocs.length > 0 && (
              <button
                onClick={() => {
                  setDeletingId('batch');
                  setShowDeleteModal(true);
                }}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
              >
                <Trash2 className="h-4 w-4 mr-1.5" />
                Delete Selected ({selectedDocs.length})
              </button>
            )}
            <span className="text-sm text-gray-500">Total Size: {formatTotalSize(calculateTotalSize())}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4">
            {isAdmin && <RestoreDocuments />}
            <div className="relative flex-grow max-w-xs">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400"/>
              <input
                type="text"
                className="w-full pl-9 pr-4 py-2 text-sm border-b border-gray-300 focus:border-secondary focus:ring-0 outline-none"
                placeholder="Search by name or ID..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
              <DocumentsFilter
                availableLabels={availableLabels}
                availableCategories={availableCategories}
                selectedFilters={selectedFilters}
                onFilterChange={setSelectedFilters}
              />
              <div className="flex items-center gap-4">
                <CSVDownload 
                  data={filteredDocuments.map(doc => {
                    const matchStandard = doc.name.match(/\b(\d{2,4}-\d{3,4})\b/);
                    const matchAlt = doc.name.match(/\b(\d+[.-]\d{4})\b/);
                    const id = matchStandard ? matchStandard[1] : 
                              matchAlt ? `${matchAlt[1].split(/[.-]/)[0]}-${matchAlt[1].split(/[.-]/)[1]}` : 'N/A';
                      return {
                        ID: doc.id_field || id,
                      Name: doc.name,
                      Type: getSimpleFileType(doc.type),
                      Size: `${(doc.size / 1024 / 1024).toFixed(2)} MB`,
                      Uploaded: doc.uploadedAt ? formatDate(doc.uploadedAt) : 'N/A',
                      'Uploaded By': doc.uploadedBy || 'N/A',
                      'Last Labeled': doc.labeledAt ? formatDate(doc.labeledAt) : 'Never',
                      Labels: doc.labels ? doc.labels.map(l => typeof l === 'string' ? l : l.label).join('; ') : '',
                      Categories: doc.categories ? doc.categories.map(c => typeof c === 'string' ? c : c.category).join('; ') : ''
                    };
                  })}
                  filename="documents.csv"
                />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto max-h-[calc(100vh-250px)]">
            <table className="min-w-full divide-y divide-gray-200 relative">
            <thead className="bg-white border-b-2 border-gray-300 sticky top-0 z-10">
              <tr>
                <th scope="col" className="px-3 py-3">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={selectedDocs.length === filteredDocuments.length && filteredDocuments.length > 0}
                    onChange={handleSelectAll}
                  />
                </th>
                <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  #
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  ID
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Categories
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Labels
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Comments
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Size
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Uploaded
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Uploaded By
                </th>
                <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                  Last Labeled
                </th>
                {isAdmin && (
                  <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {filteredDocuments.map((doc, index) => (
                  <tr key={doc.id}>
                  <td className="px-3 py-4">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={selectedDocs.includes(doc.id)}
                      onChange={() => handleSelectDoc(doc.id)}
                    />
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap max-w-[200px]">
                    <div className="truncate">
                      <DocumentID document={doc} />
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                      <div 
                        className="flex items-center cursor-pointer hover:text-blue-600"
                        onClick={() => handleDownload(doc)}
                      >
                        {React.createElement(getFileIcon(doc.type), { 
                          className: `h-5 w-5 mr-2 ${(() => {
                            switch (doc.type) {
                              case 'application/pdf':
                                return 'text-red-500';
                              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                              case 'text/csv':
                                return 'text-emerald-600';
                              case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                              case 'application/msword':
                                return 'text-blue-600';
                              case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                                return 'text-orange-500';
                              case 'image/jpeg':
                              case 'image/png':
                              case 'image/gif':
                                return 'text-purple-500';
                              case 'application/json':
                              case 'text/javascript':
                                return 'text-yellow-600';
                              default:
                                return 'text-gray-400';
                            }
                          })()}`
                        })}
                        <div className="text-sm font-medium text-gray-900 hover:text-blue-600 flex items-center">
                          <span title={doc.name}>
                            {doc.name.length > 30 ? `${doc.name.substring(0, 30)}...` : doc.name}
                          </span>
                          {downloadingId === doc.id ? (
                            <span className="ml-2 animate-spin">⌛</span>
                          ) : (
                            <Download className="h-4 w-4 ml-2 opacity-50" />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {getSimpleFileType(doc.type)}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                    <div className="flex flex-col gap-2">
                      <button
                        onClick={() => handleCategoryClick(doc)}
                        className="inline-flex items-center px-2.5 py-1.5 rounded text-sm font-medium text-blue-600 hover:bg-blue-50 transition-colors w-fit"
                        title="Manage categories"
                      >
                        <Plus className="h-3.5 w-3.5 mr-1.5" />
                        {doc.categories?.length ? 'Edit' : 'Add'}
                      </button>
                      <div className="flex flex-wrap gap-2 max-w-[200px]">
                        {doc.categories?.map((categoryItem, index) => {
                          const category = typeof categoryItem === 'object' ? categoryItem.category : categoryItem;
                          const quote = typeof categoryItem === 'object' ? categoryItem.quote : '';
                          const isManualTag = typeof categoryItem === 'string' || !categoryItem.quote;
                          return (
                            <CategoryLabel
                              key={index}
                              category={category}
                              quote={quote}
                              isManualTag={isManualTag}
                              onRemove={() => handleRemoveCategory(doc, categoryItem)}
                              disabled={updatingCategories}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-wrap gap-2 max-w-[200px]">
                      <button
                        onClick={() => handleLabelClick(doc)}
                        className="inline-flex items-center px-2.5 py-1.5 rounded text-sm font-medium text-blue-600 hover:bg-blue-50 transition-colors w-fit"
                        title="Manage labels"
                      >
                        <Plus className="h-3.5 w-3.5 mr-1.5" />
                        {doc.labels?.length ? 'Edit' : 'Add'}
                      </button>
                        {doc.labels?.map((labelObj, index) => {
                          const isManualTag = typeof labelObj === 'string' || !labelObj.quote;
                          return (
                            <DocumentLabel
                              key={index}
                              label={labelObj}
                              quote={typeof labelObj === 'string' ? '' : labelObj.quote}
                              isManualTag={isManualTag}
                              onRemove={() => handleRemoveLabel(doc, labelObj)}
                              disabled={updatingLabels}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 relative">
                    <DocumentComments document={doc} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {(doc.size / 1024 / 1024).toFixed(2)} MB
                    </div>
                  </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {(doc.size / 1024 / 1024).toFixed(2)} MB
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {formatDate(doc.uploadedAt)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {doc.uploadedBy || 'N/A'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {doc.labeledAt ? formatDate(doc.labeledAt) : 'Never'}
                      </div>
                    </td>
                    {isAdmin && (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() => handleDeleteClick(doc)}
                          className="text-gray-500 hover:text-red-600 focus:outline-none"
                          title="Delete document"
                          disabled={deletingId === doc.id}
                        >
                          {deletingId === doc.id ? (
                            <div className="animate-spin h-5 w-5">⌛</div>
                          ) : (
                            <Trash2 className="h-5 w-5" />
                          )}
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
                {filteredDocuments.length === 0 && (
                  <tr>
                    <td colSpan={isAdmin ? "11" : "10"} className="px-6 py-4 text-center text-sm text-gray-500">
                      {documents.length === 0 ? 'No documents uploaded yet' : 'No documents match the selected filters'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteModal}
        onClose={handleDeleteCancel}
        title="Delete Document"
        showButtons={false}
      >
        <div className="p-6">
          <div className="mb-4">
            <p className="text-gray-700">
              Are you sure you want to delete this document? This action cannot be undone.
            </p>
            {deleteError && (
              <div className="mt-2 flex items-center text-red-600">
                <AlertCircle className="h-5 w-5 mr-2" />
                <p className="text-sm">{deleteError}</p>
              </div>
            )}
          </div>
          <div className="flex justify-end space-x-3">
            <button
              onClick={handleDeleteCancel}
              disabled={deletingId === 'batch-in-progress'}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900 disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteConfirm}
              disabled={deletingId === 'batch-in-progress'}
              className="px-4 py-2 text-sm text-white bg-red-600 rounded hover:bg-red-700 disabled:opacity-50 inline-flex items-center"
            >
              {deletingId === 'batch-in-progress' ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Deleting...
                </>
              ) : (
                'Delete'
              )}
            </button>
          </div>
        </div>
      </Modal>

      {/* Labels Management Modal */}
      <Modal
        isOpen={showLabelsModal}
        onClose={() => {
          setShowLabelsModal(false);
          setLabelUpdateError(null);
          setTempLabels([]);
          setLabelSearchTerm('');
        }}
        title="Manage Labels"
        showButtons={false}
      >
        <div className="p-6">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">
                Select labels for: {selectedDocument?.name}
              </p>
              {updatingLabels && (
                <div className="text-sm text-blue-600 flex items-center">
                  <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  Updating...
                </div>
              )}
            </div>
            
            {labelUpdateError && (
              <div className="text-sm text-red-600 bg-red-50 p-2 rounded flex items-center">
                <AlertCircle className="h-4 w-4 mr-2" />
                {labelUpdateError}
              </div>
            )}

            <div className="relative mb-4">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400"/>
              <input
                type="text"
                className="w-full pl-9 pr-4 py-2 text-sm border-b border-gray-300 focus:border-blue-500 focus:ring-0 outline-none"
                placeholder="Search labels..."
                value={labelSearchTerm}
                onChange={(e) => setLabelSearchTerm(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {availableLabels
                .filter(label => label.toLowerCase().includes(labelSearchTerm.toLowerCase()))
                .map((label, index) => {
                const isSelected = tempLabels.some(l => 
                  typeof l === 'string' ? l === label : l.label === label
                );
                return (
                  <button
                    key={index}
                    onClick={() => handleLabelToggle(label)}
                    disabled={updatingLabels}
                    className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                      isSelected
                        ? 'bg-blue-100 text-blue-800 hover:bg-blue-200 ring-2 ring-blue-300'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    } ${updatingLabels ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                  >
                    <Tag className={`h-3.5 w-3.5 mr-1.5 ${isSelected ? 'text-blue-600' : 'text-gray-500'}`} />
                    {label}
                  </button>
                );
              })}
              {availableLabels.length === 0 && (
                <p className="text-sm text-gray-500 italic">
                  No labels available. Create labels in the Labels tab first.
                </p>
              )}
            </div>
            
            <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
              <button
                onClick={() => {
                  setShowLabelsModal(false);
                  setLabelUpdateError(null);
                  setTempLabels([]);
                }}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
                disabled={updatingLabels}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveLabels}
                disabled={updatingLabels}
                className="px-4 py-2 text-sm text-white bg-blue-600 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Categories Management Modal */}
      <Modal
        isOpen={showCategoriesModal}
        onClose={() => {
          setShowCategoriesModal(false);
          setCategoryUpdateError(null);
          setTempCategories([]);
          setCategorySearchTerm('');
        }}
        title="Manage Categories"
        showButtons={false}
      >
        <div className="p-6">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">
                Select categories for: {selectedDocument?.name}
              </p>
              {updatingCategories && (
                <div className="text-sm text-blue-600 flex items-center">
                  <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  Updating...
                </div>
              )}
            </div>
            
            {categoryUpdateError && (
              <div className="text-sm text-red-600 bg-red-50 p-2 rounded flex items-center">
                <AlertCircle className="h-4 w-4 mr-2" />
                {categoryUpdateError}
              </div>
            )}

            <div className="relative mb-4">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400"/>
              <input
                type="text"
                className="w-full pl-9 pr-4 py-2 text-sm border-b border-gray-300 focus:border-blue-500 focus:ring-0 outline-none"
                placeholder="Search categories..."
                value={categorySearchTerm}
                onChange={(e) => setCategorySearchTerm(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {availableCategories
                .filter(category => category.toLowerCase().includes(categorySearchTerm.toLowerCase()))
                .map((category, index) => {
                const isSelected = tempCategories.some(c => 
                  typeof c === 'string' ? c === category : c.category === category
                );
                return (
                  <button
                    key={index}
                    onClick={() => handleCategoryToggle(category)}
                    disabled={updatingCategories}
                    className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                      isSelected
                        ? 'bg-blue-100 text-blue-800 hover:bg-blue-200 ring-2 ring-blue-300'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    } ${updatingCategories ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                  >
                    <FolderTree className={`h-3.5 w-3.5 mr-1.5 ${isSelected ? 'text-blue-600' : 'text-gray-500'}`} />
                    {category}
                  </button>
                );
              })}
              {availableCategories.length === 0 && (
                <p className="text-sm text-gray-500 italic">
                  No categories available. Create categories in the Categories tab first.
                </p>
              )}
            </div>
            
            <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
              <button
                onClick={() => {
                  setShowCategoriesModal(false);
                  setCategoryUpdateError(null);
                  setTempCategories([]);
                }}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
                disabled={updatingCategories}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveCategories}
                disabled={updatingCategories}
                className="px-4 py-2 text-sm text-white bg-blue-600 rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocumentList;