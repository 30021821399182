// src/components/Docs/LabelGeneration.js
import React, { useState, useEffect } from 'react';
import Card from '../Card';
import { Tag, RefreshCw, X, ChevronDown, ChevronRight, Check } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

const LabelGeneration = ({ suggestedLabels, setSuggestedLabels }) => {
  // Load from localStorage when component mounts
  useEffect(() => {
    const savedLabels = localStorage.getItem('suggestedLabels');
    if (savedLabels) {
      setSuggestedLabels(JSON.parse(savedLabels));
    }
  }, []);

  // Save to localStorage whenever suggestedLabels changes
  useEffect(() => {
    localStorage.setItem('suggestedLabels', JSON.stringify(suggestedLabels));
  }, [suggestedLabels]);  
  const [mode, setMode] = useState('processing');
  const [results, setResults] = useState(null);
  const [batchProgress, setBatchProgress] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isProgressExpanded, setIsProgressExpanded] = useState(true);
  const [currentBatchId, setCurrentBatchId] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const { currentUser } = useAuth();


  const functions = getFunctions();

  // Monitor batch progress
  useEffect(() => {
    if (!currentBatchId) return;
  
    localStorage.setItem('labelGenerationBatchId', currentBatchId);
    
    const unsubscribe = onSnapshot(
      doc(db, 'batchLabelGeneration', currentBatchId),
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setBatchProgress(data || null);
          
          if (data.suggestedLabels) {
            setSuggestedLabels(prev => {
              const newLabels = data.suggestedLabels.filter(
                label => !prev.includes(label)
              );
              return [...prev, ...newLabels];
            });
          }
  
          if (data.status === 'completed') {
            setIsProcessing(false);
          }
        }
      },
      (error) => {
        console.error('Error monitoring batch:', error);
        setError('Failed to monitor progress');
      }
    );
  
    return () => {
      unsubscribe();
    };
  }, [currentBatchId, setSuggestedLabels]);

  const handleGenerateLabels = async () => {
    if (isProcessing) return;
  
    try {
      // Clear previous state
      setCurrentBatchId(null);
      setError(null);
      setResults(null);
      setSuggestedLabels([]);
      setIsProcessing(true);
      setBatchProgress({
        totalDocuments: 0,
        processedDocuments: 0,
        failedDocuments: 0,
        status: 'initializing',
        currentBatch: 0,
        totalBatches: 0,
        errors: []
      });
  
      // Initialize batch processing
      const initiateLabelGeneration = httpsCallable(functions, 'initiateLabelGeneration');
      const processNextBatch = httpsCallable(functions, 'processNextLabelBatch');
      
      const response = await initiateLabelGeneration({ 
        mode,
        userId: currentUser.uid 
      });
      
      if (response.data.success && response.data.batchProcessingId) {
        setCurrentBatchId(response.data.batchProcessingId);
        
        await processNextBatch({ 
          batchId: response.data.batchProcessingId,
          userId: currentUser.uid
        });
      } else {
        throw new Error('Failed to initialize label generation');
      }
    } catch (err) {
      console.error('Label generation error:', err);
      setError(err.message || 'Failed to start label generation');
      setIsProcessing(false);
      setCurrentBatchId(null);
    }
  };

  const handleApproveSuggestedLabel = async (labelToApprove) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Adding label...' });

      // Get current labels config
      const configDoc = await getDoc(doc(db, 'DocsLabels', 'Config'));
      const currentVersionId = configDoc.data()?.currentVersion;
      
      if (!currentVersionId) {
        throw new Error('No current version found');
      }

      // Get current version
      const versionDoc = await getDoc(doc(db, 'DocsLabels', 'Config', 'versions', currentVersionId));
      const currentLabels = versionDoc.data()?.labels || [];

      // Add new label if it doesn't exist
      if (!currentLabels.includes(labelToApprove)) {
        // Remove from suggestions
        setSuggestedLabels(prev => prev.filter(label => label !== labelToApprove));
        
        // Update version with new label
        const updatedLabels = [...currentLabels, labelToApprove];
        await updateDoc(doc(db, 'DocsLabels', 'Config', 'versions', currentVersionId), {
          labels: updatedLabels
        });

        setSaveStatus({ type: 'success', message: 'Label added successfully!' });
      } else {
        setSaveStatus({ type: 'info', message: 'Label already exists' });
      }
    } catch (error) {
      console.error('Error approving label:', error);
      setSaveStatus({ type: 'error', message: 'Failed to add label' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleRejectSuggestedLabel = async (labelToReject) => {
    try {
      // Remove from local suggestions
      setSuggestedLabels(prev => prev.filter(label => label !== labelToReject));
      
      // Update batch document if it exists
      if (currentBatchId) {
        const batchDoc = await getDoc(doc(db, 'batchLabelGeneration', currentBatchId));
        if (batchDoc.exists()) {
          const currentLabels = batchDoc.data().suggestedLabels || [];
          const updatedLabels = currentLabels.filter(label => label !== labelToReject);
          
          await updateDoc(doc(db, 'batchLabelGeneration', currentBatchId), {
            suggestedLabels: updatedLabels
          });
        }
      }
    } catch (error) {
      console.error('Error rejecting label:', error);
      setSaveStatus({ 
        type: 'error', 
        message: 'Failed to reject label'
      });
    }
  };

  const getProgressDisplay = () => {
    if (!batchProgress) return 'Initializing...';
    
    const { processedDocuments, totalDocuments, status } = batchProgress;
    
    if (status === 'completed') return 'Completed';
    if (status === 'error') return 'Error';
    
    if (totalDocuments === 0) return 'Preparing...';
    
    const percentage = Math.round((processedDocuments / totalDocuments) * 100);
    return `Processing: ${percentage}% (${processedDocuments}/${totalDocuments})`;
  };

  return (
    <div className="w-full space-y-4">
      {/* Status Messages */}
      {saveStatus.message && (
        <div className={`px-4 py-2 rounded ${
          saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
          saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
          'bg-blue-100 text-blue-700'
        }`}>
          {saveStatus.message}
        </div>
      )}

      <Card className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <select
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              className="px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isProcessing}
            >
              <option value="processing">New Only</option>
              <option value="all">All Documents</option>
            </select>
            <button
              onClick={handleGenerateLabels}
              disabled={isProcessing}
              className={`flex items-center gap-2 px-4 py-2 rounded-md text-white transition-colors ${
                isProcessing
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-primary hover:bg-opacity-90'
              }`}
            >
              {isProcessing ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin" />
                  <span>{getProgressDisplay()}</span>
                </>
              ) : (
                <>
                  <Tag className="h-4 w-4" />
                  Generate Labels
                </>
              )}
            </button>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md flex items-center gap-2">
            <X className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        {/* Progress Section */}
        {isProcessing && batchProgress && (
          <div className="mb-4">
            <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
              <div 
                className="bg-blue-500 h-2 rounded-full transition-all duration-500"
                style={{ 
                  width: `${Math.round((batchProgress.processedDocuments / Math.max(batchProgress.totalDocuments, 1)) * 100)}%` 
                }}
              />
            </div>
            <div className="text-sm text-gray-600">
              {getProgressDisplay()}
            </div>
          </div>
        )}

        {/* Suggested Labels Section */}
        {suggestedLabels.length > 0 && (
          <div className="mt-6">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Suggested Labels ({suggestedLabels.length})
            </h4>
            <div className="flex flex-wrap gap-2">
              {suggestedLabels.map((label, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 rounded-full group"
                >
                  <Tag className="h-4 w-4 text-blue-500" />
                  <span className="text-blue-700">{label}</span>
                  <div className="flex items-center gap-1">
                    <button
                      onClick={() => handleApproveSuggestedLabel(label)}
                      className="p-1 hover:bg-blue-100 rounded-full"
                      title="Approve"
                      disabled={saveStatus.type === 'loading'}
                    >
                      {saveStatus.type === 'loading' ? (
                        <RefreshCw className="h-4 w-4 text-green-600 animate-spin" />
                      ) : (
                        <Check className="h-4 w-4 text-green-600" />
                      )}
                    </button>
                    <button
                      onClick={() => handleRejectSuggestedLabel(label)}
                      className="p-1 hover:bg-blue-100 rounded-full"
                      title="Reject"
                    >
                      <X className="h-4 w-4 text-red-500" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default LabelGeneration;