// src/components/Review/DocAnalysisPrompt.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, collection, query, orderBy, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import TextEditor from '../TextEditor';
import { useAuth } from '../../contexts/AuthContext';
import PromptVersionList from '../PromptVersionList';
import { convertMarkdownToHtml, convertHtmlToMarkdown } from '../markdownConverter';

const DocAnalysisPrompt = () => {
  const [configData, setConfigData] = useState({
    prompt: '',
    promptId: ''
  });
  const [previewData, setPreviewData] = useState({
    prompt: null
  });
  const [versions, setVersions] = useState({
    prompt: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const { currentUser } = useAuth();

  const formatText = (text) => {
    if (!text) return '';
    const lines = text.split('\n');
    return lines
      .map(line => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return null;
        if (trimmedLine.startsWith('•') || trimmedLine.startsWith('-')) {
          return trimmedLine;
        }
        return trimmedLine;
      })
      .filter(Boolean)
      .join('\n');
  };

  const fetchVersions = async () => {
    try {
      const versionsRef = collection(db, 'DocsConfig', 'DocAnalysisPrompts', 'versions');
      const versionsQuery = query(versionsRef, orderBy('versionNumber', 'desc'));
      const versionsSnapshot = await getDocs(versionsQuery);
      
      return versionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching versions:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoading(true);
        
        // Fetch versions
        const promptVersions = await fetchVersions();
        setVersions({
          prompt: promptVersions
        });

        // Fetch Config document
        const configDoc = await getDoc(doc(db, 'DocsConfig', 'Config'));
        if (configDoc.exists()) {
          const configDataFromDb = configDoc.data();
          
          // Get the current version content
          const currentPrompt = promptVersions.find(v => v.id === configDataFromDb.doc_analysis_prompt)?.content || '';

          const initialConfig = {
            prompt: convertMarkdownToHtml(currentPrompt),
            promptId: configDataFromDb.doc_analysis_prompt
          };

          setConfigData(initialConfig);
          setPreviewData({
            prompt: initialConfig.prompt
          });
        }

      } catch (err) {
        console.error('Error fetching configuration:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  const handleVersionSelect = (version) => {
    setPreviewData(prev => ({
      ...prev,
      prompt: convertMarkdownToHtml(version.content)
    }));
  };

  const handleVersionApply = async (version) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Applying version...' });
      
      // Update Config document
      const configRef = doc(db, 'DocsConfig', 'Config');
      
      // Update the version document
      const versionRef = doc(db, 'DocsConfig', 'DocAnalysisPrompts', 'versions', version.id);
      await updateDoc(versionRef, {
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      });
  
      // Update config to point to this version
      await updateDoc(configRef, {
        doc_analysis_prompt: version.id
      });
  
      // Update states
      const newContent = convertMarkdownToHtml(version.content);
      setConfigData(prev => ({
        ...prev,
        prompt: newContent,
        promptId: version.id
      }));
      setPreviewData(prev => ({
        ...prev,
        prompt: newContent
      }));
  
      // Update versions state
      setVersions(prev => ({
        ...prev,
        prompt: prev.prompt.map(v => 
          v.id === version.id 
            ? { 
                ...v, 
                lastAppliedAt: new Date(),
                lastAppliedBy: currentUser?.email || 'unknown'
              } 
            : v
        )
      }));
  
      setSaveStatus({ type: 'success', message: `Applied version ${version.versionNumber}` });
    } catch (error) {
      console.error('Error applying version:', error);
      setSaveStatus({ type: 'error', message: 'Failed to apply version' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleSave = async (content) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Saving new version...' });
      
      const markdownContent = convertHtmlToMarkdown(content);
  
      // Get the next version number
      const currentVersions = versions.prompt || [];
      const nextVersionNumber = currentVersions.length > 0 ? 
        Math.max(...currentVersions.map(v => v.versionNumber || 0)) + 1 : 1;
  
      // Create new version document
      const versionsRef = collection(db, 'DocsConfig', 'DocAnalysisPrompts', 'versions');
      const newVersion = {
        content: markdownContent,
        createdAt: serverTimestamp(),
        createdBy: currentUser?.email || 'unknown',
        versionNumber: nextVersionNumber,
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      };
  
      const newVersionRef = await addDoc(versionsRef, newVersion);
  
      // Update Config document
      const configRef = doc(db, 'DocsConfig', 'Config');
      await updateDoc(configRef, {
        doc_analysis_prompt: newVersionRef.id
      });
  
      // Update local state
      const newVersionWithId = {
        id: newVersionRef.id,
        ...newVersion,
        createdAt: new Date(),
        lastAppliedAt: new Date()
      };
  
      setVersions(prev => ({
        ...prev,
        prompt: [newVersionWithId, ...(prev.prompt || [])]
      }));
  
      setConfigData(prev => ({
        ...prev,
        prompt: content,
        promptId: newVersionRef.id
      }));
      setPreviewData(prev => ({
        ...prev,
        prompt: content
      }));
  
      setSaveStatus({ type: 'success', message: 'Saved and activated new version!' });
    } catch (err) {
      console.error('Error saving:', err);
      setSaveStatus({ type: 'error', message: `Failed to save changes: ${err.message}` });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  const handleCancel = () => {
    const currentVersion = versions.prompt?.find(v => v.id === configData.promptId);
    if (currentVersion) {
      setPreviewData(prev => ({
        ...prev,
        prompt: convertMarkdownToHtml(currentVersion.content)
      }));
    }
    setSaveStatus({ type: '', message: '' });
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        {saveStatus.message && (
          <div className={`px-4 py-2 rounded ${
            saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
            saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
            'bg-blue-100 text-blue-700'
          }`}>
            {saveStatus.message}
          </div>
        )}
      </div>

      <div className="px-4 pb-4 border-b">
        <PromptVersionList
          versions={versions.prompt}
          currentVersion={configData.promptId}
          onVersionSelect={handleVersionSelect}
          onVersionApply={handleVersionApply}
          isLoading={isLoading}
        />
      </div>
      <div className="flex-1">
        <TextEditor
          title=""
          initialContent={formatText(previewData.prompt)}
          onSave={handleSave}
          onCancel={handleCancel}
          height="h-full"
        />
      </div>
    </>
  );
};

export default DocAnalysisPrompt;