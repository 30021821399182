import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Card from './Card';
import { Plus, Trash2 } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ProposedQuestionsManagement = () => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const docRef = doc(db, 'appConfiguration', 'proposedQuestions');
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists() && docSnap.data().questions) {
        setQuestions(docSnap.data().questions);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      setError('Failed to load questions');
    } finally {
      setLoading(false);
    }
  };

  const saveQuestions = async (newQuestions) => {
    try {
      await setDoc(doc(db, 'appConfiguration', 'proposedQuestions'), {
        questions: newQuestions,
        updatedAt: new Date()
      });
      
      // Clear local storage cache so ProposedQuestions component will fetch fresh data
      localStorage.removeItem('proposed_questions_cache');
    } catch (error) {
      console.error('Error saving questions:', error);
      setError('Failed to save questions');
    }
  };

  const addQuestion = async () => {
    if (newQuestion.trim()) {
      const newQuestions = [...questions, newQuestion.trim()];
      setQuestions(newQuestions);
      setNewQuestion('');
      await saveQuestions(newQuestions);
    }
  };

  const removeQuestion = async (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
    await saveQuestions(newQuestions);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newQuestion.trim()) {
      addQuestion();
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    setQuestions(reorderedQuestions);
    await saveQuestions(reorderedQuestions);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <Card className="p-6">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">Manage Proposed Questions</h2>
        </div>

        {error && (
          <div className="p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <div className="flex items-center gap-2">
          <input
            type="text"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a new question..."
            className="flex-1 p-2 border rounded-md"
          />
          <button
            onClick={addQuestion}
            disabled={!newQuestion.trim()}
            className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 disabled:opacity-50"
          >
            <Plus size={16} />
            Add
          </button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <div 
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {questions.map((question, index) => (
                  <Draggable 
                    key={index} 
                    draggableId={`question-${index}`} 
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`flex items-center justify-between p-3 rounded-md group
                          ${snapshot.isDragging ? 'bg-gray-100' : 'bg-gray-50'}`}
                      >
                        <span className="flex-1">{question}</span>
                        <button
                          onClick={() => removeQuestion(index)}
                          className="text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {questions.length === 0 && (
          <div className="text-center text-gray-500 p-4">
            No questions added yet
          </div>
        )}
      </div>
    </Card>
  );
};

export default ProposedQuestionsManagement;