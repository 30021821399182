import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import QueryExplanation from './QueryExplanation';
import ResultView from './ResultView';
import ControlPanel from './ControlPanel';
import ActionPanel from './ActionPanel';
import Card from '../Card';
import AlignedButtonLayout from './AlignedButtonLayout';
import QueryMetadata from './QueryMetadata';
import * as XLSX from 'xlsx';
import { X, Info, ChevronUp, ChevronDown, MessageSquarePlus, Filter, Download } from 'lucide-react';
import QueryInput from './QueryInput';
import useFollowUpQuery from '../../hooks/useFollowUpQuery';
import { useConfig } from '../../contexts/ConfigContext';
import { processData, formatNumber } from './dataProcessing';
import DownloadAllButton from './DownloadAllButton';



const IntermediatePopup = ({ position, onClick }) => (
  <div 
    className="fixed z-50 cursor-pointer intermediate-popup"
    style={{ 
      left: `${position.x}px`, 
      top: `${position.y}px`,
      transform: 'translate(-50%, -50%)'
    }}
    onClick={onClick}
  >
    <div className="w-10 h-10 rounded-full bg-white shadow-custom animate-popIn flex items-center justify-center">
      <img src="/logo512.png" alt="App Icon" className="w-8 h-8" />
    </div>
  </div>
);

const QueryResultCard = ({ 
  query, 
  currentUser, 
  onSaveQuery, 
  onExplanationClick,
  isExplanationLoading,
  isExplanationPending,
  isDashboardMode = false,
  onSaveCardSettings,
  cardWidth,
  onCardWidthChange,
  onFollowUpQuery,
  onClose,
  isAdmin,
  isLoading,
  fetchRecentSearches,
  onTextSelection,
  onClearResult,
  onQueryResult,
  onAddToLibrary,
  saveQuerySettings
}) => {

  console.log('QueryResultCard.js - saveQuerySettings received:', typeof saveQuerySettings === 'function');


  const [viewMode, setViewMode] = useState((query.cardSettings && query.cardSettings.viewMode) || 'table');
  const [chartType, setChartType] = useState((query.cardSettings && query.cardSettings.chartType) || 'bar');
  const [selectedXAxis, setSelectedXAxis] = useState((query.cardSettings && query.cardSettings.selectedXAxis) || '');
  const [selectedYAxis, setSelectedYAxis] = useState((query.cardSettings && query.cardSettings.selectedYAxis) || '');
  const [sortColumn, setSortColumn] = useState((query.cardSettings && query.cardSettings.sortColumn) || '');
  const [sortOrder, setSortOrder] = useState((query.cardSettings && query.cardSettings.sortOrder) || 'asc');
  const [processedData, setProcessedData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [localCardWidth, setLocalCardWidth] = useState(cardWidth || '100%');
  const [isExplanationCollapsed, setIsExplanationCollapsed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [statsData, setStatsData] = useState(null);
  const [isNumberFormatted, setIsNumberFormatted] = useState(true);
  const [visibleColumns, setVisibleColumns] = useState(query?.cardSettings?.visibleColumns || []);
  const [columnOrder, setColumnOrder] = useState(query?.cardSettings?.columnOrder || []);
  const [showSummary, setShowSummary] = useState(query?.cardSettings?.showSummary ?? true);
  const [syncScales, setSyncScales] = useState((query.cardSettings && query.cardSettings.syncScales) || false);
  const [isRtl, setIsRtl] = useState(false);
  const [isTransposing, setIsTransposing] = useState(false);
  const [activeFilters, setActiveFilters] = useState(query?.cardSettings?.activeFilters || {});
  const { config } = useConfig();

  const cardRef = useRef(null);
  const popupRef = useRef(null);
  const modalRef = useRef(null);
  const chartRef = useRef(null);

  const {
    selectedText,
    selectedColumns,
    showIntermediatePopup,
    intermediatePopupPosition,
    showFollowUpInput,
    isFromHighlight,
    handleTextSelection,
    handleIntermediatePopupClick,
    handleFollowUpSubmit: hookHandleFollowUpSubmit,
    handleClickOutside,
    handleEscKey,
    setShowFollowUpInput,
    resetFollowUpState
  } = useFollowUpQuery(async (submittedQuery, selectionData) => {
    setShowFollowUpInput(false);
    
    // Get current column configuration
    const currentColumnConfig = {
      visibleColumns,
      columnOrder,
      showSummary,
      viewMode,
      chartType,
      syncScales,
      selectedXAxis,
      selectedYAxis,
      sortColumn,
      sortOrder
    };
  
    console.log('[QueryResultCard] Follow-up query started with config:', currentColumnConfig);
  
    await onFollowUpQuery(
      submittedQuery, 
      selectionData.text, 
      query.sqlQuery, 
      selectionData.columns,
      currentColumnConfig
    );
  });

  
  const hasNumericFields = useCallback(() => {
    if (query && query.schema && query.schema.fields) {
      // Only check explicit numeric types
      return query.schema.fields.some(field => 
        ['INTEGER', 'FLOAT', 'NUMERIC'].includes(field.type.toUpperCase())
      );
    }
    return false;
  }, [query]);
  
  const showFormatCheckbox = useMemo(() => {
    return hasNumericFields() && query.result && query.result.length > 0;
  }, [hasNumericFields, query.result]);

  useEffect(() => {
    if (query?.cardSettings) {
      console.log('Applying saved visualization settings from query:', query.cardSettings);
      
      // Update view mode and chart type
      if (query.cardSettings.viewMode) {
        setViewMode(query.cardSettings.viewMode);
      }
      if (query.cardSettings.chartType) {
        setChartType(query.cardSettings.chartType);
      }
      
      // Update axes
      if (query.cardSettings.selectedXAxis) {
        setSelectedXAxis(query.cardSettings.selectedXAxis);
      }
      if (query.cardSettings.selectedYAxis) {
        setSelectedYAxis(query.cardSettings.selectedYAxis);
      }
      
      // Update sorting
      if (query.cardSettings.sortColumn) {
        setSortColumn(query.cardSettings.sortColumn);
      }
      if (query.cardSettings.sortOrder) {
        setSortOrder(query.cardSettings.sortOrder);
      }
      
      // Other settings
      if (query.cardSettings.syncScales !== undefined) {
        setSyncScales(query.cardSettings.syncScales);
      }
      if (query.cardSettings.showSummary !== undefined) {
        setShowSummary(query.cardSettings.showSummary);
      }
      
      // Don't set visibleColumns and columnOrder here, 
      // they are handled by another useEffect
    }
  }, [query?.cardSettings]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const handleOutsideClick = (event) => handleClickOutside(event, modalRef);

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [handleClickOutside, handleEscKey]);

  useEffect(() => {
    setLocalCardWidth(cardWidth || '100%');
  }, [cardWidth]);

    // Store current document ID for BigBro to access
    useEffect(() => {
      if (query && query.documentId) {
        localStorage.setItem('currentDocumentId', query.documentId);
        console.log('Stored current document ID for BigBro:', query.documentId);
      }
      
      return () => {
        // Only clear if this component set it
        if (query && query.documentId && 
            localStorage.getItem('currentDocumentId') === query.documentId) {
          localStorage.removeItem('currentDocumentId');
        }
      };
    }, [query?.documentId]);

  useEffect(() => {
    if (query && query.result && query.result.length > 0) {
      const firstRow = query.result[0];
      const keys = Object.keys(firstRow);
      
      const dateColumn = keys.find(key => {
        const value = firstRow[key];
        if (typeof value === 'object' && value !== null && 'value' in value) {
          return value.value && typeof value.value === 'string' && 
                 (value.value.match(/^\d{4}-\d{2}-\d{2}/) || value.value.match(/^\d{4}-\d{2}/));
        }
        return value && typeof value === 'string' && 
               (value.match(/^\d{4}-\d{2}-\d{2}/) || value.match(/^\d{4}-\d{2}/));
      }) || keys[0];
  
      // Find all numeric columns
      const numericColumns = keys.filter(key => {
        const value = firstRow[key];
        return typeof value === 'number' || 
               (typeof value === 'object' && value !== null && typeof value.value === 'number');
      });
  
      let xAxis = query.cardSettings?.selectedXAxis || dateColumn;
      let yAxes = query.cardSettings?.selectedYAxis || (numericColumns.length > 0 ? [numericColumns[0]] : [keys[0]]);
  
      // Convert yAxes to array if it's not already
      if (!Array.isArray(yAxes)) {
        yAxes = [yAxes];
      }
  
      // If no Y axes are selected or the only Y axis is the same as X axis
      if (yAxes.length === 0 || (yAxes.length === 1 && yAxes[0] === xAxis)) {
        const availableNumeric = numericColumns.filter(col => col !== xAxis);
        yAxes = availableNumeric.length > 0 ? [availableNumeric[0]] : [keys.find(k => k !== xAxis) || ''];
      }
  
      setSelectedXAxis(xAxis);
      setSelectedYAxis(yAxes);
  
      const isXAxisDate = (() => {
        const value = firstRow[xAxis];
        if (typeof value === 'object' && value !== null && 'value' in value) {
          return value.value && typeof value.value === 'string' && 
                 (value.value.match(/^\d{4}-\d{2}-\d{2}/) || value.value.match(/^\d{4}-\d{2}/));
        }
        return value && typeof value === 'string' && 
               (value.match(/^\d{4}-\d{2}-\d{2}/) || value.match(/^\d{4}-\d{2}/));
      })();
  
      const isYAxisNumeric = numericColumns.includes(yAxes[0]);
  
      // Set default sort based on column type
      if (query.cardSettings?.sortColumn) {
        setSortColumn(query.cardSettings.sortColumn);
        setSortOrder(query.cardSettings.sortOrder || 'asc');
      } else {
        if (isXAxisDate) {
          setSortColumn(xAxis);
          setSortOrder('asc');
        } else if (isYAxisNumeric) {
          setSortColumn(yAxes[0]);
          setSortOrder('desc');
        } else {
          setSortColumn(yAxes[0]);
          setSortOrder('asc');
        }
      }
  
      if (!query.cardSettings?.chartType) {
        setChartType(isXAxisDate ? 'line' : 'bar');
      }
    }
  }, [query]);

// In QueryResultCard.js, around line 235 in the useEffect
useEffect(() => {
  if (query?.result?.length > 0) {
    const resultColumns = Object.keys(query.result[0]);
    
    console.log('[QueryResultCard] Setting up columns with:', {
      existingVisibleColumns: visibleColumns,
      existingColumnOrder: columnOrder,
      cardSettings: query.cardSettings,
      resultColumns,
      isFollowUp: query.isFollowUp
    });

    if (query.cardSettings?.visibleColumns && query.cardSettings?.columnOrder) {
      // Only use columns that exist in the current result
      const validVisibleColumns = query.cardSettings.visibleColumns.filter(col => 
        resultColumns.includes(col)
      );
      const validColumnOrder = query.cardSettings.columnOrder.filter(col => 
        resultColumns.includes(col)
      );
      
      // Add any new columns from the result that weren't in the original configuration
      const newColumns = resultColumns.filter(col => 
        !validColumnOrder.includes(col)
      );

      // For follow-up queries, show all columns including new ones
      if (query.isFollowUp) {
        setVisibleColumns([...validVisibleColumns, ...newColumns]);
      } else {
        setVisibleColumns(validVisibleColumns.length ? validVisibleColumns : resultColumns);
      }
      
      setColumnOrder([...validColumnOrder, ...newColumns]);
      console.log('[QueryResultCard] Using cardSettings for columns');
    } else {
      // Just use the columns exactly as they come from the SQL result
      setVisibleColumns(resultColumns);
      setColumnOrder(resultColumns);
      console.log('[QueryResultCard] Using SQL result columns');
    }
    
    setShowSummary(query.cardSettings?.showSummary ?? true);
  }
}, [query?.cardSettings, query?.result]);

useEffect(() => {
  // Check if explanation exists and is primarily in Hebrew/RTL
  if (query?.explanation) {
    // Use the same detection logic as in QueryExplanation
    const explanationText = typeof query.explanation === 'string' 
      ? query.explanation
      : query.explanation.explanation;
      
    if (explanationText) {
      const hebrewPattern = /[\u0590-\u05FF]/g;
      const hebrewMatches = explanationText.match(hebrewPattern) || [];
      setIsRtl(hebrewMatches.length > explanationText.length * 0.4);
    }
  }
}, [query?.explanation]);

useEffect(() => {
  if (query?.cardSettings?.activeFilters) {
    setActiveFilters(query.cardSettings.activeFilters);
  }
}, [query]);


useEffect(() => {
  if (query) {
    console.log('QueryResultCard - query object properties:', {
      savedQueryId: query.savedQueryId,
      documentId: query.documentId,
      id: query.id,
      isSavedQuery: query.isSavedQuery,
      isDashboardMode
    });
  }
}, [query, isDashboardMode]);

useEffect(() => {
  // Debug info
  console.log('Settings useEffect triggered with:', {
    queryObject: query,
    hasSavedQueryId: Boolean(query?.savedQueryId),
    hasId: Boolean(query?.id),
    isDashboardMode,
    hasSaveFunction: Boolean(saveQuerySettings),
    isSavedQuery: query?.isSavedQuery
  });
  
  // Modified condition to also check for isSavedQuery property
  if (query && (query.savedQueryId || query.id || query.isSavedQuery) && !isDashboardMode && saveQuerySettings) {
    try {
      // Extract query ID - prioritize savedQueryId, then id
      const queryId = query.savedQueryId || query.id || (query.isSavedQuery ? `saved_${Date.now()}` : null);
      
      if (!queryId) {
        console.log('No valid query ID found for saving settings');
        return;
      }
      
      // Create a settings object with all visualization properties
      const settings = {
        viewMode,
        chartType,
        selectedXAxis,
        selectedYAxis,
        visibleColumns,
        columnOrder,
        syncScales,
        sortColumn,
        sortOrder,
        showSummary,
        activeFilters
      };
      
      // Save the settings for this query
      console.log('About to save settings for query ID:', queryId, 'with settings:', settings);
      saveQuerySettings(queryId, settings);
      console.log('Successfully saved visualization settings for library query:', queryId);
    } catch (error) {
      console.error('Error saving library query settings:', error);
    }
  }
}, [
  query?.savedQueryId,
  query?.id,
  query?.isSavedQuery,  // Add isSavedQuery as a dependency
  viewMode, 
  chartType, 
  selectedXAxis, 
  selectedYAxis, 
  visibleColumns, 
  columnOrder, 
  syncScales, 
  sortColumn, 
  sortOrder, 
  showSummary,
  activeFilters,
  isDashboardMode,
  saveQuerySettings
]);
  
  
  const handleColumnOrderChange = useCallback((newColumnOrder) => {
    setColumnOrder(newColumnOrder);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({
        ...query.cardSettings,
        viewMode,
        chartType,
        syncScales,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder,
        visibleColumns,
        columnOrder: newColumnOrder,
        showSummary
      });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings, viewMode, chartType, syncScales, selectedXAxis, selectedYAxis, sortColumn, sortOrder, visibleColumns, showSummary]);
  
  const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
    setVisibleColumns(newVisibleColumns);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({
        ...query.cardSettings,
        viewMode,
        chartType,
        syncScales,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder,
        visibleColumns: newVisibleColumns,
        columnOrder
      });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings, viewMode, chartType, syncScales, selectedXAxis, selectedYAxis, sortColumn, sortOrder, columnOrder]);
  
  const handleFollowUpButtonClick = () => {
    resetFollowUpState();
    setShowFollowUpInput(true);
  };
  
  const handleNumberFormatToggle = useCallback((checked) => {
    setIsNumberFormatted(checked);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, isNumberFormatted: checked });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);
  
  const resultDimensions = useMemo(() => {
    return query && query.result && query.result.length > 0 ? Object.keys(query.result[0]).length : 0;
  }, [query]);

  const isSingleValue = useMemo(() => {
    return query && query.result && query.result.length === 1 && resultDimensions === 1;
  }, [query, resultDimensions]);

  const showControls = useMemo(() => {
    return query && query.result && query.result.length > 1 && resultDimensions >= 2;
  }, [query, resultDimensions]);

  const canShowGraph = useMemo(() => {
    if (!query || !query.result || query.result.length === 0) return false;
    if (query.result.length === 1 && resultDimensions <= 3) return false;
    return query.result.length > 1 && resultDimensions >= 2 && hasNumericFields();
  }, [query, resultDimensions, hasNumericFields]);

  const handleCardWidthChange = useCallback((newWidth) => {
    if (!isMobile && onCardWidthChange) {
      setLocalCardWidth(newWidth);
      onCardWidthChange(newWidth);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ cardWidth: newWidth });
      }
    }
  }, [onCardWidthChange, isMobile, isDashboardMode, onSaveCardSettings]);

  const handleViewModeChange = useCallback((newViewMode) => {
    setViewMode(newViewMode);
    if (newViewMode !== 'table') {
      setChartType(newViewMode);
    }
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ 
        ...query.cardSettings, 
        viewMode: newViewMode, 
        chartType: newViewMode !== 'table' ? newViewMode : chartType,
        syncScales,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder
      });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings, syncScales, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder]);

  const handleSyncScalesChange = useCallback((newSyncValue) => {
    setSyncScales(newSyncValue);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({
        ...query.cardSettings,
        viewMode,
        chartType,
        syncScales: newSyncValue,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder
      });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings, viewMode, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder]);

  const handleShowSummaryChange = useCallback((newShowSummary) => {
    setShowSummary(newShowSummary);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({
        ...query.cardSettings,
        viewMode,
        chartType,
        syncScales,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder,
        showSummary: newShowSummary
      });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings, viewMode, chartType, syncScales, selectedXAxis, selectedYAxis, sortColumn, sortOrder]);

  const handleExcelDownload = useCallback(() => {
    if (query.result && query.result.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(query.result);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "query_result.xlsx");
    }
  }, [query.result]);

  const handleCloseCard = () => {
    if (onClose) {
      onClose(); // This will trigger the parent's handleClose which handles everything
    }
  };

  const handleSortChange = useCallback((column, order) => {
    setSortColumn(column);
    setSortOrder(order);
    if (isDashboardMode && onSaveCardSettings) {
      onSaveCardSettings({ ...query.cardSettings, sortColumn: column, sortOrder: order });
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings]);

  const handleAxisChange = useCallback((axis, value) => {
    if (axis === 'x') {
      setSelectedXAxis(value);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ 
          ...query.cardSettings,
          selectedXAxis: value,
          viewMode,
          chartType,
          syncScales,
          sortColumn,
          sortOrder
        });
      }
    } else if (axis === 'y') {
      const yAxes = Array.isArray(value) ? value : [value];
      setSelectedYAxis(yAxes);
      if (isDashboardMode && onSaveCardSettings) {
        onSaveCardSettings({ 
          ...query.cardSettings,
          selectedYAxis: yAxes,
          viewMode,
          chartType,
          syncScales,
          sortColumn,
          sortOrder
        });
      }
    }
  }, [isDashboardMode, onSaveCardSettings, query.cardSettings, viewMode, chartType, syncScales, sortColumn, sortOrder]);

  const getCardTitle = useCallback(() => {
    let titleText;
    
    if (isDashboardMode && query.name) {
      titleText = query.name;
    } else {
      titleText = query.userQuery || query.name || "Query Result";
    }
  
    if (typeof titleText !== 'string') {
      console.warn('Unexpected titleText type:', titleText);
      titleText = "Query Result";
    }

    const isFollowUp = query.isFollowUp || 
                       titleText.toLowerCase().includes('follow-up') || 
                       titleText.toLowerCase().includes('follow up') ||
                       (query.originalQuery && query.originalQuery !== query.userQuery);
  
    if (isFollowUp) {
      titleText = titleText.replace(/^(follow-up:|follow up:)\s*/i, '');
      titleText = `Follow-up: ${titleText}`;
    }
  
    if (!isDashboardMode) {
      const words = titleText.split(' ');
      if (words.length > 10) {
        titleText = `${words.slice(0, 10).join(' ')}...`;
      }
    }
  
    return `${titleText}`;
  }, [query.name, query.userQuery, query.isFollowUp, query.originalQuery, isDashboardMode]);

  const cardStyle = useMemo(() => {
    const baseStyle = "bg-white shadow-card rounded-lg p-4 mb-4 flex flex-col";
    if (isDashboardMode) {
      return `${baseStyle}`;
    } else {
      return `${baseStyle} border-l-4 border-secondary bg-gradient-to-r from-secondary/5 to-secondary/20`;
    }
  }, [isDashboardMode]);

  const toggleExplanationCollapse = () => {
    setIsExplanationCollapsed(prev => !prev);
  };

  const toggleEditMode = () => {
    setIsEditMode(prev => !prev);
  };

  const calculateStats = useCallback(() => {
    if (query && query.result && query.result.length > 0 && selectedYAxis) {
      const yAxis = Array.isArray(selectedYAxis) ? selectedYAxis[0] : selectedYAxis;
      const values = query.result.map(row => parseFloat(row[yAxis])).filter(val => !isNaN(val));
      
      if (values.length > 0) {
        const sum = values.reduce((acc, val) => acc + val, 0);
        const avg = sum / values.length;

        setStatsData({
          sum: sum,
          avg: avg
        });
      } else {
        setStatsData(null);
      }
    } else {
      setStatsData(null);
    }
  }, [query, selectedYAxis]);

  useEffect(() => {
    calculateStats();
  }, [calculateStats]);


  const handleFollowUpSubmit = async (submittedQuery, selectionData) => {
    const allColumns = query.result && query.result.length > 0 
      ? Object.keys(query.result[0]) 
      : [];
      
    const hiddenColumns = allColumns.filter(col => !visibleColumns.includes(col));
    
    const currentColumnConfig = {
      visibleColumns,
      columnOrder,
      showSummary,
      viewMode,
      chartType,
      syncScales,
      selectedXAxis,
      selectedYAxis,
      sortColumn,
      sortOrder,
      activeFilters  // Include active filters in the column config
    };

    console.log('Follow-up submission with data:', { 
      selectionData,
      currentColumnConfig,
      activeFilters
    });

    await onFollowUpQuery(
      submittedQuery, 
      selectedText, 
      query.sqlQuery, 
      selectedColumns,
      {
        ...currentColumnConfig,
        valueContext: selectionData?.valueContext || query.valueContext,
        isFromValueSearchList: selectionData?.isFromValueSearchList || false,
        activeFilters // Include at the top level too for backward compatibility
      }
    );

    resetFollowUpState();
    setShowFollowUpInput(false);
  };

  const handleTranspose = async () => {
    if (!query.documentId || !config) return;
    
    setIsTransposing(true);
    try {
      if (query.isTransposed) {
        // When reverting, we should use the original response and schema
        const updatedQuery = {
          ...query,
          result: query.response || query.result,
          schema: query.responseSchema || query.schema,
          isTransposed: false
        };
  
        if (typeof onQueryResult === 'function') {
          onQueryResult(updatedQuery);
        }
      } else {
        // Make BE call when transposing from original to transposed
        const response = await fetch(
          `https://us-central1-${config.core.projectId}.cloudfunctions.net/transposeQueryResult`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                documentId: query.documentId,
                revert: false
              }
            })
          }
        );
  
        const result = await response.json();
        
        if (!result.data.success) {
          throw new Error(result.data.error || 'Failed to transpose data');
        }
  
        // Create a schema for transposed data
        const transposedSchema = {
          fields: Object.keys(result.data.result[0]).map(key => ({
            name: key,
            // Infer type based on the first row that isn't the header row
            type: key === 'Field' ? 'STRING' : 
                  result.data.result[1] && !isNaN(result.data.result[1][key]) ? 'FLOAT' : 'STRING'
          }))
        };
  
        // For transposed data, we'll process numeric values directly
        const processedResult = result.data.result.map(row => {
          const processedRow = { ...row };
          if (row.Field === 'total_sales') {
            // Process numeric values
            Object.keys(row).forEach(key => {
              if (key !== 'Field' && !isNaN(parseFloat(row[key]))) {
                processedRow[key] = formatNumber(parseFloat(row[key]), row.Field);
              }
            });
          } else if (row.Field === 'total_transactions' || row.Field === 'unique_customers') {
            // Remove leading zeros from these fields
            Object.keys(row).forEach(key => {
              if (key !== 'Field') {
                processedRow[key] = parseInt(row[key], 10).toString();
              }
            });
          }
          return processedRow;
        });
  
        const updatedQuery = {
          ...query,
          result: processedResult,
          schema: transposedSchema,
          isTransposed: true,
          // Store original data for reversion
          response: query.result,
          responseSchema: query.schema
        };
  
        if (typeof onQueryResult === 'function') {
          onQueryResult(updatedQuery);
        }
      }
    } catch (error) {
      console.error('Error transposing data:', error);
    } finally {
      setIsTransposing(false);
    }
  };

  const handleRemoveFilter = (filter) => {
    console.log(`Removing filter: ${filter}`);
    const followUpQuery = `recalculate without filter: ${filter}`;
    
    if (onFollowUpQuery) {
      // Get current column configuration
      const currentColumnConfig = {
        visibleColumns,
        columnOrder,
        showSummary,
        viewMode,
        chartType,
        syncScales,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder,
        activeFilters
      };
      
      onFollowUpQuery(
        followUpQuery, 
        '', // No highlighted text
        query.sqlQuery, 
        [], // No selected columns
        currentColumnConfig
      );
    }
  };

  const handleRemoveTable = (table) => {
    console.log(`Removing table: ${table}`);
    const followUpQuery = `recalculate without table: ${table}`;
    
    if (onFollowUpQuery) {
      // Get current column configuration
      const currentColumnConfig = {
        visibleColumns,
        columnOrder,
        showSummary,
        viewMode,
        chartType,
        syncScales,
        selectedXAxis,
        selectedYAxis,
        sortColumn,
        sortOrder,
        activeFilters
      };
      
      onFollowUpQuery(
        followUpQuery, 
        '', // No highlighted text
        query.sqlQuery, 
        [], // No selected columns
        currentColumnConfig
      );
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="relative">
      <Card 
        ref={cardRef}
        className={`overflow-hidden relative flex flex-col z-0 ${cardStyle}`}
        style={{ 
          width: isMobile ? '100%' : (isDashboardMode ? localCardWidth : '100%'),
          minHeight: '400px',
        }}
      >
        <div className={`flex justify-between items-center mb-4 ${isRtl ? 'flex-row-reverse' : ''}`}>
          <div className={`flex items-center gap-4 flex-grow ${isRtl ? 'flex-row-reverse' : ''}`}>
            <h3 className="text-lg font-semibold text-gray-700 truncate">
              {isDashboardMode ? getCardTitle() : (query.explanation?.title || "Query Result")}
            </h3>
            {!isDashboardMode && query.explanation && (
              <div className={`flex items-center ${isRtl ? 'flex-row-reverse' : ''}`}>
                {query.explanation.filters?.length > 0 && (
                  <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full bg-accent/10 text-gray-800 text-xs font-medium">
                    <Filter size={12} />
                    {isRtl ? "פילטרים פעילים" : "Filters Active"}
                  </span>
                )}
                <button 
                  onClick={toggleExplanationCollapse}
                  className={`p-1 rounded-full hover:bg-gray-100 ${isRtl ? 'mr-2' : 'ml-2'}`}
                  aria-label={isExplanationCollapsed ? "Expand explanation" : "Collapse explanation"}
                >
                  {isExplanationCollapsed ? (
                    <ChevronDown size={18} className="text-gray-500" />
                  ) : (
                    <ChevronUp size={18} className="text-gray-500" />
                  )}
                </button>
              </div>
            )}
          </div>
          {!isDashboardMode && (
            <button
              onClick={handleCloseCard}
              className="p-1 rounded-full transition-colors duration-200 hover:bg-gray-200"
              aria-label="Close result"
            >
              <X size={18} className="text-gray-700" />
            </button>
          )}
        </div>

        {!isDashboardMode && (query.explanation || isExplanationLoading || isExplanationPending) && (
          <div className={`mb-4 transition-all duration-300 ${isExplanationCollapsed ? 'h-0 opacity-0 overflow-hidden' : 'h-auto opacity-100'}`}>
            <QueryExplanation
              explanation={query.explanation}
              isCollapsed={isExplanationCollapsed}
              onToggleCollapse={toggleExplanationCollapse}
              className="mb-4"
              isLoading={isExplanationLoading || isExplanationPending}
              onRemoveFilter={handleRemoveFilter}
              onRemoveTable={handleRemoveTable}
            />
          </div>
        )}

        <AlignedButtonLayout
          viewMode={viewMode}
          setViewMode={handleViewModeChange}
          chartType={chartType}
          setChartType={setChartType}
          canShowGraph={canShowGraph}
          isSingleValue={isSingleValue}
          hasResults={query.result && query.result.length > 0}
          isMobile={isMobile}
          isDashboardMode={isDashboardMode}
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          statsData={statsData}
          yAxisLabel={selectedYAxis}
          isNumberFormatted={isNumberFormatted}
          onNumberFormatToggle={handleNumberFormatToggle}
          showFormatCheckbox={showFormatCheckbox}
          isTransposed={query.isTransposed}
        />

        {(isDashboardMode ? isEditMode : viewMode !== 'table') && (
          <ControlPanel
            selectedXAxis={selectedXAxis}
            setSelectedXAxis={(value) => handleAxisChange('x', value)}
            selectedYAxis={selectedYAxis}
            setSelectedYAxis={(value) => handleAxisChange('y', value)}
            sortColumn={sortColumn}
            setSortColumn={(column) => handleSortChange(column, sortOrder)}
            sortOrder={sortOrder}
            setSortOrder={(order) => handleSortChange(sortColumn, order)}
            columns={query.result && query.result.length > 0 ? Object.keys(query.result[0]) : []}
            schema={query.schema}
            isVisible={true}  // Changed this line
            viewMode={viewMode}
            visibleColumns={visibleColumns}
            setVisibleColumns={handleVisibleColumnsChange}
            columnOrder={columnOrder}
            setColumnOrder={handleColumnOrderChange}
            showSummary={showSummary}
            setShowSummary={handleShowSummaryChange}
            isTransposed={query.isTransposed}
            onTranspose={handleTranspose}
            isTransposing={isTransposing}
            isDashboardMode={isDashboardMode}
          />
        )}

        <div className="flex-grow overflow-y-auto mt-4 pb-2">
        {query.truncationMessage && (
          <div className="mb-4 p-3 rounded bg-blue-100 text-blue-800 flex items-center justify-between relative">
            <div className="flex items-center flex-grow">
              <Info className="mr-2 flex-shrink-0" size={18} />
              <p>
                {query.truncationMessage}
                {query.totalCount && !query.truncationMessage.includes(query.totalCount.toLocaleString())}
              </p>
            </div>
            <div className="flex-shrink-0 ml-4" style={{ position: 'relative', zIndex: 100 }}>
              <DownloadAllButton 
                documentId={query.documentId}
                userId={currentUser?.email || query.userId || 'anonymous'}
                fileName={getCardTitle()}
                disabled={!query.documentId}
                icon={Download}
                title=""
                className="px-3 py-1.5 bg-white text-primary rounded-full hover:bg-gray-50 shadow-sm hover:shadow transition-all duration-200 flex items-center border border-gray-200"
                buttonText="Download All"
              />
            </div>
          </div>
        )}
          <ResultView 
            result={query}
            query={query}
            viewMode={viewMode}
            chartType={chartType}
            selectedXAxis={selectedXAxis}
            selectedYAxis={selectedYAxis}
            setSelectedYAxis={setSelectedYAxis}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            isSingleValue={isSingleValue}
            onProcessedDataChange={setProcessedData}
            isNumberFormatted={isNumberFormatted}
            onTextSelection={handleTextSelection}
            visibleColumns={visibleColumns}
            columnOrder={columnOrder}
            onSortChange={handleSortChange}
            chartRef={chartRef}
            showSummary={showSummary}
            syncScales={syncScales}
            onSyncScalesChange={handleSyncScalesChange}
            onVisibleColumnsChange={handleVisibleColumnsChange}
            onColumnOrderChange={handleColumnOrderChange}
            onActiveFiltersChange={setActiveFilters}
            activeFilters={activeFilters}  
          />
        </div>

        <div className="mt-auto flex justify-between items-center">
          <button
            onClick={handleFollowUpButtonClick}
            className="p-1.5 rounded-full transition-all duration-200 bg-white hover:bg-gray-100 border border-gray-200 shadow-sm hover:shadow-md"
            title="Ask follow-up question"
            style={{
              transform: 'scale(1)',
              transition: 'transform 0.2s ease-in-out'
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <img src="/favicon.ico" alt="App Icon" className="w-6 h-6" />
          </button>
          <ActionPanel 
            result={{
              ...query,
              processedData: processedData,
              cardSettings: {
                viewMode,
                chartType,
                selectedXAxis,
                selectedYAxis,
                visibleColumns,
                columnOrder,
                syncScales,
                sortColumn,
                sortOrder,
                showSummary,
                activeFilters
              }
            }}
            onSaveQuery={onSaveQuery}
            onAddToLibrary={onAddToLibrary}
            isSavedQuery={query.isSavedQuery}
            onExplanationClick={onExplanationClick}
            isExplanationLoading={isExplanationLoading}
            isExplanationPending={isExplanationPending}
            explanation={query.explanation}
            isDashboardMode={isDashboardMode}
            isAdmin={isAdmin}
            isEmptyResult={!query.result || query.result.length === 0}
            isInDashboard={isDashboardMode}
            downloadExcel={handleExcelDownload}
            viewMode={viewMode}
            chartRef={chartRef}
            cardTitle={getCardTitle()}
            statsData={statsData}
            selectedYAxis={selectedYAxis}
            truncationMessage={query.truncationMessage}
          />   
        </div>
      </Card>

      {showIntermediatePopup && (
        <IntermediatePopup 
          position={intermediatePopupPosition} 
          onClick={handleIntermediatePopupClick} 
        />
      )}

      {showFollowUpInput && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl">
            <h3 className="text-lg font-semibold mb-4">Follow-up Query</h3>
            {isLoading ? (
              <div className="flex justify-center items-center h-24">
                <p>Loading...</p>
              </div>
            ) : (
              <>
                <QueryInput
                  onSubmit={handleFollowUpSubmit}
                  isLoading={isLoading}
                  isFollowUpMode={true}
                  selectedText={selectedText}
                  selectedColumns={selectedColumns}
                  isCardTitle={getCardTitle()}
                  fetchRecentSearches={fetchRecentSearches}
                />
              </>
            )}
            <button 
              className="mt-4 text-sm text-gray-600 hover:text-gray-800"
              onClick={() => {
                setShowFollowUpInput(false);
                resetFollowUpState();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueryResultCard;