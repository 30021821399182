// src/components/Docs/DocumentsFilter.js
import React, { useState, useEffect, useRef } from 'react';
import { Tag, X, FolderTree } from 'lucide-react';

const DocumentsFilter = ({ availableLabels, availableCategories, selectedFilters, onFilterChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('labels');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleToggleFilter = (filter, type) => {
    const updatedFilters = selectedFilters.includes(filter)
      ? selectedFilters.filter(f => f !== filter)
      : [...selectedFilters, filter];
    onFilterChange(updatedFilters);
  };

  const handleRemoveFilter = (filter) => {
    onFilterChange(selectedFilters.filter(f => f !== filter));
  };

  const isLabelFilter = (filter) => availableLabels.includes(filter);
  const isCategoryFilter = (filter) => availableCategories.includes(filter);

  return (
    <div className="flex flex-wrap items-center gap-2">
      {/* Selected Filters */}
      <div className="flex flex-wrap gap-2">
        {selectedFilters.map(filter => {
          const isLabel = isLabelFilter(filter);
          const isCategory = isCategoryFilter(filter);
          
          return (
            <span 
              key={filter}
              className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium whitespace-nowrap ${
                isLabel 
                  ? 'bg-secondary/50 text-black hover:bg-secondary/60'
                  : isCategory 
                    ? 'bg-gray-100 text-gray-700'
                    : 'bg-blue-100 text-blue-800'
              }`}
            >
              {isLabel ? (
                <Tag className="flex-shrink-0 w-3 h-3 mr-1 text-purple-600" />
              ) : isCategory ? (
                <FolderTree className="flex-shrink-0 w-3 h-3 mr-1 text-gray-500" />
              ) : (
                <Tag className="w-3 h-3 mr-1" />
              )}
              {filter}
              <button
                onClick={() => handleRemoveFilter(filter)}
                className="ml-1 hover:text-blue-600"
                aria-label={`Remove ${filter} filter`}
              >
                <X className="w-3 h-3" />
              </button>
            </span>
          );
        })}
      </div>
      
      {/* Filter Dropdown */}
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
          Filter
        </button>
        
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 border border-gray-200 max-h-96 flex flex-col">
            {/* Tab Selection */}
            <div className="flex border-b border-gray-200 flex-shrink-0">
              <button
                onClick={() => setActiveTab('labels')}
                className={`flex-1 px-4 py-2 text-sm font-medium ${
                  activeTab === 'labels'
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Labels
              </button>
              <button
                onClick={() => setActiveTab('categories')}
                className={`flex-1 px-4 py-2 text-sm font-medium ${
                  activeTab === 'categories'
                    ? 'text-blue-600 border-b-2 border-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Categories
              </button>
            </div>

            {/* Scrollable Content */}
            <div className="overflow-y-auto flex-grow">
              <div className="p-2">
                {activeTab === 'labels' ? (
                  <div className="space-y-1">
                    {availableLabels.length === 0 ? (
                      <div className="px-2 py-1.5 text-sm text-gray-500 italic">
                        No labels available
                      </div>
                    ) : (
                      availableLabels.map(label => (
                        <label
                          key={label}
                          className="flex items-center px-2 py-1.5 hover:bg-gray-50 rounded cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={selectedFilters.includes(label)}
                            onChange={() => handleToggleFilter(label, 'label')}
                            className="mr-2"
                          />
                          <span className="text-sm text-gray-700">{label}</span>
                        </label>
                      ))
                    )}
                  </div>
                ) : (
                  <div className="space-y-1">
                    {availableCategories.length === 0 ? (
                      <div className="px-2 py-1.5 text-sm text-gray-500 italic">
                        No categories available
                      </div>
                    ) : (
                      availableCategories.map(category => (
                        <label
                          key={category}
                          className="flex items-center px-2 py-1.5 hover:bg-gray-50 rounded cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={selectedFilters.includes(category)}
                            onChange={() => handleToggleFilter(category, 'category')}
                            className="mr-2"
                          />
                          <span className="text-sm text-gray-700">{category}</span>
                        </label>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsFilter;