// src/pages/Organize.js
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useDocuments } from '../hooks/useDocuments';  // Import the hook
import OrganizeDocuments from '../components/Docs/OrganizeDocuments';

const Organize = () => {
  const { currentUser } = useAuth();
  const { documents, error } = useDocuments();  // Use the hook instead of our own fetch logic

  if (!currentUser) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold mb-4">Please log in to view this page</h2>
        <p>You need to be logged in to access the document library.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold mb-4 text-red-600">Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  return <OrganizeDocuments documents={documents} />;
};

export default Organize;