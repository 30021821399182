import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ChartView from './ChartView';
import TableView from './TableView';
import { processData, sortData, formatColumnName } from './dataProcessing';

const ResultView = ({ 
  result, 
  viewMode, 
  chartType, 
  selectedXAxis, 
  setSelectedYAxis,
  selectedYAxis, 
  sortColumn, 
  sortOrder, 
  isSingleValue, 
  onProcessedDataChange,
  isNumberFormatted,
  onTextSelection,
  visibleColumns,
  columnOrder,
  onSortChange,
  chartRef,
  showSummary,
  syncScales,
  onSyncScalesChange,
  onVisibleColumnsChange,
  onColumnOrderChange,
  onActiveFiltersChange,
  activeFilters: initialActiveFilters = {},
  query
}) => {
  const [processedData, setProcessedData] = useState([]);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters);
  const [originalData, setOriginalData] = useState([]);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('ResultView props:', { result, viewMode, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder, isSingleValue });
  }, [result, viewMode, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder, isSingleValue]);

  useEffect(() => {
    if (result && Array.isArray(result.result) && result.schema) {
      try {
        const processed = processData(result.result, result.schema, isNumberFormatted);
        setOriginalData(processed); // Store original unfiltered data
        
        // Apply filters if any exist
        let filteredData = processed;
        if (Object.keys(activeFilters).length > 0) {
          filteredData = processed.filter(row => {
            return Object.entries(activeFilters).every(([columnName, allowedValues]) => {
              const cellValue = row[columnName]?.displayValue ?? row[columnName];
              const stringValue = cellValue === null || cellValue === undefined 
                ? 'N/A' 
                : String(cellValue);
              return allowedValues.includes(stringValue);
            });
          });
        }
        
        setProcessedData(filteredData);
        setError(null);
        if (typeof onProcessedDataChange === 'function') {
          onProcessedDataChange(filteredData, { viewMode, chartRef: chartRef.current });
        }
      } catch (err) {
        console.error('Error processing data:', err);
        setError('An error occurred while processing the data. Please try again.');
      }
    } else {
      console.error('Invalid result structure:', result);
      setError('Invalid data structure received. Missing result array or schema.');
    }
  }, [result, onProcessedDataChange, isNumberFormatted, viewMode, activeFilters]);

  const handleFilterChange = (newFilters) => {
    // Log before changing state for debugging
    console.log('ResultView receiving filter change:', newFilters);
    
    // Update local state
    setActiveFilters(newFilters);
    
    // Notify parent component with the same filters
    if (typeof onActiveFiltersChange === 'function') {
      console.log('Propagating filter change to parent component');
      onActiveFiltersChange(newFilters);
    } else {
      console.warn('onActiveFiltersChange is not a function or not provided');
    }
  };
  
  const columns = useMemo(() => {
    if (result && result.schema && Array.isArray(result.schema.fields)) {
      return result.schema.fields.map(field => field.name);
    }
    console.warn('Invalid or missing schema:', result?.schema);
    return [];
  }, [result]);

  const defaultAxes = useMemo(() => {
    if (columns.length < 2) return { x: columns[0] || '', y: '' };
    const dateColumn = columns.find(col => 
      result.schema.fields.find(f => f.name === col && f.type.toLowerCase().includes('date'))
    );
    const numericColumn = columns.find(col => 
      result.schema.fields.find(f => f.name === col && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(f.type.toUpperCase()))
    );
    return { 
      x: dateColumn || columns[0], 
      y: numericColumn || columns.find(col => col !== (dateColumn || columns[0])) || ''
    };
  }, [columns, result]);

  const formattedSelectedXAxis = useMemo(() => {
    if (!selectedXAxis || typeof selectedXAxis !== 'string' || !columns.includes(selectedXAxis)) {
      console.warn('Invalid selectedXAxis:', selectedXAxis);
      return defaultAxes.x;
    }
    return selectedXAxis;
  }, [columns, selectedXAxis, defaultAxes]);
  
// In ResultView.js, update the formattedSelectedYAxis:

  const formattedSelectedYAxis = useMemo(() => {
    if (!selectedYAxis) {
      return defaultAxes.y;
    }
    if (Array.isArray(selectedYAxis)) {
      return selectedYAxis.filter(axis => typeof axis === 'string' && columns.includes(axis));
    }
    if (typeof selectedYAxis === 'string' && columns.includes(selectedYAxis)) {
      return selectedYAxis;
    }
    return defaultAxes.y;
  }, [columns, selectedYAxis, defaultAxes]);

  const defaultSortConfig = useMemo(() => {
    const xAxisField = result?.schema?.fields?.find(field => field.name === formattedSelectedXAxis);
    const yAxisField = result?.schema?.fields?.find(field => field.name === formattedSelectedYAxis);
    
    const isXAxisDate = xAxisField && xAxisField.type.toLowerCase().includes('date');
    const isYAxisNumeric = yAxisField && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(yAxisField.type.toUpperCase());
    
    if (isXAxisDate) {
      return { column: formattedSelectedXAxis, order: 'asc' };  // Dates stay ascending
    } else if (isYAxisNumeric) {
      return { column: formattedSelectedYAxis, order: 'desc' };  // Numeric values default to descending
    } else {
      return { column: formattedSelectedYAxis, order: 'asc' };  // Other types stay ascending
    }
  }, [result, formattedSelectedXAxis, formattedSelectedYAxis]);

  const sortedData = useMemo(() => {
    const effectiveSortColumn = sortColumn || defaultSortConfig.column;
    const effectiveSortOrder = sortOrder || defaultSortConfig.order;
    return sortData(processedData, effectiveSortColumn, effectiveSortOrder);
  }, [processedData, sortColumn, sortOrder, defaultSortConfig]);

  const handleTextSelection = (selectionData) => {
    if (typeof onTextSelection === 'function') {
      onTextSelection(selectionData);
    }
  };

  if (error) {
    return (
      <div className="text-left text-red-600">
        <p className="text-base font-bold">{error}</p>
      </div>
    );
  }

  const hasNoData = !result || 
    !Array.isArray(result.result) || 
    result.result.length === 0 ||
    (result.result.length === 1 && Object.values(result.result[0]).every(value => value === null));

  if (hasNoData) {
    return (
      <div className="text-left">
        <p className="text-base font-bold flex items-center">
          There's no data matching your question. 🙃
        </p>
        <hr className="my-4 border-gray-300" />
        <p className="text-base">
          Please try adjusting your search terms or explore related topics, and we'll help you find what you need!
        </p>
      </div>
    );
  }

  if (isSingleValue && processedData.length > 0) {
    const [key, value] = Object.entries(processedData[0])[0];
    if (value === null || value.displayValue === null) {
      return (
        <div className="text-left">
          <p className="text-base font-bold flex items-center">
            There's no data matching your question. 🙃
          </p>
          <hr className="my-4 border-gray-300" />
          <p className="text-base">
            Please try adjusting your search terms or explore related topics, and we'll help you find what you need!
          </p>
        </div>
      );
    }
    
    // Get the display value
    const displayValue = value.displayValue || value;
    
    // Check if the value is a number
    const isNumber = typeof displayValue === 'number' || 
                    (typeof displayValue === 'string' && !isNaN(parseFloat(displayValue)) && 
                     displayValue.trim() !== '');
    
    // Detect type of message and extract content
    const isNoResultsMessage = typeof displayValue === 'string' && 
                             displayValue.toLowerCase().includes('no results found');
    
    // Check if autocomplete tip exists in the message
    const hasAutocompleteTip = typeof displayValue === 'string' && 
                             displayValue.toLowerCase().includes('try using autocomplete');
    
    if (isNoResultsMessage) {
      // Process text to bold items in quotes or date ranges
      const processText = (text) => {
        if (typeof text !== 'string') return text;
        
        // Split text by quotes and date patterns
        const parts = text.split(/(['"][^'"]*['"]|\d{4}-\d{2}-\d{2}|\d{2}\/\d{2}\/\d{4}|\d{2}\.\d{2}\.\d{4})/);
        
        return parts.map((part, index) => {
          // Check if this part is in quotes
          const isQuoted = /^['"][^'"]*['"]$/.test(part);
          
          // Check if this part is a date
          const isDate = /^\d{4}-\d{2}-\d{2}$|^\d{2}\/\d{2}\/\d{4}$|^\d{2}\.\d{2}\.\d{4}$/.test(part);
          
          if (isQuoted) {
            return <span key={index} className="font-bold">{part}</span>;
          } else if (isDate) {
            return (
              <span key={index} className="inline-flex items-center font-medium text-indigo-700 align-baseline">
                <svg className="inline-block w-4 h-4 mr-1 mb-0.5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
                {part}
              </span>
            );
          }
          return part;
        });
      };
      
      // Extract the complete message without redundancy
      let fullMessage = '';
      if (typeof displayValue === 'string') {
        // Keep "No results found" but remove redundant autocomplete tip
        fullMessage = displayValue
          .replace(/try using autocomplete\.?/gi, '')
          .replace(/\.{2,}/g, '.') // Replace multiple periods with one
          .trim();
      }
      
      return (
        <div className="text-left p-1">
          <div className="flex flex-col gap-2">
            <p className="text-base leading-relaxed">{processText(fullMessage)}</p>
            
            {hasAutocompleteTip && (
              <div className="flex items-center gap-2 mt-2">
                <span className="text-amber-600">💡</span> 
                <span className="font-medium">Tip:</span> 
                <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded">
                  Try using autocomplete
                </span>
              </div>
            )}
          </div>
        </div>
      );
    } else if (isNumber) {
      // Original rendering for numbers
      return (
        <div className="text-left">
          <p className="text-base">{formatColumnName(key)}: <span className="text-base font-bold">{displayValue}</span></p>
        </div>
      );
    } else {
      // Process text for regular text responses too
      const processText = (text) => {
        if (typeof text !== 'string') return text;
        
        // Split text by quotes and date patterns
        const parts = text.split(/(['"][^'"]*['"]|\d{4}-\d{2}-\d{2}|\d{2}\/\d{2}\/\d{4}|\d{2}\.\d{2}\.\d{4})/);
        
        return parts.map((part, index) => {
          // Check if this part is in quotes
          const isQuoted = /^['"][^'"]*['"]$/.test(part);
          
          // Check if this part is a date
          const isDate = /^\d{4}-\d{2}-\d{2}$|^\d{2}\/\d{2}\/\d{4}$|^\d{2}\.\d{2}\.\d{4}$/.test(part);
          
          if (isQuoted) {
            return <span key={index} className="font-bold">{part}</span>;
          } else if (isDate) {
            return (
              <span key={index} className="inline-flex items-center font-medium text-indigo-700 align-baseline">
                <svg className="inline-block w-4 h-4 mr-1 mb-0.5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
                {part}
              </span>
            );
          }
          return part;
        });
      };
      
      // Regular rendering for text (no column name)
      return (
        <div className="text-left">
          <p className="text-base leading-relaxed">{processText(displayValue)}</p>
        </div>
      );
    }
  }

  if (processedData.length === 1 || Object.keys(processedData[0] || {}).length === 1) {
    return (
      <div className="relative">
        <TableView 
          data={sortedData}
          originalData={originalData}
          schema={result.schema}
          initialSortColumn={sortColumn || defaultSortConfig.column}
          initialSortOrder={sortOrder || defaultSortConfig.order}
          onTextSelection={onTextSelection}
          visibleColumns={visibleColumns}
          columnOrder={columnOrder}
          onSortChange={onSortChange}
          showSummary={showSummary}
          onFilterChange={handleFilterChange}
          activeFilters={activeFilters}
          isTransposed={result.isTransposed}
          isTransposing={result.isTransposing}
          query={result}
          onVisibleColumnsChange={onVisibleColumnsChange}
          onColumnOrderChange={onColumnOrderChange} 
          isNumberFormatted={isNumberFormatted}     
        />
      </div>
    );
  }


  const renderContent = () => {
    const chartTypes = ['bar', 'line', 'pie'];
    if (chartTypes.includes(viewMode)) {
      // For charts, always use original data
      const chartData = result.response || processedData;
      let adjustedXAxis = formattedSelectedXAxis;
      let adjustedYAxis = formattedSelectedYAxis;
  
      return (
        <div ref={chartRef} className={`chart-container ${isMobile ? 'px-0' : 'px-4'}`}>
          <ChartView 
            data={chartData}
            chartType={viewMode}
            selectedXAxis={adjustedXAxis}
            selectedYAxis={adjustedYAxis}
            schema={result.responseSchema || result.schema}
            sortColumn={sortColumn || defaultSortConfig.column}
            sortOrder={sortOrder || defaultSortConfig.order}
            onYAxisRemove={setSelectedYAxis}
            syncScales={syncScales}
            onSyncScalesChange={onSyncScalesChange}
            isNumberFormatted={isNumberFormatted}
            isTransposed={false}
          />
        </div>
      );
    } else {
      // For tables, keep existing transposed functionality
      return (
        <div className="relative">
          <TableView 
            data={sortedData}
            originalData={originalData}
            schema={result.schema}
            initialSortColumn={sortColumn || defaultSortConfig.column}
            initialSortOrder={sortOrder || defaultSortConfig.order}
            onTextSelection={onTextSelection}
            visibleColumns={visibleColumns}
            columnOrder={columnOrder}
            onSortChange={onSortChange}
            showSummary={showSummary}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            isTransposed={result.isTransposed}
            isTransposing={result.isTransposing}
            query={result}
            onVisibleColumnsChange={onVisibleColumnsChange}
            onColumnOrderChange={onColumnOrderChange} 
            isNumberFormatted={isNumberFormatted}
          />
        </div>
      );
    }
  };

  return (
    <div className={`result-view-container ${isMobile ? 'p-0' : 'p-4'}`}>
      {renderContent()}
    </div>
  );
};


ResultView.propTypes = {
  result: PropTypes.shape({
    result: PropTypes.array,
    schema: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string
      }))
    })
  }),
  viewMode: PropTypes.oneOf(['chart', 'table', 'bar', 'line', 'pie']).isRequired,
  chartType: PropTypes.oneOf(['bar', 'line', 'pie']),
  selectedXAxis: PropTypes.string,
  selectedYAxis: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  setSelectedYAxis: PropTypes.func,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  isSingleValue: PropTypes.bool,
  onProcessedDataChange: PropTypes.func,
  isNumberFormatted: PropTypes.bool.isRequired,
  onSortChange: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  chartRef: PropTypes.object,
  showSummary: PropTypes.bool,
  syncScales: PropTypes.bool,
  onSyncScalesChange: PropTypes.func,
  isTransposed: PropTypes.bool,
  onTranspose: PropTypes.func,
  isTransposing: PropTypes.bool,
  onVisibleColumnsChange: PropTypes.func,
  onColumnOrderChange: PropTypes.func,  
  onActiveFiltersChange: PropTypes.func,
  activeFilters: PropTypes.object,
  query: PropTypes.object
};

ResultView.defaultProps = {
  chartType: 'bar',
  isSingleValue: false,
  visibleColumns: [],
  columnOrder: [],
  showSummary: true,
  syncScales: false,
  activeFilters: {}
};

export default ResultView;