// src/components/Docs/RestoreDocuments.js
import React, { useState, useRef } from 'react';
import { Database, AlertCircle, FileSpreadsheet, Check, X } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Modal from '../Modal';

const RestoreDocuments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1); // 1: Select CSV, 2: Confirm, 3: Results
  const [csvFile, setCsvFile] = useState(null);
  const [isRestoring, setIsRestoring] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  
  const csvInputRef = useRef(null);
  
  const functions = getFunctions();
  
  const handleCsvSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    setCsvFile(file);
  };
  
  const resetForm = () => {
    setCsvFile(null);
    setStep(1);
    setError(null);
    setResults(null);
    if (csvInputRef.current) csvInputRef.current.value = '';
  };
  
  const closeModal = () => {
    resetForm();
    setIsModalOpen(false);
  };
  
  const goToNextStep = () => {
    if (step === 1) {
      if (!csvFile) {
        setError('Please select a CSV file');
        return;
      }
      setStep(2);
    } else if (step === 2) {
      startRestore();
    }
  };
  
  const goToPreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  
  const startRestore = async () => {
    setIsRestoring(true);
    setError(null);
    
    try {
      // Read the CSV file as base64
      const csvBase64Promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Content = reader.result.split(',')[1];
          resolve(base64Content);
        };
        reader.onerror = reject;
        reader.readAsDataURL(csvFile);
      });
      
      console.log('Reading CSV file');
      const csvBase64 = await csvBase64Promise;
      console.log('CSV read complete, length:', csvBase64.length);
      
      // Call the Cloud Function
      console.log('Calling restoreDocumentsMetadata function');
      const restoreDocumentsMetadata = httpsCallable(functions, 'restoreDocumentsMetadata');
      const result = await restoreDocumentsMetadata({
        csvBase64
      });
      
      console.log('Restore result:', result.data);
      setResults(result.data.results);
      setStep(3);
    } catch (err) {
      console.error('Restore error:', err);
      setError(err.message || 'Failed to restore documents');
    } finally {
      setIsRestoring(false);
    }
  };
  
  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="inline-flex items-center px-4 py-2 ml-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        <Database className="h-4 w-4 mr-2" />
        Import Metadata
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={
          step === 1 ? "Import Document Metadata - Select CSV" :
          step === 2 ? "Import Document Metadata - Confirm" :
          "Import Document Metadata - Results"
        }
        showButtons={false}
      >
        <div className="p-6">
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-start">
              <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 mr-2 flex-shrink-0" />
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}
          
          {step === 1 && (
            <div className="space-y-6">
              <div className="flex flex-col space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Select CSV file with document metadata
                </label>
                <p className="text-sm text-gray-500">
                  This will apply ID, labels, categories, and comments from the CSV to matching documents that have already been uploaded.
                </p>
                <div className="flex items-center mt-1">
                  <input
                    ref={csvInputRef}
                    type="file"
                    className="hidden"
                    accept=".csv"
                    onChange={handleCsvSelect}
                  />
                  <button
                    onClick={() => csvInputRef.current.click()}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <FileSpreadsheet className="h-5 w-5 mr-2 text-gray-500" />
                    Choose CSV File
                  </button>
                  {csvFile && (
                    <span className="ml-3 text-sm text-gray-600">
                      {csvFile.name} ({(csvFile.size / 1024).toFixed(2)} KB)
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {step === 2 && (
            <div className="space-y-4">
              <p className="text-sm text-gray-700">
                You are about to import metadata from {csvFile?.name}.
                This operation will:
              </p>
              <ul className="list-disc pl-5 text-sm text-gray-600 space-y-1">
                <li>Match documents by filename</li>
                <li>Set document IDs, labels, categories, and comments as defined in the CSV</li>
                <li>Mark documents as labeled and categorized</li>
              </ul>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <AlertCircle className="h-5 w-5 text-yellow-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      This operation will overwrite any existing metadata on matching documents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {step === 3 && results && (
            <div className="space-y-4">
              <div className="flex items-center justify-center space-x-8 mb-6">
                <div className="text-center">
                  <div className="text-3xl font-bold text-primary mb-1">{results.successCount}</div>
                  <div className="text-sm text-gray-500">Successfully updated</div>
                </div>
                <div className="text-center">
                  <div className="text-3xl font-bold text-red-500 mb-1">{results.failureCount}</div>
                  <div className="text-sm text-gray-500">Failed to update</div>
                </div>
              </div>
              
              {results.successCount > 0 && (
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Successfully Updated Documents</h4>
                  <div className="max-h-40 overflow-y-auto border border-gray-200 rounded-md">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Document Name</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {results.successDetails.map((item, index) => (
                          <tr key={index}>
                            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                              <div className="flex items-center">
                                <Check className="h-4 w-4 text-green-500 mr-2" />
                                {item.originalName || (item.record && item.record.Name)}
                              </div>
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                              {item.id}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              
              {results.failureCount > 0 && (
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Failed Updates</h4>
                  <div className="max-h-40 overflow-y-auto border border-gray-200 rounded-md">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Document Name</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Error</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {results.failureDetails.map((item, index) => (
                          <tr key={index}>
                            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                              <div className="flex items-center">
                                <X className="h-4 w-4 text-red-500 mr-2" />
                                {item.record.Name || "Unknown"}
                              </div>
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap text-sm text-red-500">
                              {item.error}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
          
          <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
            {step < 3 ? (
              <>
                <button
                  onClick={closeModal}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
                >
                  Cancel
                </button>
                {step > 1 && (
                  <button
                    onClick={goToPreviousStep}
                    disabled={isRestoring}
                    className="px-4 py-2 text-sm text-blue-600 hover:text-blue-700 disabled:opacity-50"
                  >
                    Back
                  </button>
                )}
                <button
                  onClick={goToNextStep}
                  disabled={isRestoring}
                  className="px-4 py-2 text-sm text-white bg-blue-600 rounded hover:bg-blue-700 disabled:opacity-50 inline-flex items-center"
                >
                  {isRestoring ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      {step === 1 ? "Processing..." : "Importing..."}
                    </>
                  ) : (
                    step === 1 ? "Next" : "Start Import"
                  )}
                </button>
              </>
            ) : (
              <button
                onClick={closeModal}
                className="px-4 py-2 text-sm text-white bg-blue-600 rounded hover:bg-blue-700"
              >
                Close
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RestoreDocuments;