import React, { useState, useRef, useEffect } from 'react';
import Sidebar from './Sidebar';
import { Menu, RefreshCw } from 'lucide-react';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../contexts/ConfigContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const ALLOWED_UNREGISTERED_PATHS = ['/login', '/signup', '/reset-password'];

const Layout = ({ children, mainContentRef, showSidebar = true }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { currentUser, isAdmin, isRegistrationComplete } = useAuth();
  const { config } = useConfig();
  const location = useLocation();
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (mainContentRef.current) {
        mainContentRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
  
    window.addEventListener('followUpQuery', handleScroll);
  
    return () => {
      window.removeEventListener('followUpQuery', handleScroll);
    };
  }, [mainContentRef]);

  // Version check effect
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const db = getFirestore();
        const configRef = doc(db, 'appConfiguration', 'core');
        const configSnap = await getDoc(configRef);
        
        if (configSnap.exists()) {
          const serverVersion = configSnap.data().version;
          const currentVersion = localStorage.getItem('appVersion');
        
          
          if (serverVersion && currentVersion !== serverVersion) {
            setShowUpdateBanner(true);
          }
        }
      } catch (error) {
        console.error('Error checking version:', error);
      }
    };

    // Initial version set if not exists
    if (config?.core?.version && !localStorage.getItem('appVersion')) {
      localStorage.setItem('appVersion', config.core.version);
    }

    // Check immediately and then every 5 minutes
    checkVersion();
    const interval = setInterval(checkVersion, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [config]);

  const handleRefresh = () => {
    window.location.reload(true);
  };

  // Show full layout for auth routes and when registration is complete
  const shouldShowFullLayout = isRegistrationComplete || ALLOWED_UNREGISTERED_PATHS.includes(location.pathname);

  // If we're on a protected route and registration is not complete, show minimal layout
  if (!shouldShowFullLayout && currentUser) {
    return (
      <div className="flex h-screen bg-gray-100 overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
            {children}
          </div>
        </main>
      </div>
    );
  }

  const bannerClasses = `
    w-full 
    px-4 py-2 text-center
    font-medium text-sm
    border-b
    animate-bannerSlideDown
    transition-all duration-300
  `;

  return (
    <div className="flex flex-col h-screen bg-gray-100 overflow-hidden">
      {/* Update Banner */}
      {showUpdateBanner && (
        <div className={`${bannerClasses} bg-primary text-white`}>
          <div className="flex items-center justify-center gap-2">
            <span>A new version is available!</span>
            <button
              onClick={handleRefresh}
              className="inline-flex items-center px-3 py-1 rounded-full bg-white/20 hover:bg-white/30 transition-colors duration-200"
            >
              <RefreshCw className="h-4 w-4 mr-1" />
              Refresh now
            </button>
          </div>
        </div>
      )}
      
      {/* Test Mode Banner */}
      {config?.core?.isProduction === false && (
        <div className={`${bannerClasses} bg-white text-accent`}>
          📢 Setup Mode: This is a work-in-progress environment. Data might be incomplete or inaccurate.
        </div>
      )}
      
      <div className="flex flex-1 overflow-hidden">
        {!ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) && (
          <Sidebar 
            open={sidebarOpen} 
            setOpen={setSidebarOpen} 
            isAdmin={isAdmin}
          />
        )}
        <div className="flex-1 flex flex-col overflow-hidden">
          {!ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) && (
            <header className="bg-white shadow-sm lg:hidden sticky top-0 z-10">
              <div className="px-4 py-2 flex items-center justify-between">
                <button
                  onClick={() => setSidebarOpen(true)}
                  className="text-gray-500 focus:outline-none focus:text-gray-700"
                >
                  <Menu className="h-6 w-6" />
                </button>
                <img src={logo} alt="Logo" className="h-8 w-auto" />
                <div className="w-6" /> {/* Spacer for alignment */}
              </div>
            </header>
          )}
          <main 
            ref={mainContentRef} 
            className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100"
          >
            <div className={`container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 ${
              ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) ? 'max-w-md' : ''
            }`}>
              {React.cloneElement(children, { isAdmin, mainContentRef })}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;