import React, { useState, useEffect, useRef } from 'react';
import { Tag, FolderTree, X, ChevronDown, Check, Search } from 'lucide-react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const FilterSelector = ({ selectedFilters, onFilterChange, type = 'category' }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // Determine which collection to query based on type
        const configDoc = type === 'category' 
          ? await getDoc(doc(db, 'DocsCategories', 'Config'))
          : await getDoc(doc(db, 'DocsLabels', 'Config'));
        
        if (!configDoc.exists()) {
          setItems([]);
          return;
        }
        
        const configData = configDoc.data();
        const currentVersionId = configData.currentVersion;
        
        if (!currentVersionId) {
          setItems([]);
          return;
        }
        
        // Get the current version document
        const versionDoc = type === 'category'
          ? await getDoc(doc(db, 'DocsCategories', 'Config', 'versions', currentVersionId))
          : await getDoc(doc(db, 'DocsLabels', 'Config', 'versions', currentVersionId));
        
        if (!versionDoc.exists()) {
          setItems([]);
          return;
        }
        
        const versionData = versionDoc.data();
        const itemsList = type === 'category' ? versionData.categories : versionData.labels;
        
        setItems(itemsList || []);
        setFilteredItems(itemsList || []);
      } catch (err) {
        console.error(`Error fetching ${type} items:`, err);
        setError(`Failed to load ${type} filters`);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchItems();
  }, [type]);

  // Filter items based on search query
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredItems(items);
      return;
    }

    const normalizedQuery = searchQuery.toLowerCase().trim();
    
    // Support Hebrew - handle with or without Hebrew prefix ל
    const hebrewPrefix = 'ל';
    let queryWithoutPrefix = normalizedQuery;
    let queryWithPrefix = normalizedQuery;
    
    if (normalizedQuery.startsWith(hebrewPrefix)) {
      queryWithoutPrefix = normalizedQuery.substring(1);
    } else {
      queryWithPrefix = hebrewPrefix + normalizedQuery;
    }
    
    // Score function to prioritize results (beginning > word boundary > anywhere)
    const getMatchScore = (text, searchTerm) => {
      const lowerText = text.toLowerCase();
      
      // Check if it starts with the query (highest priority)
      if (lowerText.startsWith(searchTerm)) return 3;
      
      // Check if it contains the query at a word boundary (medium priority)
      const wordBoundaryRegex = new RegExp(`\\b${searchTerm}`, 'i');
      if (wordBoundaryRegex.test(text)) return 2;
      
      // Check if it contains the query anywhere (lowest priority)
      if (lowerText.includes(searchTerm)) return 1;
      
      return 0;
    };
    
    // Filter and sort items by relevance
    const filtered = items
      .map(item => {
        // Get the highest match score from the different query variations
        const score = Math.max(
          getMatchScore(item, normalizedQuery),
          getMatchScore(item, queryWithoutPrefix),
          getMatchScore(item, queryWithPrefix)
        );
        return { item, score };
      })
      .filter(result => result.score > 0) // Only include matches
      .sort((a, b) => b.score - a.score) // Sort by score (highest first)
      .map(result => result.item); // Extract just the item name
    
    setFilteredItems(filtered);
  }, [searchQuery, items]);

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Focus on search input when dropdown opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
    }
  }, [isOpen]);

  const toggleFilter = (item) => {
    if (selectedFilters.includes(item)) {
      onFilterChange(selectedFilters.filter(i => i !== item));
    } else {
      onFilterChange([...selectedFilters, item]);
    }
  };

  const clearFilters = () => {
    onFilterChange([]);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center gap-2 px-3 py-2 text-sm rounded-md hover:bg-gray-50 transition-colors ${
          type === 'category' 
            ? 'text-purple-700 border-b border-purple-200 hover:bg-purple-50' 
            : 'text-amber-700 border-b border-amber-200 hover:bg-amber-50'
        }`}
      >
        {type === 'category' ? (
          <FolderTree className={`w-4 h-4 ${selectedFilters.length > 0 ? 'text-purple-500' : 'text-gray-500'}`} />
        ) : (
          <Tag className={`w-4 h-4 ${selectedFilters.length > 0 ? 'text-amber-500' : 'text-gray-500'}`} />
        )}
        <span className="capitalize">
          {type === 'category' ? 'Categories' : `${type}s`}
        </span>
        {selectedFilters.length > 0 && (
          <span className={`px-1.5 py-0.5 text-xs rounded-full ${
            type === 'category' 
              ? 'bg-purple-100 text-purple-700' 
              : 'bg-amber-100 text-amber-700'
          }`}>
            {selectedFilters.length}
          </span>
        )}
        <ChevronDown className="w-4 h-4 text-gray-400" />
      </button>

      {isOpen && (
        <div className={`absolute z-10 mt-1 w-64 bg-white rounded-md shadow-lg border ${
          type === 'category' ? 'border-purple-200' : 'border-amber-200'
        } overflow-hidden`}>
          <div className={`p-2 border-b ${
            type === 'category' ? 'border-purple-100 bg-purple-50' : 'border-amber-100 bg-amber-50'
          } flex justify-between items-center`}>
            <span className={`text-sm font-medium capitalize ${
              type === 'category' ? 'text-purple-700' : 'text-amber-700'
            }`}>
              {type} Filters
            </span>
            {selectedFilters.length > 0 && (
              <button
                onClick={clearFilters}
                className={`text-xs ${
                  type === 'category' ? 'text-purple-600 hover:text-purple-800' : 'text-amber-600 hover:text-amber-800'
                }`}
              >
                Clear all
              </button>
            )}
          </div>
          
          {/* Search input */}
          <div className="p-2 border-b">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                <Search className="w-4 h-4 text-gray-400" />
              </div>
              <input
                ref={searchInputRef}
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={`Search ${type}s...`}
                className={`w-full py-1.5 pl-8 pr-3 text-sm border rounded-md focus:outline-none focus:ring-1 ${
                  type === 'category' 
                    ? 'focus:ring-purple-500 focus:border-purple-500' 
                    : 'focus:ring-amber-500 focus:border-amber-500'
                }`}
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <X className="w-3.5 h-3.5 text-gray-400 hover:text-gray-600" />
                </button>
              )}
            </div>
          </div>
          
          <div className="max-h-64 overflow-y-auto p-1">
            {isLoading ? (
              <div className="flex justify-center items-center p-4">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : error ? (
              <div className="p-3 text-sm text-red-500">{error}</div>
            ) : filteredItems.length === 0 ? (
              <div className="p-3 text-sm text-gray-500">
                {searchQuery ? `No ${type}s matching "${searchQuery}"` : `No ${type}s available`}
              </div>
            ) : (
              <div className="flex flex-col">
                {filteredItems.map((item) => (
                  <button
                    key={item}
                    onClick={() => toggleFilter(item)}
                    className={`flex items-center gap-2 px-3 py-2 text-sm text-left transition-colors ${
                      selectedFilters.includes(item) 
                        ? type === 'category' 
                          ? 'bg-purple-50 text-purple-700' 
                          : 'bg-amber-50 text-amber-700'
                        : `hover:${type === 'category' ? 'bg-purple-50/30' : 'bg-amber-50/30'}`
                    }`}
                  >
                    <div className={`w-4 h-4 flex items-center justify-center border rounded ${
                      selectedFilters.includes(item) 
                        ? type === 'category'
                          ? 'border-purple-500 bg-purple-500 text-white' 
                          : 'border-amber-500 bg-amber-500 text-white'
                        : 'border-gray-300'
                    }`}>
                      {selectedFilters.includes(item) && (
                        <Check className="w-3 h-3" />
                      )}
                    </div>
                    <span>
                      {highlightMatch(item, searchQuery)}
                    </span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* REMOVED: We've removed the selectedFilters display section that was causing duplication */}
    </div>
  );
};

// Helper function to highlight the matching part of text
function highlightMatch(text, query) {
  if (!query || query.trim() === '') return text;
  
  const normalizedText = text.toLowerCase();
  const normalizedQuery = query.toLowerCase().trim();
  
  // Handle Hebrew prefix variations
  const hebrewPrefix = 'ל';
  const queryVariations = [
    normalizedQuery,
    normalizedQuery.startsWith(hebrewPrefix) ? normalizedQuery.substring(1) : normalizedQuery,
    !normalizedQuery.startsWith(hebrewPrefix) ? hebrewPrefix + normalizedQuery : normalizedQuery
  ];
  
  // Try to find the best match position using different query variations
  let bestMatchIndex = -1;
  let bestMatchLength = 0;
  let bestQueryVariation = normalizedQuery;
  
  // First try to find matches at the beginning (highest priority)
  for (const queryVar of queryVariations) {
    if (normalizedText.startsWith(queryVar)) {
      bestMatchIndex = 0;
      bestMatchLength = queryVar.length;
      bestQueryVariation = queryVar;
      break;
    }
  }
  
  // If no match at beginning, check for word boundaries (medium priority)
  if (bestMatchIndex === -1) {
    for (const queryVar of queryVariations) {
      const wordBoundaryRegex = new RegExp(`\\b${queryVar}`, 'i');
      const match = wordBoundaryRegex.exec(normalizedText);
      if (match) {
        bestMatchIndex = match.index;
        bestMatchLength = queryVar.length;
        bestQueryVariation = queryVar;
        break;
      }
    }
  }
  
  // If still no match, look anywhere in the text (lowest priority)
  if (bestMatchIndex === -1) {
    for (const queryVar of queryVariations) {
      const matchIndex = normalizedText.indexOf(queryVar);
      if (matchIndex !== -1) {
        bestMatchIndex = matchIndex;
        bestMatchLength = queryVar.length;
        bestQueryVariation = queryVar;
        break;
      }
    }
  }
  
  // If no match found, return original text
  if (bestMatchIndex === -1) return text;
  
  // Split text into parts: before match, match, after match
  const firstPart = text.substring(0, bestMatchIndex);
  const matchedPart = text.substring(bestMatchIndex, bestMatchIndex + bestMatchLength);
  const lastPart = text.substring(bestMatchIndex + bestMatchLength);
  
  return (
    <>
      {firstPart}
      <span className="font-bold text-blue-600">{matchedPart}</span>
      {lastPart}
    </>
  );
}

export default FilterSelector;