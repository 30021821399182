import React, { useState, useRef, useEffect } from 'react';
import { Lightbulb, X, Clock, FileText, ChevronDown, Copy, Check, Filter, Tag, FolderTree, File, Save } from 'lucide-react';

const AnalysisResultHeader = ({ 
  title, 
  onClose, 
  onCopy, 
  onSave,
  isSaving,
  isSaved,
  copySuccess, 
  metadata = {}, 
  documentLinks = [], 
  referencedDocumentIds, 
  selectedCategories = [], 
  selectedLabels = [], 
  selectedDocuments = [] 
}) => {
  const [showAnalyzedDocs, setShowAnalyzedDocs] = useState(false);
  const [showReferencedDocs, setShowReferencedDocs] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showSelectedDocs, setShowSelectedDocs] = useState(false);
  
  const analyzedDocsRef = useRef(null);
  const referencedDocsRef = useRef(null);
  const filtersRef = useRef(null);
  const selectedDocsRef = useRef(null);
  
  // Handle clicks outside of the dropdowns
  useEffect(() => {
    function handleClickOutside(event) {
      if (analyzedDocsRef.current && !analyzedDocsRef.current.contains(event.target)) {
        setShowAnalyzedDocs(false);
      }
      if (referencedDocsRef.current && !referencedDocsRef.current.contains(event.target)) {
        setShowReferencedDocs(false);
      }
      if (filtersRef.current && !filtersRef.current.contains(event.target)) {
        setShowFilters(false);
      }
      if (selectedDocsRef.current && !selectedDocsRef.current.contains(event.target)) {
        setShowSelectedDocs(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Function to handle document clicks
  const handleDocumentClick = (e, docUrl) => {
    e.preventDefault();
    window.open(docUrl, '_blank', 'noopener,noreferrer');
    // Close the dropdowns
    setShowAnalyzedDocs(false);
    setShowReferencedDocs(false);
    setShowFilters(false);
    setShowSelectedDocs(false);
  };
  
  // Filter for referenced documents only
  const referencedDocuments = documentLinks.filter((_, index) => referencedDocumentIds.has(index));
  
  const hasMetrics = metadata.documentsProcessed || metadata.processingTime;
  const hasReferencedDocuments = referencedDocuments.length > 0;
  const hasFilters = selectedCategories.length > 0 || selectedLabels.length > 0;
  
  // Display the provided title or fall back to "Document Analysis"
  const displayTitle = title || "Document Analysis";
  
  return (
    <div className="p-4 pb-2">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center flex-1 space-x-2">
          <div className="bg-amber-100 rounded-full p-1.5">
            <Lightbulb className="h-5 w-5 text-amber-600" />
          </div>
          <h3 className="font-medium text-gray-800">{displayTitle}</h3>
          
          <div className="ml-3 flex items-center space-x-2">
            <button 
              className="flex items-center text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded p-1 text-xs"
              aria-label="Copy analysis"
              onClick={onCopy}
              title="Copy analysis to clipboard"
            >
              {copySuccess ? (
                <span className="flex items-center text-green-600">
                  <Check size={14} className="mr-1" />
                  <span>Copied!</span>
                </span>
              ) : (
                <span className="flex items-center">
                  <Copy size={14} className="mr-1" />
                </span>
              )}
            </button>
            
            <button 
              className="flex items-center text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded p-1 text-xs"
              aria-label="Save analysis"
              onClick={onSave}
              title="Save analysis for later"
              disabled={isSaving || isSaved}
            >
              {isSaving ? (
                <span className="flex items-center text-blue-600">
                  <div className="animate-spin mr-1 h-3 w-3 border-2 border-blue-600 border-t-transparent rounded-full"></div>
                  <span>Saving...</span>
                </span>
              ) : isSaved ? (
                <span className="flex items-center text-green-600">
                  <Check size={14} className="mr-1" />
                  <span>Saved</span>
                </span>
              ) : (
                <span className="flex items-center">
                  <Save size={14} className="mr-1" />
                  <span>Save</span>
                </span>
              )}
            </button>
          </div>
        </div>
        
        <button 
          className="text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full p-1"
          aria-label="Close analysis"
          onClick={onClose}
        >
          <X size={18} />
        </button>
      </div>
      
      <div className="ml-9 flex items-center flex-wrap gap-4 text-sm text-gray-500">
        {hasMetrics && metadata.documentsProcessed && (
          <div className="relative" ref={analyzedDocsRef}>
            <button 
              onClick={() => {
                setShowAnalyzedDocs(!showAnalyzedDocs);
                setShowReferencedDocs(false);
                setShowFilters(false);
                setShowSelectedDocs(false);
              }}
              className="flex items-center cursor-pointer transition-colors duration-150 hover:text-primary"
            >
              <FileText className="h-4 w-4 mr-1.5 text-gray-400" />
              <span>
                {metadata.documentsWithContentAnalyzed || metadata.documentsProcessed} docs analyzed
              </span>
              <ChevronDown className={`h-3 w-3 ml-1 transition-transform ${showAnalyzedDocs ? 'rotate-180' : ''}`} />
            </button>
            
            {showAnalyzedDocs && documentLinks.length > 0 && (
              <div className="absolute z-10 mt-2 p-2 bg-white border border-gray-100 rounded shadow-md w-64 max-h-64 overflow-y-auto">
                <h4 className="text-xs font-medium text-gray-700 mb-2 pb-1 border-b border-gray-100">
                  All Analyzed Documents ({documentLinks.length})
                </h4>
                <div className="space-y-1">
                  {documentLinks.map((doc, index) => (
                    <a 
                      key={`all-doc-${index}`}
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => handleDocumentClick(e, doc.url)}
                      className={`flex items-center py-1 px-2 text-xs group hover:bg-gray-50 rounded ${
                        referencedDocumentIds.has(index) ? 'text-blue-600' : 'text-gray-600'
                      }`}
                    >
                      <FileText className={`h-3 w-3 mr-1.5 flex-shrink-0 ${
                        referencedDocumentIds.has(index) 
                          ? 'text-blue-400' 
                          : 'text-gray-400'
                      }`} />
                      <span className="truncate">
                        {doc.name}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        
        {hasReferencedDocuments && (
          <div className="relative" ref={referencedDocsRef}>
            <button 
              onClick={() => {
                setShowReferencedDocs(!showReferencedDocs);
                setShowAnalyzedDocs(false);
                setShowFilters(false);
                setShowSelectedDocs(false);
              }}
              className="flex items-center cursor-pointer transition-colors duration-150 hover:text-primary"
            >
              <FileText className="h-4 w-4 mr-1.5 text-gray-400" />
              <span>{referencedDocuments.length} docs referenced</span>
              <ChevronDown className={`h-3 w-3 ml-1 transition-transform ${showReferencedDocs ? 'rotate-180' : ''}`} />
            </button>
            
            {showReferencedDocs && referencedDocuments.length > 0 && (
              <div className="absolute z-10 mt-2 p-2 bg-white border border-gray-100 rounded shadow-md w-64 max-h-64 overflow-y-auto">
                <h4 className="text-xs font-medium text-gray-700 mb-2 pb-1 border-b border-gray-100">
                  Referenced Documents ({referencedDocuments.length})
                </h4>
                <div className="space-y-1">
                  {referencedDocuments.map((doc, index) => (
                    <a 
                      key={`ref-doc-${index}`}
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => handleDocumentClick(e, doc.url)}
                      className="flex items-center py-1 px-2 text-xs group hover:bg-gray-50 rounded text-blue-600"
                    >
                      <FileText className="h-3 w-3 mr-1.5 flex-shrink-0 text-blue-400" />
                      <span className="truncate">
                        {doc.name}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Selected Documents Dropdown */}
        {selectedDocuments && selectedDocuments.length > 0 && (
          <div className="relative" ref={selectedDocsRef}>
            <button 
              onClick={() => {
                setShowSelectedDocs(!showSelectedDocs);
                setShowAnalyzedDocs(false);
                setShowReferencedDocs(false);
                setShowFilters(false);
              }}
              className="flex items-center cursor-pointer transition-colors duration-150 hover:text-primary"
            >
              <File className="h-4 w-4 mr-1.5 text-gray-400" />
              <span>{selectedDocuments.length} docs selected</span>
              <ChevronDown className={`h-3 w-3 ml-1 transition-transform ${showSelectedDocs ? 'rotate-180' : ''}`} />
            </button>
            
            {showSelectedDocs && selectedDocuments.length > 0 && (
              <div className="absolute z-10 mt-2 p-2 bg-white border border-gray-100 rounded shadow-md w-64 max-h-64 overflow-y-auto">
                <h4 className="text-xs font-medium text-gray-700 mb-2 pb-1 border-b border-gray-100">
                  Selected Documents ({selectedDocuments.length})
                </h4>
                <div className="space-y-1">
                  {selectedDocuments.map((doc, index) => {
                    // Check if this document is in the documentLinks array
                    const documentLink = documentLinks.find(link => link.name === doc.name);
                    
                    return documentLink ? (
                      // If the document has a URL, make it clickable
                      <a 
                        key={`selected-doc-${index}`}
                        href={documentLink.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => handleDocumentClick(e, documentLink.url)}
                        className="flex items-center py-1 px-2 text-xs group hover:bg-gray-50 rounded text-blue-600"
                      >
                        <File className="h-3 w-3 mr-1.5 flex-shrink-0 text-blue-400" />
                        <span className="truncate">
                          {doc.name}
                        </span>
                      </a>
                    ) : (
                      // Otherwise, show as a non-clickable item
                      <div 
                        key={`selected-doc-${index}`}
                        className="flex items-center py-1 px-2 text-xs hover:bg-gray-50 rounded text-gray-600"
                      >
                        <File className="h-3 w-3 mr-1.5 flex-shrink-0 text-gray-400" />
                        <span className="truncate">
                          {doc.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        
        {hasFilters && (
          <div className="relative" ref={filtersRef}>
            <button 
              onClick={() => {
                setShowFilters(!showFilters);
                setShowAnalyzedDocs(false);
                setShowReferencedDocs(false);
                setShowSelectedDocs(false);
              }}
              className="flex items-center cursor-pointer transition-colors duration-150 hover:text-primary"
            >
              <Filter className="h-4 w-4 mr-1.5 text-gray-400" />
              <span>Filters applied</span>
              <ChevronDown className={`h-3 w-3 ml-1 transition-transform ${showFilters ? 'rotate-180' : ''}`} />
            </button>
            
            {showFilters && (
              <div className="absolute z-10 mt-2 p-2 bg-white border border-gray-100 rounded shadow-md w-64 max-h-64 overflow-y-auto">
                {selectedCategories.length > 0 && (
                  <div className="mb-3">
                    <h4 className="text-xs font-medium text-gray-700 mb-2 pb-1 border-b border-gray-100">
                      Categories
                    </h4>
                    <div className="flex flex-wrap gap-1.5">
                      {selectedCategories.map((category, index) => (
                        <span 
                          key={`category-${index}`}
                          className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-50 text-purple-700"
                        >
                          <FolderTree className="w-3 h-3 mr-1" />
                          {category}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                
                {selectedLabels.length > 0 && (
                  <div>
                    <h4 className="text-xs font-medium text-gray-700 mb-2 pb-1 border-b border-gray-100">
                      Labels
                    </h4>
                    <div className="flex flex-wrap gap-1.5">
                      {selectedLabels.map((label, index) => (
                        <span 
                          key={`label-${index}`}
                          className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-amber-50 text-amber-700"
                        >
                          <Tag className="w-3 h-3 mr-1" />
                          {label}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        
        {metadata.processingTime && (
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1.5 text-gray-400" />
            <span>{metadata.processingTime}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalysisResultHeader;