import React, { useMemo } from 'react';
import { Database, Filter, Info } from 'lucide-react';

// Helper function to detect if text is primarily Hebrew
const isHebrewText = (text) => {
  if (!text) return false;
  
  // Hebrew Unicode range (0x0590-0x05FF)
  const hebrewPattern = /[\u0590-\u05FF]/g;
  const hebrewMatches = text.match(hebrewPattern) || [];
  
  // If more than 40% of characters are Hebrew, consider it Hebrew text
  return hebrewMatches.length > text.length * 0.4;
};

const MetadataSection = ({ icon: Icon, title, items, badgeColorClass, isRtl, onRemoveFilter, isFilterSection, onRemoveTable, isTableSection }) => {
  if (!items || items.length === 0) return null;
  
  const validItems = items.filter(item => item && item.trim().length > 0);
  if (validItems.length === 0) return null;
  
  const isClickable = isFilterSection || isTableSection;
  const tooltipText = isFilterSection ? 'Click to remove filter' : isTableSection ? 'Click to remove table' : '';
  const handleClick = (item) => {
    if (isFilterSection && onRemoveFilter) {
      onRemoveFilter(item);
    } else if (isTableSection && onRemoveTable) {
      onRemoveTable(item);
    }
  };
  
  return (
    <div className={`flex items-start ${isRtl ? 'space-x-reverse space-x-2' : 'space-x-2'} flex-shrink-0`}>
      <div className="mt-1">
        <Icon size={16} className="text-gray-600" />
      </div>
      <div className={isRtl ? 'text-right' : ''}>
        <div className="font-medium text-gray-700 mb-1">{title}</div>
        <div className="flex flex-wrap gap-2">
          {validItems.map((item, index) => (
            <span
            key={index}
            className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs ${badgeColorClass} ${
              isClickable ? 'cursor-pointer hover:bg-red-100 hover:text-red-700 transition-colors relative group' : ''
            }`}
            dir={isRtl ? 'rtl' : 'ltr'}
            onClick={isClickable ? () => handleClick(item) : undefined}
            title={isClickable ? `Click to remove ${isFilterSection ? 'filter' : 'table'}: ${item}` : ''}
          >
            {item}
            {isClickable && (
              <>
                <span className={`${isRtl ? 'mr-1.5' : 'ml-1.5'} text-gray-500 group-hover:text-red-500`}>×</span>
                <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                  {tooltipText}
                </span>
              </>
            )}
          </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const formatTableName = (fullTableId) => {
  if (!fullTableId) return '';
  
  // Remove backticks if present
  const cleanTableId = fullTableId.replace(/`/g, '');
  
  // Split by dots and get the last part (table name)
  const parts = cleanTableId.split('.');
  const tableName = parts[parts.length - 1];
  
  // Split by underscores and format each word
  return tableName
    .split('_')
    .map(word => {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

const QueryExplanation = ({ 
  explanation,
  isCollapsed = false,
  onToggleCollapse,
  className = '',
  isLoading = false,
  onRemoveFilter,
  onRemoveTable
}) => {
  const parsedExplanation = useMemo(() => {
    if (!explanation) return null;
    
    let parsed;
    
    // If explanation is a string, try to parse it as JSON first
    if (typeof explanation === 'string') {
      try {
        parsed = JSON.parse(explanation);
        console.log('Successfully parsed explanation string as JSON:', parsed);
      } catch (e) {
        console.log('Failed to parse explanation string as JSON, using as-is');
        return { 
          explanation: explanation,
          title: '',
          tables: [],
          filters: [],
          logic_by_column: {}
        };
      }
    } else {
      // Otherwise use the object directly
      parsed = explanation;
      console.log('Using explanation as object:', parsed);
    }
  
    // Safety check - if parsed is null or not an object, create a default
    if (!parsed || typeof parsed !== 'object') {
      console.log('Explanation not valid, using default empty structure');
      return { 
        explanation: explanation?.toString() || '',
        title: '',
        tables: [],
        filters: [],
        logic_by_column: {}
      };
    }
  
    // Format table names if they exist
    if (Array.isArray(parsed.tables)) {
      parsed.tables = parsed.tables.map(formatTableName);
    }
  
    // Create a clean object with all required fields
    const cleanParsed = {
      // Make sure all fields exist with defaults if not present
      explanation: parsed.explanation || '',
      title: parsed.title || '',
      tables: Array.isArray(parsed.tables) ? parsed.tables : [],
      filters: Array.isArray(parsed.filters) ? parsed.filters : [],
      logic_by_column: parsed.logic_by_column || {}
    };
  
    // Process column logic data to ensure consistent format
    const processedLogicByColumn = {};
    
    if (typeof cleanParsed.logic_by_column === 'object' && cleanParsed.logic_by_column !== null) {
      Object.keys(cleanParsed.logic_by_column).forEach(column => {
        const columnData = cleanParsed.logic_by_column[column];
        
        if (typeof columnData === 'string') {
          // If it's just a string, use it as the logic
          processedLogicByColumn[column] = {
            logic: columnData,
            example: ''
          };
        } else if (typeof columnData === 'object' && columnData !== null) {
          // If it's an object, extract logic and example
          processedLogicByColumn[column] = {
            logic: columnData.logic || '',
            example: columnData.example || ''
          };
        } else {
          // Default empty values
          processedLogicByColumn[column] = {
            logic: '',
            example: ''
          };
        }
      });
    }
    
    cleanParsed.logic_by_column = processedLogicByColumn;
    
    console.log('Final parsed explanation:', cleanParsed);
    return cleanParsed;
  }, [explanation]);

  // Detect if content is primarily Hebrew
  const isRtl = useMemo(() => {
    if (!parsedExplanation) return false;
    return isHebrewText(parsedExplanation.explanation);
  }, [parsedExplanation]);

  if (!parsedExplanation && !isLoading) return null;

  if (isLoading) {
    return (
      <div className={`bg-white rounded-lg border border-gray-100 ${className}`}>
        <div className="px-4 py-4">
          <div className="flex flex-col md:flex-row md:gap-8 md:items-start">
            <div className="flex items-start space-x-2 mb-6 md:mb-0 md:w-7/12">
              <div className="mt-1 flex-shrink-0">
                <Info size={16} className="text-gray-300" />
              </div>
              <div className="space-y-3 w-full">
                <div className="h-4 bg-gray-100 rounded w-3/4 relative overflow-hidden">
                  <div className="absolute inset-0">
                    <div 
                      className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-200 to-transparent"
                      style={{
                        animation: 'wave 1s infinite linear',
                        width: '200%',
                        transform: 'translateX(-50%)'
                      }}
                    />
                  </div>
                </div>
                <div className="h-4 bg-gray-100 rounded w-1/2 relative overflow-hidden">
                  <div className="absolute inset-0">
                    <div 
                      className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-200 to-transparent"
                      style={{
                        animation: 'wave 1s infinite linear',
                        width: '200%',
                        transform: 'translateX(-50%)',
                        animationDelay: '0.1s'
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:gap-8 md:w-5/12">
              <div className="md:w-2/5">
                <div className="flex items-start space-x-2">
                  <Database size={16} className="text-gray-300" />
                  <div className="space-y-2 w-full">
                    <div className="h-3 bg-gray-100 rounded w-full relative overflow-hidden">
                      <div className="absolute inset-0">
                        <div 
                          className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-200 to-transparent"
                          style={{
                            animation: 'wave 1s infinite linear',
                            width: '200%',
                            transform: 'translateX(-50%)',
                            animationDelay: '0.2s'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-3/5">
                <div className="flex items-start space-x-2">
                  <Filter size={16} className="text-gray-300" />
                  <div className="space-y-2 w-full">
                    <div className="h-3 bg-gray-100 rounded w-full relative overflow-hidden">
                      <div className="absolute inset-0">
                        <div 
                          className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-200 to-transparent"
                          style={{
                            animation: 'wave 1s infinite linear',
                            width: '200%',
                            transform: 'translateX(-50%)',
                            animationDelay: '0.3s'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`
          @keyframes wave {
            0% {
              transform: translateX(-50%);
            }
            100% {
              transform: translateX(0%);
            }
          }
        `}</style>
      </div>
    );
  }

  // Translations for section headers
  const translations = {
    tablesUsed: {
      en: "Tables Used",
      he: "טבלאות"
    },
    filtersApplied: {
      en: "Filters Applied",
      he: "פילטרים"
    }
  };

  const sections = [
    {
      icon: Database,
      title: isRtl ? translations.tablesUsed.he : translations.tablesUsed.en,
      items: parsedExplanation.tables || [],
      badgeColorClass: "bg-blue-50 text-gray-800",
      isFilterSection: false,
      isTableSection: true
    },
    {
      icon: Filter,
      title: isRtl ? translations.filtersApplied.he : translations.filtersApplied.en,
      items: parsedExplanation.filters || [],
      badgeColorClass: "bg-accent/10 text-gray-800",
      isFilterSection: true,
      isTableSection: false
    }
  ].filter(section => section.items && section.items.length > 0);

  return (
    <div className={`bg-white rounded-lg border border-gray-100 ${className}`} dir={isRtl ? 'rtl' : 'ltr'}>
      <div 
        className={`transition-all duration-300 ease-in-out overflow-hidden cursor-pointer hover:bg-gray-50 ${
          isCollapsed ? 'max-h-0' : 'max-h-screen'
        }`}
        onClick={onToggleCollapse}
      >
        <div className="px-4 py-4">
          <div className="flex flex-col md:flex-row md:gap-8 md:items-start">
            <div className={`flex items-start ${isRtl ? 'space-x-reverse space-x-2' : 'space-x-2'} mb-6 md:mb-0 md:w-7/12`}>
              <div className="mt-1 flex-shrink-0">
                <Info size={16} className="text-gray-600" />
              </div>
              <div className={isRtl ? 'text-right' : ''}>
                <p className="text-gray-700 leading-relaxed">
                  {parsedExplanation.explanation}
                </p>
              </div>
            </div>

            <div className={`flex flex-col md:flex-row md:gap-8 md:w-5/12 ${isRtl ? 'md:flex-row-reverse' : ''}`}>
              {sections.map((section, index) => (
                <div key={index} className={`${index === 0 ? 'md:w-2/5' : 'md:w-3/5'}`}>
                  <MetadataSection 
                    {...section} 
                    isRtl={isRtl}
                    onRemoveFilter={onRemoveFilter}
                    onRemoveTable={onRemoveTable}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryExplanation;