// src/components/Docs/AutoLabeling.js
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AlertCircle, Tag, RefreshCw, FileText } from 'lucide-react';
import Card from '../Card';
import { useAutoLabeling } from '../../hooks/useAutoLabeling';
import AutoLabelProgress from './AutoLabelProgress';

const AutoLabeling = ({ 
  results, 
  setResults, 
  batchProgress, 
  setBatchProgress, 
  isProcessing, 
  setIsProcessing, 
  error, 
  setError 
}) => {
  const [labelMode, setLabelMode] = useState('processing');
  const [initiatingProcess, setInitiatingProcess] = useState(false);
  const [currentBatchId, setCurrentBatchId] = useState(() => {
    // Initialize from localStorage if available
    return localStorage.getItem('currentBatchId');
  });
  const functions = getFunctions();
  
  const { setBatchId, clearBatchId } = useAutoLabeling({
    isProcessing,
    setIsProcessing,
    setError,
    setResults,
    setBatchProgress,
  });

  // Update localStorage when currentBatchId changes
  useEffect(() => {
    if (currentBatchId) {
      localStorage.setItem('currentBatchId', currentBatchId);
    } else {
      localStorage.removeItem('currentBatchId');
    }
  }, [currentBatchId]);

  // Clear processing state on unmount or error
  useEffect(() => {
    return () => {
      // Don't clear localStorage on unmount
      setIsProcessing(false);
    };
  }, [setIsProcessing]);

  useEffect(() => {
    if (error) {
      clearBatchId();
      setIsProcessing(false);
      setInitiatingProcess(false);
      localStorage.removeItem('currentBatchId');
      setCurrentBatchId(null);
    }
  }, [error, clearBatchId, setIsProcessing]);

  // Initialize processing state if there's a stored batchId
  useEffect(() => {
    if (currentBatchId && !isProcessing) {
      setBatchId(currentBatchId);
      setIsProcessing(true);
    }
    // Add this check for completion
    if (batchProgress && batchProgress.status === 'completed') {
      setIsProcessing(false);
      setInitiatingProcess(false);
      // Don't clear the currentBatchId yet so the results stay visible
    }
  }, [currentBatchId, isProcessing, setBatchId, setIsProcessing, batchProgress]);

  const handleAutoLabel = async () => {
    setInitiatingProcess(true);
    setIsProcessing(true);
    setError(null);
    setResults(null);
    setCurrentBatchId(null);
    setBatchProgress({
      totalDocuments: 0,
      processedDocuments: 0,
      failedDocuments: 0,
      status: 'initializing',
      currentBatch: 0,
      totalBatches: 0,
      errors: []
    });
    clearBatchId();
    localStorage.removeItem('currentBatchId');

    try {
      const initiateBatchProcessing = httpsCallable(functions, 'initiateBatchProcessing');
      const processNextBatch = httpsCallable(functions, 'processNextBatch');
      
      const response = await initiateBatchProcessing({ mode: labelMode });
      
      if (response.data.success && response.data.batchProcessingId) {
        setCurrentBatchId(response.data.batchProcessingId);
        setBatchId(response.data.batchProcessingId);
        
        try {
          await processNextBatch({ batchId: response.data.batchProcessingId });
        } catch (processError) {
          console.error('Error processing first batch:', processError);
          setError(processError.message || 'Failed to process first batch');
          clearBatchId();
          setIsProcessing(false);
          localStorage.removeItem('currentBatchId');
          setCurrentBatchId(null);
        }
      } else {
        throw new Error('Invalid response from batch initialization');
      }
    } catch (err) {
      console.error('Auto-labeling error:', err);
      setError(err.message || 'Failed to start processing');
      clearBatchId();
      setIsProcessing(false);
      localStorage.removeItem('currentBatchId');
      setCurrentBatchId(null);
    } finally {
      setInitiatingProcess(false);
    }
  };

  return (
    <Card>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Auto Labeling</h3>
            <div className="flex items-center gap-4">
              <select
                value={labelMode}
                onChange={(e) => setLabelMode(e.target.value)}
                className="px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isProcessing || initiatingProcess}
              >
                <option value="processing">New Only</option>
                <option value="all">All Documents</option>
              </select>
              <button
                onClick={handleAutoLabel}
                disabled={isProcessing || initiatingProcess}
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-white transition-colors ${
                  isProcessing || initiatingProcess
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-primary hover:bg-opacity-90'
                }`}
              >
                {isProcessing || initiatingProcess ? (
                  <>
                    <RefreshCw className="h-4 w-4 animate-spin" />
                    {initiatingProcess ? 'Initializing...' : 'Processing...'}
                  </>
                ) : (
                  <>
                    <Tag className="h-4 w-4" />
                    Auto Label Documents
                  </>
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md flex items-center gap-2">
              <AlertCircle className="h-5 w-5" />
              <span>{error}</span>
            </div>
          )}

          {/* Real-time progress tracker */}
          {currentBatchId && (
            <AutoLabelProgress batchId={currentBatchId} />
          )}

          {results && results.success && (
            <div className="mt-4 p-4 bg-green-50 rounded-md">
              <h4 className="font-medium text-green-700 mb-2">Processing Complete</h4>
              <p className="text-green-600">
                {results.totalProcessed} documents were labeled.
                {results.failed > 0 ? ` (${results.failed} failed)` : ''}
              </p>
              {batchProgress && batchProgress.results && batchProgress.results.length > 0 && (
                <div className="mt-4">
                  <h5 className="font-medium text-green-700 mb-2">Successfully Labeled Documents:</h5>
                  <div className="space-y-3">
                    {batchProgress.results.map((result, index) => (
                      <div key={index} className="flex items-start gap-2 bg-white p-3 rounded-lg shadow-sm">
                        <FileText className="h-5 w-5 text-gray-500 flex-shrink-0 mt-0.5" />
                        <div className="flex-1">
                          <div className="font-medium text-gray-900 mb-1.5">{result.name}</div>
                          <div className="flex flex-wrap gap-2">
                            {result.suggestedLabels.map((labelObj, labelIndex) => (
                              <span
                                key={labelIndex}
                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium whitespace-nowrap bg-secondary/50 text-black"
                              >
                                {labelObj.label}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {batchProgress && batchProgress.errors && batchProgress.errors.length > 0 && (
                <div className="mt-4">
                  <h5 className="font-medium text-red-700 mb-2">Failed Documents:</h5>
                  <div className="space-y-2">
                    {batchProgress.errors.map((error, index) => (
                      <div key={index} className="text-sm text-red-600">
                        {error.name}: {error.error}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default AutoLabeling;