// src/components/Docs/AutoCategorize.js
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AlertCircle, FolderTree, RefreshCw, FileText } from 'lucide-react';
import Card from '../Card';
import { useAutoCategories } from '../../hooks/useAutoCategories';
import AutoCategorizeProgress from './AutoCategorizeProgress';

const formatSuggestedCategories = (suggestedCategories) => {
  if (!Array.isArray(suggestedCategories)) return [];
  
  // Ensure each category has both category and explanation fields
  return suggestedCategories.map(cat => {
    if (typeof cat === 'string') {
      return {
        category: cat,
        explanation: ''
      };
    }
    return {
      category: cat.category || '',
      explanation: cat.explanation || ''
    };
  });
};

const AutoCategorize = () => {
  const [results, setResults] = useState(null);
  const [batchProgress, setBatchProgress] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [categorizeMode, setCategorizeMode] = useState('processing');
  const [initiatingProcess, setInitiatingProcess] = useState(false);
  const [currentBatchId, setCurrentBatchId] = useState(() => {
    return localStorage.getItem('currentCategorizeBatchId');
  });
  
  const functions = getFunctions();
  
  const { setBatchId, clearBatchId } = useAutoCategories({
    isProcessing,
    setIsProcessing,
    setError,
    setResults,
    setBatchProgress,
  });

  useEffect(() => {
    if (currentBatchId) {
      localStorage.setItem('currentCategorizeBatchId', currentBatchId);
    } else {
      localStorage.removeItem('currentCategorizeBatchId');
    }
  }, [currentBatchId]);

  useEffect(() => {
    return () => {
      setIsProcessing(false);
    };
  }, [setIsProcessing]);

  useEffect(() => {
    if (error) {
      clearBatchId();
      setIsProcessing(false);
      setInitiatingProcess(false);
      localStorage.removeItem('currentCategorizeBatchId');
      setCurrentBatchId(null);
    }
  }, [error, clearBatchId, setIsProcessing]);

  useEffect(() => {
    if (currentBatchId && !isProcessing) {
      setBatchId(currentBatchId);
      setIsProcessing(true);
    }
    if (batchProgress?.status === 'completed') {
      setIsProcessing(false);
      setInitiatingProcess(false);
    }
  }, [currentBatchId, isProcessing, setBatchId, setIsProcessing, batchProgress]);

  const handleAutoCategories = async () => {
    setInitiatingProcess(true);
    setIsProcessing(true);
    setError(null);
    setResults(null);
    setCurrentBatchId(null);
    setBatchProgress({
      totalDocuments: 0,
      processedDocuments: 0,
      failedDocuments: 0,
      status: 'initializing',
      currentBatch: 0,
      totalBatches: 0,
      errors: []
    });
    clearBatchId();
    localStorage.removeItem('currentCategorizeBatchId');

    try {
      const initiateBatchCategorization = httpsCallable(functions, 'initiateBatchCategorization');
      const processNextCategoryBatch = httpsCallable(functions, 'processNextCategoryBatch');
      
      const response = await initiateBatchCategorization({ mode: categorizeMode });
      
      if (response.data.success && response.data.batchProcessingId) {
        setCurrentBatchId(response.data.batchProcessingId);
        setBatchId(response.data.batchProcessingId);
        
        try {
          await processNextCategoryBatch({ batchId: response.data.batchProcessingId });
        } catch (processError) {
          console.error('Error processing first batch:', processError);
          setError(processError.message || 'Failed to process first batch');
          clearBatchId();
          setIsProcessing(false);
          localStorage.removeItem('currentCategorizeBatchId');
          setCurrentBatchId(null);
        }
      } else {
        throw new Error('Invalid response from batch initialization');
      }
    } catch (err) {
      console.error('Auto-categorization error:', err);
      setError(err.message || 'Failed to start processing');
      clearBatchId();
      setIsProcessing(false);
      localStorage.removeItem('currentCategorizeBatchId');
      setCurrentBatchId(null);
    } finally {
      setInitiatingProcess(false);
    }
  };

  const renderResults = () => {
    if (!results?.success) return null;

    return (
      <div className="mt-4 p-4 bg-green-50 rounded-md">
        <h4 className="font-medium text-green-700 mb-2">Processing Complete</h4>
        <p className="text-green-600">
          {results.totalProcessed} documents were categorized.
          {results.failed > 0 ? ` (${results.failed} failed)` : ''}
        </p>
        {Array.isArray(batchProgress?.results) && batchProgress.results.length > 0 && (
          <div className="mt-4">
            <h5 className="font-medium text-green-700 mb-2">Successfully Categorized Documents:</h5>
            <div className="space-y-3">
              {batchProgress.results.map((result, index) => (
                <div key={index} className="flex items-start gap-2 bg-white p-3 rounded-lg shadow-sm">
                  <FileText className="h-5 w-5 text-gray-500 flex-shrink-0 mt-0.5" />
                  <div className="flex-1">
                    <div className="font-medium text-gray-900 mb-1.5">{result.name}</div>
                    <div className="flex flex-col gap-2">
                      {formatSuggestedCategories(result.suggestedCategories).map((categoryObj, categoryIndex) => (
                        <div key={categoryIndex} className="space-y-1">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium whitespace-nowrap bg-secondary/50 text-black">
                            {categoryObj.category}
                          </span>
                          {categoryObj.explanation && (
                            <p className="text-sm text-gray-600 ml-2">
                              {categoryObj.explanation}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {Array.isArray(batchProgress?.errors) && batchProgress.errors.length > 0 && (
          <div className="mt-4">
            <h5 className="font-medium text-red-700 mb-2">Failed Documents:</h5>
            <div className="space-y-2">
              {batchProgress.errors.map((error, index) => (
                <div key={index} className="text-sm text-red-600">
                  {error.name}: {error.error}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Auto Categorization</h3>
            <div className="flex items-center gap-4">
              <select
                value={categorizeMode}
                onChange={(e) => setCategorizeMode(e.target.value)}
                className="px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={isProcessing || initiatingProcess}
              >
                <option value="processing">New Only</option>
                <option value="all">All Documents</option>
              </select>
              <button
                onClick={handleAutoCategories}
                disabled={isProcessing || initiatingProcess}
                className={`flex items-center gap-2 px-4 py-2 rounded-md text-white transition-colors ${
                  isProcessing || initiatingProcess
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-primary hover:bg-opacity-90'
                }`}
              >
                {isProcessing || initiatingProcess ? (
                  <>
                    <RefreshCw className="h-4 w-4 animate-spin" />
                    {initiatingProcess ? 'Initializing...' : 'Processing...'}
                  </>
                ) : (
                  <>
                    <FolderTree className="h-4 w-4" />
                    Auto Categorize Documents
                  </>
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md flex items-center gap-2">
              <AlertCircle className="h-5 w-5" />
              <span>{error}</span>
            </div>
          )}

          {currentBatchId && (
            <AutoCategorizeProgress batchId={currentBatchId} />
          )}

          {renderResults()}
        </div>
      </div>
    </Card>
  );
};

export default AutoCategorize;