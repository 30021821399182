import React, { useState, useMemo, useRef, useEffect } from 'react';
import { File, FileText, FileSpreadsheet, Image, Download, Search, Tag, X, FolderTree } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Card from '../Card';
import CSVDownload from '../CSVDownload';

const OrganizeDocuments = ({ documents = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState('labels');
  const [downloadingId, setDownloadingId] = useState(null);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsFilterDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Extract unique categories and labels from documents for filtering
  const { uniqueCategories, uniqueLabels } = useMemo(() => {
    const categories = new Set();
    const labels = new Set();

    documents.forEach(doc => {
      doc.categories?.forEach(cat => {
        categories.add(typeof cat === 'string' ? cat : cat.category);
      });
      doc.labels?.forEach(label => {
        labels.add(typeof label === 'string' ? label : label.label);
      });
    });

    return {
      uniqueCategories: Array.from(categories),
      uniqueLabels: Array.from(labels)
    };
  }, [documents]);

  // Download documents
  const handleDownload = async (doc) => {
    try {
      setDownloadingId(doc.id);
      const functions = getFunctions();
      const generateSignedUrl = httpsCallable(functions, 'generateSignedUrl');
      const result = await generateSignedUrl({ documentId: doc.id });
      
      if (result.data.success) {
        // Instead of opening in a new tab, download directly
        const link = document.createElement('a');
        link.href = result.data.url;
        link.download = doc.name; // Set the download attribute
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Download error:', error);
      alert('Error downloading file. Please ensure you are logged in and try again.');
    } finally {
      setDownloadingId(null);
    }
  };
  
  // Filter documents based on search term and selected filters
  const filteredDocuments = useMemo(() => {
    return documents.filter(doc => {
      const searchMatch = searchTerm === '' || 
        doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (doc.id_field && String(doc.id_field).toLowerCase().includes(searchTerm.toLowerCase()));

      const filterMatch = selectedFilters.length === 0 || 
        selectedFilters.every(filter => 
          doc.categories?.some(cat => 
            (typeof cat === 'string' ? cat : cat.category) === filter
          ) ||
          doc.labels?.some(label => 
            (typeof label === 'string' ? label : label.label) === filter
          )
        );

      return searchMatch && filterMatch;
    });
  }, [documents, searchTerm, selectedFilters]);

  const getFileIcon = (mimeType) => {
    const iconMap = {
      'application/pdf': FileText,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileSpreadsheet,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': FileText,
      'image/jpeg': Image,
      'image/png': Image
    };
    return iconMap[mimeType] || File;
  };

  const getIconColor = (mimeType) => {
    const colorMap = {
      'application/pdf': 'text-red-500',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'text-emerald-600',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'text-blue-600',
      'image/jpeg': 'text-purple-500',
      'image/png': 'text-purple-500'
    };
    return colorMap[mimeType] || 'text-gray-400';
  };


  const getDocumentId = (doc) => {
    // Use the id_field if it exists in the document
    if (doc.id_field) return doc.id_field;
    
    // Otherwise extract from filename as before
    const filename = doc.name;
    const matchStandard = filename.match(/\b(\d{2,4}-\d{3,4})\b/);
    const matchAlt = filename.match(/\b(\d+[.-]\d{4})\b/);
    if (matchStandard) return matchStandard[1];
    return matchAlt ? `${matchAlt[1].split(/[.-]/)[0]}-${matchAlt[1].split(/[.-]/)[1]}` : 'N/A';
  };
  
  // Function to truncate ID for display
  const getTruncatedID = (id) => {
    if (id.length <= 20) return id;
    return `${id.substring(0, 20)}...`;
  };

  const handleRemoveFilter = (filter) => {
    setSelectedFilters(prev => prev.filter(f => f !== filter));
  };

  const handleToggleFilter = (filter) => {
    setSelectedFilters(prev => 
      prev.includes(filter) 
        ? prev.filter(f => f !== filter)
        : [...prev, filter]
    );
  };

  // Simple component to show comments with tooltip on hover but no editing
  const ReadOnlyComments = ({ document }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    
    const hasComments = document.comments && document.comments.trim().length > 0;
    const displayComments = hasComments ? document.comments : 'No comments';
    
    return (
      <div className="relative">
        <div 
          className="text-sm text-gray-900 max-w-[180px] truncate cursor-pointer" 
          onMouseEnter={() => hasComments && setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          ref={tooltipRef}
        >
          {displayComments}
        </div>
        
        {/* Tooltip for displaying full comment */}
        {showTooltip && hasComments && (
          <div className="absolute z-50 left-0 -top-1 transform -translate-y-full mt-1">
            <div className="bg-gray-800 text-white p-2 rounded shadow-lg max-w-xs break-words text-xs">
              {document.comments}
            </div>
            <div className="w-2 h-2 bg-gray-800 transform rotate-45 absolute -bottom-1 left-4"></div>
          </div>
        )}
      </div>
    );
  };

  const isLabelFilter = (filter) => uniqueLabels.includes(filter);
  const isCategoryFilter = (filter) => uniqueCategories.includes(filter);

  return (
    <div className="container mx-auto px-0 py-2 sm:py-4">
      <div className="mb-6">
        <h1 className="text-gray-500 text-xl font-semibold">Document Library</h1>
        <p className="text-sm text-gray-500 mt-1">
          {filteredDocuments.length} {filteredDocuments.length === 1 ? 'file' : 'files'} available
        </p>
      </div>
      
      <Card className="mb-8">
        <div className="sticky top-0 z-10 bg-white p-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            {/* Smaller, more elegant search component */}
            <div className="relative flex-grow max-w-xs">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400"/>
              <input
                type="text"
                className="w-full pl-9 pr-4 py-2 text-sm border-b border-gray-300 focus:border-secondary focus:ring-0 outline-none"
                placeholder="Search by name or ID..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="flex items-center gap-2">
              {/* Filter UI */}
              <div className="flex flex-wrap items-center gap-2">
                {selectedFilters.map(filter => (
                  <span 
                    key={filter}
                    className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                      isLabelFilter(filter) 
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {isLabelFilter(filter) ? (
                      <Tag className="h-3 w-3 mr-1" />
                    ) : (
                      <FolderTree className="h-3 w-3 mr-1" />
                    )}
                    {filter}
                    <button
                      onClick={() => handleRemoveFilter(filter)}
                      className="ml-1 hover:text-red-600"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </span>
                ))}
                
                <div className="relative" ref={dropdownRef}>
                  <button
                    onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
                    className="px-3 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Filter
                  </button>
                  
                  {isFilterDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-50 border border-gray-200">
                      <div className="flex border-b border-gray-200">
                        <button
                          onClick={() => setActiveFilterTab('labels')}
                          className={`flex-1 px-4 py-2 text-sm font-medium ${
                            activeFilterTab === 'labels'
                              ? 'text-blue-600 border-b-2 border-blue-600'
                              : 'text-gray-500 hover:text-gray-700'
                          }`}
                        >
                          Labels
                        </button>
                        <button
                          onClick={() => setActiveFilterTab('categories')}
                          className={`flex-1 px-4 py-2 text-sm font-medium ${
                            activeFilterTab === 'categories'
                              ? 'text-blue-600 border-b-2 border-blue-600'
                              : 'text-gray-500 hover:text-gray-700'
                          }`}
                        >
                          Categories
                        </button>
                      </div>
                      
                      <div className="max-h-60 overflow-y-auto p-2">
                        {activeFilterTab === 'labels' ? (
                          uniqueLabels.map(label => (
                            <label
                              key={label}
                              className="flex items-center px-2 py-1.5 hover:bg-gray-50 rounded cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                checked={selectedFilters.includes(label)}
                                onChange={() => handleToggleFilter(label)}
                                className="mr-2"
                              />
                              <span className="text-sm">{label}</span>
                            </label>
                          ))
                        ) : (
                          uniqueCategories.map(category => (
                            <label
                              key={category}
                              className="flex items-center px-2 py-1.5 hover:bg-gray-50 rounded cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                checked={selectedFilters.includes(category)}
                                onChange={() => handleToggleFilter(category)}
                                className="mr-2"
                              />
                              <span className="text-sm">{category}</span>
                            </label>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* Smaller Export CSV button */}
                <CSVDownload 
                  data={filteredDocuments.map(doc => ({
                    ID: getDocumentId(doc),
                    Name: doc.name,
                    Type: doc.type.split('/').pop().toUpperCase(),
                    Categories: doc.categories?.map(c => typeof c === 'string' ? c : c.category).join('; ') || '',
                    Labels: doc.labels?.map(l => typeof l === 'string' ? l : l.label).join('; ') || '',
                    Comments: doc.comments || ''
                  }))}
                  filename="documents.csv"
                  className="inline-flex items-center px-3 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Table section */}
        <div className="overflow-x-auto">
          <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-12">#</th>
            <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">ID</th>
                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-48">Name</th>
                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">Type</th>
                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Categories</th>
                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Labels</th>
                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comments</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredDocuments.map((doc, index) => (
                <tr key={doc.id} className="hover:bg-gray-50">
                  <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-500">
                    {index + 1}
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                    <span title={getDocumentId(doc)}>
                      {getTruncatedID(getDocumentId(doc))}
                    </span>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap">
                    <div 
                      className="flex items-center cursor-pointer hover:text-blue-600"
                      onClick={() => handleDownload(doc)}
                    >
                      {React.createElement(getFileIcon(doc.type), {
                        className: `h-5 w-5 mr-2 ${getIconColor(doc.type)}`
                      })}
                      <div className="text-sm font-medium text-gray-900 hover:text-blue-600 flex items-center">
                        <span className="truncate max-w-[160px]" title={doc.name}>
                          {doc.name}
                        </span>
                        {downloadingId === doc.id ? (
                          <span className="ml-2 animate-spin">⌛</span>
                        ) : (
                          <Download className="h-4 w-4 ml-2 opacity-50" />
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                    {doc.type.split('/').pop().toUpperCase()}
                  </td>
                  <td className="px-4 py-3">
                  <div className="flex flex-wrap gap-2">
                      {doc.categories?.map((cat, idx) => {
                        const category = typeof cat === 'string' ? cat : cat.category;
                        return (
                          <span key={idx} className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-blue-100 text-gray-800">
                            <FolderTree className="h-3 w-3 mr-1.5" />
                            {category}
                          </span>
                        );
                      })}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                  <div className="flex flex-wrap gap-2">
                      {doc.labels?.map((label, idx) => {
                        const labelText = typeof label === 'string' ? label : label.label;
                        return (
                          <span key={idx} className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#FFADFF33] text-gray-800">
                            <Tag className="h-3 w-3 mr-1.5" />
                            {labelText}
                          </span>
                        );
                      })}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <ReadOnlyComments document={doc} />
                  </td>
                </tr>
              ))}
              {filteredDocuments.length === 0 && (
                <tr>
                  <td colSpan={7} className="px-4 py-3 text-center text-sm text-gray-500">
                    {documents.length === 0 
                      ? 'No documents available' 
                      : 'No documents match the current search and filters'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default OrganizeDocuments;