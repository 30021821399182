// src/components/Docs/DocsManagement.js
import React, { useState } from 'react';
import UploadSection from './UploadSection';
import DocumentList from './DocumentList';
import StatusMessage from './StatusMessage';
import LabelsManagement from './LabelsManagement';
import CategoriesManagement from './CategoriesManagement';
import LabelGeneration from './LabelGeneration';
import AutoLabeling from './AutoLabeling';
import AutoCategorize from './AutoCategorize';
import DocsConfigManagement from './DocsConfigManagement';
import EmbeddingsManagement from './EmbeddingsManagement';
import { useDocuments } from '../../hooks/useDocuments';
import { Tag, FileText, Zap, Settings, FolderTree, Tags, Database, ScrollText, Search } from 'lucide-react';
import Card from '../Card';

const DocsManagement = () => {
  const {
    documents,
    isUploading,
    error,
    saveStatus,
    handleUpload,
    updateCategories,
    batchDeleteDocuments,
  } = useDocuments();

  const [activeTab, setActiveTab] = useState('documents');
  const [activeSubTab, setActiveSubTab] = useState('labels');
  const [autoLabelResults, setAutoLabelResults] = useState(null);
  const [batchProgress, setBatchProgress] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [labelError, setLabelError] = useState(null);
  
  // States for auto-categorize
  const [autoCategorizeResults, setAutoCategorizeResults] = useState(null);
  const [categorizeBatchProgress, setCategorizeBatchProgress] = useState(null);
  const [isCategorizingProcessing, setIsCategorizingProcessing] = useState(false);
  const [categorizeError, setCategorizeError] = useState(null);
  
  const [suggestedLabels, setSuggestedLabels] = useState(() => {
    const savedLabels = localStorage.getItem('suggestedLabels');
    return savedLabels ? JSON.parse(savedLabels) : [];
  });

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    
    // Set default sub-tabs when switching main tabs
    if (tab === 'labels') {
      setActiveSubTab('labels');
    } else if (tab === 'categories') {
      setActiveSubTab('categories');
    }
  };

  return (
    <div className="space-y-4">
      {/* Main Tab Navigation */}
      <div className="border-b border-gray-200">
        <div className="flex items-end gap-6">
          <button
            onClick={() => handleTabChange('documents')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'documents' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <FileText className="h-4 w-4" />
            Documents
            {activeTab === 'documents' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>

          <button
            onClick={() => handleTabChange('categories')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'categories' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <FolderTree className="h-4 w-4" />
            Categories
            {activeTab === 'categories' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>

          <button
            onClick={() => handleTabChange('labels')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'labels' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <Tag className="h-4 w-4" />
            Labels
            {activeTab === 'labels' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>

          <button
            onClick={() => handleTabChange('embeddings')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'embeddings' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <Search className="h-4 w-4" />
            Embeddings
            {activeTab === 'embeddings' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>

          <button
            onClick={() => handleTabChange('config')}
            className={`relative flex items-center gap-2 px-4 py-3 text-sm font-medium transition-all ${
              activeTab === 'config' ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            <Settings className="h-4 w-4" />
            Config
            {activeTab === 'config' && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary" />
            )}
          </button>
        </div>
      </div>

      {/* Content Area */}
      {activeTab === 'documents' && (
        <>
          <UploadSection 
            onUpload={handleUpload}
            isUploading={isUploading}
          />
          
          {error && (
            <StatusMessage
              type="error"
              message={error}
            />
          )}

          {(isUploading || saveStatus) && (
            <StatusMessage
              type={saveStatus?.type}
              message={saveStatus?.message}
              isLoading={isUploading}
            />
          )}

          <DocumentList 
            documents={documents} 
            updateCategories={updateCategories}
            batchDeleteDocuments={batchDeleteDocuments}
          />
        </>
      )}
      
      {activeTab === 'labels' && (
        <div className="flex gap-4">
          {/* Vertical Sub-Tabs for Labels */}
          <div className="w-52 flex-shrink-0">
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
              <button
                onClick={() => setActiveSubTab('labels')}
                className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                  activeSubTab === 'labels' 
                    ? 'bg-primary/5 border-r-4 border-primary'
                    : 'hover:bg-gray-50'
                }`}
              >
                <Tag className={`h-4 w-4 ${activeSubTab === 'labels' ? 'text-primary' : 'text-gray-500'}`} />
                <div>
                  <div className={`text-sm font-medium ${activeSubTab === 'labels' ? 'text-primary' : 'text-gray-700'}`}>
                    Labels
                  </div>
                  <div className="text-[11px] text-gray-500">
                    Manage document labels
                  </div>
                </div>
              </button>
              
              <button
                onClick={() => setActiveSubTab('labelgeneration')}
                className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                  activeSubTab === 'labelgeneration' 
                    ? 'bg-primary/5 border-r-4 border-primary'
                    : 'hover:bg-gray-50'
                }`}
              >
                <Tags className={`h-4 w-4 ${activeSubTab === 'labelgeneration' ? 'text-primary' : 'text-gray-500'}`} />
                <div>
                  <div className={`text-sm font-medium ${activeSubTab === 'labelgeneration' ? 'text-primary' : 'text-gray-700'}`}>
                    Label Generation
                  </div>
                  <div className="text-[11px] text-gray-500">
                    Generate labels for documents
                  </div>
                </div>
              </button>
              
              <button
                onClick={() => setActiveSubTab('auto-label')}
                className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                  activeSubTab === 'auto-label' 
                    ? 'bg-primary/5 border-r-4 border-primary'
                    : 'hover:bg-gray-50'
                }`}
              >
                <Zap className={`h-4 w-4 ${activeSubTab === 'auto-label' ? 'text-primary' : 'text-gray-500'}`} />
                <div>
                  <div className={`text-sm font-medium ${activeSubTab === 'auto-label' ? 'text-primary' : 'text-gray-700'}`}>
                    Auto Label
                  </div>
                  <div className="text-[11px] text-gray-500">
                    Automatically label documents
                  </div>
                </div>
              </button>
            </div>
          </div>
          
          {/* Label Content Area */}
          <div className="flex-1">
            <Card className="h-full">
              <div className="h-full">
                {activeSubTab === 'labels' && <LabelsManagement />}
                {activeSubTab === 'labelgeneration' && 
                  <LabelGeneration 
                    suggestedLabels={suggestedLabels}
                    setSuggestedLabels={setSuggestedLabels}
                  />
                }
                {activeSubTab === 'auto-label' && (
                  <AutoLabeling 
                    results={autoLabelResults}
                    setResults={setAutoLabelResults}
                    batchProgress={batchProgress}
                    setBatchProgress={setBatchProgress}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    error={labelError}
                    setError={setLabelError}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      )}
      
      {activeTab === 'categories' && (
        <div className="flex gap-4">
          {/* Vertical Sub-Tabs for Categories */}
          <div className="w-52 flex-shrink-0">
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
              <button
                onClick={() => setActiveSubTab('categories')}
                className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                  activeSubTab === 'categories' 
                    ? 'bg-primary/5 border-r-4 border-primary'
                    : 'hover:bg-gray-50'
                }`}
              >
                <FolderTree className={`h-4 w-4 ${activeSubTab === 'categories' ? 'text-primary' : 'text-gray-500'}`} />
                <div>
                  <div className={`text-sm font-medium ${activeSubTab === 'categories' ? 'text-primary' : 'text-gray-700'}`}>
                    Categories
                  </div>
                  <div className="text-[11px] text-gray-500">
                    Manage document categories
                  </div>
                </div>
              </button>
              
              <button
                onClick={() => setActiveSubTab('auto-categorize')}
                className={`w-full flex items-center gap-2 px-3 py-3 text-left transition-colors ${
                  activeSubTab === 'auto-categorize' 
                    ? 'bg-primary/5 border-r-4 border-primary'
                    : 'hover:bg-gray-50'
                }`}
              >
                <Database className={`h-4 w-4 ${activeSubTab === 'auto-categorize' ? 'text-primary' : 'text-gray-500'}`} />
                <div>
                  <div className={`text-sm font-medium ${activeSubTab === 'auto-categorize' ? 'text-primary' : 'text-gray-700'}`}>
                    Auto Categorize
                  </div>
                  <div className="text-[11px] text-gray-500">
                    Automatically categorize documents
                  </div>
                </div>
              </button>
            </div>
          </div>
          
          {/* Category Content Area */}
          <div className="flex-1">
            <Card className="h-full">
              <div className="h-full">
                {activeSubTab === 'categories' && <CategoriesManagement />}
                {activeSubTab === 'auto-categorize' && (
                  <AutoCategorize 
                    results={autoCategorizeResults}
                    setResults={setAutoCategorizeResults}
                    batchProgress={categorizeBatchProgress}
                    setBatchProgress={setCategorizeBatchProgress}
                    isProcessing={isCategorizingProcessing}
                    setIsProcessing={setIsCategorizingProcessing}
                    error={categorizeError}
                    setError={setCategorizeError}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      )}
      
      {activeTab === 'embeddings' && <EmbeddingsManagement />}
      
      {activeTab === 'config' && <DocsConfigManagement />}
    </div>
  );
};

export default DocsManagement;