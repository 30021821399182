import React, { useState, useEffect } from 'react';
import { Search, FileText, ChevronRight, Calendar } from 'lucide-react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { format, formatDistance } from 'date-fns';

const SavedAnalysisList = ({ onSelectAnalysis, selectedAnalysisId }) => {
  const { currentUser } = useAuth();
  const [savedAnalyses, setSavedAnalyses] = useState([]);
  const [filteredAnalyses, setFilteredAnalyses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSavedAnalyses = async () => {
      if (!currentUser) return;

      try {
        setIsLoading(true);
        setError(null);

        const savedAnalysisRef = collection(db, 'savedDocAnalysis');
        const q = query(
          savedAnalysisRef,
          where('userEmail', '==', currentUser.email),
          orderBy('createdAt', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const analyses = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          savedAt: doc.data().savedAt?.toDate() || new Date()
        }));

        setSavedAnalyses(analyses);
        setFilteredAnalyses(analyses);
      } catch (err) {
        console.error('Error fetching saved analyses:', err);
        setError('Failed to load saved analyses');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedAnalyses();
  }, [currentUser]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredAnalyses(savedAnalyses);
      return;
    }

    const lowercaseQuery = searchQuery.toLowerCase();
    const filtered = savedAnalyses.filter(analysis => {
      return (
        (analysis.title || "Document Analysis").toLowerCase().includes(lowercaseQuery) ||
        (analysis.query || "").toLowerCase().includes(lowercaseQuery)
      );
    });

    setFilteredAnalyses(filtered);
  }, [searchQuery, savedAnalyses]);

  const handleClick = (analysis) => {
    if (onSelectAnalysis) {
      onSelectAnalysis(analysis);
    }
  };

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center p-3 bg-transparent">
        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-primary"></div>
        <span className="ml-2 text-gray-600 text-xs">Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-3 text-red-500 text-xs bg-transparent">
        {error}
      </div>
    );
  }

  if (savedAnalyses.length === 0) {
    return (
      <div className="h-full flex flex-col items-center justify-center p-3 text-center text-gray-500 bg-transparent">
        <FileText className="w-8 h-8 mb-2 text-gray-300" />
        <p className="text-xs">No saved analyses found</p>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col bg-transparent">
      {/* Search input section */}
      <div className="px-4 py-2">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
            <Search className="h-3.5 w-3.5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-8 pr-2 py-1.5 border border-gray-200 rounded-md text-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary bg-transparent"
            placeholder="Search by title"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      
      <div className="flex-1 overflow-y-auto">
        {filteredAnalyses.length === 0 ? (
          <div className="p-3 text-center text-gray-500 text-xs bg-transparent">
            No analyses matching "{searchQuery}"
          </div>
        ) : (
          <div>
            {filteredAnalyses.map(analysis => {
              const isSelected = selectedAnalysisId === analysis.id;
              return (
                <div
                  key={analysis.id}
                  className={`p-3 px-4 mb-1 cursor-pointer hover:bg-gray-50 transition-colors ${
                    isSelected ? 'bg-blue-50 hover:bg-blue-50 border-l-2 border-primary' : 'bg-transparent'
                  }`}
                  onClick={() => handleClick(analysis)}
                >
                  <div className="flex justify-between items-start">
                    <h4 className="text-xs font-medium text-gray-700 line-clamp-1">
                      {analysis.title || "Document Analysis"}
                    </h4>
                    <ChevronRight className={`w-3 h-3 ${isSelected ? 'text-primary' : 'text-gray-300'}`} />
                  </div>
                  
                  {analysis.query && (
                    <p className="text-xs text-gray-500 mt-0.5 line-clamp-1">
                      {analysis.query}
                    </p>
                  )}
                  
                  <div className="flex items-center justify-between mt-1 text-xs text-gray-400">
                    <div className="flex items-center">
                      <Calendar className="w-2.5 h-2.5 mr-0.5 flex-shrink-0" />
                      <span title={format(analysis.createdAt, 'PPP p')} className="flex-shrink-0">
                        {formatDistance(analysis.createdAt, new Date(), { addSuffix: true })}
                      </span>
                    </div>
                    
                    <div className="flex items-center">
                      <FileText className="w-2.5 h-2.5 mr-0.5 flex-shrink-0" />
                      <span className="flex-shrink-0">
                        {analysis.selectedDocumentIds?.length || 
                         (analysis.metadata?.documentsProcessed || "?")} docs
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedAnalysisList;