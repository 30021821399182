// src/components/Docs/DocumentComments.js
import React, { useState, useRef } from 'react';
import { Edit, Check, X } from 'lucide-react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const DocumentComments = ({ document }) => {
  const db = getFirestore();
  const [isEditing, setIsEditing] = useState(false);
  const [editedComments, setEditedComments] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const handleEditClick = () => {
    setEditedComments(document.comments || '');
    setIsEditing(true);
    setError(null);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setError(null);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError(null);
      
      // Update the document in Firestore with the comments field
      await updateDoc(doc(db, 'docs', document.id), {
        comments: editedComments.trim()
      });
      
      // Update the local document object for immediate UI feedback
      document.comments = editedComments.trim();
      
      setIsEditing(false);
    } catch (err) {
      setError("Failed to save: " + err.message);
      console.error("Error updating document comments:", err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isEditing) {
    return (
      <div className="relative w-full">
        <div className="flex flex-col space-y-2">
          <textarea
            value={editedComments}
            onChange={(e) => setEditedComments(e.target.value)}
            className={`w-full px-2 py-1 text-sm border ${error ? 'border-red-500' : 'border-gray-300'} rounded min-h-[60px] max-h-[120px]`}
            placeholder="Add comments here..."
            disabled={isSaving}
            rows={3}
          />
          <div className="flex items-center space-x-2 justify-end">
            <button 
              onClick={handleSave}
              disabled={isSaving}
              className="text-green-600 hover:text-green-800 p-1 flex items-center"
              title="Save"
            >
              {isSaving ? (
                <span className="animate-spin">⌛</span>
              ) : (
                <>
                  <Check className="h-4 w-4 mr-1" />
                  <span className="text-xs">Save</span>
                </>
              )}
            </button>
            <button 
              onClick={handleCancel}
              disabled={isSaving}
              className="text-red-600 hover:text-red-800 p-1 flex items-center"
              title="Cancel"
            >
              <X className="h-4 w-4 mr-1" />
              <span className="text-xs">Cancel</span>
            </button>
          </div>
        </div>
        {error && (
          <div className="absolute left-0 top-full mt-1 z-10">
            <span className="text-xs text-red-500 bg-white shadow-sm px-1">{error}</span>
          </div>
        )}
      </div>
    );
  }

  const hasComments = document.comments && document.comments.trim().length > 0;
  const displayComments = hasComments ? document.comments : 'No comments';

  return (
    <div className="flex items-center relative">
      <div 
        className="text-sm text-gray-900 max-w-[180px] truncate cursor-pointer" 
        onMouseEnter={() => hasComments && setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        ref={tooltipRef}
      >
        {displayComments}
      </div>
      <button
        onClick={handleEditClick}
        className="text-gray-400 hover:text-blue-600 ml-1 p-1 flex-shrink-0"
        title="Edit Comments"
      >
        <Edit className="h-3.5 w-3.5" />
      </button>
      
      {/* Tooltip for displaying full comment */}
      {showTooltip && hasComments && (
        <div className="absolute z-50 left-0 -top-1 transform -translate-y-full mt-1">
          <div className="bg-gray-800 text-white p-2 rounded shadow-lg max-w-xs break-words text-xs">
            {document.comments}
          </div>
          <div className="w-2 h-2 bg-gray-800 transform rotate-45 absolute -bottom-1 left-4"></div>
        </div>
      )}
    </div>
  );
};

export default DocumentComments;