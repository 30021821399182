// src/hooks/useAutoLabeling.js
import { useEffect, useRef } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

export const useAutoLabeling = ({
  isProcessing,
  setIsProcessing,
  setError,
  setResults,
  setBatchProgress,
}) => {
  const batchIdRef = useRef(null);
  const unsubscribeRef = useRef(null);
  const lastProgressRef = useRef(null);

  useEffect(() => {
    // Clear processing state if no batchId
    if (isProcessing && !batchIdRef.current) {
      setIsProcessing(false);
      return;
    }

    // If we're processing and have a batchId, but no listener
    if (isProcessing && batchIdRef.current && !unsubscribeRef.current) {
      const firestore = getFirestore();
      const batchRef = doc(firestore, 'batchProcessing', batchIdRef.current);

      // Set up the listener
      unsubscribeRef.current = onSnapshot(batchRef, 
        async (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            
            // Skip update if data hasn't changed
            if (JSON.stringify(data) === JSON.stringify(lastProgressRef.current)) {
              return;
            }
            
            lastProgressRef.current = data;
            
            // Calculate proper progress values
            const processedTotal = (data.processedDocuments || 0) + 
                                 (data.failedDocuments || 0) + 
                                 (data.noLabelsDocuments || 0);
            
            const updatedData = {
              ...data,
              processedDocuments: data.processedDocuments || 0,
              failedDocuments: data.failedDocuments || 0,
              noLabelsDocuments: data.noLabelsDocuments || 0,
              totalProcessed: processedTotal,
              progress: data.totalDocuments > 0 
                ? Math.min(Math.round((processedTotal / data.totalDocuments) * 100), 99)
                : 0
            };

            setBatchProgress(updatedData);
            
            // Handle completion or failure
            if (data.status === 'completed' || data.status === 'failed') {
              setIsProcessing(false);
              
              if (data.status === 'completed') {
                // Set final progress to 100%
                setBatchProgress({
                  ...updatedData,
                  progress: 100
                });
                
                setResults({
                  totalProcessed: data.processedDocuments,
                  failed: data.failedDocuments,
                  success: true
                });
              } else {
                setError('Batch processing failed. Check logs for details.');
              }
              
              // Clean up
              if (unsubscribeRef.current) {
                unsubscribeRef.current();
                unsubscribeRef.current = null;
              }
              batchIdRef.current = null;
              lastProgressRef.current = null;
            }
          } else {
            // Document doesn't exist anymore - cleanup
            setIsProcessing(false);
            if (unsubscribeRef.current) {
              unsubscribeRef.current();
              unsubscribeRef.current = null;
            }
            batchIdRef.current = null;
            lastProgressRef.current = null;
          }
        }, 
        (err) => {
          console.error('Error listening to batch progress:', err);
          setError('Failed to monitor processing status');
          setIsProcessing(false);
          // Clean up on error
          if (unsubscribeRef.current) {
            unsubscribeRef.current();
            unsubscribeRef.current = null;
          }
          batchIdRef.current = null;
          lastProgressRef.current = null;
        }
      );
    }

    // Cleanup function
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
      lastProgressRef.current = null;
    };
  }, [isProcessing, setIsProcessing, setError, setResults, setBatchProgress]);

  return {
    setBatchId: (id) => {
      batchIdRef.current = id;
      lastProgressRef.current = null; // Reset progress tracking
    },
    clearBatchId: () => {
      batchIdRef.current = null;
      lastProgressRef.current = null;
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
    }
  };
};