import React, { useState, useRef, useEffect } from 'react';
import { X, SendHorizontal, Zap, Filter, FolderTree, Tag, File } from 'lucide-react';
import FilterSelector from './FilterSelector';
import TagSuggestions from './TagSuggestions';
import DocumentSelector from './DocumentSelector';

// Further refined useTextareaHeight hook for true single line
const useTextareaHeight = (textareaRef, value) => {
  // Changed initial height to match QueryInput component (48px)
  const [height, setHeight] = useState(48);
  // Changed MIN_TEXTAREA_HEIGHT to match QueryInput component
  const MIN_TEXTAREA_HEIGHT = 48;
  const LINE_HEIGHT = 24;
  const MAX_LINES = 5;

  const adjustHeight = () => {
    if (!textareaRef.current) return;
    
    // Reset height temporarily to get the correct scrollHeight
    textareaRef.current.style.height = 'auto';
    
    // Calculate the new height based on scrollHeight
    const scrollHeight = textareaRef.current.scrollHeight;
    const newHeight = Math.min(
      Math.max(MIN_TEXTAREA_HEIGHT, scrollHeight),
      MIN_TEXTAREA_HEIGHT + (LINE_HEIGHT * (MAX_LINES - 1))
    );
    
    setHeight(newHeight);
    textareaRef.current.style.height = `${newHeight}px`;
  };

  const resetHeight = () => {
    setHeight(MIN_TEXTAREA_HEIGHT);
    if (textareaRef.current) {
      textareaRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  return { height, adjustHeight, resetHeight, MIN_TEXTAREA_HEIGHT };
};

const ReviewQueryInput = ({ 
  onSubmit, 
  isLoading, 
  query,
  setQuery,
  selectedCategories,
  setSelectedCategories,
  selectedLabels,
  setSelectedLabels,
  selectedDocuments = [],
  setSelectedDocuments = () => {},
  error
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showDocumentSelector, setShowDocumentSelector] = useState(false);
  const inputRef = useRef(null);
  // Use the textarea height hook
  const { height, adjustHeight, resetHeight, MIN_TEXTAREA_HEIGHT } = useTextareaHeight(inputRef, query);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query?.trim()) {
      onSubmit(e);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    
    // Check if we should show tag suggestions
    // (if the input contains a # character)
    if (e.target.value.includes('#')) {
      setShowSuggestions(true);
      setShowDocumentSelector(false);
    } else {
      setShowSuggestions(false);
    }
    
    // Check if we should show document selector
    // Match @ followed by any characters except @ and whitespace at the end of string
    const atMentionMatch = e.target.value.match(/@[^@\s]*$/);
    if (atMentionMatch && !isFiltersDisabled) {
      // Always show document selector when @ is typed, even if documents are already selected
      setShowDocumentSelector(true);
      setShowSuggestions(false);
    } else {
      setShowDocumentSelector(false);
    }
    
    // Adjust height when content changes
    adjustHeight();
  };

  const handleKeyDown = (e) => {
    // Only submit on Enter if suggestions aren't visible and not creating a new line
    if (e.key === 'Enter' && !e.shiftKey && !showSuggestions && !showDocumentSelector) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const clearInput = () => {
    setQuery('');
    resetHeight();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const toggleFilters = () => {
    // If documents are selected, clear them when showing filters
    if (selectedDocuments.length > 0) {
      setSelectedDocuments([]);
    }
    setShowFilters(!showFilters);
  };
  
  // Auto-show filters when categories or labels are selected
  useEffect(() => {
    if (selectedCategories.length > 0 || selectedLabels.length > 0) {
      setShowFilters(true);
    }
  }, [selectedCategories, selectedLabels]);

  // Calculate if filters are disabled (documents selected)
  const isFiltersDisabled = selectedDocuments.length > 0;
  
  // Calculate if document selection is disabled (filters active)
  const isDocSelectionDisabled = selectedCategories.length > 0 || selectedLabels.length > 0;
  
  // Calculate if any filters are active
  const hasActiveFilters = selectedCategories.length > 0 || selectedLabels.length > 0;

  // Flag to determine if document selection can be triggered
  const canTriggerDocSelection = !isDocSelectionDisabled;

  return (
    <div className="w-full">
      <div className="relative">
        <div className="relative w-full flex flex-col gap-4">
          {/* Search Input - Styled using tailwind config */}
          <div className="relative w-full">
            <div className="bg-white rounded-full focus-within:ring-2 focus-within:ring-secondary overflow-hidden">
              <div className="flex items-center">
                <div className="pl-4 flex-shrink-0">
                  <img
                    src="/favicon.ico"
                    alt="Company Icon"
                    className="w-6 h-6"
                  />
                </div>
                {/* Textarea with increased padding to match QueryInput */}
                <textarea
                  ref={inputRef}
                  value={query || ''}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder={isDocSelectionDisabled 
                    ? "Type your query..." 
                    : "@ to select documents, # to filter by topics"
                  }
                  className="w-full bg-transparent text-gray-800 border-none focus:outline-none resize-none overflow-hidden leading-tight py-3 pl-3 pr-[88px]"
                  style={{
                    height: `${height}px`,
                    maxWidth: '100%',
                    minHeight: `${MIN_TEXTAREA_HEIGHT}px`,
                    lineHeight: '24px', // Match the line height from QueryInput
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  disabled={isLoading}
                  rows="1" // Force single row initially
                />
                <div className="absolute right-2 flex items-center space-x-1">
                  {query && (
                    <button
                      type="button"
                      onClick={clearInput}
                      className="p-1.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
                      disabled={isLoading}
                    >
                      <X className="w-4 h-4 text-gray-500" />
                    </button>
                  )}

                  <button
                    type="button"
                    onClick={toggleFilters}
                    className={`p-1.5 rounded-full transition-colors duration-200 relative ${
                      isFiltersDisabled 
                        ? 'cursor-not-allowed text-gray-300'
                        : hasActiveFilters
                          ? 'hover:bg-gray-100 text-primary'
                          : 'hover:bg-gray-100 text-gray-500'
                    }`}
                    title={isFiltersDisabled 
                      ? "Remove document selections to use filters" 
                      : showFilters ? "Hide filters" : "Show filters"
                    }
                    disabled={isFiltersDisabled}
                  >
                    <Filter className="w-4 h-4" />
                    {hasActiveFilters && (
                      <div className="absolute -top-1 -right-1 flex items-center justify-center">
                        <span className="w-4 h-4 bg-primary text-white text-xs flex items-center justify-center rounded-full">
                          {selectedCategories.length + selectedLabels.length}
                        </span>
                      </div>
                    )}
                  </button>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="p-1.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
                    disabled={isLoading || !query?.trim()}
                  >
                    <SendHorizontal 
                      className={`w-4 h-4 ${query?.trim() ? 'text-blue-500' : 'text-gray-400'}`}
                    />
                  </button>
                </div>
              </div>
            </div>
            
            {/* Tag Suggestions */}
            <TagSuggestions
              query={query}
              setQuery={setQuery}
              visible={showSuggestions && !isDocSelectionDisabled}
              setVisible={setShowSuggestions}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedLabels={selectedLabels}
              setSelectedLabels={setSelectedLabels}
              inputRef={inputRef}
            />

            {/* Document Selector */}
            <DocumentSelector
              query={query}
              setQuery={setQuery}
              visible={showDocumentSelector && canTriggerDocSelection}
              setVisible={setShowDocumentSelector}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              inputRef={inputRef}
            />
          </div>
          
          {/* Selected Documents Display */}
          {selectedDocuments.length > 0 && (
            <div className="w-full bg-white rounded-md mt-0 shadow-sm">
              <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200 flex justify-between items-center">
                <span>Selected Documents</span>
                {selectedDocuments.length > 0 && (
                  <button
                    onClick={() => setSelectedDocuments([])}
                    className="text-xs text-gray-500 hover:text-gray-700 flex items-center gap-1"
                  >
                    <X className="w-3 h-3" />
                    Clear all
                  </button>
                )}
              </div>
              <div className="py-3 px-4">
                <div className="flex flex-wrap gap-2">
                  {selectedDocuments.map(doc => (
                    <div 
                      key={doc.id}
                      className="flex items-center gap-1 px-2 py-1 text-xs bg-blue-50 text-blue-700 rounded-md shadow-sm"
                    >
                      <File className="w-3 h-3" />
                      <span className="font-medium max-w-[200px] truncate">{doc.name}</span>
                      <button 
                        onClick={() => {
                          setSelectedDocuments(selectedDocuments.filter(d => d.id !== doc.id));
                        }}
                        className="hover:bg-opacity-10 rounded-full p-0.5 hover:bg-blue-200"
                        aria-label={`Remove ${doc.name}`}
                      >
                        <X className="w-3 h-3" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          
          {/* Filters Expandable Section - Removed border from the container */}
          {showFilters && !isFiltersDisabled && (
            <div className="w-full bg-white rounded-md mt-0 shadow-sm">
              <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200 flex justify-between items-center">
                <span>Review Filters</span>
                {(selectedCategories.length > 0 || selectedLabels.length > 0) && (
                  <button
                    onClick={() => {
                      setSelectedCategories([]);
                      setSelectedLabels([]);
                    }}
                    className="text-xs text-gray-500 hover:text-gray-700 flex items-center gap-1"
                  >
                    <X className="w-3 h-3" />
                    Clear all filters
                  </button>
                )}
              </div>
              <div className="py-3 px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <div className="flex flex-col gap-2">
                      <FilterSelector 
                        type="category"
                        selectedFilters={selectedCategories}
                        onFilterChange={setSelectedCategories}
                      />
                      <SelectedFilterTags 
                        selectedFilters={selectedCategories}
                        toggleFilter={(filter) => {
                          setSelectedCategories(selectedCategories.filter(c => c !== filter));
                        }}
                        type="category"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-2">
                      <FilterSelector 
                        type="label"
                        selectedFilters={selectedLabels}
                        onFilterChange={setSelectedLabels}
                      />
                      <SelectedFilterTags 
                        selectedFilters={selectedLabels}
                        toggleFilter={(filter) => {
                          setSelectedLabels(selectedLabels.filter(l => l !== filter));
                        }}
                        type="label"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {/* AI Summary Info Badge */}
          <div className="flex items-center justify-end">
            <div className="flex items-center px-3 py-1.5 bg-blue-50 text-blue-700 rounded-full text-xs">
              <Zap className="w-3.5 h-3.5 mr-1.5 text-blue-500" />
              <span>AI-powered documents analysis</span>
            </div>
          </div>
        </div>
      </div>
      
      {error && (
        <div className="text-red-500 text-sm mt-2">
          {error.message}
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center mt-4">
          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"></div>
          <span className="ml-2 text-gray-600 text-sm">Analyzing documents...</span>
        </div>
      )}
    </div>
  );
};

// Updated to remove border
const SelectedFilterTags = ({ selectedFilters, toggleFilter, type }) => {
  if (selectedFilters.length === 0) return null;
  
  return (
    <div className="flex flex-wrap gap-1.5 mt-2">
      {selectedFilters.map(filter => (
        <div 
          key={filter}
          className={`flex items-center gap-1 px-2 py-1 text-xs ${
            type === 'category' 
              ? 'bg-purple-50 text-purple-700' 
              : 'bg-amber-50 text-amber-700'
          } rounded-md shadow-sm`}
        >
          {type === 'category' ? (
            <FolderTree className="w-3 h-3" />
          ) : (
            <Tag className="w-3 h-3" />
          )}
          <span className="font-medium">{filter}</span>
          <button 
            onClick={() => toggleFilter(filter)}
            className={`hover:bg-opacity-10 rounded-full p-0.5 ${
              type === 'category' ? 'hover:bg-purple-200' : 'hover:bg-amber-200'
            }`}
            aria-label={`Remove ${filter}`}
          >
            <X className="w-3 h-3" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ReviewQueryInput;