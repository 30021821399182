import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, collection, query, where, orderBy, limit, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import BigBroTooltip from './BigBroTooltip';
import { useAuth } from '../contexts/AuthContext';

// Create context
const BigBroContext = createContext();

// Log when module is loaded
console.log('BigBroProvider module loaded');

export const useBigBro = () => useContext(BigBroContext);

export const BigBroProvider = ({ children }) => {
  console.log('BigBroProvider component rendered');

  const [activeTooltip, setActiveTooltip] = useState(null);
  const [targetElement, setTargetElement] = useState(null);
  const [tooltipText, setTooltipText] = useState(null);
  const [currentDocId, setCurrentDocId] = useState(localStorage.getItem('currentDocumentId'));
  const [currentAnalysisId, setCurrentAnalysisId] = useState(null);
  const { currentUser } = useAuth();
  
  // Log important events to console
  const logEvent = (message) => {
    console.log(`BigBro: ${message}`);
  };
  
  // Check for document ID changes periodically
  useEffect(() => {
    const checkInterval = setInterval(() => {
      const newDocId = localStorage.getItem('currentDocumentId');
      if (newDocId !== currentDocId) {
        console.log(`BigBro: Document ID changed from ${currentDocId || 'none'} to ${newDocId || 'none'}`);
        setCurrentDocId(newDocId);
      }
    }, 1000); // Check every second
    
    return () => clearInterval(checkInterval);
  }, [currentDocId]);
  
  // Listen for new BigBro analyses - re-run when document ID changes
  useEffect(() => {
    console.log(`BigBroProvider useEffect called for document ID: ${currentDocId || 'none'}`);
    
    // Check if we're on the Ask page by using window.location
    const isAskPage = window.location.pathname === '/' || window.location.pathname === '/shoot';
    
    if (!isAskPage) {
      logEvent('Not on Ask page, not setting up listener');
      return;
    }
    
    // Make sure we have a currentUser and document ID before attempting to listen
    if (!currentUser) {
      logEvent('No authenticated user, not setting up listener');
      return;
    }
    
    if (!currentDocId) {
      logEvent('No document ID in localStorage, not setting up listener');
      return;
    }
    
    logEvent(`Setting up BigBroAnalyses listener for document ID: ${currentDocId}`);
    
    // Set up Firestore listener
    const db = getFirestore();
    const analysesRef = collection(db, 'BigBroAnalyses');
    
    // Create query
    const q = query(
      analysesRef,
      where('documentId', '==', currentDocId),
      where('userId', '==', currentUser.email),
      orderBy('createdAt', 'desc'),
      limit(1)
    );
    
    logEvent('Starting onSnapshot listener');
    
    // Set up real-time listener
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        logEvent(`Snapshot received: ${snapshot.size} documents, ${snapshot.docChanges().length} changes`);
        
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added' || change.type === 'modified') {
            const analysis = change.doc.data();
            logEvent(`Analysis data received: ${JSON.stringify(analysis.response || {})}`);
            
            // Skip if seen is true
            if (analysis.seen === true) {
              logEvent('Tooltip already marked as seen, skipping');
              return;
            }
            
            // Process analysis if it has an action
            if (analysis.response && analysis.response.action && analysis.response.action !== 'none') {
              logEvent(`Processing action: ${analysis.response.action}`);
              processAnalysis(analysis.response, change.doc.id);
            }
          }
        });
      }, 
      (error) => {
        // This handler will be called if there's an error with the listener
        logEvent(`Error in BigBroAnalyses listener: ${error.message}`);
        console.error('BigBro Firestore error:', error);
      }
    );
    
    logEvent('Listener setup complete');
    
    // Cleanup function to unsubscribe when component unmounts or dependencies change
    return () => {
      logEvent('Cleaning up listener');
      unsubscribe();
    };
  }, [currentUser, currentDocId]); // Re-run when currentUser or currentDocId changes
  
  // Find the appropriate target element for the tooltip
  const findTargetElement = (locationText) => {
    logEvent(`Finding target for location: ${locationText}`);
    
    if (!locationText) return document.body;
    
    const searchText = locationText.toLowerCase().trim();
    
    // Special case for table locations
    if (searchText === 'table' || searchText === 'table_header') {
      // Try different selectors for tables
      const tableElements = [
        document.querySelector('thead'),
        document.querySelector('table'),
        document.querySelector('.table-view'),
        document.querySelector('.column-header')
      ].filter(el => el !== null);
      
      if (tableElements.length > 0) {
        logEvent(`Found table element: ${tableElements[0].tagName}`);
        return tableElements[0];
      }
    }
    
    // Try to find by text content
    const elementTypes = ['button', 'a', 'h1', 'h2', 'h3', 'p', 'span', 'div', 'th', 'td'];
    
    for (const type of elementTypes) {
      const elements = Array.from(document.querySelectorAll(type));
      
      // Look for exact matches first
      const exactMatch = elements.find(el => {
        const text = el.textContent.toLowerCase().trim();
        return text === searchText;
      });
      
      if (exactMatch) {
        logEvent(`Found exact match in ${type}`);
        return exactMatch;
      }
      
      // Then look for partial matches
      const partialMatch = elements.find(el => {
        const text = el.textContent.toLowerCase().trim();
        return text.includes(searchText);
      });
      
      if (partialMatch) {
        logEvent(`Found partial match in ${type}`);
        return partialMatch;
      }
    }
    
    // Fallback to common UI elements if no text match
    const commonElements = [
      document.querySelector('.query-result-card'),
      document.querySelector('.card-content'),
      document.querySelector('main'),
      document.body
    ].filter(el => el !== null);
    
    if (commonElements.length > 0) {
      logEvent(`Using fallback element: ${commonElements[0].tagName}`);
      return commonElements[0];
    }
    
    // Last resort
    return document.body;
  };
  
  // Process the analysis data
  const processAnalysis = (analysis, analysisId) => {
    logEvent(`Processing analysis: ${JSON.stringify(analysis)}`);
    
    // Only handle tooltip actions for now
    if (analysis.action !== 'tooltip' || !analysis.text) {
      logEvent('Not a valid tooltip action');
      return;
    }
    
    // Get location
    const location = analysis.location || 'default';
    
    // Find target element
    const targetEl = findTargetElement(location);
    
    // Store the analysis ID for use when marking as seen
    setCurrentAnalysisId(analysisId);
    
    // Set up the tooltip
    setTooltipText(analysis.text);
    setTargetElement(targetEl);
    setActiveTooltip(Math.random().toString(36).substring(2, 9)); // Generate random ID
    logEvent('Tooltip activated');
  };
  
  // Update Firestore in the background - this doesn't affect the UI
  const updateFirestoreTooltipStatus = async (analysisId) => {
    if (!analysisId) return;
    
    try {
      const db = getFirestore();
      const analysisRef = doc(db, 'BigBroAnalyses', analysisId);
      
      // Set seen flag to true
      await updateDoc(analysisRef, {
        seen: true
      });
      
      logEvent(`Marked tooltip as seen in Firestore`);
    } catch (error) {
      console.error('Error updating seen status in Firestore:', error);
    }
  };
  
  // Handle closing the tooltip
  const closeTooltip = () => {
    logEvent('Closing tooltip');
    
    // Immediately reset tooltip state for visual feedback
    const analysisId = currentAnalysisId;
    
    // Reset tooltip state immediately to make UI responsive
    setActiveTooltip(null);
    setTargetElement(null);
    setTooltipText(null);
    setCurrentAnalysisId(null);
    
    // Update Firestore in the background WITHOUT awaiting
    if (analysisId) {
      updateFirestoreTooltipStatus(analysisId);
    }
  };
  
  // Only show tooltips on the Ask page
  const isAskPage = window.location.pathname === '/' || window.location.pathname === '/shoot';
  
  return (
    <BigBroContext.Provider value={{ activeTooltip, closeTooltip }}>
      {children}
      
      {/* Render tooltip only on Ask page */}
      {isAskPage && activeTooltip && tooltipText && targetElement && (
        <BigBroTooltip 
          text={tooltipText}
          target={targetElement}
          onClose={closeTooltip}
        />
      )}
    </BigBroContext.Provider>
  );
};

export default BigBroProvider;