import { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import { collection, query, where, getDocs, orderBy, limit, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Cache constants
const QUERIES_CACHE_KEY = 'saved_queries_cache';
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds
const QUERY_SETTINGS_KEY = 'query_visualization_settings'; // New constant for storing visualization settings

/**
 * Core hook for query library data fetching and caching
 * @returns {Object} Core functions and state for query management
 */
export const useQueryLibraryCore = () => {
  const { currentUser } = useAuth();
  const { config } = useConfig();
  
  const [queries, setQueries] = useState(() => {
    // Initialize from cache if available
    try {
      const cachedData = localStorage.getItem(QUERIES_CACHE_KEY);
      if (cachedData) {
        const { data, timestamp, userId } = JSON.parse(cachedData);
        // Accept cached data on initial load regardless of expiry
        // Will refresh in background if needed
        if (userId === currentUser?.email) {
          return data || [];
        }
      }
    } catch (error) {
      console.error('Error reading initial cache:', error);
    }
    return [];
  });
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const [queryJustAdded, setQueryJustAdded] = useState(false);

  // Function to read visualization settings from cache
  const getQuerySettings = useCallback(() => {
    try {
      const storedSettings = localStorage.getItem(QUERY_SETTINGS_KEY);
      if (storedSettings) {
        const { userId, settings } = JSON.parse(storedSettings);
        if (userId === currentUser?.email) {
          return settings || {};
        }
      }
      return {};
    } catch (error) {
      console.error('Error reading query visualization settings:', error);
      return {};
    }
  }, [currentUser]);

  // Function to save visualization settings to cache
  // Function to save visualization settings to cache
const saveQuerySettings = useCallback((queryId, settings) => {
  if (!queryId || !settings) {
    console.error('Cannot save settings: Missing queryId or settings object');
    return;
  }
  
  try {
    // Get current settings
    const currentSettings = getQuerySettings();
    
    // Log before update
    console.log(`Saving settings for query ${queryId}:`, settings);
    console.log('Previous settings:', currentSettings[queryId] || 'none');
    
    // Update with new settings
    const updatedSettings = {
      ...currentSettings,
      [queryId]: {
        // Preserve any existing settings
        ...(currentSettings[queryId] || {}),
        // Apply new settings
        ...settings,
        // Add timestamp for debugging
        _lastUpdated: new Date().toISOString()
      }
    };
    
    // Save to localStorage
    localStorage.setItem(QUERY_SETTINGS_KEY, JSON.stringify({
      userId: currentUser?.email,
      settings: updatedSettings
    }));
    
    // Verify the save worked
    const verifySettings = getQuerySettings();
    console.log('Updated settings saved successfully:', 
      verifySettings[queryId] ? 'Yes' : 'No',
      'Settings count:', Object.keys(verifySettings).length
    );
  } catch (error) {
    console.error('Error saving query settings:', error);
  }
}, [currentUser, getQuerySettings]);

  // Function to read from cache
  const getQueriesFromCache = useCallback(() => {
    try {
      const cachedData = localStorage.getItem(QUERIES_CACHE_KEY);
      if (!cachedData) return null;
      
      const { data, timestamp, userId } = JSON.parse(cachedData);
      
      // Check if cache is valid (not expired and belongs to current user)
      const now = Date.now();
      if (now - timestamp < CACHE_EXPIRY && userId === currentUser?.email) {
        console.log('Using cached queries data');
        return data;
      }
      return null;
    } catch (error) {
      console.error('Error reading from cache:', error);
      return null;
    }
  }, [currentUser]);

  // Function to write to cache
  const saveQueriesToCache = useCallback((data) => {
    try {
      const cacheData = {
        data,
        timestamp: Date.now(),
        userId: currentUser?.email
      };
      localStorage.setItem(QUERIES_CACHE_KEY, JSON.stringify(cacheData));
      console.log('Saved queries to cache', data.length);
    } catch (error) {
      console.error('Error writing to cache:', error);
    }
  }, [currentUser]);

  // Function to invalidate cache
  const invalidateCache = useCallback(() => {
    try {
      const cachedData = localStorage.getItem(QUERIES_CACHE_KEY);
      if (cachedData) {
        const cached = JSON.parse(cachedData);
        cached.timestamp = 0; // Force cache expiration
        localStorage.setItem(QUERIES_CACHE_KEY, JSON.stringify(cached));
      }
    } catch (cacheError) {
      console.error('Error invalidating cache:', cacheError);
    }
  }, []);

  // Direct Firestore fetch function - updated to sort by createdAt
  const fetchQueriesFromFirestore = useCallback(async () => {
    if (!currentUser?.email) return [];
    
    try {
      // We'll first try to fetch using direct Firestore access for better performance
      const userQueries = [];
      
      // Get user's own queries - sort by createdAt descending
      const userQueryRef = query(
        collection(db, 'SavedQueries'),
        where('userId', '==', currentUser.email),
        where('isDeleted', '==', false),
        orderBy('createdAt', 'desc') // Sort by creation date descending
      );
      
      const userQuerySnapshot = await getDocs(userQueryRef);
      userQuerySnapshot.forEach(doc => {
        // Only add queries that aren't hidden
        const queryData = doc.data();
        if (!queryData.hidden) {
          userQueries.push({ id: doc.id, ...queryData });
        }
      });
      
      // Get shared queries if not admin
      let sharedQueries = [];
      if (!currentUser.isAdmin) {
        const sharedQueryRef = query(
          collection(db, 'SavedQueries'),
          where('isPrivate', '==', false),
          where('isDeleted', '==', false),
          where('userId', '!=', currentUser.email),
          orderBy('userId'),
          orderBy('createdAt', 'desc'), // Sort by creation date descending
          limit(50) // Limit to keep response size reasonable
        );
        
        const sharedQuerySnapshot = await getDocs(sharedQueryRef);
        sharedQuerySnapshot.forEach(doc => {
          // Only add queries that aren't hidden
          const queryData = doc.data();
          if (!queryData.hidden) {
            sharedQueries.push({ id: doc.id, ...queryData });
          }
        });
      } else {
        // For admins, fetch all queries (with limit)
        const allQueriesRef = query(
          collection(db, 'SavedQueries'),
          where('isDeleted', '==', false),
          orderBy('createdAt', 'desc'), // Sort by creation date descending
          limit(100) // Higher limit for admins but still reasonable
        );
        
        const allQueriesSnapshot = await getDocs(allQueriesRef);
        allQueriesSnapshot.forEach(doc => {
          const queryData = doc.data();
          if (queryData.userId !== currentUser.email && !queryData.hidden) {
            sharedQueries.push({ id: doc.id, ...queryData });
          }
        });
      }
      
      // Combine results, ensuring uniqueness
      const seen = new Set(userQueries.map(q => q.id));
      const allQueries = [
        ...userQueries,
        ...sharedQueries.filter(q => !seen.has(q.id))
      ];
      
      // Sort by createdAt (descending)
      allQueries.sort((a, b) => {
        const aTime = a.createdAt?.toMillis?.() || 
                     (typeof a.createdAt === 'string' ? new Date(a.createdAt).getTime() : 0) || 
                     a.lastRun?.toMillis?.() || 
                     (typeof a.lastRun === 'string' ? new Date(a.lastRun).getTime() : 0) || 
                     0;
                     
        const bTime = b.createdAt?.toMillis?.() || 
                     (typeof b.createdAt === 'string' ? new Date(b.createdAt).getTime() : 0) || 
                     b.lastRun?.toMillis?.() || 
                     (typeof b.lastRun === 'string' ? new Date(b.lastRun).getTime() : 0) || 
                     0;
                     
        return bTime - aTime; // Newest first
      });
      
      return allQueries;
    } catch (error) {
      console.error('Error fetching from Firestore directly:', error);
      throw error;
    }
  }, [currentUser]);

  // Fallback to backend function if direct Firestore fails
  const fetchQueriesFromBackend = useCallback(async () => {
    if (!currentUser?.email || !config?.core?.projectId) return [];
    
    const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/getSavedQueries`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        data: { 
          userId: currentUser.email,
          isAdmin: currentUser.isAdmin
        }
      })
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch saved queries');
    }
    
    const result = await response.json();
    // Filter out hidden queries
    const queriesData = result.queries || result;
    return queriesData.filter(query => !query.hidden);
  }, [currentUser, config]);

  const fetchSavedQueries = useCallback(async (forceFresh = false) => {
    if (!currentUser || !config) return;
    
    // Check if we should use cached data
    if (!forceFresh) {
      const cachedQueries = getQueriesFromCache();
      if (cachedQueries) {
        setQueries(cachedQueries);
        setIsLoading(false);
        return;
      }
    }
    
    setIsLoading(true);
    try {
      // Try direct Firestore first, fall back to backend
      let queriesData;
      try {
        queriesData = await fetchQueriesFromFirestore();
        console.log('Fetched from Firestore:', queriesData.length);
      } catch (firestoreError) {
        console.warn('Falling back to backend fetch:', firestoreError);
        queriesData = await fetchQueriesFromBackend();
        console.log('Fetched from backend:', queriesData.length);
      }
      
      // Save to cache and state
      saveQueriesToCache(queriesData);
      setQueries(queriesData);
      setLastFetchTime(Date.now());
      setError(null);
    } catch (err) {
      console.error('Error fetching saved queries:', err);
      setError('Failed to load saved queries');
      
      // Try to use cached data even if expired as a fallback
      const cachedQueries = getQueriesFromCache();
      if (cachedQueries && cachedQueries.length > 0) {
        setQueries(cachedQueries);
        console.log('Using expired cache as fallback');
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentUser, 
    config, 
    getQueriesFromCache, 
    saveQueriesToCache, 
    fetchQueriesFromFirestore, 
    fetchQueriesFromBackend
  ]);

  // Function to hide a query (set hidden = true in Firestore)
  const hideQuery = useCallback(async (queryId) => {
    if (!queryId || !currentUser?.email) return;
    
    try {
      setIsLoading(true);
      
      // Update the document in Firestore
      const queryRef = doc(db, 'SavedQueries', queryId);
      await updateDoc(queryRef, {
        hidden: true,
        hiddenAt: new Date(),
        hiddenBy: currentUser.email
      });
      
      // Update local state by removing the query
      setQueries(prevQueries => prevQueries.filter(query => query.id !== queryId));
      
      // Invalidate cache
      invalidateCache();
      
      console.log('Query hidden successfully:', queryId);
    } catch (error) {
      console.error('Error hiding query:', error);
      setError('Failed to hide query. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentUser, invalidateCache]);

  // Add to library function - enhanced to store visualization settings
  const addToLibrary = useCallback(async (result) => {
    if (!result || !currentUser?.email || !config?.core?.projectId) return null;
    
    try {
      // Use the query's name if it exists, otherwise generate one from the query text
      let name;
      if (result.name && typeof result.name === 'string' && result.name.trim()) {
        name = result.name.trim();
      } else {
        // Generate a name based on the first few words of the query
        const words = result.userQuery.split(' ');
        const nameWords = words.slice(0, Math.min(7, words.length));
        name = nameWords.join(' ');
        if (name.length > 40) {
          name = name.substring(0, 37) + '...';
        }
      }
      
      // Extract relevant card settings for saving with the query
      const cardSettings = result.cardSettings ? {
        viewMode: result.cardSettings.viewMode || 'table',
        chartType: result.cardSettings.chartType || 'bar',
        selectedXAxis: result.cardSettings.selectedXAxis,
        selectedYAxis: result.cardSettings.selectedYAxis,
        visibleColumns: result.cardSettings.visibleColumns,
        columnOrder: result.cardSettings.columnOrder,
        syncScales: result.cardSettings.syncScales,
        sortColumn: result.cardSettings.sortColumn,
        sortOrder: result.cardSettings.sortOrder,
        showSummary: result.cardSettings.showSummary,
        activeFilters: result.cardSettings.activeFilters || {}
      } : null;
      
      const response = await fetch(
        `https://us-central1-${config.core.projectId}.cloudfunctions.net/saveQuery`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              userId: currentUser.email,
              name: name,
              userQuery: result.userQuery,
              sqlQuery: result.sqlQuery,
              llmModel: result.config?.usedModel || result.llmModel,
              fallbackUsed: result.config?.fallbackUsed || result.fallbackUsed,
              originalModel: result.originalModel,
              documentId: result.documentId,
              isPrivate: true, // Default to private for quick saves
              explanation: result.explanation,
              schema: result.schema,
              cardSettings, // Save card settings with the query
              hidden: false // Explicitly set hidden to false for new queries
            }
          })
        }
      );
  
      const responseData = await response.json();
      console.log('DEBUG - Server response from saveQuery:', responseData);

      // Try different possible property names for the ID
      const queryId = responseData.queryId || responseData.id || responseData.savedQueryId;
      console.log('DEBUG - Found query ID:', queryId);

      // Save visualization settings to localStorage if we got any valid query ID
      if (queryId && cardSettings) {
        console.log('DEBUG - Saving card settings for query:', queryId);
        console.log('DEBUG - Settings being saved:', cardSettings);
        saveQuerySettings(queryId, cardSettings);
        console.log('DEBUG - Visualization settings saved successfully for library query:', queryId);
        
        // Verify it was saved
        const allSettings = getQuerySettings();
        console.log('DEBUG - All settings after save:', allSettings);
      }
      
      // Invalidate cache
      invalidateCache();
      
      // Show a success indicator
      setQueryJustAdded(true);
      setTimeout(() => setQueryJustAdded(false), 3000);
      
      // Refresh the queries
      fetchSavedQueries(true);
      
      return responseData;
    } catch (error) {
      console.error('Error adding to library:', error);
      setError('Failed to add to library. Please try again.');
      throw error;
    }
  }, [config, currentUser, invalidateCache, fetchSavedQueries, saveQuerySettings]);

  // Refresh data on config or currentUser change
  useEffect(() => {
    if (currentUser && config) {
      fetchSavedQueries();
    }
  }, [fetchSavedQueries, currentUser, config]);

  return {
    // State
    queries,
    isLoading,
    error,
    lastFetchTime,
    queryJustAdded,
    
    // State setters
    setQueries,
    setQueryJustAdded,
    
    // Functions
    fetchSavedQueries,
    addToLibrary,
    invalidateCache,
    getQueriesFromCache,
    saveQueriesToCache,
    hideQuery, // Export the hideQuery function
    
    // New functions for visualization settings
    getQuerySettings,
    saveQuerySettings
  };
};

export default useQueryLibraryCore;