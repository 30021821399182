import React from 'react';

const AnalysisResultContent = ({ analysisResponse, documentLinks }) => {
  // Function to check if text is Hebrew
  const isHebrew = (text) => {
    // Hebrew characters are in the range of 0x0590-0x05FF
    const hebrewPattern = /[\u0590-\u05FF]/;
    return hebrewPattern.test(text);
  };
  
  // Function to get document link by index
  const getDocumentLinkByIndex = (index) => {
    if (!documentLinks || documentLinks.length === 0 || index >= documentLinks.length) {
      return null;
    }
    return documentLinks[index];
  };
  
  const formatResponse = (text) => {
    // Remove the redundant "Document Analysis Results" title and metrics from the content
    let formattedText = text;
    
    // Remove "Document Analysis Results" if present - using non-greedy matching
    formattedText = formattedText.replace(/^(\s*)?## Document Analysis Results.*?(?:\n|$)/im, '');
  
    // Remove the metrics lines if they are in the text - using non-greedy matching
    formattedText = formattedText.replace(/^(\s*)?(\*\*Documents Analyzed:\*\*.*?)(?:\n|$)/im, '');
    formattedText = formattedText.replace(/^(\s*)?(\*\*Processing Time:\*\*.*?)(?:\n|$)/im, '');
  
    // If there are multiple newlines at the beginning, trim them to just one
    formattedText = formattedText.replace(/^\n+/, '\n');
    
    // Parse the text line by line
    const lines = formattedText.split('\n');
    const result = [];
    
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      
      // Skip empty lines but add a spacer
      if (!line.trim()) {
        result.push(<div key={`empty-${i}`} className="h-2"></div>);
        continue;
      }
      
      // Check for header patterns - supporting both RTL and LTR
      // This will match both "## Header" and "Header ##" formats to handle RTL text
      if (line.includes('##') && !line.includes('###')) {
        const cleanedLine = line.replace(/#{2,}/g, '').trim();
        result.push(
          <h3 key={`h2-${i}`} className="text-xl font-semibold mt-4 mb-3 border-b pb-1 border-gray-100">
            {processDocumentReferences(cleanedLine, i)}
          </h3>
        );
        continue;
      }
      
      // Check for subheader patterns
      if (line.includes('###')) {
        const cleanedLine = line.replace(/#{3,}/g, '').trim();
        result.push(
          <h4 key={`h3-${i}`} className="text-lg font-semibold mt-3 mb-2">
            {processDocumentReferences(cleanedLine, i)}
          </h4>
        );
        continue;
      }
      
      // Check for asterisk bullet points
      if (line.trim().startsWith('*') && !line.trim().startsWith('**')) {
        const bulletText = line.replace(/^\s*\*\s*/, '').trim();
        result.push(
          <div key={`bullet-${i}`} className="relative rtl:pr-8 ltr:pl-8 mb-4">
            <span className={`absolute ${isHebrew(analysisResponse) ? 'right-0' : 'left-0'} top-1 text-black text-lg`}>•</span>
            <span>{processDocumentReferences(bulletText, i)}</span>
          </div>
        );
        continue;
      }
      
      // Regular paragraph with bold text and document reference processing
      result.push(
        <p key={`p-${i}`} className="mb-2.5">
          {processDocumentReferences(line, i)}
        </p>
      );
    }
    
    return result;
  };

  // Function to process document references in the format "(doc:X)" and bold text
  const processDocumentReferences = (text, lineIndex) => {
    // Handle standalone document references at beginning of line
    const standalonePattern = /^(\s*\(doc:(\d+)\))\s*/;
    const standaloneMatch = text.match(standalonePattern);
    
    if (standaloneMatch) {
      const [fullMatch, refText, docIndexStr] = standaloneMatch;
      const docIndex = parseInt(docIndexStr, 10);
      const docLink = getDocumentLinkByIndex(docIndex);
      
      // Get remaining text after the reference
      const remainingText = text.substring(fullMatch.length);
      
      // Return a fragment with the reference link hidden and process the remaining text
      return [
        docLink ? (
          <a 
            key={`sup-ref-${lineIndex}`}
            href={docLink.url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center transition hover:underline text-primary"
            title={`Open "${docLink.name}"`}
          >
            <sup className="text-xs opacity-0 absolute">
              {refText}
            </sup>
          </a>
        ) : null,
        processBoldTextWithInlineRefs(remainingText, `remaining-${lineIndex}`)
      ];
    }
    
    // If no standalone reference at the beginning, process the whole text
    return processBoldTextWithInlineRefs(text, `line-${lineIndex}`);
  };
  
  // Process text for both bold segments and inline document references
  const processBoldTextWithInlineRefs = (text, lineKey) => {
    if (typeof text !== 'string') return text;
    
    // Process bold text first
    const boldRegex = /\*\*(.+?)\*\*/g;
    let boldMatch;
    let parts = [];
    let lastIndex = 0;
    
    while ((boldMatch = boldRegex.exec(text)) !== null) {
      // Add text before the bold match, processing it for references
      if (boldMatch.index > lastIndex) {
        const beforeBold = text.substring(lastIndex, boldMatch.index);
        parts.push(...processInlineReferences(beforeBold, `before-bold-${lineKey}-${lastIndex}`));
      }
      
      // Process the bold text itself for references
      const boldContent = boldMatch[1];
      parts.push(
        <strong key={`bold-${lineKey}-${boldMatch.index}`} className="font-medium">
          {processInlineReferences(boldContent, `in-bold-${lineKey}-${boldMatch.index}`)}
        </strong>
      );
      
      lastIndex = boldMatch.index + boldMatch[0].length;
    }
    
    // Process any remaining text for references
    if (lastIndex < text.length) {
      const remaining = text.substring(lastIndex);
      parts.push(...processInlineReferences(remaining, `after-bold-${lineKey}`));
    }
    
    return parts.length > 0 ? parts : processInlineReferences(text, `plain-${lineKey}`);
  };
  
  // Process inline document references - text followed by (doc:X)
  const processInlineReferences = (text, keyPrefix) => {
    if (typeof text !== 'string' || !text.includes('(doc:')) return [text];
    
    // This regex captures text preceding the document reference and the reference itself
    // but tries to be more conservative about how much text it captures
    const docRefRegex = /([^.,:;!?\s]+(?:\s+[^.,:;!?\s]+){0,2})\s*(\(doc:(\d+)(?:[,\s].*?)?\))/g;
    const parts = [];
    let lastIndex = 0;
    let match;
    
    // Create a copy to work with
    let remaining = text;
    let matchFound = false;
    
    while ((match = docRefRegex.exec(remaining)) !== null) {
      matchFound = true;
      
      // Text before the match
      if (match.index > 0) {
        parts.push(remaining.substring(0, match.index));
      }
      
      // Extract text and document index
      const [fullMatch, docText, refText, docIndexStr] = match;
      const docIndex = parseInt(docIndexStr, 10);
      
      // Get the document link
      const docLink = getDocumentLinkByIndex(docIndex);
      
      if (docLink) {
        // Create link element but hide the document reference
        parts.push(
          <a 
            key={`doc-link-${keyPrefix}-${match.index}`}
            href={docLink.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:underline transition-colors inline-flex items-center group"
            title={`Open "${docLink.name}"`}
          >
            <span>{docText}</span>
            <span className="sr-only">{refText}</span>
          </a>
        );
      } else {
        // No link found, just show the text (without reference)
        parts.push(
          <span key={`doc-text-${keyPrefix}-${match.index}`}>
            {docText}
            <span className="sr-only">{refText}</span>
          </span>
        );
      }
      
      // Update remaining text
      remaining = remaining.substring(match.index + fullMatch.length);
      
      // Reset regex to work with the new remaining text
      docRefRegex.lastIndex = 0;
    }
    
    // Add any remaining text
    if (remaining) {
      parts.push(remaining);
    }
    
    return matchFound ? parts : [text];
  };
  
  return (
    <div className="p-4 px-6">
      <div 
        className={`max-w-none text-gray-700 ${isHebrew(analysisResponse) ? 'text-right' : ''}`}
        dir={isHebrew(analysisResponse) ? "rtl" : "ltr"}
      >
        {formatResponse(analysisResponse)}
      </div>
    </div>
  );
};

export default AnalysisResultContent;