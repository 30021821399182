import React, { useEffect, useState } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { RefreshCw, CheckCircle2, XCircle, AlertCircle } from 'lucide-react';

const AutoLabelProgress = ({ batchId }) => {
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!batchId) return;

    const db = getFirestore();
    const batchRef = doc(db, 'batchProcessing', batchId);

    // Set up real-time listener
    const unsubscribe = onSnapshot(batchRef, 
      (doc) => {
        if (doc.exists()) {
          setProgress(doc.data());
          setError(null);
        } else {
          setError('Batch processing record not found');
        }
      },
      (err) => {
        console.error('Error fetching progress:', err);
        setError('Failed to fetch progress updates');
      }
    );

    // Cleanup subscription
    return () => unsubscribe();
  }, [batchId]);

  if (!batchId) {
    return null;
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 rounded-lg flex items-center gap-2 text-red-700">
        <AlertCircle className="h-5 w-5" />
        <span>{error}</span>
      </div>
    );
  }

  if (!progress) {
    return (
      <div className="p-4 bg-gray-50 rounded-lg flex items-center gap-2">
        <RefreshCw className="h-5 w-5 animate-spin" />
        <span>Loading progress...</span>
      </div>
    );
  }

  const calculatePercentage = () => {
    const totalProcessed = (progress.processedDocuments || 0) + 
                         (progress.failedDocuments || 0);
    
    if (progress.status === 'completed') return 100;
    if (progress.totalDocuments === 0) return 0;
    
    const percent = Math.round((totalProcessed / progress.totalDocuments) * 100);
    return Math.min(percent, 99);
  };

  const percentage = calculatePercentage();
  const isActive = progress.status === 'in_progress' || progress.status === 'initializing';

  return (
    <div className="p-4 bg-white rounded-lg shadow-sm border">
      <div className="space-y-4">
        {/* Header with status */}
        <div className="flex items-center justify-between">
          <span className={`text-sm font-medium px-2 py-1 rounded ${
            progress.status === 'completed' ? 'bg-green-100 text-green-800' :
            progress.status === 'failed' ? 'bg-red-100 text-red-800' :
            'bg-blue-100 text-blue-800'
          }`}>
            {progress.status.charAt(0).toUpperCase() + progress.status.slice(1)}
          </span>
        </div>

        {/* Progress bar */}
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span>Progress</span>
            <span>{percentage}%</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className={`h-full rounded-full transition-all duration-300 ${
                progress.status === 'completed' ? 'bg-green-500' :
                progress.status === 'failed' ? 'bg-red-500' :
                'bg-blue-500'
              }`}
              style={{ width: `${percentage}%` }}
            />
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <span className="text-gray-600">Current Batch:</span>
            <span className="ml-2 font-medium">
              {progress.currentBatch || 0} of {progress.totalBatches || 0}
            </span>
          </div>
          <div>
            <span className="text-gray-600">Documents Processed:</span>
            <span className="ml-2 font-medium">
              {progress.totalDocuments || 0} total 
              ({(progress.processedDocuments || 0)} labeled, {progress.failedDocuments || 0} failed)
            </span>
          </div>
        </div>

        {/* Status indicator */}
        {isActive && (
          <div className="flex items-center gap-2 text-blue-600">
            <RefreshCw className="h-4 w-4 animate-spin" />
            <span>Processing in progress...</span>
          </div>
        )}

        {progress.status === 'completed' && (
          <div className="flex items-center gap-2 text-green-600">
            <CheckCircle2 className="h-5 w-5" />
            <span>Processing completed successfully</span>
          </div>
        )}

        {progress.status === 'failed' && (
          <div className="flex items-center gap-2 text-red-600">
            <XCircle className="h-5 w-5" />
            <span>Processing failed</span>
          </div>
        )}

        {/* Errors section */}
        {progress.errors && progress.errors.length > 0 && (
          <div className="mt-4 space-y-2">
            <h4 className="text-sm font-medium text-red-600">Errors:</h4>
            <div className="max-h-32 overflow-y-auto space-y-1">
              {progress.errors.map((error, index) => (
                <div key={index} className="text-sm text-red-600 bg-red-50 p-2 rounded">
                  {error.error}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoLabelProgress;