import React, { useState, useEffect } from 'react';
import AnalysisResultCard from './AnalysisResultCard';

const AnalysisResultsContainer = ({ 
  analysisResult, 
  query, 
  onClose, 
  selectedCategories = [], 
  selectedLabels = [], 
  selectedDocuments = [] 
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (analysisResult) {
      console.log("[AnalysisResultsContainer] Received analysisResult:", {
        success: analysisResult.success,
        title: analysisResult.title,
        responseLength: analysisResult.response ? analysisResult.response.length : 0,
        responsePreview: analysisResult.response ? 
          (analysisResult.response.length > 100 ? 
            analysisResult.response.substring(0, 100) + "..." : analysisResult.response) : 
          "No response"
      });
    }
  }, [analysisResult]);

  if (!analysisResult || !isVisible) {
    return null;
  }

  // If the analysis failed, show an error message
  if (!analysisResult.success) {
    return (
      <div className="bg-red-50 p-4 rounded-md mb-6">
        <div className="text-red-600 font-medium">Analysis Error</div>
        <div className="text-red-500 text-sm">{analysisResult.message}</div>
      </div>
    );
  }

  const handleClose = () => {
    setIsVisible(false);
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  // Show analysis if available
  return (
    <div className="transition-all duration-500 ease-in-out animate-fadeIn">
      {analysisResult.response ? (
        <AnalysisResultCard 
          analysisResponse={analysisResult.response} 
          metadata={analysisResult.metadata}
          documentLinks={analysisResult.documentLinks || []}
          selectedCategories={selectedCategories}
          selectedLabels={selectedLabels}
          selectedDocuments={selectedDocuments}
          title={analysisResult.title} // Pass the title if available
          query={query} // Pass the original query
          onClose={handleClose}
        />
      ) : (
        <div className="text-center p-6 bg-white rounded-md border border-gray-200">
          <p className="text-gray-500">No analysis could be generated for your query.</p>
        </div>
      )}
    </div>
  );
};

export default AnalysisResultsContainer;