import React, { useState, useEffect } from 'react';
import { AlertTriangle, Copy, ChevronDown, ChevronUp, Lightbulb, Mail } from 'lucide-react';
import Card from '../Card';
import Button from '../Button';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';

const SuggestedAction = ({ action }) => (
  <div className="flex items-center gap-3 text-gray-700 p-2 hover:bg-secondary/5 rounded-lg transition-colors">
    <Lightbulb className="text-secondary flex-shrink-0" size={20} />
    <p className="text-left">{action}</p>
  </div>
);

const QueryError = ({ error, onClose }) => {
  const [copiedError, setCopiedError] = useState(false);
  const [expandedTry, setExpandedTry] = useState(null);
  const [supportEmails, setSupportEmails] = useState([]);
  const { isAdmin } = useAuth();

  useEffect(() => {
    const fetchSupportEmails = async () => {
      const db = getFirestore();
      const configDoc = await getDoc(doc(db, 'DBI Configuration', 'Config'));
      if (configDoc.exists()) {
        setSupportEmails(configDoc.data().Support || []);
      }
    };
    fetchSupportEmails();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopiedError(true);
        setTimeout(() => setCopiedError(false), 2000);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const toggleTryExpansion = (index) => {
    setExpandedTry(expandedTry === index ? null : index);
  };

  const formatErrorContent = () => {
    let content = `Error Details:\n\n`;
    content += `Query ID: ${error.documentId || 'N/A'}\n`;
    content += `Error message: ${getErrorMessage()}\n`;
    content += `User ID: ${error.userId || 'N/A'}\n`;
    content += `User question: ${error.userQuestion || 'N/A'}\n`;
    content += `Big Query Error: ${error.bigQueryError || 'N/A'}\n`;
    content += `Retry Count: ${error.retryCount || 'N/A'}\n`;
    content += `Retry Result: ${error.retryResult || 'N/A'}\n`;
    content += `Status: ${error.status || 'N/A'}\n`;
    content += `End Time: ${error.endTime || 'N/A'}\n\n`;
  
    if (isAdmin && error.details && error.details.stack) {
      content += `Stack Trace:\n${error.details.stack}\n\n`;
    }
  
    if (isAdmin && error.queryVersions && error.queryVersions.length > 0) {
      error.queryVersions.forEach((version, index) => {
        content += `Query Version ${index + 1}:\n`;
        content += `SQL Query:\n${version.sqlQuery || 'N/A'}\n`;
        content += `Error: ${version.error || 'N/A'}\n\n`;
      });
    }
  
    return content;
  };

  const getErrorMessage = () => {
    if (typeof error.error === 'string') {
      return error.error;
    } else if (error.details && typeof error.details.message === 'string') {
      return error.details.message;
    } else {
      return 'An unexpected error occurred. Please try again.';
    }
  };

  const getErrorDetails = () => {
    if (error.details && typeof error.details.stack === 'string') {
      return error.details.stack;
    } else if (typeof error.bigQueryError === 'string') {
      return error.bigQueryError;
    } else {
      return '';
    }
  };

  const getSuggestedAction = () => {
    if (error.details && typeof error.details.suggestedAction === 'string') {
      return error.details.suggestedAction;
    } else {
      return 'Please try rephrasing your question or contact support if the issue persists.';
    }
  };

  const handleReportIssue = () => {
    const subject = encodeURIComponent(`Support ticket about a query from ${window.location.hostname}, ${format(new Date(), 'dd/MM/yy HH:mm')}`);
    const body = encodeURIComponent(`
User Query:
${error.userQuestion || 'N/A'}

Document ID:
${error.documentId || 'N/A'}
    `);
    const mailtoLink = `mailto:${supportEmails.join(',')}?subject=${subject}&body=${body}`;
    window.open(mailtoLink, '_blank');
  };

  let errorMessage = getErrorMessage();
  let errorDetails = getErrorDetails();
  let suggestedAction = getSuggestedAction();

  // Regular user view
  if (!isAdmin) {
    return (
      <Card className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-8 border border-secondary/20">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-1 rounded-full text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-all"
          aria-label="Close error message"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>

        {/* Main content */}
        <div className="flex flex-col items-center text-center space-y-6">
          <h3 className="text-2xl font-semibold bg-gradient-to-r from-secondary to-secondary/70 bg-clip-text text-transparent">
            Oops, Something Went Wrong
          </h3>

          <p className="text-gray-600 max-w-md text-lg leading-relaxed">
          No worries - let's try a different approach! 
          </p>

          <div className="bg-gradient-to-b from-secondary/5 to-transparent rounded-xl p-6 w-full max-w-md mt-4">
            <h4 className="font-medium text-gray-800 mb-4 text-left text-lg">Try these alternatives:</h4>
            <div className="space-y-3">
              <SuggestedAction action="Rephrase your question using different words" />
              <SuggestedAction action="Break down your question into simpler parts" />
              <SuggestedAction action="Give it another try in a few moments" />
            </div>
          </div>

          <div className="w-full max-w-md pt-6">
            <p className="text-gray-600 mb-6 text-lg">
              Still not working? Our support team is ready to help!
            </p>
            <button 
              onClick={handleReportIssue}
              className="group relative flex items-center justify-center gap-2 bg-secondary text-white px-8 py-3 rounded-lg hover:bg-secondary/90 transition-all duration-300 shadow-lg hover:shadow-secondary/25 w-full"
            >
              <Mail size={18} className="transition-transform group-hover:scale-110" />
              <span className="font-medium">Contact Support</span>
              <div className="absolute inset-0 bg-white/20 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </button>
            
            <p className="text-gray-400 text-sm mt-6">
              Reference: {error.documentId || 'N/A'}
            </p>
          </div>
        </div>
      </Card>
    );
  }

  // Admin view
  return (
    <Card className="bg-gray-50 border-l-4 border-gray-500 text-gray-700 p-4 mb-4 relative">
      <div className="flex items-center mb-4">
        <AlertTriangle className="mr-2 text-gray-700" />
        <h3 className="text-lg font-semibold text-gray-700">Something Went Wrong</h3>
        <button
          onClick={onClose}
          className="ml-auto p-1 rounded-full transition-colors duration-200 hover:bg-gray-200"
          aria-label="Close error message"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <p className="mb-2">
        <strong>Error message:</strong> {errorMessage}
      </p>
      <SuggestedAction action={suggestedAction} />
      <p className="mb-2">
        <strong>Query ID:</strong> {error.documentId || 'N/A'}
      </p>
      <p className="mb-2">
        <strong>User ID:</strong> {error.userId || 'N/A'}
      </p>
      <p className="mb-2">
        <strong>User Question:</strong> {error.userQuestion || 'N/A'}
      </p>
      <p className="mb-2">
        <strong>Retry Count:</strong> {error.retryCount || 'N/A'}
      </p>
      <p className="mb-2">
        <strong>Retry Result:</strong> {error.retryResult || 'N/A'}
      </p>
      <p className="mb-2">
        <strong>Status:</strong> {error.status || 'N/A'}
      </p>
      <p className="mb-2">
        <strong>End Time:</strong> {error.endTime || 'N/A'}
      </p>
  
      {errorDetails && (
        <div className="mb-2">
          <strong>Error details:</strong>
          <pre className="bg-gray-100 p-2 rounded mt-1 overflow-x-auto">
            {errorDetails}
          </pre>
        </div>
      )}
  
      <div className="mt-4">
        <h4 className="font-semibold mb-2">Admin Details:</h4>
        {error.queryVersions && error.queryVersions.length > 0 ? (
          error.queryVersions.map((version, index) => (
            <div key={index} className="mb-4 bg-white rounded-md p-2">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleTryExpansion(index)}
              >
                <h5 className="font-semibold">Query Version {index + 1}</h5>
                {expandedTry === index ? (
                  <ChevronUp size={18} />
                ) : (
                  <ChevronDown size={18} />
                )}
              </div>
              {expandedTry === index && (
                <div className="mt-2">
                  <p>
                    <strong>Error details:</strong> {version.error || 'N/A'}
                  </p>
                  <p>
                    <strong>SQL Query:</strong>
                  </p>
                  <pre className="bg-gray-100 p-2 rounded mt-1 overflow-x-auto">
                    {version.sqlQuery || 'N/A'}
                  </pre>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>
            <strong>Error details:</strong> {errorMessage}
          </p>
        )}
        <p>
          <strong>Big Query Error:</strong> {getErrorDetails() || 'N/A'}
        </p>
      </div>
  
      <div className="mt-4">
        <Button
          onClick={() => copyToClipboard(formatErrorContent())}
          icon={Copy}
          className="p-2"
          title={copiedError ? 'Copied!' : 'Copy Error Details'}
        >
          {copiedError ? 'Copied!' : 'Copy Error Details'}
        </Button>
      </div>
    </Card>
  );
};

export default QueryError;