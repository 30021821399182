import React, { useState, useEffect, useRef } from 'react';
import { SlidersHorizontal, X, Search, Calendar, HelpCircle, MessageCircle } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ValueSearchList from '../ValueSearchList';
import { useAuth } from '../../contexts/AuthContext';

const QuickFilter = ({ 
  onSubmit,
  onClose
}) => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const { allowedTables } = useAuth();
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [textQuery, setTextQuery] = useState('');
  const [indexedColumns, setIndexedColumns] = useState({});
  const [valueSearchSelections, setValueSearchSelections] = useState({}); 
  const modalRef = useRef(null);
  
  // Add this to store the last selected table ID
  const LAST_TABLE_KEY = 'quickFilter_lastTableId';

  const saveFiltersToLocalStorage = (tableId, filterData) => {
    try {
      localStorage.setItem(`quickFilter_${tableId}`, JSON.stringify({
        filters: filterData.filters,
        dateRange: filterData.dateRange,
        valueSearchSelections: filterData.valueSearchSelections
      }));
      
      // Save the last used table ID
      localStorage.setItem(LAST_TABLE_KEY, tableId);
    } catch (error) {
      console.error('Error saving filters to localStorage:', error);
    }
  };

  const loadFiltersFromLocalStorage = (tableId) => {
    try {
      const savedData = localStorage.getItem(`quickFilter_${tableId}`);
      if (savedData) {
        return JSON.parse(savedData);
      }
    } catch (error) {
      console.error('Error loading filters from localStorage:', error);
    }
    return null;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const formatFieldName = (fieldName) => {
    return fieldName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getPlaceholderText = (fieldType) => {
    if (fieldType === 'FLOAT' || fieldType === 'INTEGER') {
      return 'Enter number';
    }
    return 'Enter text';
  };

  const formatTableName = (fullTableId) => {
    const tableName = fullTableId.split('.').pop();
    return tableName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const fetchIndexedColumns = async () => {
    try {
      const indexingConfig = await getDoc(doc(db, 'appConfiguration', 'indexing'));
      if (indexingConfig.exists()) {
        const indexData = indexingConfig.data();
        // Transform the data to match what the component expects
        // Create a map where the key is the full table ID and the value is the array of indexed columns
        const transformedIndexes = Object.entries(indexData).reduce((acc, [tableName, tableConfig]) => {
          acc[tableConfig.id] = tableConfig.columns;
          return acc;
        }, {});
        setIndexedColumns(transformedIndexes);
      }
    } catch (err) {
      console.error('Error fetching indexed columns:', err);
    }
  };

  useEffect(() => {
    fetchIndexedColumns();
  }, []);

  useEffect(() => {
    if (selectedTable?.id) {
      const savedFilterData = loadFiltersFromLocalStorage(selectedTable.id);
      if (savedFilterData) {
        setFilters(savedFilterData.filters || {});
        setDateRange(savedFilterData.dateRange || { from: '', to: '' });
        setValueSearchSelections(savedFilterData.valueSearchSelections || {});
      }
    }
  }, [selectedTable]);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        setLoading(true);
        setError(null); // Reset error state
        
        // Wait for allowedTables to be initialized
        if (!allowedTables) {
          console.log('[QuickFilter] Waiting for allowed tables configuration...');
          return; // Just return without setting error
        }

        const configDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
        
        if (!configDoc.exists()) {
          throw new Error('Tables configuration not found');
        }

        const docData = configDoc.data();
        const tablesData = Object.entries(docData)
          .filter(([_, value]) => 
            typeof value === 'object' && 
            value !== null && 
            value.schema && 
            Array.isArray(value.schema)
          )
          // Filter for allowed tables
          .filter(([_, tableData]) => {
            const hasAccess = allowedTables[tableData.id];
            console.log('[QuickFilter] Checking table access:', {
              tableId: tableData.id,
              hasAccess,
              allowedTables // Log the full allowedTables object
            });
            return hasAccess;
          })
          .map(([name, tableData]) => ({
            name,
            id: tableData.id,
            schema: tableData.schema.filter(field => 
              !field.name.toLowerCase().includes('created') &&
              !field.name.toLowerCase().includes('modified') &&
              !field.name.toLowerCase().includes('timestamp') &&
              field.mode !== 'REPEATED'
            ),
            prompt: tableData.prompt || ''
          }));

        console.log('[QuickFilter] Filtered tables:', tablesData);
        
        if (tablesData.length === 0) {
          console.log('[QuickFilter] No accessible tables found');
          setError('No accessible tables found');
          return;
        }

        setTables(tablesData);
        
        // Try to load the last used table
        const lastTableId = localStorage.getItem(LAST_TABLE_KEY);
        if (lastTableId) {
          const lastTable = tablesData.find(t => t.id === lastTableId);
          if (lastTable) {
            setSelectedTable(lastTable);
            return;
          }
        }
        
        // If no last table or it's not found, use the first one
        if (tablesData.length > 0) {
          setSelectedTable(tablesData[0]);
        }
      } catch (err) {
        console.error('[QuickFilter] Error fetching tables:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, [allowedTables]);

  const handleTableChange = (tableName) => {
    const table = tables.find(t => t.name === tableName);
    setSelectedTable(table);
    setTextQuery(''); // Always reset text query
    
    // Load saved filters for this table if they exist
    if (table?.id) {
      const savedFilterData = loadFiltersFromLocalStorage(table.id);
      if (savedFilterData) {
        setFilters(savedFilterData.filters || {});
        setDateRange(savedFilterData.dateRange || { from: '', to: '' });
        setValueSearchSelections(savedFilterData.valueSearchSelections || {});
      } else {
        // If no saved filters, reset everything
        setFilters({});
        setDateRange({ from: '', to: '' });
        setValueSearchSelections({});
      }
    } else {
      // If no table, reset everything
      setFilters({});
      setDateRange({ from: '', to: '' });
      setValueSearchSelections({});
    }
  };

  const handleFilterChange = (fieldName, value, isFromValueSearch = false) => {
    if (value === '') {
      const newFilters = { ...filters };
      delete newFilters[fieldName];
      setFilters(newFilters);
      
      const newValueSearchSelections = { ...valueSearchSelections };
      delete newValueSearchSelections[fieldName];
      setValueSearchSelections(newValueSearchSelections);
    } else {
      if (isFromValueSearch) {
        setFilters(prev => {
          const currentValues = Array.isArray(prev[fieldName]) ? prev[fieldName] : [];
          if (!currentValues.includes(value)) {
            return {
              ...prev,
              [fieldName]: [...currentValues, value]
            };
          }
          return prev;
        });
        
        setValueSearchSelections(prev => ({
          ...prev,
          [fieldName]: true
        }));
      } else {
        setFilters(prev => ({
          ...prev,
          [fieldName]: value
        }));
      }
    }
  };

  const removeSelectedValue = (fieldName, valueToRemove) => {
    setFilters(prev => {
      const currentValues = prev[fieldName];
      if (Array.isArray(currentValues)) {
        const newValues = currentValues.filter(v => v !== valueToRemove);
        if (newValues.length === 0) {
          const newFilters = { ...prev };
          delete newFilters[fieldName];
          return newFilters;
        }
        return {
          ...prev,
          [fieldName]: newValues
        };
      }
      return prev;
    });
  };

  const handleDateRangeChange = (type, value) => {
    setDateRange(prev => ({
      ...prev,
      [type]: value
    }));
  };
  
  // Add clear all filters function
  const handleClearAll = () => {
    if (selectedTable) {
      setFilters({});
      setDateRange({ from: '', to: '' });
      setValueSearchSelections({});
      setTextQuery('');
      
      // Remove saved filters for this table
      try {
        localStorage.removeItem(`quickFilter_${selectedTable.id}`);
      } catch (error) {
        console.error('Error clearing filters from localStorage:', error);
      }
    }
  };

// NEW CODE
const handleSubmit = (e) => {
  e.preventDefault();
  
  if (!selectedTable) return;

  let query = `SELECT * FROM \`${selectedTable.id}\` WHERE 1=1`;
  
  // Generate friendly summary while building SQL query
  let filterSummary = [];
  
  Object.entries(filters).forEach(([fieldName, value]) => {
    const field = selectedTable.schema.find(f => f.name === fieldName);
    if (!field) return;
    
    const friendlyName = formatFieldName(fieldName);
    
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const values = value.map(v => `'${v}'`).join(', ');
        query += ` AND ${fieldName} IN (${values})`;
        
        // Add to summary
        if (value.length <= 3) {
          filterSummary.push(`${friendlyName}: ${value.join(', ')}`);
        } else {
          filterSummary.push(`${friendlyName}: ${value.length} selected values`);
        }
      }
    } else {
      if (!value.trim()) return;
      
      if (field.type === 'STRING') {
        query += ` AND LOWER(${fieldName}) LIKE LOWER('%${value}%')`;
        filterSummary.push(`${friendlyName}: contains "${value}"`);
      } else if (['FLOAT', 'INTEGER'].includes(field.type)) {
        query += ` AND ${fieldName} = ${value}`;
        filterSummary.push(`${friendlyName}: ${value}`);
      }
    }
  });

  if (dateRange.from && dateRange.to) {
    const dateField = selectedTable.schema.find(f => 
      ['DATE', 'DATETIME', 'TIMESTAMP'].includes(f.type)
    )?.name;

    if (dateField) {
      query += ` AND DATE(${dateField}) BETWEEN '${dateRange.from}' AND '${dateRange.to}'`;
      
      // Add date range to summary
      const fromDate = new Date(dateRange.from).toLocaleDateString();
      const toDate = new Date(dateRange.to).toLocaleDateString();
      filterSummary.push(`Date: ${fromDate} to ${toDate}`);
    }
  }

  // Create user-friendly display text
  const tableName = formatTableName(selectedTable.name);
  let userFriendlyText = `Filtered ${tableName}`;
  
  if (filterSummary.length > 0) {
    // If we have many filters, keep the summary brief
    if (filterSummary.length > 3) {
      userFriendlyText += ` by ${filterSummary.length} criteria`;
    } else {
      userFriendlyText += ` by ${filterSummary.join(', ')}`;
    }
  }
  
  // Still create the same SQL prompt for backend processing
  let fullPrompt = `sql automatically formatted to focus the query:\n${query}`;
  if (textQuery.trim()) {
    fullPrompt += `\n\nSpecific question: ${textQuery.trim()}`;
  }

  const hasIndexedSelection = Object.keys(valueSearchSelections).length > 0;
  
  // Save filters to localStorage before submitting
  saveFiltersToLocalStorage(selectedTable.id, {
    filters,
    dateRange,
    valueSearchSelections
  });

  onSubmit(fullPrompt, { 
    isQuickFilter: true,
    isFromValueSearchList: hasIndexedSelection,
    isIndexed: hasIndexedSelection,             
    valueSearchSelections,
    displayText: userFriendlyText // Add the user-friendly text to the context
  });
  
  // Reset just the text query as requested
  setTextQuery('');
};

  const buttonClass = textQuery.trim() 
    ? "w-full md:w-[120px] bg-gradient-to-r from-primary to-secondary text-white px-6 py-2 rounded-full hover:bg-opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition-all duration-300 ease-in-out"
    : "w-full md:w-[120px] bg-secondary text-white px-6 py-2 rounded-full hover:bg-opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition-all duration-300 ease-in-out";

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center">
        <p>Error loading tables: {error}</p>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <div ref={modalRef} className="bg-gray-800 rounded-lg shadow-lg w-full max-w-4xl mx-auto h-[60vh] flex flex-col">
      <div className="p-6 border-b border-gray-700">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4 flex-1">
            <h3 className="text-lg font-semibold text-white">Select Table:</h3>
            <div className="flex-1 max-w-md">
              <select
                id="tableSelect"
                className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary outline-none cursor-pointer"
                value={selectedTable?.name || ''}
                onChange={(e) => handleTableChange(e.target.value)}
              >
                {tables.map(table => (
                  <option key={table.name} value={table.name}>
                    {formatTableName(table.name)}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="button"
              onClick={handleClearAll}
              className="px-4 py-2 bg-gray-600 text-white text-sm rounded-md hover:bg-gray-500 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              Clear All
            </button>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {selectedTable && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {selectedTable.schema.map(field => {
                if (['DATE', 'DATETIME', 'TIMESTAMP'].includes(field.type)) {
                  return (
                    <div key={field.name} className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <label className="block text-sm font-medium text-gray-300">
                          {formatFieldName(field.name)}
                        </label>
                        {field.description && (
                          <div className="relative group">
                            <HelpCircle size={16} className="text-gray-400 hover:text-white cursor-help" />
                            <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-900 text-white text-xs p-2 rounded shadow-lg w-48 z-10">
                              {field.description}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2">
                        <div className="flex-1">
                          <input
                            type="date"
                            className="w-full bg-gray-700 text-secondary rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary"
                            value={dateRange.from}
                            onChange={(e) => handleDateRangeChange('from', e.target.value)}
                          />
                        </div>
                        <div className="flex-1">
                          <input
                            type="date"
                            className="w-full bg-gray-700 text-secondary rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary"
                            value={dateRange.to}
                            onChange={(e) => handleDateRangeChange('to', e.target.value)}
                            min={dateRange.from}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }

                if (['RECORD', 'REPEATED'].includes(field.type)) {
                  return null;
                }

                return (
                  <div key={field.name}>
                    <div className="flex items-center space-x-2 mb-2">
                      <label className="block text-sm font-medium text-gray-300">
                        {formatFieldName(field.name)}
                      </label>
                      {field.description && (
                        <div className="relative group">
                          <HelpCircle size={16} className="text-gray-400 hover:text-white cursor-help" />
                          <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-900 text-white text-xs p-2 rounded shadow-lg w-48 z-10">
                            {field.description}
                          </div>
                        </div>
                      )}
                    </div>
                    {indexedColumns[selectedTable.id] && 
                     indexedColumns[selectedTable.id].includes(field.name) ? (
                      <div className="space-y-2">
                        <ValueSearchList
                          tableId={selectedTable.id}
                          columnName={field.name}
                          onSelect={(value) => handleFilterChange(field.name, value, true)}
                          placeholder={getPlaceholderText(field.type)}
                          className="bg-gray-700 text-white"
                          selectedValue=""
                          autoFocus={false}
                        />
                        {Array.isArray(filters[field.name]) && filters[field.name].length > 0 && (
                          <div className="flex flex-wrap gap-2 mt-2">
                            {filters[field.name].map((value, index) => (
                              <div 
                                key={index}
                                className="bg-secondary text-white px-2 py-1 rounded-md flex items-center gap-1"
                              >
                                <span>{value}</span>
                                <button
                                  type="button"
                                  onClick={() => removeSelectedValue(field.name, value)}
                                  className="text-gray-300 hover:text-white"
                                >
                                  <X size={14} />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <input
                        type={field.type === 'FLOAT' || field.type === 'INTEGER' ? 'number' : 'text'}
                        className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:ring-2 focus:ring-secondary"
                        value={filters[field.name] || ''}
                        onChange={(e) => handleFilterChange(field.name, e.target.value, false)}
                        placeholder={getPlaceholderText(field.type)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="p-6 border-t border-gray-700 bg-gray-800">
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-4 md:space-y-0">
            <div className="flex-1 relative">
              <img
                src="/favicon.ico"
                alt="Company Icon"
                className="absolute left-2.5 top-1/2 -translate-y-1/2 w-6 h-6"
              />
              <input
                type="text"
                className="w-full bg-gray-700 text-secondary rounded-full pl-12 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-secondary text-base placeholder-gray-400"
                value={textQuery}
                onChange={(e) => setTextQuery(e.target.value)}
                placeholder="Add a question... (optional)"
              />
            </div>
            <div className="md:w-auto">
              <button
                type="submit"
                className={buttonClass}
              >
                <div className="flex items-center justify-center space-x-2">
                  {textQuery.trim() ? (
                    <>
                      <MessageCircle size={16} />
                      <span>Ask</span>
                    </>
                  ) : (
                    <>
                      <Search size={16} />
                      <span>Search</span>
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  );
};

export default QuickFilter;