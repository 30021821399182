// src/components/TextEditor.js
import React, { useState, useRef, useEffect } from 'react';
import { Bold, Save, X, List, Type, ListOrdered, Copy } from 'lucide-react';
import { convertHtmlToMarkdown, convertMarkdownToHtml } from './markdownConverter';

const TextEditor = ({ 
  initialContent = '', 
  onSave, 
  onCancel,
  title,
  height = 'h-full'
}) => {
  const [content, setContent] = useState(initialContent);
  const [isEditing, setIsEditing] = useState(false);
  const editorRef = useRef(null);

  // Update local content state when initialContent prop changes
  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  // Initialize editor with correct HTML on editing start
  useEffect(() => {
    if (isEditing && editorRef.current) {
      editorRef.current.focus();
      
      // Ensure content is properly converted when editing starts
      // This helps handle cases where markdown was saved previously
      if (editorRef.current.innerHTML !== content) {
        editorRef.current.innerHTML = content;
      }
    }
  }, [isEditing, content]);

  useEffect(() => {
    // Add event listeners for copy events when the editor is active
    const handleCopy = (e) => {
      if (isEditing && editorRef.current && window.getSelection().toString().length > 0) {
        e.preventDefault();
        
        // Get the selected HTML
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const fragment = range.cloneContents();
          const div = document.createElement('div');
          div.appendChild(fragment.cloneNode(true));
          
          // Convert the selected HTML to markdown
          const markdownContent = convertHtmlToMarkdown(div.innerHTML);
          
          // Copy to clipboard
          e.clipboardData.setData('text/plain', markdownContent);
        }
      }
    };

    if (isEditing && editorRef.current) {
      editorRef.current.addEventListener('copy', handleCopy);
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('copy', handleCopy);
      }
    };
  }, [isEditing]);

  const handleCopyMarkdown = async () => {
    if (editorRef.current) {
      const content = editorRef.current.innerHTML;
      const markdownContent = convertHtmlToMarkdown(content);
      try {
        await navigator.clipboard.writeText(markdownContent);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  };

  const handleKeyCommand = (e) => {
    if (e.ctrlKey || e.metaKey) {
      switch (e.key.toLowerCase()) {
        case 'b':
          e.preventDefault();
          document.execCommand('bold', false);
          break;
        case 's':
          e.preventDefault();
          handleSave();
          break;
        case 'escape':
          e.preventDefault();
          handleCancel();
          break;
        case '.':
          e.preventDefault();
          toggleBulletList();
          break;
        case '1':
          e.preventDefault();
          toggleOrderedList();
          break;
        default:
          break;
      }
    } else if (e.key === 'Enter') {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const currentNode = range.startContainer;
      const listItem = currentNode.closest('li');
      const headingNode = currentNode.closest('h1, h2, h3, h4, h5, h6');
      
      if (headingNode) {
        e.preventDefault();
        document.execCommand('insertParagraph', false);
        const newParagraph = document.getSelection().getRangeAt(0).startContainer.parentElement;
        if (newParagraph.closest('h1, h2, h3, h4, h5, h6')) {
          document.execCommand('formatBlock', false, 'p');
        }
      } else if (listItem) {
        e.preventDefault();
        if (listItem.textContent.trim() === '') {
          document.execCommand('insertParagraph', false);
          document.execCommand('outdent', false);
        } else {
          document.execCommand('insertListItem', false);
        }
      }
    }
  };

  const handleSave = () => {
    if (editorRef.current) {
      // Get the HTML content from the editor
      const htmlContent = editorRef.current.innerHTML;
      
      // Process any markdown-style text that might be in the HTML content
      // This ensures consistent formatting
      const markdownContent = convertHtmlToMarkdown(htmlContent);
      const processedHtmlContent = convertMarkdownToHtml(markdownContent);
      
      // Update local state
      setContent(processedHtmlContent);
      
      // Call the parent's onSave with the properly formatted HTML
      onSave(processedHtmlContent);
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    if (editorRef.current) {
      // Reset to the current content state
      editorRef.current.innerHTML = content;
      setIsEditing(false);
      onCancel?.();
    }
  };

  const handleDoubleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const toggleBold = () => {
    document.execCommand('bold', false);
    editorRef.current?.focus();
  };

  const toggleBulletList = () => {
    document.execCommand('insertUnorderedList', false);
    editorRef.current?.focus();
  };

  const toggleOrderedList = () => {
    document.execCommand('insertOrderedList', false);
    editorRef.current?.focus();
  };

  const applyH4 = () => {
    document.execCommand('formatBlock', false, 'h4');
    editorRef.current?.focus();
  };

  // Common button styles for toolbar
  const toolbarButtonClass = "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 hover:bg-gray-700 hover:text-gray-100 h-9 w-9 text-gray-900 [&.bg-gray-700]:text-gray-100";
  
  return (
    <div className={`flex flex-col ${height} bg-white rounded-lg shadow-sm`}>
      <div className="flex justify-between items-center px-4 py-2 bg-white border-b border-gray-200 rounded-t-lg">
        <h2 className={`text-base font-semibold text-gray-800 ${isEditing ? 'hidden sm:block' : 'block'}`}>{title}</h2>
        {isEditing ? (
          <div className="flex items-center gap-1 w-full sm:w-auto justify-end">
            <div className="flex items-center gap-1 border-r border-gray-500 pr-2 mr-2">
              <button
                onClick={toggleBold}
                className={`${toolbarButtonClass} ${document.queryCommandState('bold') ? 'bg-gray-700' : ''}`}
                title="Bold (Ctrl+B)"
              >
                <Bold className="h-4 w-4" />
              </button>
              <button
                onClick={applyH4}
                className={toolbarButtonClass}
                title="Heading 4"
              >
                <Type className="h-4 w-4" />
              </button>
              <button
                onClick={toggleBulletList}
                className={`${toolbarButtonClass} ${document.queryCommandState('insertUnorderedList') ? 'bg-gray-700' : ''}`}
                title="Bullet List (Ctrl+.)"
              >
                <List className="h-4 w-4" />
              </button>
              <button
                onClick={toggleOrderedList}
                className={`${toolbarButtonClass} ${document.queryCommandState('insertOrderedList') ? 'bg-gray-700' : ''}`}
                title="Numbered List (Ctrl+1)"
              >
                <ListOrdered className="h-4 w-4" />
              </button>
              <button
                onClick={handleCopyMarkdown}
                className={toolbarButtonClass}
                title="Copy as Markdown"
              >
                <Copy className="h-4 w-4" />
              </button>
            </div>
            <button
              onClick={handleSave}
              className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-opacity-90 h-9 px-4 text-white"
              title="Save (Ctrl+S)"
            >
              <Save className="h-4 w-4 mr-2" />
              <span className="hidden sm:inline">Save</span>
            </button>
            <button
              onClick={handleCancel}
              className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-text hover:bg-opacity-90 h-9 px-4 text-white"
              title="Cancel (Esc)"
            >
              <X className="h-4 w-4 mr-2" />
              <span className="hidden sm:inline">Cancel</span>
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-gray-100 hover:bg-gray-200 h-9 px-4 text-gray-800"
          >
            Edit
          </button>
        )}
      </div>
      <div 
        ref={editorRef}
        contentEditable={isEditing}
        onKeyDown={handleKeyCommand}
        onDoubleClick={handleDoubleClick}
        className={`flex-1 overflow-y-auto p-4 font-mono text-sm whitespace-pre-wrap leading-relaxed bg-white ${
          isEditing ? 'outline-none ring-1 ring-blue-100 focus:ring-2 focus:ring-blue-200' : ''
        } [&_ul]:list-disc [&_ul]:pl-6 [&_ol]:list-decimal [&_ol]:pl-6 [&_li]:my-0.5 [&_li]:pl-1.5
          [&_h1]:text-2xl [&_h1]:font-bold [&_h1]:mt-3 [&_h1]:mb-2
          [&_h2]:text-xl [&_h2]:font-bold [&_h2]:mt-2.5 [&_h2]:mb-1.5
          [&_h3]:text-lg [&_h3]:font-bold [&_h3]:mt-2 [&_h3]:mb-1
          [&_h4]:text-base [&_h4]:font-bold [&_h4]:mt-1.5 [&_h4]:mb-1
          [&_h5]:text-sm [&_h5]:font-bold [&_h5]:mt-1 [&_h5]:mb-0.5
          [&_h6]:text-xs [&_h6]:font-bold [&_h6]:mt-1 [&_h6]:mb-0.5
          [&_ol]:[list-style-type:decimal] [&_ol>li]:[display:list-item]`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default TextEditor;