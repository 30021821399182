import { useState, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import useQueryLibraryCore from './useQueryLibraryCore';

// Constants
const PINNED_QUERIES_KEY = 'pinned_queries';
const MAX_PINNED_QUERIES = 5;

/**
 * Hook for query library UI operations built on top of the core hook
 * @returns {Object} Enhanced hook with UI-specific functionality
 */
export const useQueryLibraryUI = () => {
  const { currentUser } = useAuth();
  const { config } = useConfig();
  
  // Use the core hook for data fetching and caching
  const coreLibrary = useQueryLibraryCore();
  
  // Initialize pinned queries from localStorage
  const [pinnedQueries, setPinnedQueries] = useState(() => {
    try {
      const pinnedData = localStorage.getItem(PINNED_QUERIES_KEY);
      if (pinnedData) {
        const { queryIds, userId } = JSON.parse(pinnedData);
        if (userId === currentUser?.email) {
          return queryIds || [];
        }
      }
    } catch (error) {
      console.error('Error reading pinned queries:', error);
    }
    return [];
  });

  // Function to save pinned queries to localStorage
  const savePinnedQueries = useCallback((queryIds) => {
    try {
      localStorage.setItem(PINNED_QUERIES_KEY, JSON.stringify({
        queryIds,
        userId: currentUser?.email
      }));
    } catch (error) {
      console.error('Error saving pinned queries:', error);
    }
  }, [currentUser]);

  // Function to toggle pin status
  const togglePinQuery = useCallback((queryId) => {
    setPinnedQueries(prev => {
      let newPinnedQueries;
      
      if (prev.includes(queryId)) {
        // Unpin: remove from pinned queries
        newPinnedQueries = prev.filter(id => id !== queryId);
      } else {
        // Pin: add to pinned queries, respecting the maximum limit
        if (prev.length >= MAX_PINNED_QUERIES) {
          // If at max limit, remove the oldest pin
          newPinnedQueries = [...prev.slice(1), queryId];
        } else {
          newPinnedQueries = [...prev, queryId];
        }
      }
      
      savePinnedQueries(newPinnedQueries);
      return newPinnedQueries;
    });
  }, [savePinnedQueries]);

  // Pass through hideQuery function from core
  const hideQuery = useCallback(async (queryId) => {
    // Clean up pins if needed
    if (pinnedQueries.includes(queryId)) {
      const newPinnedQueries = pinnedQueries.filter(id => id !== queryId);
      setPinnedQueries(newPinnedQueries);
      savePinnedQueries(newPinnedQueries);
    }
    // Call the core hideQuery function
    return await coreLibrary.hideQuery(queryId);
  }, [coreLibrary.hideQuery, pinnedQueries, setPinnedQueries, savePinnedQueries]);

  // Filter queries with explanation object
  const filteredQueries = useCallback((searchQuery = '') => {
    // First filter out queries that don't have an explanation object
    const queriesWithExplanationObject = coreLibrary.queries.filter(query => {
      // Check if explanation exists and is an object (not just a string)
      return query.explanation && 
             typeof query.explanation === 'object' && 
             query.explanation.hasOwnProperty('explanation');
    });
    
    // Then apply search filtering if there's a search query
    if (searchQuery.trim() === '') {
      return queriesWithExplanationObject;
    }
    
    const searchLower = searchQuery.toLowerCase();
    return queriesWithExplanationObject.filter(query => 
      // Search in explanation title
      (query.explanation && 
       query.explanation.title && 
       query.explanation.title.toLowerCase().includes(searchLower)) ||
      // Search in query name
      (query.name && 
       query.name.toLowerCase().includes(searchLower)) || 
      // Search in original user query
      (query.userQuery && 
       query.userQuery.toLowerCase().includes(searchLower))
    );
  }, [coreLibrary.queries]);

  // Get sorted queries with pinned ones on top
  const getSortedQueries = useCallback((filteredQueriesList) => {
    if (!filteredQueriesList || !pinnedQueries.length) return filteredQueriesList;

    // Separate pinned and unpinned queries
    const pinned = [];
    const unpinned = [];
    
    filteredQueriesList.forEach(query => {
      if (pinnedQueries.includes(query.id)) {
        pinned.push(query);
      } else {
        unpinned.push(query);
      }
    });
    
    // Sort pinned queries according to the order in pinnedQueries array
    pinned.sort((a, b) => {
      return pinnedQueries.indexOf(a.id) - pinnedQueries.indexOf(b.id);
    });
    
    // Return pinned queries followed by unpinned queries
    return [...pinned, ...unpinned];
  }, [pinnedQueries]);

// Enhanced runSavedQuery function to apply stored visualization settings
const runSavedQuery = useCallback(async (query, onQueryResult, onQueryStart, setIsLoading, setError) => {
  if (!query || !currentUser?.email || !config?.core?.projectId) return;
  
  onQueryStart?.();
  setIsLoading?.(true);
  
  try {
    const response = await fetch(
      `https://us-central1-${config.core.projectId}.cloudfunctions.net/runSavedQuery`, 
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            savedQueryId: query.id, 
            userId: currentUser.email 
          }, 
          context: { auth: true } 
        })
      }
    );
    
    if (!response.ok) {
      throw new Error('Failed to run query');
    }
    
    const data = await response.json();
    
    if (data.error) {
      throw new Error(data.error);
    }

    // Validate result data exists and is an array
    if (!data.result || !Array.isArray(data.result)) {
      console.error('Invalid result data structure:', data);
      throw new Error('Invalid query result structure');
    }

    // Retrieve saved visualization settings from localStorage
    const savedSettings = coreLibrary.getQuerySettings()[query.id] || {};
    console.log('Retrieved visualization settings for query:', query.id, savedSettings);
    
    // Create combined settings by prioritizing:
    // 1. Saved visualization settings from localStorage
    // 2. Card settings from the saved query in Firestore
    // 3. Card settings returned from the backend
    // 4. Default settings
    const combinedCardSettings = {
      // Start with default settings
      viewMode: 'table',
      chartType: 'bar',
      // Add settings from the backend response (if they exist)
      ...(data.cardSettings || {}),
      // Override with settings saved with the query in Firestore
      ...(query.cardSettings || {}),
      // Finally, apply our localStorage visualization settings which have highest priority
      ...savedSettings
    };
    
    // Process the result with the saved query metadata and enhanced settings
    const processedResult = {
      ...data,
      userQuery: query.userQuery || '',
      name: query.name || 'Saved Query',
      isSavedQuery: true,
      config: {
        usedModel: data.usedModel || query.llmModel || 'Unknown',
        fallbackUsed: Boolean(data.fallbackUsed || query.fallbackUsed),
      },
      // Use our enhanced combined settings with defaults
      cardSettings: combinedCardSettings,
      // Ensure processedData exists as an array (clone result if empty)
      processedData: Array.isArray(data.processedData) && data.processedData.length > 0 
        ? data.processedData 
        : [...data.result]
    };
    
    console.log('Final processed result:', JSON.stringify({
      resultLength: processedResult.result.length,
      processedDataLength: processedResult.processedData.length,
      cardSettings: processedResult.cardSettings
    }));
    
    onQueryResult?.(processedResult);
    return processedResult;
  } catch (error) {
    console.error('Error running saved query:', error);
    setError?.({
      message: error.message || 'Error running query',
      status: 'error',
    });
    onQueryResult?.(null);
    throw error;
  } finally {
    setIsLoading?.(false);
  }
}, [config, currentUser, coreLibrary.getQuerySettings]);

  return {
    // Re-export core state and functions
    ...coreLibrary,
    
    // UI-specific state
    pinnedQueries,
    
    // UI-specific functions
    filteredQueries,
    runSavedQuery,
    togglePinQuery,
    getSortedQueries,
    hideQuery, // Re-export the enhanced hideQuery function
    
    // Constants
    MAX_PINNED_QUERIES,
  };
};

export default useQueryLibraryUI;