import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle, useRef, useMemo } from 'react';
import QueryInput from './QueryInput';
import Card from '../Card';
import { useAuth } from '../../contexts/AuthContext';
import QueryResultCard from './QueryResultCard';
import SaveQueryModal from './SaveQueryModal';
import AddToDashboardModal from './AddToDashboardModal';
import QueryError from './QueryError';
import { useConfig } from '../../contexts/ConfigContext';
import useFollowUpQuery from '../../hooks/useFollowUpQuery';
import { getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore';



class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('An error occurred in the application:', error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  handleReset = () => {
    // Clear any problematic state
    localStorage.removeItem('recentSearches');
    sessionStorage.clear();
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <Card className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <h1 className="font-bold">Something went wrong</h1>
          <p className="mt-2">We encountered an error while processing your request.</p>
          <button
            onClick={this.handleReset}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Reset Application
          </button>
        </Card>
      );
    }

    return this.props.children;
  }
}

const QueryComponent = forwardRef(
  (
    {
      mainContentRef,
      onQueryResult,
      onQueryStart,
      queryResult,
      isLoading,
      onSaveQuery,
      onAddToDashboard,
      onFollowUpQuery,
      onDashboardUpdate,
      dashboardManager,
      isQueryJustAdded,
      setIsQueryJustAdded,
      isAskPage,
      isDashboardMode = false,
      onClearResult,
      onAddToLibrary,
      saveQuerySettings,
      getFilteredQueries,
      hideProposedQuestions
    },
    ref
  ) => {

    console.log('QueryComponent.js - saveQuerySettings received:', typeof saveQuerySettings === 'function');

    const componentRef = useRef(null);
    const [error, setError] = useState(null);
    const [isSaveQueryModalOpen, setIsSaveQueryModalOpen] = useState(false);
    const [isAddToDashboardModalOpen, setIsAddToDashboardModalOpen] = useState(false);
    const [queryName, setQueryName] = useState('');
    const [isShared, setIsShared] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [savedQueryId, setSavedQueryId] = useState(null);
    const [isExplanationLoading, setIsExplanationLoading] = useState(false);
    const [isExplanationPending, setIsExplanationPending] = useState(false);
    const [explanation, setExplanation] = useState('');
    const [showResultCard, setShowResultCard] = useState(false);
    const [truncationMessage, setTruncationMessage] = useState('');
    const [isFollowUp, setIsFollowUp] = useState(false);
    const [dashboards, setDashboards] = useState([]);
    const [recentSearches, setRecentSearches] = useState([]);
    const [currentQuery, setCurrentQuery] = useState('');
    const { config, isLoading: isConfigLoading } = useConfig();
    const {
      selectedText,
      selectedColumns,
      handleTextSelection,
      handleFollowUpSubmit: hookHandleFollowUpSubmit,
      resetFollowUpState
    } = useFollowUpQuery(async (submittedQuery, selectionData) => {
      await handleFollowUpQuery(submittedQuery, selectionData.text, queryResult?.sqlQuery, selectionData.columns);
    });
    const resultRef = React.useRef(null);
    const { currentUser, isAdmin } = useAuth();

    useImperativeHandle(ref, () => ({
      scrollIntoView: () => {
        if (componentRef.current) {
          componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      },
    }));

    useEffect(() => {
      fetchDashboards();
    }, []);

    const fetchDashboards = async () => {
      try {
        const response = await fetch(
          'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/handleDashboardOperation',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                operation: 'get',
                userId: currentUser.email,
                isAdmin: isAdmin,
              },
            }),
          }
        );
        const data = await response.json();
        setDashboards(data);
      } catch (error) {
        console.error('Error fetching dashboards:', error);
        setError('Failed to fetch dashboards. Please try again later.');
      }};

      const generateExplanation = useCallback(async (sqlQuery, documentId, savedQueryId, userQuery, justFetch = false) => {
        setIsExplanationLoading(true);
        setIsExplanationPending(false);
        try {
          const requestData = { sqlQuery, documentId, savedQueryId, userQuery, justFetch };
      
          if (!requestData.documentId && !requestData.savedQueryId) {
            throw new Error('Missing necessary identifiers.');
          }
      
          const response = await fetch(
            'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/generateExplanation',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ data: requestData }),
            }
          );
          if (!response.ok) {
            throw new Error('Failed to generate explanation.');
          }
          const data = await response.json();
          console.log('Raw explanation data received:', data);
          return data.explanation;
        } catch (error) {
          console.error('Error generating explanation:', error);
          throw error;
        } finally {
          setIsExplanationLoading(false);
        }
      }, [config]);
  
      const fetchRecentSearches = useCallback(async () => {
        const MAX_RETRIES = 3;
        const INITIAL_DELAY = 1000;
      
        const fetchWithRetry = async (retryCount) => {
          try {
            const response = await fetch('https://us-central1-' + config.core.projectId + '.cloudfunctions.net/getRecentSearches', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ data: { userId: currentUser.email } }),
            });
      
            if (!response.ok) {
              const errorMessage = await response.text();
              throw new Error(`HTTP error! status: ${response.status}, message: ${errorMessage}`);
            }
      
            const data = await response.json();
            
            // Validate and sanitize the data
            const validSearches = Array.isArray(data.recentSearches) 
              ? data.recentSearches.filter(search => 
                  search && 
                  typeof search.userQuery === 'string' &&
                  search.documentId
                )
              : [];
            
            localStorage.setItem('recentSearches', JSON.stringify(validSearches));
            setRecentSearches(validSearches);
            return validSearches;
      
          } catch (error) {
            console.error(`Attempt ${retryCount + 1} failed:`, error);
      
            if (retryCount < MAX_RETRIES - 1) {
              const delay = INITIAL_DELAY * Math.pow(2, retryCount);
              console.log(`Retrying in ${delay}ms...`);
              await new Promise(resolve => setTimeout(resolve, delay));
              return fetchWithRetry(retryCount + 1);
            } else {
              console.error('Max retries reached. Using cached data if available.');
              try {
                const storedSearches = localStorage.getItem('recentSearches');
                return storedSearches ? JSON.parse(storedSearches) : [];
              } catch (e) {
                console.error('Error parsing stored searches:', e);
                return [];
              }
            }
          }
        };
      
        try {
          return await fetchWithRetry(0);
        } catch (error) {
          console.error('Error fetching recent searches:', error);
          return [];
        }
      }, [currentUser.email, config.core.projectId]);

      useEffect(() => {
        fetchRecentSearches();
      }, [fetchRecentSearches]);
  
// NEW CODE
const handleSubmit = useCallback(
  async (
    submittedQuery,
    isFollowUp = false,
    highlightedText = '',
    originalSqlQuery = '',
    isRecentSearch = false,
    recentSearchDocumentId = null,
    selectedColumns = [],
    focusType = 'none',
    context = {}
  ) => {
    
    // Store the displayed query for UI - use context.displayQuery for tango-ID format
    setCurrentQuery(context.displayQuery || submittedQuery);

    // Determine the actual query to send to the backend
    // If context contains originalQuery (from QuickFilter), use that instead
    const actualQueryForBackend = (context?.isQuickFilter && context?.originalQuery) 
      ? context.originalQuery 
      : submittedQuery;

    console.log('Submitting query:', { 
      displayedQuery: context.displayQuery || submittedQuery, 
      backendQuery: actualQueryForBackend,
      isFollowUp, 
      highlightedText, 
      originalSqlQuery, 
      isRecentSearch, 
      recentSearchDocumentId, 
      selectedColumns,
      isTangoId: focusType === 'tangoId',
      tangoDocumentId: context.tangoDocumentId,
      usesTableMention: context.usesTableMention || false
    });
    
    onQueryStart();
    setError(null);
    setShowResultCard(false);
    setTruncationMessage('');
    setIsQueryJustAdded(false);
    setIsExplanationPending(false);
    let responseData = null;
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 120000);

      const userId = currentUser?.email || 'anonymous';

      let endpoint = 'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/processDynamicQueryWithCORS';
      let body = {
        data: {
          userQuery: actualQueryForBackend, // Use the actual SQL query for the backend
          userId: userId,
          isFollowUp: isFollowUp,
          highlightedText: highlightedText,
          originalDocumentId: isFollowUp ? queryResult?.documentId : context.tangoDocumentId || null,
          originalSavedQueryId: isFollowUp ? queryResult?.savedQueryId : null,
          originalSqlQuery: isFollowUp ? originalSqlQuery : null,
          selectedColumns: queryResult?.isTransposed ? 
            selectedColumns.map(col => col.replace(/^Value_\d+$/, 'Field')) : 
            selectedColumns,
          focusType: focusType,
          isIndexed: context?.isFromValueSearchList || false,
          isTransposed: queryResult?.isTransposed || false,
          columnConfig: context.columnConfig,
          valueContext: context.valueContext || null,  // Ensure this is passed correctly
          displayQuery: context.displayQuery || submittedQuery, // Store the display query for reference
          isQuickFilter: context?.isQuickFilter || false,
          isTangoId: focusType === 'tangoId',
          tangoDocumentId: context.tangoDocumentId || null,
          originalQuestion: context.originalQuestion || null,
          usesTableMention: context.usesTableMention || false // Pass table mention flag for intent detection
        },
        context: { auth: true },
      };
      
      console.log('Request context:', context);
      console.log('Sending to backend:', JSON.stringify(body, null, 2));

      if (isRecentSearch) {
        endpoint = 'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/runRecentSearch';
        body = {
          data: {
            documentId: recentSearchDocumentId,
            userId: userId,
          },
        };
      }
            
            const response = await fetch(endpoint, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(body),
              signal: controller.signal,
            });
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const responseData = await response.json();
            clearTimeout(timeoutId);
            
            // Handle truncation message consistently for both new and recent searches
            if (responseData.truncationMessage) {
              setTruncationMessage(responseData.truncationMessage);
            } else if (responseData.result && responseData.result.length > 2000) {
              setTruncationMessage('The query result has been limited to 2,000 rows. Metrics are calculated on displayed rows only.');
            }
            
            if (!response.ok || responseData.error) {
              throw responseData;
            }
      
            const truncationMessage = responseData.totalCount > 2000
              ? `The query result has been limited to 2,000 rows. Metrics are calculated on displayed rows only.`
              : '';
      
            setTruncationMessage(truncationMessage);
      
            // Handle explanation with Firestore listener
            if (!responseData.explanation || responseData.explanationInProgress || isFollowUp) {
              setIsExplanationPending(true);

              // Set up a real-time listener for the explanation
              const documentId = responseData.documentId;
              if (documentId) {
                console.log(`[Explanation] Setting up listener for document ID: ${documentId}`);
                
                // Create a cleanup function to detach the listener when done
                let unsubscribe = null;
                
                // Define a timeout to prevent listening indefinitely
                const listenerTimeout = setTimeout(() => {
                  console.log('[Explanation] Listener timeout reached, detaching');
                  if (unsubscribe) {
                    unsubscribe();
                    setIsExplanationPending(false);
                    
                    // Set a placeholder explanation if none was received
                    onQueryResult((prevResult) => ({
                      ...prevResult,
                      explanation: {
                        explanation: "Timed out waiting for explanation.",
                        title: "",
                        tables: [],
                        filters: []
                      }
                    }));
                  }
                }, 60000); // 60 second timeout (1 minute)
                
                try {
                  const db = getFirestore();
                  const docRef = doc(db, 'LLM Queries', documentId);
                  
                  unsubscribe = onSnapshot(docRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                      const data = docSnapshot.data();
                      
                      if (data.explanation) {
                        console.log('[Explanation] Received explanation from Firestore:', data.explanation);
                        
                        // Clear the timeout since we got our explanation
                        clearTimeout(listenerTimeout);
                        
                        // Instead of just assigning the explanation, ensure it's a proper object
                        let parsedExplanation = data.explanation;
                        
                        // If it's a string, try to parse it as JSON, otherwise use as is
                        if (typeof data.explanation === 'string') {
                          try {
                            parsedExplanation = JSON.parse(data.explanation);
                            console.log('[Explanation] Parsed string explanation into object');
                          } catch (error) {
                            // If it fails to parse, just use it as a string in an object
                            console.log('[Explanation] Failed to parse string explanation, using as-is');
                            parsedExplanation = { explanation: data.explanation };
                          }
                        }
                        
                        // Update the query result with the properly parsed explanation
                        onQueryResult((prevResult) => ({
                          ...prevResult,
                          explanation: parsedExplanation
                        }));
                        
                        // Stop pending state and detach listener
                        setIsExplanationPending(false);
                        unsubscribe();
                      } else {
                        console.log('[Explanation] Document updated but no explanation yet');
                      }
                    } else {
                      console.log('[Explanation] Document does not exist');
                    }
                  }, (error) => {
                    console.error('[Explanation] Listener error:', error);
                    clearTimeout(listenerTimeout);
                    setIsExplanationPending(false);
                    unsubscribe();
                  });
                } catch (error) {
                  console.error('[Explanation] Error setting up listener:', error);
                  clearTimeout(listenerTimeout);
                  setIsExplanationPending(false);
                }
              } else {
                console.log('[Explanation] No document ID available, cannot listen for explanation');
                setIsExplanationPending(false);
              }
            }
      
            // Get all available columns from the new result
            const availableColumns = responseData.result && responseData.result.length > 0 
              ? Object.keys(responseData.result[0]) 
              : [];

            let finalVisibleColumns = availableColumns;
            let finalColumnOrder = availableColumns;

            if (isFollowUp && context.columnConfig) {
              console.log('[QueryComponent] Processing follow-up query with context:', {
                availableColumns,
                previousConfig: context.columnConfig,
                finalVisibleColumns,
                finalColumnOrder
              });
            }
            
            const newResult = {
              ...responseData,
              userQuery: submittedQuery,
              isFollowUp: isFollowUp,
              isSavedQuery: responseData.isSavedQuery || false,
              name: responseData.name || '',
              config: {
                usedModel: responseData.usedModel || 'Unknown',
                fallbackUsed: responseData.fallbackUsed || false,
              },
              retryCount: responseData.retryCount || 0,
              retryResult: responseData.retryResult || 'N/A',
              bigQueryError: responseData.bigQueryError || null,
              result: responseData.result,
              truncationMessage: truncationMessage,
              explanation: responseData.explanation || 'Generating explanation...',
              documentId: responseData.documentId || (isFollowUp ? queryResult?.documentId : null),
              sqlQuery: responseData.sqlQuery || queryResult?.sqlQuery,
              savedQueryId: responseData.savedQueryId || queryResult?.savedQueryId,
              truncated: responseData.totalCount > 2000,
              totalCount: responseData.totalCount,
              schema: responseData.schema || {
                fields: responseData.result && responseData.result.length > 0
                  ? Object.keys(responseData.result[0]).map((key) => ({
                      name: key,
                      type: typeof responseData.result[0][key] === 'number' ? 'FLOAT' : 'STRING',
                    }))
                  : [],
              },
              emptyResultSet: responseData.result && responseData.result.length === 0,
              status: responseData.status || 'completed',
              endTime: responseData.endTime || new Date().toISOString(),
              isTransposed: responseData.isTransposed || false,
              transposedResponse: responseData.transposedResponse || null,
              transposedSchema: responseData.transposedSchema || null,
              cardSettings: {
                ...responseData.cardSettings,
                visibleColumns: (() => {
                  const availableColumns = responseData.result && responseData.result.length > 0 
                    ? Object.keys(responseData.result[0]) 
                    : [];
              
                  if (isFollowUp && context.columnConfig) {
                    // First identify columns that should be hidden - these are columns that exist
                    // in previous query but weren't visible
                    const previouslyHiddenColumns = availableColumns.filter(col => 
                      context.columnConfig.columnOrder.includes(col) && 
                      !context.columnConfig.visibleColumns.includes(col)
                    );
              
                    // All columns should be visible except those explicitly hidden before
                    return availableColumns.filter(col => !previouslyHiddenColumns.includes(col));
                  }
                  return availableColumns;
                })(),
                columnOrder: (() => {
                  const availableColumns = responseData.result && responseData.result.length > 0 
                    ? Object.keys(responseData.result[0]) 
                    : [];
              
                  if (isFollowUp && context.columnConfig?.columnOrder) {
                    // Get new columns 
                    const newColumns = availableColumns.filter(col => 
                      !context.columnConfig.columnOrder.includes(col)
                    );
                    
                    // Get existing columns in their saved order
                    const existingColumns = context.columnConfig.columnOrder.filter(col => 
                      availableColumns.includes(col)
                    );
              
                    // Existing columns in saved order, then new columns at the end
                    return [...existingColumns, ...newColumns];
                  }
                  return availableColumns;
                })(),
                showSummary: context.columnConfig?.showSummary ?? true,
                viewMode: context.columnConfig?.viewMode || 'table',
                chartType: context.columnConfig?.chartType || 'bar',
                syncScales: context.columnConfig?.syncScales || false,
                selectedXAxis: context.columnConfig?.selectedXAxis || (responseData.result && responseData.result.length > 0 ? Object.keys(responseData.result[0])[0] : ''),
                selectedYAxis: context.columnConfig?.selectedYAxis || [],
                sortColumn: context.columnConfig?.sortColumn || '',
                sortOrder: context.columnConfig?.sortOrder || 'asc',
                // In QueryComponent.js, the activeFilters property in cardSettings
                activeFilters: (() => {
                  // Get available columns in the new result
                  const availableColumns = responseData.result && responseData.result.length > 0 
                    ? Object.keys(responseData.result[0]) 
                    : [];
                  
                  // If this is a follow-up query and we have context with active filters
                  if (isFollowUp && context.columnConfig?.activeFilters) {
                    const prevFilters = context.columnConfig.activeFilters;
                    const newActiveFilters = {};
                    
                    // For each column that exists in both queries
                    availableColumns.forEach(column => {
                      if (availableColumns.includes(column) && prevFilters[column]) {
                        // Get all possible values in the new result for this column
                        const allPossibleValues = Array.from(new Set(
                          responseData.result.map(row => {
                            const val = row[column];
                            return val === null || val === undefined ? 'N/A' : String(val);
                          })
                        ));
                        
                        // Get all the values that were selected in previous query
                        const prevSelectedValues = prevFilters[column];
                        
                        // Get all possible values from previous query
                        // We need to look at original data if available
                        const originalQueryData = isFollowUp && queryResult?.result 
                          ? queryResult.result 
                          : [];
                          
                        const prevPossibleValues = Array.from(new Set(
                          originalQueryData.map(row => {
                            const val = row[column];
                            return val === null || val === undefined ? 'N/A' : String(val);
                          })
                        ));
                        
                        // Determine which values were excluded in previous query
                        const prevExcludedValues = prevPossibleValues.filter(val => 
                          !prevSelectedValues.includes(val)
                        );
                        
                        console.log(`Column ${column} - previously excluded: `, prevExcludedValues);
                        
                        // Include all new values EXCEPT for those that were excluded before
                        newActiveFilters[column] = allPossibleValues.filter(val => 
                          !prevExcludedValues.includes(val)
                        );
                        
                        // If we're showing all values (no filtering), remove this filter
                        if (newActiveFilters[column].length === allPossibleValues.length) {
                          delete newActiveFilters[column];
                        }
                      }
                    });
                    
                    console.log('Follow-up query with preserved exclusions:', newActiveFilters);
                    return newActiveFilters;
                  }
                  
                  return context.activeFilters || {}; // Use context level filters or default to empty
                })()
              }
            };
      
            onQueryResult(newResult);
            setShowResultCard(true);
            setIsFollowUp(false);
      
            setTimeout(() => {
              if (mainContentRef && mainContentRef.current) {
                mainContentRef.current.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }
            }, 100);
      
            // Refresh recent searches after a successful query
            fetchRecentSearches();
          } catch (err) {
            console.error('Error during query submission:', err);
            let errorObject = {
              error: err.error || err.message || "An unexpected error occurred. Please try again.",
              details: err.details || {},
              documentId: err.documentId || null,
              userId: err.userId || currentUser?.email || 'anonymous',
              userQuestion: err.userQuestion || submittedQuery,
              retryCount: err.retryCount || 0,
              retryResult: err.retryResult || 'Failed',
              bigQueryError: err.bigQueryError || null,
              status: err.status || 'Error',
              endTime: err.endTime || new Date().toISOString(),
              queryVersions: err.queryVersions || []
            };
            
            setError(errorObject);
            onQueryResult(null);
          }
        },
        [onQueryResult, onQueryStart, currentUser, queryResult, mainContentRef, generateExplanation]
      );
      
  
      // handleFollowUpQuery method
      const handleFollowUpQuery = useCallback(
        async (followUpQuery, highlightedText, originalSqlQuery, selectedColumns, currentColumnConfig) => {
          setCurrentQuery(followUpQuery);

          // Detect if this is a removal request
          const isFilterRemoval = typeof followUpQuery === 'string' && 
                        followUpQuery.startsWith('recalculate without filter:');
          const isTableRemoval = typeof followUpQuery === 'string' && 
                                  followUpQuery.startsWith('recalculate without table:');

          // If it's a removal operation, show a loading indicator or feedback
          if (isFilterRemoval || isTableRemoval) {
            // Optional: You can set a state variable here to show a specific loading message
            console.log('Processing removal operation:', followUpQuery);
          }

          // Add logging to show active filters before passing them
          console.log('Follow-up query filters:', {
            fromColumnConfig: currentColumnConfig?.activeFilters,
            fromContext: currentColumnConfig?.context?.activeFilters,
            isFilterRemoval
          });

          await handleSubmit(
            followUpQuery,
            true,
            isFilterRemoval || isTableRemoval ? '' : highlightedText,
            originalSqlQuery,
            false,
            null,
            isFilterRemoval || isTableRemoval ? [] : selectedColumns,
            isFilterRemoval ? 'filterRemoval' : isTableRemoval ? 'tableRemoval' : 'none',
            {
              columnConfig: currentColumnConfig,
              isTransposed: queryResult?.isTransposed || false,
              valueContext: currentColumnConfig?.valueContext || queryResult?.valueContext,
              activeFilters: currentColumnConfig?.activeFilters || {},
              isFilterRemoval,
              isTableRemoval
            }
          );
          
          setTimeout(() => {
            if (componentRef.current) {
              componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 100);
        },
        [handleSubmit, componentRef, queryResult]
      );
    
      const handleClose = useCallback(() => {
        if (onClearResult) {
          onClearResult(); // This will clear the context
        }
        setShowResultCard(false); // Also hide the card locally
      }, [onClearResult]);


// NEW CODE
const handleQueryInputSubmit = useCallback(
  async (queryOrResult, isFollowUpMode = false, selectedText = '', context = {}) => {
    console.log('handleQueryInputSubmit - context:', context);
    
    // Get current column configuration from the queryResult's cardSettings
    let columnConfig = {};
    if (isFollowUpMode && queryResult && queryResult.cardSettings) {
      columnConfig = {
        visibleColumns: queryResult.cardSettings.visibleColumns || [],
        columnOrder: queryResult.cardSettings.columnOrder || [],
        showSummary: queryResult.cardSettings.showSummary ?? true,
        viewMode: queryResult.cardSettings.viewMode,
        chartType: queryResult.cardSettings.chartType,
        syncScales: queryResult.cardSettings.syncScales,
        selectedXAxis: queryResult.cardSettings.selectedXAxis,
        selectedYAxis: queryResult.cardSettings.selectedYAxis,
        sortColumn: queryResult.cardSettings.sortColumn,
        sortOrder: queryResult.cardSettings.sortOrder,
        // Remove valueContext from cardSettings to avoid duplication
      };
    }
    
    let focusType = 'none';
    if (context.mentions && context.mentions.length > 0) {
      focusType = 'mention';
    } else if (context.isQuickFilter) {
      focusType = 'quickFilter';
    } else if (context.isTangoId) {
      focusType = 'tangoId';
    }

    // Create a clean context object without duplicate valueContext
    const cleanContext = {
      ...context,
      columnConfig,
      isIndexed: context.isFromValueSearchList || context.isIndexed || false,
      // Keep valueContext at the top level only
      valueContext: context.valueContext || null,
      // Pass through table mention flag for intent detection
      usesTableMention: context.usesTableMention || false
    };

    console.log('Passing clean context:', cleanContext);

    // Check for tango-ID format
    if (context.isTangoId && context.tangoDocumentId) {
      try {
        onQueryStart(); // Start loading state
        
        // Fetch the document from Firestore
        const db = getFirestore();
        const docRef = doc(db, 'LLM Queries', context.tangoDocumentId);
        const docSnapshot = await getDoc(docRef);
        
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          
          if (docData && docData.code) {
            console.log('Found SQL query for tango-ID:', context.tangoDocumentId);
            
            // Use the SQL code but keep the original tango-ID for display
            handleSubmit(
              docData.code, // SQL query to execute
              false,
              '',
              '',
              false,
              null,
              [],
              'tangoId',
              {
                ...cleanContext,
                displayQuery: queryOrResult, // Original tango-ID input
                originalQuestion: docData.userQuestion || queryOrResult,
                tangoDocumentId: context.tangoDocumentId
              }
            );
            return; // Exit early after handling
          } else {
            console.error('Document exists but has no SQL code:', context.tangoDocumentId);
            // Continue with normal processing but show an error
            setError({
              error: 'The specified query document does not contain SQL code',
              details: { documentId: context.tangoDocumentId }
            });
          }
        } else {
          console.error('Document not found:', context.tangoDocumentId);
          // Continue with normal processing but show an error
          setError({
            error: 'Query document not found',
            details: { documentId: context.tangoDocumentId }
          });
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        // Continue with normal processing but show an error
        setError({
          error: `Error fetching document: ${error.message}`,
          details: { documentId: context.tangoDocumentId }
        });
      }
    }

    // Standard query processing (will run if tango-ID handling fails)
    if (typeof queryOrResult === 'object' && queryOrResult.documentId) {
      // Recent search
      handleSubmit(
        queryOrResult.userQuery, 
        false, 
        '', 
        '', 
        true, 
        queryOrResult.documentId, 
        [], 
        focusType, 
        cleanContext
      );
    } else if (isFollowUpMode) {
      handleFollowUpQuery(
        queryOrResult, 
        selectedText, 
        queryResult?.sqlQuery, 
        [], 
        {
          ...cleanContext,
          valueContext: cleanContext.valueContext,  // Keep only one copy
          usesTableMention: cleanContext.usesTableMention // Pass the table mention flag
        }
      );
    } else {
      // Check if this is from QuickFilter and has a display text
      const actualQuery = context.isQuickFilter && context.displayText 
        ? context.displayText 
        : queryOrResult;
      
      handleSubmit(
        actualQuery, 
        false, 
        selectedText, 
        '', 
        false, 
        null, 
        [], 
        focusType, 
        {
          ...cleanContext,
          // If it's a quick filter, keep the original SQL in a separate field
          originalQuery: context.isQuickFilter ? queryOrResult : null
        }
      );
    }
  },
  [handleFollowUpQuery, handleSubmit, queryResult, onQueryStart, setError]
);
  
      const handleSaveQuery = async () => {
        if (!queryResult) return;
        setIsSaveQueryModalOpen(true);
      };
  
      const handleSaveQuerySubmit = async () => {
        if (!queryName || isSaving) return;
      
        setIsSaving(true);
        try {
          const response = await fetch(
            'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/saveQuery',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                data: {
                  userId: currentUser.email,
                  name: queryName,
                  userQuery: queryResult.userQuery,
                  sqlQuery: queryResult.sqlQuery,
                  llmModel: queryResult.config.usedModel,
                  fallbackUsed: queryResult.config.fallbackUsed,
                  originalModel: queryResult.originalModel,
                  documentId: queryResult.documentId,
                  isPrivate: !isShared,
                  explanation: explanation,
                  schema: queryResult.schema,
                },
              }),
            }
          );
          const { id } = await response.json();
          setSavedQueryId(id);
          setIsSaveQueryModalOpen(false);
          setIsQueryJustAdded(false);
          setIsAddToDashboardModalOpen(true);
          if (onSaveQuery) {
            onSaveQuery();
          }
          // Refresh recent searches after saving a query
          fetchRecentSearches();
        } catch (error) {
          console.error('Error saving query:', error);
          setError('Failed to save query. Please try again.');
        } finally {
          setIsSaving(false);
        }
      };
  
      const handleAddToDashboard = async (dashboardId) => {
        try {
          const response = await fetch(
            'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/handleDashboardOperation',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                data: {
                  operation: 'addQueryToDashboard',
                  reportData: {
                    dashboardId: dashboardId,
                    query: {
                      id: savedQueryId,
                      name: queryName,
                      userQuery: queryResult.userQuery,
                      sqlQuery: queryResult.sqlQuery,
                      result: queryResult.result,
                    },
                  },
                  userId: currentUser.email,
                },
              }),
            }
          );
          if (!response.ok) {
            throw new Error('Failed to add query to dashboard.');
          }
          const updatedDashboard = await response.json();
          setIsQueryJustAdded(true);
          if (onDashboardUpdate) {
            onDashboardUpdate(updatedDashboard);
          }
          if (!isAskPage && dashboardManager && typeof dashboardManager.handleSelectDashboard === 'function') {
            await dashboardManager.handleSelectDashboard(updatedDashboard);
          } else if (isAskPage) {
            console.log('Query added to dashboard successfully, but not selecting dashboard on Ask page');
          }
          setIsAddToDashboardModalOpen(false);
        } catch (error) {
          console.error('Error adding query to dashboard:', error);
          setError('Failed to add query to dashboard. Please try again.');
        }
      };
  
      const handleCreateNewDashboard = async (dashboardName) => {
        try {
          const response = await fetch(
            'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/handleDashboardOperation',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                data: {
                  operation: 'create',
                  reportData: {
                    name: dashboardName,
                    queries: [savedQueryId],
                  },
                  userId: currentUser.email,
                },
              }),
            }
          );
          if (!response.ok) {
            throw new Error('Failed to create new dashboard.');
          }
          const newDashboard = await response.json();
          if (!newDashboard || !newDashboard.id) {
            throw new Error('Invalid dashboard data received from server.');
          }
          setIsQueryJustAdded(true);
          if (onDashboardUpdate) {
            onDashboardUpdate(newDashboard);
          }
          setIsAddToDashboardModalOpen(false);
          return newDashboard;
        } catch (error) {
          console.error('Error creating new dashboard:', error);
          setError('Failed to create new dashboard. Please try again.');
          return null;
        }
      };
  
      const handleExplanationClick = async () => {
        if (explanation) return;
        try {
          const newExplanation = await generateExplanation(
            queryResult.sqlQuery,
            queryResult.documentId,
            queryResult.savedQueryId
          );
          setExplanation(newExplanation);
          onQueryResult({
            ...queryResult,
            explanation: newExplanation,
          });
        } catch (error) {
          console.error('Error generating explanation:', error);
          setError('Failed to generate explanation. Please try again.');
        }
      };

      const hasSavedQueries = useMemo(() => {
        return getFilteredQueries && getFilteredQueries('').length > 0;
      }, [getFilteredQueries]);
  
      useEffect(() => {
        if (queryResult && queryResult.result) {
          setShowResultCard(true);
          setExplanation(queryResult.explanation || '');
          setTruncationMessage(queryResult.truncationMessage || '');
        }
      }, [queryResult]);
  
      return (
        <ErrorBoundary>
          <div ref={componentRef}>
            <QueryInput
              onSubmit={handleQueryInputSubmit}
              isLoading={isLoading || isConfigLoading}
              isFollowUp={isFollowUp}
              fetchRecentSearches={fetchRecentSearches}
              initialRecentSearches={recentSearches}
              selectedText={selectedText}
              isFollowUpMode={isFollowUp}
              onTextSelection={handleTextSelection}
              initialQuery={currentQuery}
              hideProposedQuestions={hideProposedQuestions || (showResultCard && !!queryResult) || hasSavedQueries}
              />
            {error && <QueryError error={error} onClose={() => setError(null)} />}
            {queryResult && showResultCard && (
              <div ref={resultRef}>
                <QueryResultCard
                  query={queryResult}
                  currentUser={currentUser}
                  onSaveQuery={handleSaveQuery}
                  onAddToLibrary={onAddToLibrary}
                  onAddToDashboard={() => setIsAddToDashboardModalOpen(true)}
                  onExplanationClick={handleExplanationClick}
                  isExplanationLoading={isExplanationLoading}
                  isExplanationPending={isExplanationPending}
                  truncationMessage={truncationMessage}
                  onFollowUpQuery={handleFollowUpQuery}
                  isDashboardMode={isDashboardMode}
                  fetchRecentSearches={fetchRecentSearches}
                  onTextSelection={handleTextSelection}
                  isAdmin={isAdmin}
                  isLoading={isLoading || isConfigLoading}
                  onClose={handleClose}
                  onQueryResult={onQueryResult}
                  saveQuerySettings={saveQuerySettings}
                  // Intentionally not displaying intentLog in the UI unless needed
                  // If we want to display it, we would pass it here
                  // intentLog={queryResult.intentLog}
                />
              </div>
            )}
            <SaveQueryModal
              isOpen={isSaveQueryModalOpen}
              onClose={() => setIsSaveQueryModalOpen(false)}
              onSubmit={handleSaveQuerySubmit}
              queryName={queryName}
              setQueryName={setQueryName}
              isShared={isShared}
              setIsShared={setIsShared}
              isSaving={isSaving}
            />
            <AddToDashboardModal
              isOpen={isAddToDashboardModalOpen}
              onClose={() => {
                setIsAddToDashboardModalOpen(false);
                setIsQueryJustAdded(false);
              }}
              dashboards={dashboards}
              onAddToDashboard={handleAddToDashboard}
              onCreateNewDashboard={handleCreateNewDashboard}
              onDashboardUpdate={onDashboardUpdate}
              isQueryJustAdded={isQueryJustAdded}
              fetchDashboards={fetchDashboards}
            />
          </div>
        </ErrorBoundary>
      );
    }
  );
  
  export default QueryComponent;