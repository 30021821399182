import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import AnalysisResultHeader from './AnalysisResultHeader';
import AnalysisResultContent from './AnalysisResultContent';

const AnalysisResultCard = ({ 
  analysisResponse, 
  onClose, 
  metadata = {}, 
  documentLinks = [], 
  selectedCategories = [], 
  selectedLabels = [], 
  selectedDocuments = [], 
  title = null,
  query = null
}) => {
  const { currentUser } = useAuth();
  const [copySuccess, setCopySuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [referencedDocumentIds, setReferencedDocumentIds] = useState(new Set());
  
  // Extract referenced document IDs from the content when the component mounts
  useEffect(() => {
    if (analysisResponse) {
      const docRefs = new Set();
      const docRefPattern = /\(doc:(\d+)\)/g;
      let match;
      
      // Find all document references in the text
      while ((match = docRefPattern.exec(analysisResponse)) !== null) {
        docRefs.add(parseInt(match[1], 10));
      }
      
      setReferencedDocumentIds(docRefs);
    }
  }, [analysisResponse]);
  
  if (!analysisResponse) {
    return null;
  }

  // Function to copy analysis content to clipboard
  const copyToClipboard = () => {
    // Remove markdown formatting and document references for a cleaner copy
    const cleanText = analysisResponse
      .replace(/#{2,3}/g, '')
      .replace(/\*\*/g, '')
      .replace(/\s?\(doc:\d+\)/g, ''); // Remove (doc:X) references
      
    navigator.clipboard.writeText(cleanText).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    });
  };
  
  // Function to save analysis to Firestore
  const saveAnalysis = async () => {
    if (!currentUser || isSaving || isSaved) return;
    
    setIsSaving(true);
    try {
      // Create a new document in the savedDocAnalysis collection
      const savedAnalysisRef = collection(db, 'savedDocAnalysis');
      await addDoc(savedAnalysisRef, {
        userId: currentUser.uid,
        userEmail: currentUser.email,
        title: title || 'Document Analysis',
        query: query || '',
        content: analysisResponse,
        selectedCategories,
        selectedLabels,
        selectedDocumentIds: selectedDocuments.map(doc => doc.id),
        metadata: {
          documentsProcessed: metadata.documentsProcessed,
          documentsWithContentAnalyzed: metadata.documentsWithContentAnalyzed,
          processingTimeMs: metadata.processingTimeMs
        },
        documentLinks: documentLinks.map(link => ({
          id: link.id,
          name: link.name,
          // Don't save URLs as they expire - we'll generate new ones when viewing
        })),
        savedAt: serverTimestamp(),
        createdAt: serverTimestamp()
      });
      
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving analysis:', error);
    } finally {
      setIsSaving(false);
    }
  };
  
  // Function to format the processing time in a human-readable format
  const formatProcessingTime = (timeMs) => {
    if (!timeMs) return null;
    return (timeMs / 1000).toFixed(2) + 's';
  };
  
  // Extract metrics from the formatted text if available and metadata is not provided
  const extractMetrics = (text) => {
    const documentsMatch = text.match(/Documents Analyzed:\s*(\d+)/i);
    const timeMatch = text.match(/Processing Time:\s*([\d.]+)\s*seconds/i);
    
    return {
      documentsProcessed: documentsMatch ? documentsMatch[1] : null,
      processingTime: timeMatch ? timeMatch[1] : null,
    };
  };
  
  // Use metadata from props if available, otherwise extract from text
  const displayMetrics = {
    documentsProcessed: metadata.documentsProcessed || extractMetrics(analysisResponse).documentsProcessed,
    documentsWithContentAnalyzed: metadata.documentsWithContentAnalyzed,
    processingTime: metadata.processingTimeMs ? formatProcessingTime(metadata.processingTimeMs) : extractMetrics(analysisResponse).processingTime
  };
  
  return (
    <div className="mb-6 transition-all duration-200">
      <AnalysisResultHeader 
        title={title}
        onClose={onClose}
        onCopy={copyToClipboard}
        onSave={saveAnalysis}
        isSaving={isSaving}
        isSaved={isSaved}
        copySuccess={copySuccess}
        metadata={displayMetrics}
        documentLinks={documentLinks}
        referencedDocumentIds={referencedDocumentIds}
        selectedCategories={selectedCategories}
        selectedLabels={selectedLabels}
        selectedDocuments={selectedDocuments}
      />
      
      <AnalysisResultContent 
        analysisResponse={analysisResponse} 
        documentLinks={documentLinks} 
      />
    </div>
  );
};

export default AnalysisResultCard;