import React, { useState, useEffect, useRef } from 'react';
import { File, Search, X, Clock, CheckSquare, Square } from 'lucide-react';
import { collection, query as firestoreQuery, limit, getDocs, where, orderBy, startAt, endAt } from 'firebase/firestore';
import { db } from '../../firebase';

const DocumentSelector = ({ 
  query: inputQuery,
  setQuery,
  visible,
  setVisible,
  selectedDocuments,
  setSelectedDocuments,
  inputRef
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const selectorRef = useRef(null);
  const itemsRef = useRef([]);
  
  // Reset highlighted index when documents list changes
  useEffect(() => {
    setHighlightedIndex(0);
    itemsRef.current = itemsRef.current.slice(0, documents.length);
  }, [documents]);
  
  // Extract document mention pattern from query
  useEffect(() => {
    if (visible && inputQuery) {
      const pattern = /@([^@\s]*)$/;
      const match = inputQuery.match(pattern);
      if (match) {
        setSearchTerm(match[1]);
      } else {
        setSearchTerm('');
      }
    }
  }, [inputQuery, visible]);
  
  // Handle clicks outside of the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target) && 
          inputRef.current !== event.target) {
        setVisible(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setVisible]);
  
  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!visible) return;
      
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setHighlightedIndex(prev => 
            prev < documents.length - 1 ? prev + 1 : prev
          );
          // Scroll into view if needed
          if (itemsRef.current[highlightedIndex + 1]) {
            itemsRef.current[highlightedIndex + 1].scrollIntoView({
              behavior: 'smooth',
              block: 'nearest'
            });
          }
          break;
        case 'ArrowUp':
          e.preventDefault();
          setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
          // Scroll into view if needed
          if (itemsRef.current[highlightedIndex - 1]) {
            itemsRef.current[highlightedIndex - 1].scrollIntoView({
              behavior: 'smooth',
              block: 'nearest'
            });
          }
          break;
        case 'Enter':
          if (documents.length > 0 && highlightedIndex >= 0) {
            e.preventDefault();
            toggleDocumentSelection(documents[highlightedIndex]);
          }
          break;
        case 'Escape':
          e.preventDefault();
          setVisible(false);
          break;
        case ' ': // Spacebar
          if (documents.length > 0 && highlightedIndex >= 0) {
            e.preventDefault();
            toggleDocumentSelection(documents[highlightedIndex]);
          }
          break;
        default:
          break;
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible, documents, highlightedIndex, selectedDocuments]);
  
  // Fetch documents based on search term
  useEffect(() => {
    const fetchDocuments = async () => {
      if (!visible) return;
      
      setLoading(true);
      setError(null);
      
      try {
        const documentsRef = collection(db, 'docs');
        let q;
        
        if (searchTerm) {
          // Build a more flexible query that can match parts of the document name
          // This is similar to the FilterSelector approach but simpler
          q = firestoreQuery(
            documentsRef,
            orderBy('name'),
            limit(20)
          );
          
          // We'll filter these results client-side for flexibility
        } else {
          // Just get recent documents
          q = firestoreQuery(
            documentsRef,
            orderBy('uploadedAt', 'desc'),
            limit(20)
          );
        }
        
        const querySnapshot = await getDocs(q);
        let documentsList = [];
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          documentsList.push({
            id: doc.id,
            name: data.name || 'Unnamed Document',
            type: data.type || 'Unknown',
            // Convert Firestore timestamp to JS Date if it exists
            uploadedAt: data.uploadedAt?.toDate?.() || new Date()
          });
        });
        
        // If we have a search term, filter the results client-side to match parts of words
        if (searchTerm) {
          const normalizedSearchTerm = searchTerm.toLowerCase();
          
          // Score function to prioritize results (similar to FilterSelector)
          const getMatchScore = (docName, searchTerm) => {
            const lowerName = docName.toLowerCase();
            
            // Exact match at start of document name or word
            if (lowerName.startsWith(searchTerm) || lowerName.includes(' ' + searchTerm)) {
              return 3;
            }
            
            // Match anywhere in the document name
            if (lowerName.includes(searchTerm)) {
              return 2;
            }
            
            // Match individual words (split and check each word)
            const words = lowerName.split(/\s+/);
            for (const word of words) {
              if (word.includes(searchTerm)) {
                return 1;
              }
            }
            
            return 0;
          };
          
          // Filter and sort documents by relevance
          documentsList = documentsList
            .map(doc => {
              const score = getMatchScore(doc.name, normalizedSearchTerm);
              return { ...doc, score };
            })
            .filter(doc => doc.score > 0)
            .sort((a, b) => b.score - a.score)
            .map(({ score, ...doc }) => doc); // Remove the score prop
        }
        
        setDocuments(documentsList);
      } catch (error) {
        console.error('Error fetching documents:', error);
        setError('Failed to load documents. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    const timeoutId = setTimeout(() => {
      fetchDocuments();
    }, 300); // Debounce search
    
    return () => clearTimeout(timeoutId);
  }, [searchTerm, visible]);
  
  // Toggle document selection (add if not selected, remove if already selected)
  const toggleDocumentSelection = (document) => {
    const isSelected = selectedDocuments.some(doc => doc.id === document.id);
    
    if (isSelected) {
      setSelectedDocuments(selectedDocuments.filter(doc => doc.id !== document.id));
    } else {
      setSelectedDocuments([...selectedDocuments, document]);
    }
    
    // Replace @mention with empty string in query
    if (inputRef.current) {
      const mentionPattern = /@[^@\s]*$/;
      const newQuery = inputQuery.replace(mentionPattern, '');
      setQuery(newQuery);
      
      // Make sure the input maintains focus
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
    
    // Don't close the selector after selection to allow multiple selections
    // Keep the dropdown open for multiple selections
  };
  
  // Check if a document is selected
  const isDocumentSelected = (document) => {
    return selectedDocuments.some(doc => doc.id === document.id);
  };
  
  // Handle done button click - close the selector
  const handleDone = () => {
    setVisible(false);
    // Focus back on input
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  // Format the date to a readable string
  const formatDate = (date) => {
    if (!date) return '';
    const now = new Date();
    const diff = now - date;
    
    // If less than 24 hours, show relative time
    if (diff < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(diff / (60 * 60 * 1000));
      if (hours < 1) {
        const minutes = Math.floor(diff / (60 * 1000));
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      }
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }
    
    // Otherwise show date
    return date.toLocaleDateString();
  };
  
  // Helper function to highlight the matching part of text (similar to FilterSelector)
  const highlightMatch = (text, term) => {
    if (!term || term.trim() === '') return text;
    
    const normalizedText = text.toLowerCase();
    const normalizedTerm = term.toLowerCase().trim();
    
    // Find the best match position
    let matchIndex = normalizedText.indexOf(normalizedTerm);
    
    // If no match found, return original text
    if (matchIndex === -1) {
      // Try to find match in individual words
      const words = normalizedText.split(/\s+/);
      for (let i = 0; i < words.length; i++) {
        if (words[i].includes(normalizedTerm)) {
          // Calculate the index in the original text
          const leadingWords = words.slice(0, i).join(' ');
          const leadingSpace = leadingWords.length > 0 ? ' ' : '';
          matchIndex = leadingWords.length + leadingSpace.length + words[i].indexOf(normalizedTerm);
          break;
        }
      }
      
      // If still no match, return original text
      if (matchIndex === -1) return text;
    }
    
    // Split text into parts: before match, match, after match
    const firstPart = text.substring(0, matchIndex);
    const matchedPart = text.substring(matchIndex, matchIndex + normalizedTerm.length);
    const lastPart = text.substring(matchIndex + normalizedTerm.length);
    
    return (
      <>
        {firstPart}
        <span className="font-bold text-blue-600">{matchedPart}</span>
        {lastPart}
      </>
    );
  };
  
  if (!visible) return null;
  
  return (
    <div 
      ref={selectorRef}
      className="absolute z-30 mt-2 bg-white rounded-md shadow-lg border border-gray-200 w-full max-w-md max-h-96 overflow-y-auto"
      style={{ top: '100%', left: 0 }}
      aria-modal="true"
      role="dialog"
      aria-label="Document selector"
    >
      <div className="sticky top-0 z-10 bg-white p-3 border-b border-gray-200 flex items-center justify-between">
        <div className="flex items-center">
          <Search className="w-5 h-5 text-gray-400 mr-2" />
          <span className="font-medium text-gray-700">
            {searchTerm 
              ? `Search documents: "${searchTerm}"` 
              : "Select documents"}
          </span>
        </div>
        
        {selectedDocuments.length > 0 && (
          <div className="flex items-center gap-2">
            <span className="text-xs text-blue-600 font-medium">
              {selectedDocuments.length} selected
            </span>
            <button
              onClick={() => setSelectedDocuments([])}
              className="text-xs text-gray-500 hover:text-gray-700 flex items-center"
              title="Clear all selections"
            >
              <X className="w-3.5 h-3.5" />
            </button>
          </div>
        )}
      </div>
      
      {loading ? (
        <div className="flex items-center justify-center p-6 text-gray-500">
          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-primary mr-3"></div>
          <span>Loading documents...</span>
        </div>
      ) : error ? (
        <div className="p-4 text-center text-red-500">
          {error}
        </div>
      ) : documents.length === 0 ? (
        <div className="p-6 text-center text-gray-500">
          <File className="w-10 h-10 mx-auto mb-2 text-gray-300" />
          <p>No documents found</p>
          <p className="text-xs mt-1">Try a different search term</p>
        </div>
      ) : (
        <>
          <div className="p-2">
            {documents.map((document, index) => {
              const isSelected = isDocumentSelected(document);
              return (
                <div
                  key={document.id}
                  ref={el => itemsRef.current[index] = el}
                  className={`flex items-center p-3 ${
                    index === highlightedIndex
                      ? 'bg-blue-50'
                      : 'hover:bg-gray-50'
                  } rounded-md cursor-pointer transition`}
                  onClick={() => toggleDocumentSelection(document)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  role="option"
                  aria-selected={index === highlightedIndex}
                  tabIndex="-1"
                >
                  <div className="p-2 mr-3">
                    {isSelected ? (
                      <CheckSquare className="w-5 h-5 text-blue-500" />
                    ) : (
                      <Square className="w-5 h-5 text-gray-400" />
                    )}
                  </div>
                  <div className="p-2 bg-blue-50 rounded-md mr-3">
                    <File className="w-6 h-6 text-blue-500" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className={`text-sm font-medium ${isSelected ? 'text-blue-700' : 'text-gray-800'} truncate`}>
                      {searchTerm ? highlightMatch(document.name, searchTerm) : document.name}
                    </div>
                    <div className="flex items-center mt-1 text-xs text-gray-500">
                      <span className="capitalize">{document.type.split('/')[1] || document.type}</span>
                      <span className="mx-1">•</span>
                      <Clock className="w-3 h-3 mr-1" />
                      {formatDate(document.uploadedAt)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          
          {/* Add a "Done" button at the bottom */}
          <div className="sticky bottom-0 z-10 bg-white p-3 border-t border-gray-200 flex justify-end">
            <button
              onClick={handleDone}
              className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 transition-colors"
            >
              Done
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentSelector;