import React, { useState, useEffect, useCallback } from 'react';
import { Copy, Code, FileText, Info, MailQuestion, Download, Share2, AlertCircle, FilePlus2, Database, Bookmark } from 'lucide-react';
import Button from '../Button';
import PopupCard from '../PopupCard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import ShareQueryResult from './ShareQueryResult';
import RatingButton from './RatingButton';
import * as XLSX from 'xlsx';
import { createImageWrapper, captureImage } from './imageUtils';
import { useConfig } from '../../contexts/ConfigContext';
import DownloadAllButton from './DownloadAllButton'; 

const ActionPanel = ({ 
  result, 
  onSaveQuery, 
  onAddToLibrary,
  isSavedQuery, 
  onExplanationClick, 
  isExplanationLoading, 
  isExplanationPending,
  explanation, 
  isDashboardMode, 
  isEmptyResult,
  isInDashboard,
  downloadExcel,
  viewMode,
  chartRef,
  cardTitle,
  statsData,
  selectedYAxis,
  truncationMessage
}) => {
  const [supportEmails, setSupportEmails] = useState([]);
  const [showSqlPopup, setShowSqlPopup] = useState(false);
  const [showLogsPopup, setShowLogsPopup] = useState(false);
  const [showLogicPopup, setShowLogicPopup] = useState(false);
  const [copiedResult, setCopiedResult] = useState(false);
  const [copiedSql, setCopiedSql] = useState(false);
  const [addingToLibrary, setAddingToLibrary] = useState(false);
  const { isAdmin, currentUser } = useAuth(); 
  const { config } = useConfig();

  useEffect(() => {
    const fetchSupportEmails = async () => {
      const db = getFirestore();
      const configDoc = await getDoc(doc(db, 'DBI Configuration', 'Config'));
      if (configDoc.exists()) {
        setSupportEmails(configDoc.data().Support || []);
      }
    };
    fetchSupportEmails();
  }, []);

  const copyToClipboard = (text, callback) => {
    navigator.clipboard.writeText(text).then(() => {
      if (typeof callback === 'function') {
        callback();
      } else if (typeof callback === 'object') {
        // For backward compatibility with existing uses
        callback(true);
        setTimeout(() => callback(false), 2000);
      }
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const formatResultForCopy = (data) => {
    if (!Array.isArray(data)) {
      return JSON.stringify(data, null, 2);
    }
    return JSON.stringify(data.map(item => {
      if (typeof item !== 'object' || item === null) {
        return item;
      }
      return Object.fromEntries(
        Object.entries(item).map(([key, value]) => {
          if (value && typeof value === 'object' && 'displayValue' in value) {
            return [key, value.displayValue];
          }
          return [key, value];
        })
      );
    }), null, 2);
  };

  const formatLogs = () => {
    const versions = result.queryVersions || [];
    let logsContent = `Document ID: ${result.documentId}\n`;
    logsContent += `Number of Versions: ${versions.length}\n\n`;
    
    // Add original query information
    logsContent += `User Query:\n${result.userQuery}\n\n`;
    
    // Add configuration details
    logsContent += `Configuration:\n${JSON.stringify(result.config, null, 2)}\n\n`;
    
    // Add versions information
    if (versions.length > 0) {
      logsContent += `Query Versions:\n`;
      versions.forEach((version, index) => {
        logsContent += `\n=== Version ${index + 1} ===\n`;
        if (version.sqlQuery) {
          logsContent += `SQL Query:\n${version.sqlQuery}\n`;
        }
        if (version.bigQueryError) {
          logsContent += `BigQuery Error:\n${version.bigQueryError}\n`;
        }
        logsContent += `\n`;
      });
    }

    // Add final results
    if (result.result && result.result.length > 0) {
      logsContent += `\nFinal Results:\n${formatResultForCopy(result.result)}\n`;
    }

    return logsContent;
  };

  const handleReportIssue = () => {
    const subject = encodeURIComponent(`Support ticket about a query from ${window.location.hostname}, ${format(new Date(), 'dd/MM/yy HH:mm')}`);
    const body = encodeURIComponent(`
Document ID:
${result.documentId}

User Query:
${result.userQuery}
    `);
    const mailtoLink = `mailto:${supportEmails.join(',')}?subject=${subject}&body=${body}`;
    window.open(mailtoLink, '_blank');
  };

  const handleDownload = async () => {
    if (viewMode !== 'table') {
      // Chart download code remains unchanged
      try {
        const wrapper = createImageWrapper(chartRef, cardTitle, statsData, selectedYAxis);
        const canvas = await captureImage(wrapper);
        
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${cardTitle || 'chart'}_${format(new Date(), 'yyyyMMdd_HHmmss')}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }, 'image/png');
      } catch (error) {
        console.error('Error downloading chart:', error);
        alert('Failed to download chart. Please try again.');
      }
    } else {
      try {
        // Simple approach: get the current table headers from the DOM
        const tableHeaders = Array.from(document.querySelectorAll('table th'))
          .map(th => th.textContent.trim());
        
        console.log('Table headers from DOM:', tableHeaders);
        
        // Get data to export
        const data = result.processedData || result.result;
        
        // Create a new array with only the data we want in the right order
        const exportData = data.map(row => {
          const orderedRow = {};
          tableHeaders.forEach(header => {
            // Find the actual column name that matches this header text
            const columnName = Object.keys(row).find(key => {
              // Simple transformation: replace underscores with spaces
              const formattedKey = key.replace(/_/g, ' ');
              
              // Case-insensitive comparison
              return formattedKey.toLowerCase() === header.toLowerCase();
            });
            
            if (columnName) {
              const value = row[columnName];
              orderedRow[header] = (value && typeof value === 'object' && 'displayValue' in value) 
                ? value.displayValue 
                : value;
            }
          });
          return orderedRow;
        });
        
        // Create the worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, `${cardTitle || 'data'}_${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`);
      } catch (error) {
        console.error('Error downloading table:', error);
        alert('Failed to download table. Please try again.');
      }
    }
  };

  // Check if there are multiple versions to show
  const hasMultipleVersions = () => {
    const versions = result.queryVersions || (result.data && result.data.queryVersions) || [];
    return Array.isArray(versions) && versions.length > 1;
  };

  // Check if download all should be available
  const shouldUseBackgroundDownload = () => {
    // Enable if we have truncation message or more than 2000 rows
    return truncationMessage || (result.totalCount && result.totalCount > 2000);
  };

  const renderVersions = () => {
    // Debug the entire result object
    console.log('Debug full result:', result);
    
    // First try to get versions directly from result
    let versions = result.queryVersions;
    
    // If not found, try to fetch from result.data
    if (!versions && result.data) {
      versions = result.data.queryVersions;
    }
    
    // Ensure we have an array
    versions = Array.isArray(versions) ? versions : [];
    
    console.log('Debug processed versions:', versions);
    
    if (versions.length === 0) {
      return (
        <div className="text-gray-500 italic">
          No version history available
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {versions.map((version, index) => {
          // Extract data based on common version structure patterns
          const sqlQuery = version.sqlQuery || 
                          version.context?.modifiedSqlQuery ||
                          version.completePrompt?.context?.modifiedSqlQuery;
                          
          const bigQueryError = version.bigQueryError ||
                               version.context?.bigQueryError ||
                               version.completePrompt?.context?.bigQueryError;
                               
          const versionModel = version.completePrompt?.model;
          const versionTemp = version.completePrompt?.temperature;
          
          return (
            <div key={index} className="border rounded-lg p-4 bg-gray-50">
              <div className="font-semibold text-gray-700 mb-2">
                Version {version.version || (index + 1)}
                {version.isInvestigation && " (Investigation)"}
              </div>
              
              {/* SQL Query and Error from next version */}
              {sqlQuery && (
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-600 mb-1">SQL Query:</div>
                  <SyntaxHighlighter 
                    language="sql" 
                    style={docco} 
                    customStyle={{ fontSize: '0.875rem', backgroundColor: 'white' }}
                  >
                    {sqlQuery}
                  </SyntaxHighlighter>
                  
                  {/* Show error from next version if it exists */}
                  {versions[index + 1]?.bigQueryError && (
                    <div className="mt-2 mb-4 border-l-4 border-red-500 pl-3">
                      <div className="text-sm font-medium text-red-600 mb-1">Error:</div>
                      <div className="text-sm bg-red-50 text-red-700 p-2 rounded">
                        {versions[index + 1]?.bigQueryError}
                      </div>
                    </div>
                  )}
                </div>
              )}
          
              {/* Modified SQL Query if different */}
              {version.modifiedSqlQuery && version.modifiedSqlQuery !== sqlQuery && (
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-600 mb-1">Modified SQL Query:</div>
                  <SyntaxHighlighter 
                    language="sql" 
                    style={docco} 
                    customStyle={{ fontSize: '0.875rem', backgroundColor: 'white' }}
                  >
                    {version.modifiedSqlQuery}
                  </SyntaxHighlighter>
                </div>
              )}
          
              {/* Model Info */}
              {(versionModel || versionTemp) && (
                <div className="mt-2 text-xs text-gray-500">
                  {versionModel && <div>Model: {versionModel}</div>}
                  {versionTemp && <div>Temperature: {versionTemp}</div>}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };



  const handleAddToLibrary = async () => {
    if (!result || !currentUser || !onAddToLibrary) {
      console.log('Cannot add to library:', { 
        resultExists: !!result, 
        userExists: !!currentUser, 
        handlerExists: !!onAddToLibrary 
      });
      return;
    }
    
    console.log('DEBUG - ActionPanel handleAddToLibrary - result object:', result);
    console.log('DEBUG - ActionPanel handleAddToLibrary - cardSettings:', result.cardSettings);
    
    setAddingToLibrary(true);
    try {
      // Pass the result with the title/name explicitly included
      await onAddToLibrary({
        ...result,
        name: cardTitle || result.name || result.userQuery
      });
      // Success feedback is now visual instead of an alert
    } catch (error) {
      console.error('Error adding to library:', error);
      // Still show an error alert as it's important to communicate failures
      alert('Failed to add to library');
    } finally {
      setAddingToLibrary(false);
    }
  };

  return (
    <div className="flex justify-end space-x-2 font-sans">
      {/* Add to Library button */}
      {!isDashboardMode && !isSavedQuery && (
        <Button
          onClick={handleAddToLibrary}
          icon={addingToLibrary ? null : Bookmark}
          className={`p-2 relative ${addingToLibrary ? 'text-gray-400' : 'text-primary'}`}
          disabled={addingToLibrary}
          title={addingToLibrary ? "Adding to Library..." : "Add to Library"}
        >
          {addingToLibrary && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-4 h-4 border-2 border-t-transparent border-primary rounded-full animate-spin"></div>
            </div>
          )}
        </Button>
      )}
      
      {/* Add the RatingButton for admin users */}
      {isAdmin && (
        <RatingButton 
          documentId={result.documentId}
          initialRatings={result.ratings}
          initialComments={result.ratingComments}
      />
      )}
      
      <ShareQueryResult 
        viewMode={viewMode}
        chartRef={chartRef}
        tableData={result.result}
        fileName={`${cardTitle || 'query_result'}_${format(new Date(), 'yyyyMMdd_HHmmss')}`}
        cardTitle={cardTitle}
        statsData={statsData}
        selectedYAxis={selectedYAxis}
      />
      
      {shouldUseBackgroundDownload() ? (
        <DownloadAllButton 
          documentId={result.documentId}
          userId={currentUser?.email || result.userId || 'anonymous'}
          fileName={cardTitle || 'query_result'}
          disabled={!result.documentId}
          icon={Download}
          title="Download Data"
        />
      ) : (
        <Button
          onClick={handleDownload}
          icon={Download}
          className="p-2"
          title="Download Result"
        />
      )}

      {isInDashboard && (
        <Button
          onClick={() => {
            setShowLogicPopup(true);
            if (!explanation && !isExplanationPending) {
              onExplanationClick();
            }
          }}
          icon={Info}
          className="p-2"
          title="Show Logic"
        />
      )}
  
      {isAdmin && (
        <Button
          onClick={() => copyToClipboard(formatResultForCopy(result.result), setCopiedResult)}
          icon={Copy}
          className="p-2"
          title={copiedResult ? 'Copied!' : 'Copy Result'}
        />
      )}
  
      {isAdmin && (
        <Button
          onClick={() => setShowLogsPopup(true)}
          icon={FileText}
          className={`p-2 relative ${hasMultipleVersions() ? 'text-accent' : ''}`}
          title="View Logs"
        >
          {hasMultipleVersions() && (
            <span className="absolute top-0.5 right-0.5 h-2 w-2 bg-accent rounded-full"></span>
          )}
        </Button>
      )}
  
      {isAdmin && (
        <Button
          onClick={() => setShowSqlPopup(true)}
          icon={Code}
          className="p-2"
          title="View SQL"
        />
      )}
  
      {!isDashboardMode && !isSavedQuery && (
        <Button
          onClick={onSaveQuery}
          icon={FilePlus2}
          className="p-2"
          title="Save to Dashboard"
        />
      )}
  
      <Button
        onClick={handleReportIssue}
        icon={MailQuestion}
        className="p-2"
        title="Report Issue"
      />
  
      <PopupCard
        isOpen={showSqlPopup}
        onClose={() => setShowSqlPopup(false)}
        title="SQL Query"
        footer={
          <Button
            onClick={() => copyToClipboard(result.sqlQuery, setCopiedSql)}
            icon={Copy}
            className="p-2"
            title={copiedSql ? 'Copied!' : 'Copy SQL'}
          />
        }
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="max-h-[60vh] overflow-auto">
          <SyntaxHighlighter language="sql" style={docco} customStyle={{ fontSize: '0.875rem' }}>
            {result?.sqlQuery}
          </SyntaxHighlighter>
        </div>
      </PopupCard>
  
      <PopupCard
        isOpen={showLogsPopup}
        onClose={() => setShowLogsPopup(false)}
        title="Debug Logs"
        className="z-50 font-sans"
        contentClassName="w-full max-w-3xl"
      >
        <div className="p-4">
          {/* Document ID and Version Count */}
          <div className="mb-4 bg-gray-100 p-3 rounded-lg flex flex-col">
            <div className="flex items-center justify-between mb-2">
              <div>
                <div className="font-medium text-gray-800 mb-1">Document ID:</div>
                <code className="bg-white px-3 py-1 rounded border select-all">{result.documentId}</code>
              </div>
              <div className="text-gray-600">
                Versions: {(result.queryVersions || []).length}
              </div>
            </div>
            <div className="mt-2">
              <div className="font-medium text-gray-800 mb-1">Tango ID:</div>
              <div 
                className="bg-primary/10 text-primary px-3 py-1.5 rounded-full border border-primary/20 inline-flex items-center cursor-pointer hover:bg-primary/20 transition-colors duration-200 select-all"
                onClick={() => {
                  // Copy to clipboard functionality
                  copyToClipboard(`tango-${result.documentId}`, () => {
                    const element = document.getElementById(`tango-id-${result.documentId}`);
                    if (element) {
                      element.textContent = "Copied!";
                      setTimeout(() => {
                        element.textContent = `tango-${result.documentId}`;
                      }, 2000);
                    }
                  });
                  
                  // Set sqlShared field to true in Firestore
                  const db = getFirestore();
                  const docRef = doc(db, 'LLM Queries', result.documentId);
                  
                  updateDoc(docRef, {
                    sqlShared: true,
                    sharedAt: new Date(),
                    sharedBy: currentUser?.email || 'unknown'
                  }).then(() => {
                    console.log('Query is now shared via tango-ID');
                  }).catch(error => {
                    console.error('Error sharing query:', error);
                  });
                }}
              >
                <span id={`tango-id-${result.documentId}`}>{`tango-${result.documentId}`}</span>
              </div>
            </div>
          </div>

          {/* User Query */}
          <div className="mb-4">
            <div className="font-medium text-gray-700 mb-2">User Query:</div>
            <div className="bg-gray-50 p-3 rounded-lg">
              {result.userQuery}
            </div>
          </div>

          {/* Configuration */}
          <div className="mb-4">
            <div className="font-medium text-gray-700 mb-2">Configuration:</div>
            <div className="bg-gray-50 p-3 rounded-lg">
              <pre className="text-sm whitespace-pre-wrap">
                {JSON.stringify(result.config, null, 2)}
              </pre>
            </div>
          </div>

          {/* Versions */}
          <div className="mb-4">
            <div className="font-medium text-gray-700 mb-2">Query Versions:</div>
            {renderVersions()}
          </div>
        </div>
      </PopupCard>
  
      <PopupCard
        isOpen={showLogicPopup}
        onClose={() => setShowLogicPopup(false)}
        title={isEmptyResult ? "Here's How I've Tried Finding the Data" : "Behind the Query"}
        footer={null}
        className="z-50 font-sans"
        contentClassName="w-full max-w-2xl"
      >
        <div className="max-h-[60vh] overflow-auto">
          {isExplanationLoading || isExplanationPending ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin border-4 border-t-4 border-primary rounded-full w-8 h-8"></div>
            </div>
          ) : explanation ? (
            <div className="whitespace-pre-wrap break-words text-sm font-sans">
              {explanation}
            </div>
          ) : (
            <p className="text-sm text-gray-600 font-sans">
              Explanation not available. Click the "Show Logic" button to generate an explanation.
            </p>
          )}
        </div>
      </PopupCard>
    </div>
  );
};

export default ActionPanel;