import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import logo from '../assets/logo.png';
import { 
  LayoutDashboard, 
  FileStack, 
  PieChart, 
  LocateFixed, 
  Settings,
  LogIn, 
  UserPlus, 
  TrendingUp, 
  HelpCircle,
  LogOut,
  MessageSquare,
  Newspaper,
  Database,
  Library,
  X,
  Info,
  BookOpenCheck
} from 'lucide-react';
import BetaBadge from './BetaBadge';
import UserProfileModal from './UserProfileModal';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { createPortal } from 'react-dom';

const DEFAULT_PAGES = {
  Shoot: { path: '/', icon: HelpCircle },
  Chat: { path: '/chat', icon: MessageSquare },
  Dashboard: { path: '/dashboard', icon: LayoutDashboard },
  News: { path: '/news', icon: Newspaper },
  Reports: { path: '/static-dashboard', icon: FileStack },
  Review: { path: '/review', icon: BookOpenCheck },
  Playground: { path: '/dynamic', icon: PieChart },
  Operate: { path: '/operate', icon: LocateFixed },
  Plan: { path: '/plan', icon: TrendingUp },
  Boost: { path: '/boost', icon: Database },
  Library: { path: '/organize', icon: Library },
  Admin: { path: '/admin', icon: Settings }
};

const Sidebar = ({ open, setOpen, isAdmin }) => {
  const { currentUser, logout } = useAuth();
  const { config, isLoading } = useConfig();
  const location = useLocation();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [userInitials, setUserInitials] = useState('');
  const [newPreferences, setNewPreferences] = useState([]);
  const [showPreferencesTooltip, setShowPreferencesTooltip] = useState(false);
  const [lastViewedPreferencesTimestamp, setLastViewedPreferencesTimestamp] = useState(null);

  useEffect(() => {
    let unsubscribe = null;
    
    const setupPreferencesListener = async () => {
      if (currentUser?.email) {
        try {
          // Initial fetch to set user initials
          const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
          const docSnap = await getDoc(userPrefsRef);
          
          if (docSnap.exists()) {
            const userData = docSnap.data();
            const { englishName } = userData;
            
            // Set user initials
            if (englishName) {
              const initials = englishName
                .split(' ')
                .map(name => name.charAt(0).toUpperCase())
                .slice(0, 2)
                .join('');
              setUserInitials(initials);
            }
          }
          
          // Set up real-time listener for changes
          unsubscribe = onSnapshot(userPrefsRef, (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              const { learnings, lastViewedPreferencesAt } = userData;
              
              // Check for new preferences
              if (learnings && Array.isArray(learnings) && learnings.length > 0) {
                setLastViewedPreferencesTimestamp(lastViewedPreferencesAt?.toDate() || null);
                
                // Filter for new preferences (those discovered after last viewed)
                const newOnes = learnings.filter(pref => {
                  if (!lastViewedPreferencesAt) return true;
                  if (!pref.discoveredAt) return false;
                  const prefTimestamp = pref.discoveredAt.toDate ? pref.discoveredAt.toDate() : new Date(pref.discoveredAt);
                  return prefTimestamp > lastViewedPreferencesAt.toDate();
                });
                
                if (newOnes.length > 0) {
                  setNewPreferences(newOnes);
                  setShowPreferencesTooltip(true);
                }
              }
            }
          });
        } catch (error) {
          console.error('Error setting up preferences listener:', error);
        }
      }
    };
  
    setupPreferencesListener();
    
    // Clean up the listener when component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  const handleLogoClick = () => {
    window.location.href = '/';
  };
  
  const handleDismissTooltip = async () => {
    setShowPreferencesTooltip(false);
    
    // Update the last viewed timestamp in Firestore
    if (currentUser?.email) {
      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
        await updateDoc(userPrefsRef, {
          lastViewedPreferencesAt: new Date()
        });
      } catch (error) {
        console.error('Error updating last viewed preferences timestamp:', error);
      }
    }
  };
  
  const handleOpenProfileModal = () => {
    setIsProfileModalOpen(true);
    // When opening the profile modal, also dismiss the tooltip if it's showing
    if (showPreferencesTooltip) {
      handleDismissTooltip();
    }
  };

  const MenuItem = ({ label }) => {
    const { path, icon: Icon } = DEFAULT_PAGES[label] || {};
    if (!path || !Icon) return null;

    const isActive = location.pathname === path;
    
    return (
      <Link
        to={path}
        className={`flex items-center menu-item py-2 my-1 rounded-lg text-sm px-4 ${
          isActive
            ? 'bg-gray-100 font-medium text-gray-900'
            : 'text-gray-700 hover:bg-gray-50'
        }`}
        onClick={() => {
          if (window.innerWidth < 1024) {
            setOpen(false);
          }
        }}
      >
        <Icon className="h-4 w-4 flex-shrink-0" />
        <div className="sidebar-text ml-3">
          <span>{label}</span>
          {(label === 'Plan' || label === 'Chat') && <BetaBadge />}
        </div>
      </Link>
    );
  };

  const renderMenuItems = () => {
    if (isLoading) {
      return (
        <div className="text-sm text-gray-500 px-4 py-2 text-center">
          ...
        </div>
      );
    }

    if (!config?.navigation) {
      return null;
    }

    const pages = isAdmin ? 
      config.navigation.adminPages.filter(label => label !== 'Admin') : 
      config.navigation.userPages;

    return Array.isArray(pages) ? pages.map((label, index) => (
      <MenuItem key={index} label={label} />
    )) : null;
  };

  // Preferences Tooltip Component
  const PreferencesTooltip = () => {
    if (!showPreferencesTooltip || newPreferences.length === 0) return null;
    
    // Get the button position to anchor the tooltip
    const getTooltipPosition = () => {
      // Find the personalize button
      const button = document.querySelector('[data-personalize-button]');
      if (!button) return { left: 0, top: 0 };
      
      const rect = button.getBoundingClientRect();
      
      return {
        left: rect.left + window.scrollX + rect.width + 10, // Position to the right of the button
        top: rect.top + window.scrollY - 100 // Position above the button
      };
    };
    
    const position = getTooltipPosition();
    
    // Create portal to render directly in body
    return createPortal(
      <div 
        className="fixed bg-white shadow-lg rounded-lg border border-gray-200 p-3 z-50 animate-fadeIn w-64"
        style={{ 
          left: `${position.left}px`, 
          top: `${position.top}px` 
        }}
      >
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-5 h-5 rounded-full bg-blue-50 text-primary mr-2">
              <Info className="h-3 w-3" />
            </div>
            <h3 className="text-sm font-medium text-primary">New Preferences Learned!</h3>
          </div>
          <button 
            onClick={handleDismissTooltip}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
        <div className="text-xs text-gray-600 mb-2">
          Your preferences have been updated based on your recent activity:
        </div>
        <ul className="space-y-2 mb-1">
          {newPreferences.map((pref, index) => (
            <li key={index} className="text-xs bg-gray-50 p-2 rounded flex items-center">
              <div className="flex items-center justify-center w-4 h-4 rounded-full bg-blue-50 text-primary mr-1 flex-shrink-0">
                <Info className="h-2.5 w-2.5" />
              </div>
              <span className="font-medium text-primary">{pref.preference}</span>
            </li>
          ))}
        </ul>
      </div>,
      document.body
    );
  };

  // Backdrop for mobile
  const renderBackdrop = () => (
    <div
      className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
        open ? 'block' : 'hidden'
      }`}
      onClick={() => setOpen(false)}
    />
  );

  // Auth buttons section
  const renderAuthButtons = () => {
    if (currentUser) {
      return (
        <>
          {isAdmin && (
            <>
              <div className="sidebar-text px-2 mb-2">
                <div className="border-t border-gray-200"></div>
              </div>
              <MenuItem label="Admin" />
            </>
          )}
          <div className="relative">
            <button
              onClick={handleOpenProfileModal}
              data-personalize-button
              className="w-full flex items-center menu-item py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg mb-2 px-4"
            >
              <div className="flex items-center justify-center w-6 h-6 rounded-full bg-secondary text-white flex-shrink-0">
                <span className="text-xs font-medium">{userInitials || '--'}</span>
              </div>
              <span className="sidebar-text ml-3">Personalize</span>
              {newPreferences.length > 0 && showPreferencesTooltip && (
                <span className="absolute top-0 right-0 h-3 w-3 bg-primary rounded-full"></span>
              )}
            </button>
            <PreferencesTooltip />
          </div>
          <button
            onClick={() => {
              logout();
              if (window.innerWidth < 1024) {
                setOpen(false);
              }
            }}
            className="w-full flex items-center menu-item py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg px-4"
          >
            <LogOut className="h-4 w-4 flex-shrink-0" />
            <span className="sidebar-text ml-3">Sign Out</span>
          </button>
        </>
      );
    }

    return (
      <>
        <Link to="/login" className="w-full flex items-center menu-item py-2 my-1 text-sm text-gray-700 hover:bg-gray-50 rounded-lg px-4">
          <LogIn className="h-4 w-4 flex-shrink-0" />
          <span className="sidebar-text ml-3">Sign In</span>
        </Link>
        <Link to="/signup" className="w-full flex items-center menu-item py-2 my-1 text-sm text-gray-700 hover:bg-gray-50 rounded-lg px-4">
          <UserPlus className="h-4 w-4 flex-shrink-0" />
          <span className="sidebar-text ml-3">Sign Up</span>
        </Link>
      </>
    );
  };

  return (
    <>
      {renderBackdrop()}

      <div
        className={`fixed inset-y-0 left-0 z-30 overflow-y-auto bg-white sidebar lg:static transform ${
          open ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        } transition-transform duration-300 ease-in-out w-64`}
      >
        {/* Logo section */}
        <div 
          className="flex items-center mt-5 mb-4 cursor-pointer px-4 justify-center"
          onClick={handleLogoClick}
        >
          <div className="sidebar-logo block">
            <img src={logo} alt="Logo" className="h-8 w-auto" />
          </div>
          <div className="sidebar-icon hidden">
            <img src="/favicon.ico" alt="Logo Icon" className="h-8 w-auto" />
          </div>
        </div>

        {/* Navigation menu */}
        <nav className="px-2">
          {currentUser && renderMenuItems()}
        </nav>

        {/* Bottom section */}
        <div className="px-2 mb-4 absolute bottom-0 w-full bottom-container">
          {renderAuthButtons()}
        </div>
      </div>
      
      {/* Add UserProfileModal here */}
      {isProfileModalOpen && (
        <UserProfileModal
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
          userEmail={currentUser?.email}
        />
      )}
    </>
  );
};

export default Sidebar;