// src/components/Docs/CategoriesManagement.js
import React, { useState, useEffect } from 'react';
import Card from '../Card';
import { db } from '../../firebase';
import { doc, getDoc, getFirestore, updateDoc, collection, query, orderBy, getDocs, addDoc, serverTimestamp, setDoc, limit } from 'firebase/firestore';
import { Loader2, FolderTree, Plus, X, Check, Search } from 'lucide-react';
import VersionList from './VersionList';
import { useAuth } from '../../contexts/AuthContext';

const CategoriesManagement = () => {
  const [configData, setConfigData] = useState({
    categories: [],
    categoryId: ''
  });
  const [previewData, setPreviewData] = useState({
    categories: []
  });
  const [versions, setVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });
  const [newCategory, setNewCategory] = useState('');
  const [categoryCounts, setCategoryCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser } = useAuth();

  // Fetch initial data and versions
  useEffect(() => {
    const fetchConfigData = async () => {
      if (!currentUser) return;
      
      try {
        setIsLoading(true);
        
        // Fetch category counts from docs collection
        const docsQuery = query(collection(db, 'docs'));
        const docsSnapshot = await getDocs(docsQuery);
        const counts = {};
        
        docsSnapshot.forEach(docSnap => {
          const data = docSnap.data();
          if (data.categories && Array.isArray(data.categories)) {
            data.categories.forEach(item => {
              if (item.category) {
                counts[item.category] = (counts[item.category] || 0) + 1;
              }
            });
          }
        });
        
        setCategoryCounts(counts);

        const configDoc = await getDoc(doc(db, 'DocsCategories', 'Config'));
        
        if (configDoc.exists()) {
          const configData = configDoc.data();
          
          // Fetch versions
          const versionsQuery = query(
            collection(db, 'DocsCategories', 'Config', 'versions'),
            orderBy('versionNumber', 'desc'),
            limit(10)
          );
          const versionsSnapshot = await getDocs(versionsQuery);
          
          const categoryVersions = versionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setVersions(categoryVersions);

          // Get the current version
          const currentVersion = categoryVersions.find(v => v.id === configData.currentVersion);
          
          const initialConfig = {
            categories: currentVersion?.categories || [],
            categoryId: configData.currentVersion
          };

          setConfigData(initialConfig);
          setPreviewData({
            categories: initialConfig.categories
          });
        } else {
          // Initialize if doesn't exist
          const initialVersion = {
            categories: [],
            versionNumber: 1,
            createdAt: serverTimestamp(),
            createdBy: currentUser?.email || 'unknown'
          };

          // Create Config document first
          await setDoc(doc(db, 'DocsCategories', 'Config'), {
            currentVersion: null
          });

          // Then create the initial version in the versions subcollection
          const versionRef = await addDoc(
            collection(db, 'DocsCategories', 'Config', 'versions'),
            initialVersion
          );
          
          // Update Config with the version reference
          await updateDoc(doc(db, 'DocsCategories', 'Config'), {
            currentVersion: versionRef.id
          });

          setConfigData({
            categories: [],
            categoryId: versionRef.id
          });
          setPreviewData({ categories: [] });
          setVersions([{ ...initialVersion, id: versionRef.id }]);
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching categories config:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfigData();
  }, [currentUser]);

  const handleVersionSelect = (version) => {
    setPreviewData({
      categories: version.categories
    });
  };

  const handleVersionApply = async (version) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Applying version...' });
      
      // Update the version document
      const versionRef = doc(db, 'DocsCategories', 'Config', 'versions', version.id);
      await updateDoc(versionRef, {
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      });
  
      // Update the config
      await updateDoc(doc(db, 'DocsCategories', 'Config'), {
        currentVersion: version.id
      });
  
      // Update states
      setConfigData(prev => ({
        ...prev,
        categories: version.categories,
        categoryId: version.id
      }));
      setPreviewData({
        categories: version.categories
      });
  
      // Update versions state
      setVersions(prev => prev.map(v => 
        v.id === version.id 
          ? { 
              ...v, 
              lastAppliedAt: new Date(),
              lastAppliedBy: currentUser?.email || 'unknown'
            } 
          : v
      ));
  
      setSaveStatus({ type: 'success', message: `Applied version ${version.versionNumber}` });
    } catch (error) {
      console.error('Error applying version:', error);
      setSaveStatus({ type: 'error', message: 'Failed to apply version' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };
  
  const handleAddCategory = async () => {
    if (!newCategory.trim()) return;
    
    try {
      setSaveStatus({ type: 'loading', message: 'Adding new category...' });
      
      const updatedCategories = [...previewData.categories, newCategory.trim()];
      await handleSaveCategories(updatedCategories);
      
      setNewCategory('');
    } catch (error) {
      console.error('Error adding category:', error);
      setSaveStatus({ type: 'error', message: 'Failed to add category' });
    }
  };

  const handleRemoveCategory = async (categoryToRemove) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Removing category...' });
      
      const updatedCategories = previewData.categories.filter(category => category !== categoryToRemove);
      await handleSaveCategories(updatedCategories);
    } catch (error) {
      console.error('Error removing category:', error);
      setSaveStatus({ type: 'error', message: 'Failed to remove category' });
    }
  };

  const handleSaveCategories = async (updatedCategories) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Saving new version...' });
      
      // Create new version with improved version number handling
      const nextVersionNumber = versions.length > 0
        ? Math.max(...versions.map(v => {
            const num = Number(v.versionNumber);
            return isFinite(num) ? num : 0;
          })) + 1
        : 1;
      
      const newVersion = {
        categories: updatedCategories,
        versionNumber: nextVersionNumber,
        createdAt: serverTimestamp(),
        createdBy: currentUser?.email || 'unknown',
        lastAppliedAt: serverTimestamp(),
        lastAppliedBy: currentUser?.email || 'unknown'
      };

      const versionsRef = collection(db, 'DocsCategories', 'Config', 'versions');
      const newVersionRef = await addDoc(versionsRef, newVersion);

      // Update Config
      await updateDoc(doc(db, 'DocsCategories', 'Config'), {
        currentVersion: newVersionRef.id
      });

      // Update states
      const newVersionWithId = {
        id: newVersionRef.id,
        ...newVersion,
        createdAt: new Date(),
        lastAppliedAt: new Date()
      };

      setVersions(prev => [newVersionWithId, ...prev]);
      setConfigData(prev => ({
        ...prev,
        categories: updatedCategories,
        categoryId: newVersionRef.id
      }));
      setPreviewData({
        categories: updatedCategories
      });

      setSaveStatus({ type: 'success', message: 'Categories updated successfully!' });
    } catch (error) {
      console.error('Error saving categories:', error);
      setSaveStatus({ type: 'error', message: 'Failed to save categories' });
    } finally {
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error loading categories: {error}
      </div>
    );
  }

  return (
    <div className="w-full space-y-4">
      {/* Status Messages */}
      {saveStatus.message && (
        <div className={`px-4 py-2 rounded ${
          saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
          saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
          'bg-blue-100 text-blue-700'
        }`}>
          {saveStatus.message}
        </div>
      )}

      {/* Search Component */}
      <div className="relative flex-grow max-w-xs mb-4">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400"/>
        <input
          type="text"
          className="w-full pl-9 pr-4 py-2 text-sm border-b border-gray-300 focus:border-secondary focus:ring-0 outline-none"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Content */}
      <Card>
        <div className="px-4 pb-4 border-b">
          <VersionList
            versions={versions}
            currentVersion={configData.categoryId}
            onVersionSelect={handleVersionSelect}
            onVersionApply={handleVersionApply}
            isLoading={isLoading}
            type="categories"  // Add this prop to specify type
          />
        </div>

        <div className="p-4">
          <div className="mb-6">
            <div className="flex items-center gap-2 mb-4">
              <input
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Enter new category"
                className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onKeyPress={(e) => e.key === 'Enter' && handleAddCategory()}
              />
              <button
                onClick={handleAddCategory}
                className="px-4 py-2 bg-primary text-white rounded-md hover:bg-opacity-90 transition-colors flex items-center gap-2"
              >
                <Plus className="h-4 w-4" />
                Add Category
              </button>
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            {previewData.categories
              .filter(category => category.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((category, index) => (
              <div
                key={index}
                className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-full group hover:bg-gray-200 transition-colors"
              >
                <FolderTree className="h-4 w-4 text-gray-500" />
                <span>{category}</span>
                {categoryCounts[category] > 0 && (
                  <span className="text-xs text-gray-500 bg-white px-2 py-0.5 rounded-full">
                    {categoryCounts[category]}
                  </span>
                )}
                {!categoryCounts[category] && (
                  <span className="text-xs text-gray-500 bg-white px-2 py-0.5 rounded-full">
                    0
                  </span>
                )}
                <button
                  onClick={() => handleRemoveCategory(category)}
                  className="opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="h-4 w-4 text-gray-500 hover:text-red-500" />
                </button>
              </div>
            ))}
            {previewData.categories.length === 0 && (
              <div className="text-gray-500 italic">No categories defined</div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CategoriesManagement;