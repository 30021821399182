import React, { useState, useEffect, useRef } from 'react';
import { Tag, FolderTree } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const TagSuggestions = ({ 
  query, 
  setQuery, 
  visible, 
  setVisible, 
  selectedCategories, 
  setSelectedCategories, 
  selectedLabels, 
  setSelectedLabels, 
  inputRef 
}) => {
  const [allItems, setAllItems] = useState({ categories: [], labels: [] });
  const [filteredItems, setFilteredItems] = useState({ categories: [], labels: [] });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const suggestionRef = useRef(null);
  const triggerChar = '#';
  
  // Extract hashtag query (text after # symbol)
  const getHashtagQuery = () => {
    if (!query || !query.includes(triggerChar)) return '';
    
    const lastHashIndex = query.lastIndexOf(triggerChar);
    const textAfterHash = query.slice(lastHashIndex + 1);
    
    // If there's a space after the hashtag, don't show suggestions
    if (/\s/.test(textAfterHash.charAt(0))) return '';
    
    // Extract the word containing the cursor
    const wordWithCursor = textAfterHash.split(/\s/)[0];
    return wordWithCursor.trim();
  };

  // Fetch all categories and labels
  useEffect(() => {
    const fetchItems = async () => {
      try {
        setIsLoading(true);
        
        // Fetch categories
        const categoryConfigDoc = await getDoc(doc(db, 'DocsCategories', 'Config'));
        let categories = [];
        
        if (categoryConfigDoc.exists()) {
          const configData = categoryConfigDoc.data();
          const currentVersionId = configData.currentVersion;
          
          if (currentVersionId) {
            const versionDoc = await getDoc(doc(db, 'DocsCategories', 'Config', 'versions', currentVersionId));
            if (versionDoc.exists()) {
              categories = versionDoc.data().categories || [];
            }
          }
        }
        
        // Fetch labels
        const labelConfigDoc = await getDoc(doc(db, 'DocsLabels', 'Config'));
        let labels = [];
        
        if (labelConfigDoc.exists()) {
          const configData = labelConfigDoc.data();
          const currentVersionId = configData.currentVersion;
          
          if (currentVersionId) {
            const versionDoc = await getDoc(doc(db, 'DocsLabels', 'Config', 'versions', currentVersionId));
            if (versionDoc.exists()) {
              labels = versionDoc.data().labels || [];
            }
          }
        }
        
        setAllItems({ categories, labels });
      } catch (err) {
        console.error('Error fetching items:', err);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchItems();
  }, []);

  // Filter items based on hashtag query
  useEffect(() => {
    const hashtagQuery = getHashtagQuery();
    
    if (!hashtagQuery) {
      setVisible(false);
      return;
    }

    // Filter categories and labels based on the query
    const normalizedQuery = hashtagQuery.toLowerCase();
    
    // Support Hebrew - normalize queries with or without Hebrew prefix ל
    const hebrewPrefix = 'ל';
    let queryWithoutPrefix = normalizedQuery;
    let queryWithPrefix = normalizedQuery;
    
    if (normalizedQuery.startsWith(hebrewPrefix)) {
      queryWithoutPrefix = normalizedQuery.substring(1);
    } else {
      queryWithPrefix = hebrewPrefix + normalizedQuery;
    }
    
    // Score function to prioritize results (beginning > word boundary > anywhere)
    const getMatchScore = (text, searchTerm) => {
      const lowerText = text.toLowerCase();
      
      // Check if it starts with the query (highest priority)
      if (lowerText.startsWith(searchTerm)) return 3;
      
      // Check if it contains the query at a word boundary (medium priority)
      const wordBoundaryRegex = new RegExp(`\\b${searchTerm}`, 'i');
      if (wordBoundaryRegex.test(text)) return 2;
      
      // Check if it contains the query anywhere (lowest priority)
      if (lowerText.includes(searchTerm)) return 1;
      
      return 0;
    };
    
    // Process categories
    const matchedCategories = allItems.categories
      .map(category => {
        // Get the highest match score from the different query variations
        const score = Math.max(
          getMatchScore(category, normalizedQuery),
          getMatchScore(category, queryWithoutPrefix),
          getMatchScore(category, queryWithPrefix)
        );
        return { item: category, score };
      })
      .filter(item => item.score > 0) // Only include matches
      .sort((a, b) => b.score - a.score) // Sort by score (highest first)
      .map(item => item.item); // Extract just the category name
    
    // Process labels
    const matchedLabels = allItems.labels
      .map(label => {
        // Get the highest match score from the different query variations
        const score = Math.max(
          getMatchScore(label, normalizedQuery),
          getMatchScore(label, queryWithoutPrefix),
          getMatchScore(label, queryWithPrefix)
        );
        return { item: label, score };
      })
      .filter(item => item.score > 0) // Only include matches
      .sort((a, b) => b.score - a.score) // Sort by score (highest first)
      .map(item => item.item); // Extract just the label name
    
    setFilteredItems({
      categories: matchedCategories,
      labels: matchedLabels
    });
    
    // If we have any matches, show the suggestions
    if (matchedCategories.length > 0 || matchedLabels.length > 0) {
      setVisible(true);
      setSelectedIndex(0);
    } else {
      setVisible(false);
    }
  }, [query, allItems, setVisible]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!visible) return;
      
      const totalItems = filteredItems.categories.length + filteredItems.labels.length;
      
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex((prevIndex) => (prevIndex + 1) % totalItems);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
          break;
        case 'Enter':
          e.preventDefault();
          selectSuggestion(getSelectedItem());
          break;
        case 'Escape':
          e.preventDefault();
          setVisible(false);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible, filteredItems, selectedIndex]);

  // Scroll selected item into view
  useEffect(() => {
    if (visible && suggestionRef.current) {
      const selectedElement = suggestionRef.current.querySelector(`[data-index="${selectedIndex}"]`);
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedIndex, visible]);

  // Click outside to close suggestions
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (suggestionRef.current && !suggestionRef.current.contains(e.target) && 
          inputRef.current && !inputRef.current.contains(e.target)) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setVisible, inputRef]);

  // Helper to get the currently selected item
  const getSelectedItem = () => {
    let index = selectedIndex;
    
    if (index < filteredItems.categories.length) {
      return { type: 'category', value: filteredItems.categories[index] };
    } else {
      index -= filteredItems.categories.length;
      return { type: 'label', value: filteredItems.labels[index] };
    }
  };

  // Handle suggestion selection
  const selectSuggestion = (item) => {
    if (!item) return;
    
    // Replace the hashtag query with the selected item
    const lastHashIndex = query.lastIndexOf(triggerChar);
    const newQuery = query.substring(0, lastHashIndex);
    setQuery(newQuery.trim());
    
    // Add the selected item to the appropriate filter
    if (item.type === 'category') {
      if (!selectedCategories.includes(item.value)) {
        setSelectedCategories([...selectedCategories, item.value]);
      }
    } else {
      if (!selectedLabels.includes(item.value)) {
        setSelectedLabels([...selectedLabels, item.value]);
      }
    }
    
    // Close the suggestions
    setVisible(false);
    
    // Focus back on the input
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Determine if we have anything to show
  const hasItems = filteredItems.categories.length > 0 || filteredItems.labels.length > 0;
  
  if (!visible || !hasItems) return null;

  const hashtagQuery = getHashtagQuery();
  
  return (
    <div 
      ref={suggestionRef}
      className="absolute z-20 mt-1 w-80 max-h-80 overflow-y-auto bg-white rounded-lg shadow-lg border border-gray-200"
      style={{ top: '100%', right: 0 }}
    >
      <div className="p-2 border-b">
        <span className="text-sm font-medium">Suggestions for #{hashtagQuery}</span>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center p-4">
          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="p-1">
          {filteredItems.categories.length > 0 && (
            <div>
              <div className="px-3 py-1 text-xs font-medium text-gray-500 bg-gray-50">
                Categories
              </div>
              {filteredItems.categories.map((category, index) => (
                <button
                  key={`category-${category}`}
                  data-index={index}
                  onClick={() => selectSuggestion({ type: 'category', value: category })}
                  className={`flex items-center gap-2 w-full px-3 py-2 text-sm text-left hover:bg-gray-50 transition-colors ${
                    selectedIndex === index ? 'bg-blue-50' : ''
                  }`}
                >
                  <FolderTree className="w-4 h-4 text-gray-500" />
                  <span>
                    {highlightMatch(category, hashtagQuery)}
                  </span>
                </button>
              ))}
            </div>
          )}
          
          {filteredItems.labels.length > 0 && (
            <div>
              <div className="px-3 py-1 text-xs font-medium text-gray-500 bg-gray-50">
                Labels
              </div>
              {filteredItems.labels.map((label, index) => (
                <button
                  key={`label-${label}`}
                  data-index={index + filteredItems.categories.length}
                  onClick={() => selectSuggestion({ type: 'label', value: label })}
                  className={`flex items-center gap-2 w-full px-3 py-2 text-sm text-left hover:bg-gray-50 transition-colors ${
                    selectedIndex === index + filteredItems.categories.length ? 'bg-blue-50' : ''
                  }`}
                >
                  <Tag className="w-4 h-4 text-gray-500" />
                  <span>
                    {highlightMatch(label, hashtagQuery)}
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Helper function to highlight the matching part of text
function highlightMatch(text, query) {
  if (!query) return text;
  
  const normalizedText = text.toLowerCase();
  const normalizedQuery = query.toLowerCase();
  
  // Handle Hebrew prefix variations
  const hebrewPrefix = 'ל';
  const queryVariations = [
    normalizedQuery,
    normalizedQuery.startsWith(hebrewPrefix) ? normalizedQuery.substring(1) : normalizedQuery,
    !normalizedQuery.startsWith(hebrewPrefix) ? hebrewPrefix + normalizedQuery : normalizedQuery
  ];
  
  // Try to find the best match position using different query variations
  let bestMatchIndex = -1;
  let bestMatchLength = 0;
  let bestQueryVariation = normalizedQuery;
  
  // First try to find matches at the beginning (highest priority)
  for (const queryVar of queryVariations) {
    if (normalizedText.startsWith(queryVar)) {
      bestMatchIndex = 0;
      bestMatchLength = queryVar.length;
      bestQueryVariation = queryVar;
      break;
    }
  }
  
  // If no match at beginning, check for word boundaries (medium priority)
  if (bestMatchIndex === -1) {
    for (const queryVar of queryVariations) {
      const wordBoundaryRegex = new RegExp(`\\b${queryVar}`, 'i');
      const match = wordBoundaryRegex.exec(normalizedText);
      if (match) {
        bestMatchIndex = match.index;
        bestMatchLength = queryVar.length;
        bestQueryVariation = queryVar;
        break;
      }
    }
  }
  
  // If still no match, look anywhere in the text (lowest priority)
  if (bestMatchIndex === -1) {
    for (const queryVar of queryVariations) {
      const matchIndex = normalizedText.indexOf(queryVar);
      if (matchIndex !== -1) {
        bestMatchIndex = matchIndex;
        bestMatchLength = queryVar.length;
        bestQueryVariation = queryVar;
        break;
      }
    }
  }
  
  // If no match found, return original text
  if (bestMatchIndex === -1) return text;
  
  // Split text into parts: before match, match, after match
  const firstPart = text.substring(0, bestMatchIndex);
  const matchedPart = text.substring(bestMatchIndex, bestMatchIndex + bestMatchLength);
  const lastPart = text.substring(bestMatchIndex + bestMatchLength);
  
  return (
    <>
      {firstPart}
      <span className="font-bold text-blue-600">{matchedPart}</span>
      {lastPart}
    </>
  );
}

export default TagSuggestions;