import { getFunctions, httpsCallable } from 'firebase/functions';

class DocumentAnalysisService {
  constructor() {
    this.functions = getFunctions();
    this.analyzeDocumentsFunction = httpsCallable(this.functions, 'analyzeDocuments');
  }

  /**
   * Analyze a query using the AI model
   * @param {string} query - The query to analyze
   * @param {Array} selectedCategories - The categories to filter by
   * @param {Array} selectedLabels - The labels to filter by
   * @param {Array} selectedDocuments - Specific documents to analyze
   * @returns {Promise<Object>} - The analysis response
   */
  async analyzeQuery(query, selectedCategories = [], selectedLabels = [], selectedDocuments = []) {
    try {
      console.log("[DocumentAnalysisService] Analyzing query with:", {
        query,
        categories: selectedCategories,
        labels: selectedLabels,
        documents: selectedDocuments.length > 0 ? 
          selectedDocuments.map(doc => ({ id: doc.id, name: doc.name })) : 
          'none'
      });
      
      const response = await this.analyzeDocumentsFunction({ 
        query, 
        selectedCategories, 
        selectedLabels,
        includeTitle: true, // Signal that we want a title in the response
        documentIds: selectedDocuments.length > 0 ? selectedDocuments.map(doc => doc.id) : undefined
      });
      
      console.log("[DocumentAnalysisService] Full response data:", response.data);
      console.log("[DocumentAnalysisService] Response text:", response.data.response ? 
        (response.data.response.length > 100 ? 
          response.data.response.substring(0, 100) + "..." : response.data.response) : 
        "No response text");
      
      if (response.data.success) {
        // Extract metadata from the response if available
        const metrics = response.data.metrics || {};
        
        const result = {
          success: true,
          response: response.data.response,
          documentLinks: response.data.documentLinks || [],
          title: response.data.title || null, // Get the title if provided
          metadata: {
            documentsProcessed: metrics.documentsProcessed || null,
            documentsWithContentAnalyzed: metrics.documentsWithContentAnalyzed || null,
            processingTimeMs: metrics.processingTimeMs || null
          }
        };
        
        console.log("[DocumentAnalysisService] Returning result with response length:", 
          result.response ? result.response.length : 0);
          
        return result;
      } else {
        return {
          success: false,
          message: response.data.message || 'Analysis failed',
          response: null
        };
      }
    } catch (error) {
      console.error('Error analyzing query:', error);
      return {
        success: false,
        message: error.message || 'An unexpected error occurred',
        response: null
      };
    }
  }
}

// Create and export a singleton instance
const documentAnalysisService = new DocumentAnalysisService();
export default documentAnalysisService;