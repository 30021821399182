// src/components/Docs/UploadSection.js
import React, { useState } from 'react';
import { Upload, FileType, HardDrive, AlertCircle } from 'lucide-react';
import Modal from '../Modal';

const UploadSection = ({ onUpload, isUploading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    onUpload(files);
    setIsModalOpen(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = Array.from(e.dataTransfer.files);
      onUpload(files);
      setIsModalOpen(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary bg-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        disabled={isUploading}
      >
        <Upload className="h-4 w-4 mr-2" />
        Upload Documents
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Upload Documents"
      >
        <div className="p-6">
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="flex flex-col items-center p-4 bg-gray-50 rounded-lg">
              <FileType className="h-6 w-6 text-gray-400 mb-2" />
              <span className="text-sm font-medium">Supported Files</span>
              <span className="text-xs text-gray-500">PDF, DOC, DOCX, TXT</span>
            </div>
            <div className="flex flex-col items-center p-4 bg-gray-50 rounded-lg">
              <HardDrive className="h-6 w-6 text-gray-400 mb-2" />
              <span className="text-sm font-medium">Max Size</span>
              <span className="text-xs text-gray-500">10MB per file</span>
            </div>
          </div>
          
          <div 
            className={`flex flex-col items-center justify-center border-2 border-dashed rounded-lg p-8 transition-colors ${
              dragActive ? 'border-primary bg-primary/5' : 'border-gray-300'
            }`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {isUploading ? (
              <div className="flex flex-col items-center">
                <div className="w-8 h-8 mb-4 border-2 border-primary border-t-transparent rounded-full animate-spin"></div>
                <p className="text-sm text-gray-500">Uploading documents...</p>
              </div>
            ) : (
              <>
                <Upload className="h-8 w-8 text-gray-400 mb-4" />
                <p className="text-sm text-gray-500 mb-2">Drag and drop your files here</p>
                <p className="text-xs text-gray-400 mb-4">or</p>
                <label htmlFor="file-upload" className="cursor-pointer">
                  <span className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                    Choose Files
                  </span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="hidden"
                    multiple
                    onChange={handleFileUpload}
                    accept=".pdf,.doc,.docx,.txt"
                    disabled={isUploading}
                  />
                </label>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadSection;