import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown, Filter, EyeOff, GripVertical, Info } from 'lucide-react';
import ColumnExplanationTooltip from './ColumnExplanationTooltip';
import { sortData, formatColumnName, formatDate, formatNumber } from './dataProcessing';
import FilterModal from './FilterModal';
import { useCompanyNews } from '../../hooks/useCompanyNews';
import NewsIndicator from '../NewsIndicator'; 
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HiddenColumnsChips from './HiddenColumnsChips';

const TableView = ({ 
  data,
  originalData,
  schema, 
  initialSortColumn, 
  initialSortOrder, 
  onTextSelection,
  visibleColumns = [],
  columnOrder = [],
  onSortChange,
  enableFiltering = true,
  onFilterChange,
  activeFilters = {},
  onProcessedDataChange,
  showSummary = false,
  query,
  onColumnOrderChange,
  onVisibleColumnsChange,
  isNumberFormatted = true
}) => {
  const [sortConfig, setSortConfig] = useState({ 
    key: initialSortColumn || null, 
    direction: initialSortOrder || 'asc' 
  });
  const [tableHeight, setTableHeight] = useState('auto');
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, width: 0 });
  const { isTrackedColumn } = useCompanyNews();
  const [showInitialButtons, setShowInitialButtons] = useState(true);
  const [showRowCount, setShowRowCount] = useState(false);
  const [visibleRowsCount, setVisibleRowsCount] = useState(0);
  const rowHeight = 40; // Standard row height in pixels
  const [filterModalState, setFilterModalState] = useState({
    isOpen: false,
    columnName: null,
    position: { top: 0, left: 0 }
  });
  
  const headerRef = useRef(null);
  const tableRef = useRef(null);
  const scrollTimeoutRef = useRef(null);

  const columnExplanations = useMemo(() => {
    if (!query?.explanation?.logic_by_column) return {};
    return query.explanation.logic_by_column;
  }, [query]);


  // Use columnOrder if provided, otherwise fall back to schema fields
  const displayColumns = useMemo(() => {
    if (columnOrder.length > 0) {
      return columnOrder.filter(col => visibleColumns.includes(col));
    }
    return schema?.fields?.map(field => field.name) || [];
  }, [columnOrder, visibleColumns, schema]);

  // Handle table height for scrolling
  useEffect(() => {
    if (headerRef.current && data.length > 7) {
      const headerHeight = headerRef.current.offsetHeight;
      const rowHeight = 40; // Standard row height
      setTableHeight(`${headerHeight + (rowHeight * 7)}px`);
    } else {
      setTableHeight('auto');
    }
  }, [data]);

  // Update sort config when initial values change
  useEffect(() => {
    setSortConfig({ key: initialSortColumn, direction: initialSortOrder });
  }, [initialSortColumn, initialSortOrder]);

  // show cue for header buttons
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInitialButtons(false);
    }, 3000); // 3 seconds delay
  
    return () => clearTimeout(timer);
  }, []);

  // New function to handle column hiding
  const handleHideColumn = useCallback((columnName) => {
    const newVisibleColumns = visibleColumns.filter(col => col !== columnName);
    if (newVisibleColumns.length > 0) { // Ensure at least one column remains visible
      onVisibleColumnsChange?.(newVisibleColumns);
    }
  }, [visibleColumns, onVisibleColumnsChange]);

  // New function to handle drag end
  const handleDragEnd = useCallback((result) => {
    if (!result.destination || result.destination.index === result.source.index) return;
    
    // Work with the full columnOrder array
    const items = Array.from(columnOrder);
    
    // Get the actual columns being displayed
    const visibleItems = items.filter(col => visibleColumns.includes(col));
    
    // Get the column being moved
    const [movedColumn] = visibleItems.splice(result.source.index, 1);
    visibleItems.splice(result.destination.index, 0, movedColumn);
    
    // Create new column order by preserving hidden columns in their positions
    const newColumnOrder = items.reduce((acc, col) => {
      if (!visibleColumns.includes(col)) {
        // Keep hidden columns in their original positions
        acc.push(col);
      }
      return acc;
    }, []);
  
    // Insert visible columns in their new order
    visibleItems.forEach((col, index) => {
      newColumnOrder.splice(index, 0, col);
    });
  
    // Use requestAnimationFrame to ensure smooth animation completion
    requestAnimationFrame(() => {
      onColumnOrderChange?.(newColumnOrder);
    });
  }, [columnOrder, visibleColumns, onColumnOrderChange]);

    const getDragStyle = useCallback((isDragging, draggableStyle) => ({
      ...draggableStyle,
      transform: draggableStyle.transform 
        ? draggableStyle.transform.replace(/translate\(([^,]+),([^)]+)\)/, (_, x, y) => `translate(${x}, 0px)`)
        : 'translate(0, 0)',
      transition: isDragging ? 'transform 0.01s linear' : 'transform 0.2s ease-in-out',
      zIndex: isDragging ? 9999 : draggableStyle.zIndex,
      cursor: isDragging ? 'grabbing' : 'grab',
      left: 'auto'
    }), []);

  // Process data with filters and sorting
  const processedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) return [];
    
    // Apply filters first if filtering is enabled
    let filteredData = data;
    if (enableFiltering && Object.keys(activeFilters).length > 0) {
      filteredData = data.filter(row => {
        return Object.entries(activeFilters).every(([columnName, allowedValues]) => {
          const cellValue = row[columnName]?.displayValue ?? row[columnName];
          const stringValue = cellValue === null || cellValue === undefined 
            ? 'N/A' 
            : String(cellValue);
          return allowedValues.includes(stringValue);
        });
      });
    }

    // Then apply sorting
    const sortedData = sortData(filteredData, sortConfig.key, sortConfig.direction);

    // Notify parent component of processed data if callback provided
    if (onProcessedDataChange) {
      onProcessedDataChange(sortedData);
    }

    return sortedData;
  }, [data, activeFilters, sortConfig, enableFiltering, onProcessedDataChange]);


  const getFilteredDataForColumn = useCallback((columnName) => {
    if (!Array.isArray(data) || data.length === 0) return [];
    
    // Apply all filters except the current column's filter
    const otherFilters = Object.entries(activeFilters).filter(([key]) => key !== columnName);
    
    if (otherFilters.length === 0) return data;
    
    return data.filter(row => {
      return otherFilters.every(([filterCol, allowedValues]) => {
        const cellValue = row[filterCol]?.displayValue ?? row[filterCol];
        const stringValue = cellValue === null || cellValue === undefined 
          ? 'N/A' 
          : String(cellValue);
        return allowedValues.includes(stringValue);
      });
    });
  }, [data, activeFilters]);

  // Handle column sort request
  const requestSort = useCallback((key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    if (onSortChange) {
      onSortChange(key, direction);
    }
  }, [sortConfig, onSortChange]);

  // Handle filter icon click
  const handleFilterClick = useCallback((e, columnName) => {
    e.stopPropagation();
    const headerRect = e.currentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const modalWidth = 300; // Width of the modal
    const modalHeight = 500; // Max height of the modal
    
    // Calculate initial position
    let left = headerRect.left + window.scrollX;
    let top = headerRect.bottom + window.scrollY;
  
    // Adjust horizontal position if modal would overflow viewport
    if (left + modalWidth > viewportWidth) {
      left = Math.max(0, viewportWidth - modalWidth - 20); // 20px padding from right edge
    }
  
    // Adjust vertical position if modal would overflow viewport
    if (top + modalHeight > viewportHeight) {
      // Position above the header if there's more space available
      if (headerRect.top > modalHeight) {
        top = headerRect.top - modalHeight + window.scrollY;
      } else {
        // If not enough space above, position at top of viewport with small padding
        top = window.scrollY + 20;
      }
    }
  
    setFilterModalState({
      isOpen: true,
      columnName,
      position: { top, left }
    });
  }, []);

  // Handle filter application
  const handleApplyFilter = useCallback((columnName, selectedValues) => {
    const newFilters = { ...activeFilters };
    
    // Get the unique values for this column from the original data
    const uniqueColumnValues = _.uniq(originalData.map(row => 
      String(row[columnName]?.displayValue ?? row[columnName])
    ));
    
    if (selectedValues.length === 0 || selectedValues.length === uniqueColumnValues.length) {
      delete newFilters[columnName];
    } else {
      newFilters[columnName] = selectedValues;
    }
  
    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  }, [activeFilters, originalData, onFilterChange]);


  const handleShowColumn = useCallback((columnName) => {
    const newVisibleColumns = [...visibleColumns, columnName];
    onVisibleColumnsChange?.(newVisibleColumns);
  }, [visibleColumns, onVisibleColumnsChange]);

  // Close filter modal
  const closeFilterModal = useCallback(() => {
    setFilterModalState(prev => ({ ...prev, isOpen: false }));
  }, []);

  // Get sort icon for column header
  const getSortIcon = useCallback((columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? 
        <ChevronUp className="inline-block w-4 h-4 ml-1" /> :
        <ChevronDown className="inline-block w-4 h-4 ml-1" />;
    }
    return null;
  }, [sortConfig]);

  // Get filter icon for column header
  const getFilterIcon = useCallback((columnName) => {
    if (!enableFiltering) return null;
    
    const isFiltered = activeFilters[columnName]?.length > 0;
    return (
      <Filter 
        size={14} 
        className={`inline-block ml-2 cursor-pointer transition-all
          ${isFiltered ? 'text-blue-600 scale-110 bg-blue-50 ring-2 ring-blue-200 p-0.5 rounded-full shadow-sm' : 'text-gray-400 hover:text-gray-600'}`}
        onClick={(e) => handleFilterClick(e, columnName)}
      />
    );
  }, [enableFiltering, activeFilters, handleFilterClick]);

  const isNumericColumn = useCallback((columnName) => {
    const field = schema?.fields?.find(f => f.name === columnName);
    // Only check schema type
    return field && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(field.type);
  }, [schema]);

    const isStringColumn = useCallback((columnName) => {
      const field = schema?.fields?.find(f => f.name === columnName);
      return field && ['STRING'].includes(field.type);
    }, [schema]);

    const calculateColumnStats = useCallback((columnName) => {
      const field = schema?.fields?.find(f => f.name === columnName);
      if (!field) return null;
    
      if (['INTEGER', 'FLOAT', 'NUMERIC'].includes(field.type) || isNumericColumn(columnName)) {
        const values = processedData
          .map(row => {
            const value = row[columnName].sortValue;
            if (typeof value === 'number') return value;
            if (typeof value === 'string') {
              const cleanValue = value.replace(/,/g, '').trim();
              return parseFloat(cleanValue);
            }
            return NaN;
          })
          .filter(val => !isNaN(val));
        
        // Rest of the stats calculation...
        
        if (values.length === 0) return null;
    
        const sum = values.reduce((acc, val) => acc + val, 0);
        const avg = sum / values.length;
        const min = Math.min(...values);
        const max = Math.max(...values);
    
        // Format numbers conditionally based on isNumberFormatted
        const formatValue = (value) => {
          if (isNumberFormatted) {
            return formatNumber(value, columnName);
          }
          // When not formatting, still ensure proper decimal places
          return typeof value === 'number' ? value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          }) : value;
        };
    
        return {
          type: 'numeric',
          sum: formatValue(sum),
          avg: formatValue(avg),
          min: formatValue(min),
          max: formatValue(max)
        };
      }
      
      if (field.type === 'STRING') {
        const values = processedData
          .map(row => row[columnName]?.displayValue)
          .filter(val => val !== null && val !== undefined);
        
        if (values.length === 0) return null;
    
        const uniqueValues = new Set(values);
        
        return {
          type: 'string',
          total: values.length,
          unique: uniqueValues.size
        };
      }
    
      return null;
    }, [processedData, schema, isNumberFormatted]);

    const hasColumnsWithStats = useMemo(() => {
      return displayColumns.some(columnName => {
        return (isNumericColumn(columnName) || isStringColumn(columnName)) && calculateColumnStats(columnName) !== null;
      });
    }, [displayColumns, isNumericColumn, isStringColumn, calculateColumnStats]);
    
    // After calculateColumnStats, add hasNumericColumnsWithData
    const hasNumericColumnsWithData = useMemo(() => {
      return displayColumns.some(columnName => {
        const isNumeric = isNumericColumn(columnName);
        if (!isNumeric) return false;
        const stats = calculateColumnStats(columnName);
        return stats !== null;
      });
    }, [displayColumns, isNumericColumn, calculateColumnStats]);

  // Handle text selection
  const handleTextSelection = useCallback(() => {
    if (!onTextSelection) return;
    
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    if (selectedText) {
      const range = selection.getRangeAt(0);
      const startNode = range.startContainer.parentElement;
      const endNode = range.endContainer.parentElement;
      console.log('Selection nodes:', {
        startNode,
        endNode,
        startNodeDataset: startNode.dataset,
        endNodeDataset: endNode.dataset,
        parentDataset: startNode.parentElement?.dataset
      });
      
      const selectedColumns = new Set();
      let currentNode = startNode;
      
      // Handle transposed data differently
      if (data && data.length > 0 && query && query.isTransposed) {
        // For transposed data, we want the Field value from the first column
        const row = currentNode.closest('tr');
        if (row) {
          const fieldCell = row.querySelector('td:first-child');
          if (fieldCell) {
            selectedColumns.add(fieldCell.textContent.trim());
          }
        }
      } else {
        // Original behavior for non-transposed data
        while (currentNode && currentNode !== endNode.nextSibling) {
          if (currentNode.dataset && currentNode.dataset.column) {
            selectedColumns.add(currentNode.dataset.column);
          }
          currentNode = currentNode.nextSibling || (currentNode.parentNode && currentNode.parentNode.nextSibling);
        }
      }
      
      const rect = range.getBoundingClientRect();
      const selectionData = {
        text: selectedText,
        columns: Array.from(selectedColumns),
        position: {
          x: rect.left + rect.width / 2,
          y: rect.top - 20
        },
        isTransposed: query && query.isTransposed
      };
      
      onTextSelection(selectionData);
    }
  }, [onTextSelection, query, data]); 

// Handle cell value rendering
const renderCellValue = useCallback((cellValue, columnName) => {
  if (cellValue === null || cellValue === undefined) {
    return 'N/A';
  }

  // If it's not an object (string or number), display it directly
  if (typeof cellValue !== 'object') {
    return (
      <div className="flex items-center">
        <span data-column={columnName}>{cellValue}</span>
        {isTrackedColumn(columnName) && <NewsIndicator companyName={cellValue} />}
      </div>
    );
  }

  // If it's an object with displayValue property
  if ('displayValue' in cellValue) {
    return (
      <div className="flex items-center">
        <span data-column={columnName}>{cellValue.displayValue}</span>
        {isTrackedColumn(columnName) && <NewsIndicator companyName={cellValue.displayValue} />}
      </div>
    );
  }

  // Fallback case
  const value = cellValue.value ?? String(cellValue);
  return (
    <div className="flex items-center">
      <span data-column={columnName}>{value}</span>
      {isTrackedColumn(columnName) && <NewsIndicator companyName={value} />}
    </div>
  );
}, [isTrackedColumn]);

  // Handle summary hover for tooltips
  const handleHeaderHover = useCallback((event, columnName) => {
    if (processedData.length <= 1) {
      setTooltipContent(null);
      return;
    }
  
    const stats = calculateColumnStats(columnName);
    if (stats) {
      setTooltipContent(
        <div className="p-2 text-sm">
          {stats.type === 'numeric' ? (
            <>
              <div>Sum: {stats.sum}</div>
              <div>Avg: {stats.avg}</div>
              <div>Min: {stats.min}</div>
              <div>Max: {stats.max}</div>
            </>
          ) : (
            <>
              <div>Total Values: {stats.total}</div>
              <div>Unique Values: {stats.unique}</div>
            </>
          )}
        </div>
      );
  
      const headerCell = event.currentTarget;
      const headerRect = headerCell.getBoundingClientRect();
      const tableRect = tableRef.current.getBoundingClientRect();
      
      setTooltipPosition({
        left: headerRect.left - tableRect.left,
        width: headerRect.width,
        top: headerRect.top - tableRect.top - 110  // Reduced offset to bring tooltip closer
      });
    }
  }, [processedData, calculateColumnStats]);

  const handleHeaderLeave = useCallback(() => {
    setTooltipContent(null);
  }, []);

  if (!Array.isArray(data) || data.length === 0) return <div>No data available</div>;


  return (
    <div className="relative overflow-visible" ref={tableRef}>
  <div className="flex gap-2">
  <div 
    className="overflow-x-auto flex-grow relative" 
    style={{ height: tableHeight, position: 'relative' }}
    onScroll={(e) => {
        const container = e.target;
        const scrollTop = container.scrollTop;
        const viewportHeight = container.clientHeight;
        
        // Calculate which rows are currently visible
        const firstVisibleRowIndex = Math.min(
          Math.ceil(scrollTop / rowHeight) + 1,  // Add 1 for human-readable row numbers
          processedData.length // Ensure we don't exceed total rows
        );
        const numberOfVisibleRows = Math.floor(viewportHeight / rowHeight);
        const lastVisibleRowIndex = Math.min(firstVisibleRowIndex + numberOfVisibleRows - 1, processedData.length);
        
        // Format as "start-end/total"
        setVisibleRowsCount(firstVisibleRowIndex);
        setShowRowCount(true);
        
        // Clear existing timeout
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
        
        scrollTimeoutRef.current = setTimeout(() => {
          setShowRowCount(false);
        }, 1000);
      }}
    >
      <div 
        className="fixed w-fit bg-gray-700 text-white px-2 py-1 rounded-full text-xs font-medium shadow-sm z-[60] flex items-center gap-1.5"
        style={{ 
          opacity: showRowCount ? 1 : 0,
          transition: 'opacity 300ms ease-in-out',
          pointerEvents: 'none',
          top: tableRef.current?.getBoundingClientRect().top + 8 + 'px',
          left: tableRef.current?.getBoundingClientRect().left + 8 + 'px'
        }}
      >
        Rows {visibleRowsCount.toLocaleString()}-{Math.min(visibleRowsCount + Math.floor(tableRef.current?.clientHeight / rowHeight) - 1, processedData.length).toLocaleString()}/{processedData.length.toLocaleString()}
      </div>
          <div className="relative min-w-full overflow-visible">
            <table className="w-full border-collapse border border-gray-300">
            {showSummary && processedData.length > 1 && hasColumnsWithStats && !query?.truncationMessage && (
              <thead className="border-b sticky bg-gray-50" style={{ top: 0, zIndex: 3 }}>
                <tr className="divide-x divide-gray-200">
                  {displayColumns.map(columnName => {
                    const stats = calculateColumnStats(columnName);
                    if (!stats) return <th key={`summary-${columnName}`} className="p-2"></th>;

                    return (
                      <th 
                        key={`summary-${columnName}`} 
                        className={`p-2 align-top transition-colors relative overflow-visible
                          ${query.truncationMessage 
                            ? 'bg-blue-100' 
                            : 'bg-gradient-to-r from-blue-50/30 to-transparent hover:bg-blue-50/50'}`}
                        onMouseEnter={(e) => handleHeaderHover(e, columnName)}
                        onMouseLeave={handleHeaderLeave}
                      >
                        <div className="text-sm text-left">
                          <div className="flex items-center">
                            {stats.type === 'numeric' ? (
                              <>
                                <span className="text-gray-500 text-xs font-medium">Sum:</span>
                                <span className="ml-2 text-gray-700 font-medium">{stats.sum}</span>
                                {query.truncationMessage && (
                                  <Info className="ml-1 text-blue-800" size={14} />
                                )}
                              </>
                            ) : (
                              <>
                                <span className="text-gray-500 text-xs font-medium">Unique:</span>
                                <span className="ml-2 text-gray-700 font-medium">{stats.unique}</span>
                                {query.truncationMessage && (
                                  <Info className="ml-1 text-blue-800" size={14} />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
  
          {/* Column Headers with Drag and Drop */}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable 
              droppableId="table-headers" 
              direction="horizontal"
              renderClone={(provided, snapshot, rubric) => (
                <th
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={{
                    ...provided.draggableProps.style,
                    transform: provided.draggableProps.style?.transform 
                      ? provided.draggableProps.style.transform.replace(/translate\(([^,]+),([^)]+)\)/, (_, x, y) => `translate(${x}, 0px)`)
                      : 'translate(0, 0)',
                    height: 'auto',
                    top: headerRef.current?.getBoundingClientRect().top + 'px',
                    zIndex: 9999,
                    opacity: 0.9,
                    cursor: 'grabbing'
                  }}
                  className="border border-gray-300 p-2 text-sm font-semibold text-left bg-gray-100 shadow-lg relative"
                >
                  <div className="min-h-[24px] flex items-center">
                    <span className="flex-grow min-w-0 truncate">
                      {formatColumnName(displayColumns[rubric.source.index])}
                    </span>
                  </div>
                </th>
              )}
            >
            {(provided) => (
                <thead 
                ref={(el) => {
                  headerRef.current = el;
                  provided.innerRef(el);
                }}
                className={`sticky transition-colors duration-200 bg-gray-100`}
                style={{ 
                  top: showSummary && processedData.length > 1 && hasColumnsWithStats && !query?.truncationMessage ? '38px' : 0,
                  zIndex: 2 
                }}
                {...provided.droppableProps}
              >
                  <tr>
                    {displayColumns.map((columnName, index) => (
                      <Draggable 
                      key={columnName} 
                      draggableId={columnName} 
                      index={index}
                      isDragDisabled={displayColumns.length <= 1}
                    >
                      {(provided, snapshot) => (
                      <th 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...getDragStyle(snapshot.isDragging, provided.draggableProps.style),
                          position: 'relative',
                        }}
                        className={`border border-gray-300 p-2 text-sm font-semibold text-left 
                          ${snapshot.isDragging ? 'bg-gray-200 shadow-lg' : 'hover:bg-gray-200'}
                          ${snapshot.dropAnimation ? 'transition-all duration-300' : 'transition-colors'}
                          relative`}
                      >
                        {/* Drop indicators */}
                        <div className={`absolute inset-y-0 -left-px w-0.5 bg-blue-500 transition-opacity duration-200
                          ${provided.placeholder ? 'opacity-100 h-full' : 'opacity-0 h-0'}`}
                        />
                        <div className={`absolute inset-y-0 -right-px w-0.5 bg-blue-500 transition-opacity duration-200
                          ${provided.placeholder ? 'opacity-100 h-full' : 'opacity-0 h-0'}`}
                        />
                        {/* Drop zone indicators */}
                        <div className={`absolute -left-3 inset-y-0 w-6 transition-opacity duration-200 pointer-events-none
                          ${provided.placeholder ? 'opacity-100' : 'opacity-0'}`}
                        >
                          <div className="absolute inset-y-0 left-1/2 w-1 bg-blue-500/20" />
                          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-3 h-3 rounded-full bg-blue-500" />
                        </div>
                        <div className={`absolute -right-3 inset-y-0 w-6 transition-opacity duration-200 pointer-events-none
                          ${provided.placeholder ? 'opacity-100' : 'opacity-0'}`}
                        >
                          <div className="absolute inset-y-0 left-1/2 w-1 bg-blue-500/20" />
                          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-3 h-3 rounded-full bg-blue-500" />
                        </div>
                        <div className="flex items-center justify-between min-h-[24px] group pr-1">
                          <div className="flex items-center flex-grow min-w-0">
                            <span 
                              onClick={() => requestSort(columnName)} 
                              className={`cursor-pointer truncate mr-3 ${columnExplanations[columnName] ? 'text-blue-700 font-medium' : ''}`}
                            >
                              {formatColumnName(columnName)}
                              {getSortIcon(columnName)}
                            </span>
                            {columnExplanations[columnName] && (
                              <ColumnExplanationTooltip 
                                columnName={columnName}
                                explanation={columnExplanations[columnName].logic}
                                example={columnExplanations[columnName].example}
                              />
                            )}
                          </div>
                          <div className="flex items-center gap-1 flex-shrink-0">
                          <span
                            {...provided.dragHandleProps}
                            className={`cursor-grab p-1 rounded hover:bg-gray-300 transition-opacity duration-300
                              ${showInitialButtons ? 'opacity-70' : 'opacity-0 group-hover:opacity-100'}
                              ${snapshot.isDragging ? 'cursor-grabbing !opacity-100 bg-gray-300' : ''}`}
                          >
                            <GripVertical size={14} className="text-gray-400" />
                          </span>
                            <button className={`p-1 rounded hover:bg-gray-300 transition-opacity duration-300 ${activeFilters[columnName]?.length > 0 ? 'opacity-100' : showInitialButtons ? 'opacity-70' : 'opacity-0 group-hover:opacity-100'}`}>
                              {originalData.length > 2 && getFilterIcon(columnName)}
                            </button>
                            {displayColumns.length > 1 && (
                              <button
                                onClick={() => handleHideColumn(columnName)}
                                className={`p-1 rounded hover:bg-gray-300 transition-opacity duration-300 ${showInitialButtons ? 'opacity-70' : 'opacity-0 group-hover:opacity-100'}`}
                                title="Hide column"
                              >
                                <EyeOff size={14} className="text-gray-500" />
                              </button>
                            )}
                          </div>
                        </div>
                      </th>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tr>
                </thead>
              )}
            </Droppable>
          </DragDropContext>
  
          {/* Table Body */}
          <tbody className="overflow-y-auto" onMouseUp={handleTextSelection}>
            {processedData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                {displayColumns.map((columnName) => (
                  <td 
                    key={columnName} 
                    className="border border-gray-300 p-2 text-sm text-left"
                    data-column={columnName}
                  >
                    {renderCellValue(row[columnName], columnName)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          </table>
      </div>
    </div>
    <HiddenColumnsChips 
      allColumns={schema?.fields?.map(field => field.name) || []}
      visibleColumns={visibleColumns}
      onShowColumn={handleShowColumn}
    />
  </div>
  
      {/* Tooltip */}
        {tooltipContent && processedData.length > 1 && (
          <div
            className="fixed bg-white border border-gray-300 rounded shadow-lg z-[100]"
            style={{
              left: `${tooltipPosition.left + tableRef.current.getBoundingClientRect().left}px`,
              width: `${tooltipPosition.width}px`,
              top: `${tooltipPosition.top + tableRef.current.getBoundingClientRect().top}px`,
              pointerEvents: 'none',
            }}
          >
            {tooltipContent}
            <div 
              className="absolute bottom-0 left-1/2 w-2 h-2 bg-white border-r border-b border-gray-300 transform translate-y-1 rotate-45 -translate-x-1/2"
              style={{ marginBottom: '-1px' }}
            />
          </div>
        )}
  
      {/* Filter Modal */}
      {enableFiltering && (
        <FilterModal 
          isOpen={filterModalState.isOpen}
          onClose={closeFilterModal}
          columnName={filterModalState.columnName}
          data={originalData}
          activeFilters={activeFilters}
          onApplyFilter={handleApplyFilter}
          position={filterModalState.position}
          schema={schema}
          style={{
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        />
      )}
    </div>
  );
};

TableView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  originalData: PropTypes.arrayOf(PropTypes.object).isRequired,
  schema: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    }))
  }),
  query: PropTypes.shape({
    explanation: PropTypes.shape({
      logic_by_column: PropTypes.objectOf(PropTypes.shape({
        logic: PropTypes.string,
        example: PropTypes.string
      }))
    })
  }),
  initialSortColumn: PropTypes.string,
  initialSortOrder: PropTypes.oneOf(['asc', 'desc']),
  onTextSelection: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  onSortChange: PropTypes.func,
  enableFiltering: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onProcessedDataChange: PropTypes.func,
  showSummary: PropTypes.bool,
  query: PropTypes.object,
  isNumberFormatted: PropTypes.bool
};

TableView.defaultProps = {
  visibleColumns: [],
  columnOrder: [],
  enableFiltering: true,
  showSummary: true,
  isNumberFormatted: true
};

export default TableView;