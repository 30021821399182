// src/utils/dataProcessing.js
import { format, parse, isValid } from 'date-fns';
import { currentConfig } from '../../contexts/ConfigContext';

export const formatDate = (dateValue, schemaType) => {
  if (!dateValue) return '';

  const dateString = typeof dateValue === 'object' && dateValue !== null
    ? (dateValue.value || dateValue.toString())
    : String(dateValue);

  const formats = [
    { format: 'yyyy-MM-dd HH:mm:ss', output: 'dd/MM/yyyy HH:mm:ss' },
    { format: 'yyyy-MM-dd', output: 'dd/MM/yyyy' },
    { format: 'yyyy-MM', output: 'MM/yyyy' },
    { format: 'MM-yyyy', output: 'MM/yyyy' },
    { format: 'yyyy', output: 'yyyy' },
    { format: 'MM/yyyy', output: 'MM/yyyy' },
    { format: 'MM/dd/yyyy', output: 'dd/MM/yyyy' },
    { format: 'dd/MM/yyyy', output: 'dd/MM/yyyy' },
    { format: 'yyyy/MM/dd', output: 'dd/MM/yyyy' },
    { format: 'MM yy', output: 'MM/yyyy' }
  ];

  if (schemaType) {
    if (schemaType.toLowerCase().includes('timestamp')) {
      const parsedDate = new Date(dateString);
      return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy HH:mm:ss') : dateString;
    } else if (schemaType.toLowerCase().includes('date')) {
      const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
      if (isValid(parsedDate)) {
        return format(parsedDate, 'dd/MM/yyyy');
      }
    }
  }

  for (const { format: formatString, output } of formats) {
    const parsedDate = parse(dateString, formatString, new Date());
    if (isValid(parsedDate)) {
      if (formatString.includes('HH:mm:ss')) {
        return format(parsedDate, 'dd/MM/yyyy HH:mm:ss');
      }
      if (formatString.includes('dd') || formatString.includes('DD')) {
        return format(parsedDate, 'dd/MM/yyyy');
      }
      return format(parsedDate, output);
    }
  }

  return dateString;
};

export const trimDecimals = (value) => {
  if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    return Number(num.toFixed(2));
  }
  return value;
};

export const formatNumber = (value, columnName) => {
  const exemptedColumns = currentConfig?.processing?.exemptedColumns || [];
  
  if (exemptedColumns.includes(columnName)) {
    return value;
  }

  if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    const absNum = Math.abs(num);
    
    if (absNum >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (absNum >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (absNum >= 1e3) {
      return (num / 1e3).toFixed(1) + 'k';
    } else {
      return num.toLocaleString('en-US', { 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 2 
      });
    }
  }
  return value;
};

export const formatColumnName = (name) => {
  if (typeof name !== 'string') {
    console.warn(`Unexpected column name type: ${typeof name}`, name);
    return String(name);
  }
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const processData = (rawData, schema, isNumberFormatted) => {
  if (!Array.isArray(rawData)) {
    console.warn('Expected array for rawData, got:', typeof rawData);
    return [];
  }

  if (!schema || !Array.isArray(schema.fields)) {
    console.warn('Invalid schema provided:', schema);
    if (rawData.length > 0) {
      schema = {
        fields: Object.keys(rawData[0]).map(key => ({
          name: key,
          type: typeof rawData[0][key] === 'number' ? 'FLOAT' : 'STRING'
        }))
      };
    } else {
      return [];
    }
  }

  return rawData.map(item => {
    if (!item || typeof item !== 'object') {
      console.warn('Invalid item in rawData:', item);
      return {};
    }

    const processedItem = {};
    Object.entries(item).forEach(([key, value]) => {
      let fieldSchema = schema.fields.find(field => field.name === key);
      
      if (!fieldSchema) {
        const inferredType = (() => {
          if (typeof value === 'number' || !isNaN(parseFloat(value))) return 'FLOAT';
          if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}/)) return 'DATE';
          return 'STRING';
        })();
        
        fieldSchema = { name: key, type: inferredType };
      }
  
      let displayValue, sortValue;
      
      if (value === null || value === undefined) {
        displayValue = '';
        sortValue = Number.NEGATIVE_INFINITY;
      } else {
        const isTransposedData = 'Field' in item;
        const fieldName = isTransposedData ? item.Field : null;
        const exemptedColumns = currentConfig?.processing?.exemptedColumns || [];
        const shouldExemptField = isTransposedData && exemptedColumns.includes(fieldName);

        switch (fieldSchema.type) {
          case 'FLOAT':
          case 'INTEGER':
          case 'NUMERIC': {
            const cleanValue = typeof value === 'string' ? 
              value.replace(/[,\s]/g, '') : value;
            
            const numericValue = typeof cleanValue === 'number' ? 
              cleanValue : parseFloat(cleanValue);

            if (!isNaN(numericValue)) {
              const trimmedValue = trimDecimals(numericValue);
              if (shouldExemptField || (!isTransposedData && exemptedColumns.includes(key))) {
                displayValue = trimmedValue;
              } else {
                displayValue = isNumberFormatted ? 
                  formatNumber(trimmedValue, key) : 
                  trimmedValue.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  });
              }
              sortValue = numericValue;
            } else {
              displayValue = value;
              sortValue = Number.NEGATIVE_INFINITY;
            }
            break;
          }
          case 'DATE':
          case 'DATETIME':
          case 'TIMESTAMP':
          case 'STRING': {
            // Special handling for dates in STRING type columns
            const stringValue = typeof value === 'object' && value !== null ? 
              (value.value || value.toString()) : String(value);
            
            // Check if the string matches ISO date format (yyyy-MM-dd)
            const isISODate = /^\d{4}-\d{2}-\d{2}/.test(stringValue);
            
            if (isISODate || fieldSchema.type.toLowerCase().includes('date') || 
                fieldSchema.type.toLowerCase().includes('timestamp')) {
              try {
                const date = new Date(stringValue);
                if (isValid(date)) {
                  displayValue = formatDate(stringValue, fieldSchema.type);
                  sortValue = date.getTime(); // Use timestamp for sorting
                  break;
                }
              } catch (e) {
                // If date parsing fails, fall through to default string handling
                console.warn(`Failed to parse date: ${stringValue}`, e);
              }
            }
            
            // Default string handling if not a valid date
            if (!sortValue) {
              displayValue = stringValue;
              // For sorting, if it's a numeric string, convert to number for proper sorting
              if (/^\d+$/.test(stringValue)) {
                sortValue = parseInt(stringValue, 10);
              } else {
                sortValue = stringValue.toLowerCase();
              }
            }
            break;
          }
          default: {
            if (typeof value === 'object' && value !== null && 'value' in value) {
              displayValue = String(value.value);
              sortValue = displayValue.toLowerCase();
            } else {
              displayValue = String(value);
              sortValue = displayValue.toLowerCase();
            }
          }
        }
      }
      
      processedItem[key] = { displayValue, sortValue };
    });
    return processedItem;
  });
};

export const sortData = (data, sortColumn, sortOrder) => {
  if (!Array.isArray(data) || data.length === 0 || !sortColumn) {
    return data;
  }

  return [...data].sort((a, b) => {
    let aValue = a[sortColumn]?.sortValue;
    let bValue = b[sortColumn]?.sortValue;

    // Handle null/undefined/invalid values
    if (aValue === Number.NEGATIVE_INFINITY && bValue === Number.NEGATIVE_INFINITY) return 0;
    if (aValue === Number.NEGATIVE_INFINITY) return 1;
    if (bValue === Number.NEGATIVE_INFINITY) return -1;

    // For numeric values (including timestamps from dates), use direct comparison
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    }

    // If they're not both numbers but could be parsed as numbers, parse and compare
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // Check for ISO date format first
      const aDate = new Date(aValue);
      const bDate = new Date(bValue);
      if (isValid(aDate) && isValid(bDate)) {
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      }

      // Try numeric comparison if not dates
      const aNum = parseFloat(aValue.replace(/[,\s]/g, ''));
      const bNum = parseFloat(bValue.replace(/[,\s]/g, ''));
      if (!isNaN(aNum) && !isNaN(bNum)) {
        return sortOrder === 'asc' ? aNum - bNum : bNum - aNum;
      }
    }

    // Default to string comparison
    const aString = String(aValue).toLowerCase();
    const bString = String(bValue).toLowerCase();
    return sortOrder === 'asc' ? 
      aString.localeCompare(bString) : 
      bString.localeCompare(aString);
  });
};