// src/components/UserRoleAssignment.js
import React, { useState, useEffect } from 'react';
import { db, functions } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Loader2, AlertCircle, Save, Check, X } from 'lucide-react';
import Card from './Card';
import UserEmailSelect from './UserEmailSelect';
import { useAuth } from '../contexts/AuthContext'; // Add this import

const UserRoleAssignment = () => {
  const { currentUser } = useAuth(); // Add this line
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [removingRole, setRemovingRole] = useState({ processing: false, userEmail: null, roleId: null });

  // Fetch roles and users
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [rolesSnapshot, usersSnapshot] = await Promise.all([
          getDocs(collection(db, 'roles')),
          getDocs(collection(db, 'registeredUsersPreferences'))
        ]);

        const rolesData = rolesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const usersData = usersSnapshot.docs.map(doc => ({
          email: doc.id,
          ...doc.data(),
          roles: doc.data().roles || []
        }));

        setRoles(rolesData);
        setUsers(usersData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch roles and users');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(null);

      const assignUserRoles = httpsCallable(functions, 'assignUserRoles');
      const result = await assignUserRoles({
        userEmails: selectedUsers,
        roleIds: selectedRoles,
        adminEmail: currentUser.email // Use current user's email
      });

      if (result.data.success) {
        // Refresh the users data to show updated roles
        const usersSnapshot = await getDocs(collection(db, 'registeredUsersPreferences'));
        const updatedUsersData = usersSnapshot.docs.map(doc => ({
          email: doc.id,
          ...doc.data(),
          roles: doc.data().roles || []
        }));
        
        setUsers(updatedUsersData);
        setSuccess('Roles assigned successfully');
        setSelectedUsers([]);
        setSelectedRoles([]);

        setTimeout(() => {
          setSuccess(null);
        }, 3000);
      } else {
        throw new Error(result.data.error || 'Failed to assign roles');
      }
    } catch (err) {
      console.error('Error assigning roles:', err);
      setError(err.message || 'Failed to assign roles');
    } finally {
      setSaving(false);
    }
  };

  const handleRemoveRole = async (userEmail, roleId) => {
    try {
      setRemovingRole({ processing: true, userEmail, roleId });
      setError(null);

      const removeUserRole = httpsCallable(functions, 'removeUserRole');
      const result = await removeUserRole({
        userEmail,
        roleId,
        adminEmail: currentUser.email // Use current user's email
      });

      if (result.data.success) {
        // Update local state to reflect the role removal
        setUsers(prev => prev.map(user => {
          if (user.email === userEmail) {
            return {
              ...user,
              roles: user.roles.filter(id => id !== roleId)
            };
          }
          return user;
        }));

        setSuccess('Role removed successfully');
        setTimeout(() => {
          setSuccess(null);
        }, 3000);
      } else {
        throw new Error(result.data.error || 'Failed to remove role');
      }
    } catch (err) {
      console.error('Error removing role:', err);
      setError(err.message || 'Failed to remove role');
    } finally {
      setRemovingRole({ processing: false, userEmail: null, roleId: null });
    }
  };

  const getUserRoles = (userEmail) => {
    const user = users.find(u => u.email === userEmail);
    if (!user?.roles?.length) return 'No roles assigned';
    
    return roles
      .filter(role => user.roles.includes(role.id))
      .map(role => role.name)
      .join(', ');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4">Assign Roles to Users</h2>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Select Users
              </label>
              <div className="space-y-2">
                <div className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id="selectAllUsers"
                    checked={selectedUsers.includes('*ALL_USERS*')}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedUsers(['*ALL_USERS*']);
                      } else {
                        setSelectedUsers([]);
                      }
                    }}
                    className="h-4 w-4 text-primary border-gray-300 rounded"
                  />
                  <label htmlFor="selectAllUsers" className="ml-2 text-sm text-gray-600">
                    Select All Users
                  </label>
                </div>
                {!selectedUsers.includes('*ALL_USERS*') && (
                  <UserEmailSelect
                    value={selectedUsers}
                    onChange={setSelectedUsers}
                    placeholder="Select users to assign roles"
                  />
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Select Roles to Assign
              </label>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                {roles.map(role => (
                  <div
                    key={role.id}
                    className={`border rounded-md p-4 cursor-pointer transition-colors ${
                      selectedRoles.includes(role.id)
                        ? 'border-primary bg-primary bg-opacity-5'
                        : 'border-gray-200 hover:border-primary'
                    }`}
                    onClick={() => {
                      setSelectedRoles(prev =>
                        prev.includes(role.id)
                          ? prev.filter(id => id !== role.id)
                          : [...prev, role.id]
                      );
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-medium">{role.name}</span>
                      {selectedRoles.includes(role.id) && (
                        <Check className="h-5 w-5 text-primary" />
                      )}
                    </div>
                    <p className="text-sm text-gray-500 mt-1">
                      {role.policies?.length || 0} {role.policies?.length === 1 ? 'policy' : 'policies'}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleSave}
                disabled={saving || selectedUsers.length === 0 || selectedRoles.length === 0}
                className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-md
                  ${saving || selectedUsers.length === 0 || selectedRoles.length === 0
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-primary hover:bg-primary/90'
                  }`}
              >
                {saving ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4 mr-2" />
                    Assign Roles
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Card>

      {error && (
        <div className="flex items-center space-x-2 text-red-500 p-4">
          <AlertCircle className="h-5 w-5" />
          <p>{error}</p>
        </div>
      )}

      {success && (
        <div className="flex items-center space-x-2 text-green-500 p-4">
          <Check className="h-5 w-5" />
          <p>{success}</p>
        </div>
      )}

      {/* Current Assignments */}
      <Card>
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4">Current Role Assignments</h2>
          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Assigned Roles
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.email} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {user.email}
                          </div>
                          {user.englishName && (
                            <div className="text-sm text-gray-500">
                              {user.englishName}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-2">
                        {!user.roles?.length ? (
                          <span className="text-sm text-gray-500">No roles assigned</span>
                        ) : (
                          user.roles.map(roleId => {
                            const role = roles.find(r => r.id === roleId);
                            if (!role) return null;
                            
                            return (
                              <div
                                key={roleId}
                                className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-gray-100 text-sm text-gray-800"
                              >
                                <span>{role.name}</span>
                                <button
                                  onClick={() => handleRemoveRole(user.email, roleId)}
                                  disabled={removingRole.processing && removingRole.userEmail === user.email && removingRole.roleId === roleId}
                                  className="text-gray-500 hover:text-red-500 focus:outline-none"
                                  title="Remove role"
                                >
                                  {removingRole.processing && removingRole.userEmail === user.email && removingRole.roleId === roleId ? (
                                    <Loader2 className="h-3 w-3 animate-spin" />
                                  ) : (
                                    <X className="h-3 w-3" />
                                  )}
                                </button>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      {/* Add any additional actions here */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UserRoleAssignment;