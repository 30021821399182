import React, { useState } from 'react';
import { Star } from 'lucide-react';
import { getFirestore, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import Button from '../Button';

const RATING_CATEGORIES = [
  { id: 'queryLogic', label: 'Query Logic' },
  { id: 'explanation', label: 'Explanation' },
  { id: 'investigation', label: 'Investigation' }
];

const RatingButton = ({ documentId, initialRatings = {}, initialComments = {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ratings, setRatings] = useState({
    queryLogic: initialRatings.queryLogic || 0,
    explanation: initialRatings.explanation || 0,
    investigation: initialRatings.investigation || 0
  });
  const [comments, setComments] = useState({
    queryLogic: initialComments.queryLogic || '',
    explanation: initialComments.explanation || '',
    investigation: initialComments.investigation || ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { currentUser, isAdmin } = useAuth();
  const db = getFirestore();

  const handleSubmit = async () => {
    if (!isAdmin) {
      setError('Only administrators can rate queries.');
      return;
    }

    // Check if at least one category has a rating
    if (!Object.values(ratings).some(rating => rating > 0)) {
      setError('Please provide at least one rating.');
      return;
    }
    
    setIsSubmitting(true);
    setError('');
    try {
      const docRef = doc(db, 'LLM Queries', documentId);
      
      // Ensure all ratings are numbers and within valid range
      const validatedRatings = Object.fromEntries(
        Object.entries(ratings).map(([key, value]) => [key, Math.max(0, Math.min(10, Number(value) || 0))])
      );

      await updateDoc(docRef, {
        ratings: validatedRatings,
        ratingComments: comments,
        ratedAt: serverTimestamp(),
        ratedBy: currentUser.email
      });

      setIsModalOpen(false);
    } catch (error) {
      console.error('Error updating rating:', error);
      setError(error.message === 'Missing or insufficient permissions' 
        ? 'You do not have permission to rate queries.' 
        : 'Failed to save rating. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAverageRating = () => {
    const validRatings = Object.values(ratings).filter(r => r > 0);
    if (validRatings.length === 0) return 0;
    return Math.round(validRatings.reduce((a, b) => a + b, 0) / validRatings.length);
  };

  const handleRatingChange = (category, value) => {
    setRatings(prev => ({
      ...prev,
      [category]: value
    }));
    setError(''); // Clear any existing errors when user makes changes
  };

  const handleCommentChange = (category, value) => {
    setComments(prev => ({
      ...prev,
      [category]: value
    }));
  };

  if (!isAdmin) return null;

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        icon={Star}
        className={`p-2 ${getAverageRating() ? 'text-yellow-500' : ''}`}
        title={getAverageRating() ? `Average Rating: ${getAverageRating()}/10` : 'Rate Query Result'}
      />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-xl w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">Rate Query Result</h3>
            
            {error && (
              <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-600 text-sm">
                {error}
              </div>
            )}

            <div className="space-y-6">
              {RATING_CATEGORIES.map(category => (
                <div key={category.id} className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    {category.label} Rating (1-10)
                  </label>
                  <div className="flex items-center gap-2">
                    {[...Array(10)].map((_, index) => (
                      <button
                        key={index}
                        className={`w-8 h-8 rounded ${
                          ratings[category.id] >= index + 1
                            ? 'bg-yellow-500 text-white'
                            : 'bg-gray-100 text-gray-400'
                        } hover:bg-yellow-400 hover:text-white transition-colors`}
                        onClick={() => handleRatingChange(category.id, index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      value={comments[category.id]}
                      onChange={(e) => handleCommentChange(category.id, e.target.value)}
                      maxLength={100}
                      placeholder={`Comment about ${category.label.toLowerCase()} (Optional)`}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setError('');
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={!Object.values(ratings).some(rating => rating > 0) || isSubmitting}
                className={`px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 disabled:opacity-50 disabled:cursor-not-allowed ${
                  isSubmitting ? 'cursor-wait' : ''
                }`}
              >
                {isSubmitting ? 'Saving...' : 'Save Rating'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RatingButton;