// src/components/Docs/BatchEmbeddingProgress.js
import React from 'react';
import { Loader2 } from 'lucide-react';

const BatchEmbeddingProgress = () => {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between text-sm text-gray-600">
        <div className="flex items-center gap-2">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span>Processing document...</span>
        </div>
      </div>

      {/* Progress bar */}
      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
        <div 
          className="h-full transition-all duration-500 bg-primary"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default BatchEmbeddingProgress;