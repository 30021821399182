// src/components/Docs/AutoCategorizeProgress.js
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { Loader2 } from 'lucide-react';

const AutoCategorizeProgress = ({ batchId, onProgressUpdate }) => {
  const [progress, setProgress] = useState({
    totalDocuments: 0,
    processedDocuments: 0,
    failedDocuments: 0,
    noCategoriesDocuments: 0,
    status: 'initializing',
    progress: 0,
    results: [],
    errors: []
  });

  useEffect(() => {
    if (!batchId) return;

    const firestore = getFirestore();
    const batchRef = doc(firestore, 'batchCategorization', batchId);

    const unsubscribe = onSnapshot(batchRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        
        // Ensure results and errors are arrays
        const results = Array.isArray(data.results) ? data.results : [];
        const errors = Array.isArray(data.errors) ? data.errors : [];
        
        // Calculate total processed documents
        const processedTotal = (data.processedDocuments || 0) + 
                             (data.failedDocuments || 0) + 
                             (data.noCategoriesDocuments || 0);
        
        // Calculate progress percentage
        const progressPercentage = data.totalDocuments > 0 
          ? Math.min(Math.round((processedTotal / data.totalDocuments) * 100), 100)
          : 0;

        const updatedProgress = {
          totalDocuments: data.totalDocuments || 0,
          processedDocuments: data.processedDocuments || 0,
          failedDocuments: data.failedDocuments || 0,
          noCategoriesDocuments: data.noCategoriesDocuments || 0,
          status: data.status || 'initializing',
          progress: progressPercentage,
          results: results.map(result => ({
            ...result,
            suggestedCategories: Array.isArray(result.suggestedCategories) 
              ? result.suggestedCategories.map(cat => ({
                  category: typeof cat === 'string' ? cat : cat?.category || '',
                  quote: typeof cat === 'string' ? '' : cat?.quote || ''
                }))
              : []
          })),
          errors: errors
        };

        setProgress(updatedProgress);
        
        // Notify parent component of progress updates if callback provided
        if (onProgressUpdate) {
          onProgressUpdate(updatedProgress);
        }
      }
    }, (error) => {
      console.error('Error listening to batch progress:', error);
    });

    return () => unsubscribe();
  }, [batchId, onProgressUpdate]);

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between text-sm text-gray-600">
        <div className="flex items-center gap-2">
          {progress.status !== 'completed' && (
            <Loader2 className="h-4 w-4 animate-spin" />
          )}
          <span>
            {progress.status === 'initializing' ? 'Initializing...' :
             progress.status === 'in_progress' ? 'Processing documents...' :
             progress.status === 'completed' ? 'Processing complete' :
             'Processing failed'}
          </span>
        </div>
        <span>
          {progress.processedDocuments} / {progress.totalDocuments} documents
          {progress.failedDocuments > 0 && ` (${progress.failedDocuments} failed)`}
          {progress.noCategoriesDocuments > 0 && ` (${progress.noCategoriesDocuments} no categories)`}
        </span>
      </div>

      {/* Progress bar */}
      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
        <div 
          className={`h-full transition-all duration-500 ${
            progress.status === 'completed' ? 'bg-green-500' : 'bg-primary'
          }`}
          style={{ width: `${progress.progress}%` }}
        />
      </div>
    </div>
  );
};

export default AutoCategorizeProgress;