import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Info } from 'lucide-react';
import { createPortal } from 'react-dom';

// Helper function to detect if text is primarily Hebrew/RTL
const isRtlText = (text) => {
  if (!text) return false;
  
  // Hebrew Unicode range (0x0590-0x05FF)
  // Arabic Unicode range (0x0600-0x06FF)
  const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/g;
  const rtlMatches = text.match(rtlPattern) || [];
  
  // If more than 40% of characters are RTL, consider it RTL text
  return rtlMatches.length > text.length * 0.4;
};

const ColumnExplanationTooltip = ({ columnName, explanation, example }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  
  // Detect if content is primarily RTL
  const isRtl = useMemo(() => {
    return isRtlText(explanation) || isRtlText(example);
  }, [explanation, example]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) &&
          triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    const updatePosition = () => {
      if (triggerRef.current && isVisible) {
        const rect = triggerRef.current.getBoundingClientRect();
        const tooltipWidth = 200; // min-width from CSS
        
        // Calculate initial position
        let left = rect.left + window.scrollX - (tooltipWidth / 2);
        let top = rect.bottom + window.scrollY + 8;

        // Adjust for viewport boundaries
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        
        // Prevent tooltip from going off-screen left
        if (left < 8) {
          left = 8;
        }
        
        // Prevent tooltip from going off-screen right
        if (left + tooltipWidth > viewportWidth - 8) {
          left = viewportWidth - tooltipWidth - 8;
        }

        // If tooltip would go off bottom of screen, show it above the trigger
        if (top + 200 > viewportHeight) { // 200 is approximate max height
          top = rect.top + window.scrollY - 8;
          if (tooltipRef.current) {
            top -= tooltipRef.current.offsetHeight;
          }
        }

        setPosition({ top, left });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    if (isVisible) {
      updatePosition();
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isVisible]);

  if (!explanation && !example) return null;

  // RTL translations for labels
  const translations = {
    explanation: {
      en: "Explanation",
      he: "הסבר"
    },
    example: {
      en: "Example",
      he: "דוגמה"
    }
  };

  const tooltipContent = (
    <div 
      ref={tooltipRef}
      className="fixed bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-[9999]"
      style={{ 
        top: position.top,
        left: position.left,
        minWidth: '200px',
        maxWidth: '300px',
        direction: isRtl ? 'rtl' : 'ltr'
      }}
    >
      {explanation && (
        <div className="mb-3">
          <h3 className="text-sm font-medium text-gray-600 mb-1">
            {isRtl ? translations.explanation.he : translations.explanation.en}
          </h3>
          <p className="text-sm text-gray-700 leading-relaxed">
            {explanation}
          </p>
        </div>
      )}
      
      {example && (
        <div>
          <h3 className="text-sm font-medium text-gray-600 mb-1">
            {isRtl ? translations.example.he : translations.example.en}
          </h3>
          <p className="text-sm text-gray-700 leading-relaxed">
            {example}
          </p>
        </div>
      )}
      
      {/* Arrow */}
      <div 
        className="absolute w-3 h-3 bg-white border-t border-l border-gray-200 transform rotate-45"
        style={{
          top: position.top > tooltipRef.current?.getBoundingClientRect().top ? 'auto' : '-6px',
          bottom: position.top > tooltipRef.current?.getBoundingClientRect().top ? '-6px' : 'auto',
          left: '50%',
          marginLeft: '-6px'
        }}
      />
    </div>
  );

  return (
    <div className="relative inline-block">
      <div 
        ref={triggerRef}
        className="p-1 rounded hover:bg-blue-100 transition-all duration-300 ml-1 cursor-help"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        aria-label="Show column explanation"
      >
        <Info size={14} className="text-blue-500" />
      </div>
      
      {isVisible && createPortal(tooltipContent, document.body)}
    </div>
  );
};

export default ColumnExplanationTooltip;