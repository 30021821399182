import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import TextEditor from './TextEditor';
import { db, functions } from '../firebase';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { Loader2, AlertCircle, RefreshCw, ChevronDown, ChevronRight, Trash2, Info } from 'lucide-react';
import { httpsCallable } from 'firebase/functions';
import { convertMarkdownToHtml, convertHtmlToMarkdown } from './markdownConverter';
import TableActions from './TableActions';
import IndexManagement from './IndexManagement';
import TrackingManagement from './TrackingManagement';
import TableAccessInfo from './TableAccessInfo';
import { useConfig } from '../contexts/ConfigContext';
import PopupCard from '../components/PopupCard';





const SchemaManagement = () => {
  // Existing state variables
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const [editingPrompt, setEditingPrompt] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ tableId: null, type: '', message: '' });
  const [autoChecking, setAutoChecking] = useState(false);
  const tableActionsRef = useRef();
  const [updatingDescription, setUpdatingDescription] = useState(false);
  const [editingTableId, setEditingTableId] = useState(null);
  const [pendingDescriptionChanges, setPendingDescriptionChanges] = useState({});
  const { config } = useConfig();
  const [policies, setPolicies] = useState({});
  const [tableFilter, setTableFilter] = useState('all'); // Options: 'all', 'full', 'restricted'
  const [isTableAccessModalOpen, setIsTableAccessModalOpen] = useState(false);

  const handleDelete = (table) => {
    // Get reference to the TableActions element using ref
    if (tableActionsRef.current) {
      tableActionsRef.current.handleDeleteClick(table);
    }
  };
  
  // Existing functions (fetchTableSchema, checkSchemaChanges, etc.)
  const fetchTableSchema = async (tableId) => {
    try {
      setRefreshing(prev => ({ ...prev, [tableId]: true }));
      
      const getTableSchema = httpsCallable(functions, 'getTableSchema');
      const result = await getTableSchema({ tableId });
      
      return {
        schema: result.data.schema,
        schemaHash: result.data.schemaHash
      };
    } catch (error) {
      console.error(`Error fetching schema for ${tableId}:`, error);
      throw error;
    } finally {
      setRefreshing(prev => ({ ...prev, [tableId]: false }));
    }
  };

  const fetchPolicies = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'policies'));
      const policiesMap = {};
      querySnapshot.docs.forEach(doc => {
        policiesMap[doc.id] = doc.data();
      });
      setPolicies(policiesMap);
    } catch (err) {
      console.error('Error fetching policies:', err);
    }
  };


  const checkSchemaChanges = async (tableId, currentSchemaHash) => {
    try {
      setAutoChecking(true);
      const { schemaHash: newSchemaHash } = await fetchTableSchema(tableId);
      
      if (newSchemaHash !== currentSchemaHash) {
        // Schema has changed
        setSaveStatus({ 
          tableId, 
          type: 'warning', 
          message: 'Schema changes detected! Click refresh to update.' 
        });
      }

      // Update last check timestamp in Firestore
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const tableName = tables.find(t => t.id === tableId)?.name;
      
      if (tableName) {
        await updateDoc(configDoc, {
          [`${tableName}.lastSchemaCheck`]: new Date().toISOString()
        });
      }
    } catch (err) {
      console.error('Error checking schema changes:', err);
    } finally {
      setAutoChecking(false);
    }
  };

  const fetchTables = async () => {
    try {
      setLoading(true);
      setError(null);
  
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const docSnap = await getDoc(configDoc);
      
      if (!docSnap.exists()) {
        console.log('No document found at appConfiguration/tables');
        setError('No tables configuration found');
        return;
      }
  
      const docData = docSnap.data();
      console.log('Document data:', docData);
  
      const tablesData = Object.entries(docData)
      .filter(([_, value]) => typeof value === 'object' && value !== null)
      .map(([name, tableData]) => ({
        name,
        id: tableData.id || '',
        prompt: convertMarkdownToHtml(tableData.prompt || ''),
        schema: tableData.schema || [],
        schemaHash: tableData.schemaHash || '',
        lastSchemaCheck: tableData.lastSchemaCheck || null,
        dataSample: tableData.dataSample || [],
        isMaterializedView: tableData.isMaterializedView || false,
        motherTable: tableData.motherTable || '',
        policyId: tableData.policyId || ''
      }));
  
      setTables(tablesData);
  
      // Check for schema changes if last check was more than 24 hours ago
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
  
      for (const table of tablesData) {
        if (table.id) {
          const shouldCheckSchema = !table.lastSchemaCheck || 
                                  new Date(table.lastSchemaCheck) < twentyFourHoursAgo ||
                                  !table.schema || 
                                  table.schema.length === 0;
  
          if (shouldCheckSchema) {
            try {
              const getTableSchema = httpsCallable(functions, 'getTableSchema');
              const result = await getTableSchema({ tableId: table.id });
              const { schema, schemaHash, dataSample } = result.data;
              
              // Update state
              setTables(currentTables => 
                currentTables.map(t => 
                  t.id === table.id ? { 
                    ...t, 
                    schema,
                    schemaHash,
                    dataSample: dataSample || [], // Ensure dataSample is never undefined
                    lastSchemaCheck: new Date().toISOString()
                  } : t
                )
              );
  
              // Prepare update data
              const updateData = {
                [`${table.name}.schema`]: schema,
                [`${table.name}.schemaHash`]: schemaHash,
                [`${table.name}.lastSchemaCheck`]: new Date().toISOString()
              };
  
              // Only include dataSample if it exists and has data
              if (dataSample && dataSample.length > 0) {
                updateData[`${table.name}.dataSample`] = dataSample;
              }
  
              // Update Firestore
              await updateDoc(configDoc, updateData);
  
              // If schema hash is different, show warning
              if (schemaHash !== table.schemaHash) {
                setSaveStatus({ 
                  tableId: table.id, 
                  type: 'warning', 
                  message: 'Schema changes detected! Click refresh to update.' 
                });
              }
            } catch (err) {
              console.error(`Error fetching schema for ${table.id}:`, err);
              setTables(currentTables =>
                currentTables.map(t =>
                  t.id === table.id ? { ...t, schemaError: err.message } : t
                )
              );
            }
          }
        }
      }
    } catch (err) {
      console.error('Error fetching tables:', err);
      setError(err.message || 'Failed to fetch tables data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  fetchTables();
  fetchPolicies();
}, []);

  const handleRefreshSchema = async (tableId) => {
    try {
      setSaveStatus({ tableId, type: 'loading', message: 'Refreshing schema...' });
      const getTableSchema = httpsCallable(functions, 'getTableSchema');
      const result = await getTableSchema({ tableId });
      const { schema, schemaHash, dataSample } = result.data;
      
      // Update state
      setTables(currentTables =>
        currentTables.map(t =>
          t.id === tableId ? { 
            ...t, 
            schema, 
            schemaHash,
            dataSample: dataSample || [], // Ensure dataSample is never undefined
            schemaError: null,
            lastSchemaCheck: new Date().toISOString()
          } : t
        )
      );
  
      // Find the table name from the current tables
      const tableName = tables.find(t => t.id === tableId)?.name;
      if (!tableName) {
        throw new Error('Table configuration not found');
      }
  
      // Save to Firestore with safe values
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const updateData = {
        [`${tableName}.schema`]: schema || [],
        [`${tableName}.schemaHash`]: schemaHash || '',
        [`${tableName}.lastSchemaCheck`]: new Date().toISOString()
      };
  
      // Only include dataSample if it exists and has data
      if (dataSample && dataSample.length > 0) {
        updateData[`${tableName}.dataSample`] = dataSample;
      }
  
      await updateDoc(configDoc, updateData);
  
      setSaveStatus({ tableId, type: 'success', message: 'Schema updated successfully!' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
    } catch (err) {
      console.error(`Error refreshing schema for ${tableId}:`, err);
      setSaveStatus({ tableId, type: 'error', message: 'Failed to update schema' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      setTables(currentTables =>
        currentTables.map(t =>
          t.id === tableId ? { ...t, schemaError: err.message } : t
        )
      );
    }
  };

// ADD these new functions
const handleStartEditing = (tableId, fieldName, currentDescription) => {
  setEditingTableId(tableId);
  setPendingDescriptionChanges(prev => ({
    ...prev,
    [fieldName]: currentDescription || ''
  }));
};

const handleDescriptionChange = (fieldName, newValue) => {
  setPendingDescriptionChanges(prev => ({
    ...prev,
    [fieldName]: newValue
  }));
};

const handleCancelEdits = () => {
  setEditingTableId(null);
  setPendingDescriptionChanges({});
};

const handleSaveAllDescriptions = async (table) => {
  try {
    setUpdatingDescription(true);
    
    const updates = Object.entries(pendingDescriptionChanges).map(([fieldName, newDesc]) => ({
      tableId: table.id,
      columnName: fieldName,
      description: newDesc
    }));

    const response = await fetch(
      `https://us-central1-${config.core.projectId}.cloudfunctions.net/updateColumnDescription`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            updates: updates
          }
        })
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update descriptions');
    }

    setTables(currentTables =>
      currentTables.map(t =>
        t.id === table.id
          ? {
              ...t,
              schema: t.schema.map(f => ({
                ...f,
                description: pendingDescriptionChanges[f.name] !== undefined
                  ? pendingDescriptionChanges[f.name]
                  : f.description
              }))
            }
          : t
      )
    );

    setEditingTableId(null);
    setPendingDescriptionChanges({});
    setSaveStatus({
      tableId: table.id,
      type: 'success',
      message: 'Descriptions updated successfully'
    });
    setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);

  } catch (error) {
    console.error('Error updating descriptions:', error);
    setSaveStatus({
      tableId: table.id,
      type: 'error',
      message: 'Failed to update descriptions'
    });
  } finally {
    setUpdatingDescription(false);
  }
};

  const handleSavePrompt = async (tableId, content) => {
    try {
      setSaveStatus({ tableId, type: 'loading', message: 'Saving...' });
      
      const configDoc = doc(db, 'appConfiguration', 'tables');
      const docSnap = await getDoc(configDoc);
      
      if (!docSnap.exists()) {
        throw new Error('Tables configuration not found');
      }
      
      const docData = docSnap.data();
      const tableData = docData[tables.find(t => t.id === tableId).name];
      
      if (!tableData) {
        throw new Error('Table configuration not found');
      }
      
      const markdownContent = convertHtmlToMarkdown(content);
      
      await updateDoc(configDoc, {
        [`${tables.find(t => t.id === tableId).name}.prompt`]: markdownContent
      });
      
      setTables(currentTables =>
        currentTables.map(t =>
          t.id === tableId ? { ...t, prompt: content } : t
        )
      );
      
      setSaveStatus({ tableId, type: 'success', message: 'Saved successfully!' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      setEditingPrompt(null);
    } catch (err) {
      console.error('Error saving prompt:', err);
      setSaveStatus({ tableId, type: 'error', message: 'Failed to save changes' });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
    }
  };

  const toggleSection = (tableId, section) => {
    if (section === 'prompt') {
      setEditingPrompt(editingPrompt === tableId ? null : tableId);
    }
    setExpandedSections(prev => ({
      ...prev,
      [`${tableId}-${section}`]: !prev[`${tableId}-${section}`]
    }));
  };


  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        <h3 className="font-semibold mb-2">Error loading tables</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <TableActions 
        ref={tableActionsRef}
        tables={tables}
        setTables={setTables}
        setSaveStatus={setSaveStatus}
      />
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center space-x-6">
          <div className="text-sm font-medium text-gray-700">Filter Tables:</div>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-primary"
                name="table-filter"
                value="all"
                checked={tableFilter === 'all'}
                onChange={(e) => setTableFilter(e.target.value)}
              />
              <span className="ml-2 text-sm text-gray-700">All Tables</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-primary"
                name="table-filter"
                value="full"
                checked={tableFilter === 'full'}
                onChange={(e) => setTableFilter(e.target.value)}
              />
              <span className="ml-2 text-sm text-gray-700">Full Access</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-primary"
                name="table-filter"
                value="restricted"
                checked={tableFilter === 'restricted'}
                onChange={(e) => setTableFilter(e.target.value)}
              />
              <span className="ml-2 text-sm text-gray-700">Restricted Access</span>
            </label>
          </div>
        </div>
        <button
          onClick={() => setIsTableAccessModalOpen(true)}
          className="inline-flex items-center px-3 py-1.5 text-sm text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
        >
          <Info className="h-4 w-4 mr-1.5" />
          <span>Available Data</span>
        </button>
        <PopupCard
          isOpen={isTableAccessModalOpen}
          onClose={() => setIsTableAccessModalOpen(false)}
          contentClassName="w-11/12 max-w-4xl"
        >
          <TableAccessInfo />
        </PopupCard>
      </div>
      {tables.length === 0 ? (
        <Card>
          <div className="p-6">
            <p className="text-gray-700">No tables configuration found.</p>
          </div>
        </Card>
      ) : (
        tables
          .filter(table => {
            if (tableFilter === 'all') return true;
            if (tableFilter === 'restricted') return table.isMaterializedView;
            if (tableFilter === 'full') return !table.isMaterializedView;
            return true;
          })
          .map((table) => (
          <Card key={table.name}>
            {/* Fixed Header Section */}
            <div className="p-4 border-b border-gray-200 sticky top-0 bg-white z-10">
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <div className="flex items-center gap-3 mb-1">
                    <h3 className="text-card-title-base">{table.name}</h3>
                    {table.id && (
                      <div className="text-xs bg-opacity-20 bg-primary text-primary px-2 py-0.5 rounded-tag">
                        {table.id}
                      </div>
                    )}
                  </div>
                  {/* Add restricted access tag and mother table info */}
                  {table.isMaterializedView && (
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center gap-2">
                        <span className="text-xs bg-accent text-white px-2 py-0.5 rounded-tag">
                          Restricted Access
                        </span>
                        {table.policyId && policies[table.policyId] && (
                          <span className="text-xs text-text-light">
                            Policy: {policies[table.policyId].name}
                          </span>
                        )}
                      </div>
                      <div className="text-xs text-text-light">
                        Mother table: {table.motherTable}
                      </div>
                    </div>
                  )}
                </div>
                {table.id && (
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleRefreshSchema(table.id)}
                      disabled={refreshing[table.id]}
                      className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                      title="Refresh schema"
                    >
                      <RefreshCw 
                        className={`h-5 w-5 ${refreshing[table.id] ? 'animate-spin' : ''}`}
                      />
                    </button>
                    <button
                      onClick={() => handleDelete(table)}
                      className="p-2 text-gray-500 hover:text-red-600 focus:outline-none"
                      title="Delete table"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>
              {saveStatus.tableId === table.id && saveStatus.message && (
                <div className={`mt-2 text-sm ${
                  saveStatus.type === 'success' ? 'text-green-600' :
                  saveStatus.type === 'error' ? 'text-red-600' :
                  'text-blue-600'
                }`}>
                  {saveStatus.message}
                </div>
              )}
            </div>
  
            <div className="p-6">
              {/* Prompt Section */}
                <div className="mb-6">
                  <button
                    onClick={() => toggleSection(table.id, 'prompt')}
                    className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                  >
                    {editingPrompt === table.id ? (
                      <ChevronDown className="h-4 w-4 mr-1" />
                    ) : (
                      <ChevronRight className="h-4 w-4 mr-1" />
                    )}
                    Prompt
                  </button>
                  {editingPrompt === table.id ? (
                    <div className="mb-4">
                      <TextEditor
                        title=""
                        initialContent={table.prompt}
                        onSave={(content) => handleSavePrompt(table.id, content)}
                        onCancel={() => {
                          setEditingPrompt(null);
                          setSaveStatus({ tableId: null, type: '', message: '' });
                        }}
                        height="h-[240px]"
                      />
                    </div>
                  ) : (
                    expandedSections[`${table.id}-prompt`] && (
                      <div
                        className="text-text-lighter text-sm whitespace-pre-wrap prose prose-sm max-w-none"
                        dangerouslySetInnerHTML={{ __html: table.prompt }}
                      />
                    )
                  )}
                </div>
              {/* Schema Section */}
              <div className="mb-6">
                <button
                  onClick={() => toggleSection(table.id, 'schema')}
                  className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                >
                  {expandedSections[`${table.id}-schema`] ? (
                    <ChevronDown className="h-4 w-4 mr-1" />
                  ) : (
                    <ChevronRight className="h-4 w-4 mr-1" />
                  )}
                  Schema
                </button>
                {expandedSections[`${table.id}-schema`] && (
                  <>
                    {table.schemaError ? (
                      <div className="flex items-center space-x-2 text-red-500 mb-4">
                        <AlertCircle className="h-5 w-5" />
                        <p className="text-sm">{table.schemaError}</p>
                      </div>
                    ) : Array.isArray(table.schema) && table.schema.length > 0 ? (
                      <div>
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                  Column Name
                                </th>
                                <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                  Type
                                </th>
                                <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                  Mode
                                </th>
                                <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {table.schema.map((field, index) => (
                                <tr key={index} className="hover:bg-gray-50 transition-colors">
                                  <td className="px-4 py-3 text-sm font-medium text-gray-900">
                                    {field.name}
                                  </td>
                                  <td className="px-4 py-3 text-sm text-gray-600">
                                    {field.type}
                                  </td>
                                  <td className="px-4 py-3 text-sm text-gray-600">
                                    {field.mode}
                                  </td>
                                  <td className="px-4 py-3 text-sm text-gray-600">
                                    {editingTableId === table.id ? (
                                      <input
                                        type="text"
                                        value={pendingDescriptionChanges[field.name] ?? field.description ?? ''}
                                        onChange={(e) => handleDescriptionChange(field.name, e.target.value)}
                                        className="flex-1 px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:border-primary w-full"
                                        placeholder="Enter description"
                                      />
                                    ) : (
                                      <div
                                        onClick={() => handleStartEditing(table.id, field.name, field.description)}
                                        className="cursor-pointer hover:text-primary group flex items-center"
                                      >
                                        <span className="group-hover:underline">
                                          {field.description || '-'}
                                        </span>
                                        <span className="ml-2 text-xs text-gray-400 opacity-0 group-hover:opacity-100">
                                          Click to edit
                                        </span>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {editingTableId === table.id && (
                          <div className="mt-4 flex justify-end space-x-3">
                            <button
                              onClick={handleCancelEdits}
                              disabled={updatingDescription}
                              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => handleSaveAllDescriptions(table)}
                              disabled={updatingDescription}
                              className="px-4 py-2 text-sm text-white bg-primary rounded hover:bg-primary/90 disabled:bg-gray-400 flex items-center space-x-2"
                            >
                              {updatingDescription ? (
                                <>
                                  <Loader2 className="h-4 w-4 animate-spin" />
                                  <span>Saving...</span>
                                </>
                              ) : (
                                <span>Save All Changes</span>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p className="text-text-light text-sm">
                        {refreshing[table.id] ? 'Loading schema...' : 'No schema information available'}
                      </p>
                    )}
                  </>
                )}
              </div>
  
              {/* Sample Data Section */}
              <div>
                <button
                  onClick={() => toggleSection(table.id, 'sample')}
                  className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                >
                  {expandedSections[`${table.id}-sample`] ? (
                    <ChevronDown className="h-4 w-4 mr-1" />
                  ) : (
                    <ChevronRight className="h-4 w-4 mr-1" />
                  )}
                  Sample Data
                </button>
                {expandedSections[`${table.id}-sample`] && (
                  <>
                    {table.dataSample && table.dataSample.length > 0 ? (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {/* Get column names from first row and store them */}
                              {(() => {
                                const columnNames = Object.keys(table.dataSample[0]).sort();
                                return columnNames.map((columnName) => (
                                  <th
                                    key={columnName}
                                    className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
                                  >
                                    {columnName}
                                  </th>
                                ));
                              })()}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {table.dataSample.map((row, rowIndex) => {
                              // Use the same column order for each row
                              const columnNames = Object.keys(table.dataSample[0]).sort();
                              return (
                                <tr key={rowIndex} className="hover:bg-gray-50 transition-colors">
                                  {columnNames.map((columnName) => (
                                    <td
                                      key={columnName}
                                      className="px-4 py-3 text-sm text-gray-600"
                                    >
                                      {row[columnName] === null ? 'NULL' : String(row[columnName])}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-text-light text-sm">
                        {refreshing[table.id] ? 'Loading sample data...' : 'No sample data available'}
                      </p>
                    )}
                  </>
                )}
              </div>
  
              {/* Indexing Section */}
              <div className="mt-6">
                <button
                  onClick={() => toggleSection(table.id, 'indexing')}
                  className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                >
                  {expandedSections[`${table.id}-indexing`] ? (
                    <ChevronDown className="h-4 w-4 mr-1" />
                  ) : (
                    <ChevronRight className="h-4 w-4 mr-1" />
                  )}
                  Indexing
                </button>
                {expandedSections[`${table.id}-indexing`] && (
                  <IndexManagement 
                    table={table}
                    onStatusUpdate={(status) => {
                      setSaveStatus({
                        tableId: table.id,
                        type: status.type,
                        message: status.message
                      });
                      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
                    }}
                  />
                )}
              </div>

              {/* News Tracking Section */}
              <div className="mt-6">
                <button
                  onClick={() => toggleSection(table.id, 'tracking')}
                  className="flex items-center text-base font-medium text-text-light mb-2 hover:text-text focus:outline-none"
                >
                  {expandedSections[`${table.id}-tracking`] ? (
                    <ChevronDown className="h-4 w-4 mr-1" />
                  ) : (
                    <ChevronRight className="h-4 w-4 mr-1" />
                  )}
                  News Tracking
                </button>
                {expandedSections[`${table.id}-tracking`] && (
                  <TrackingManagement 
                    table={table}
                    onStatusUpdate={(status) => {
                      setSaveStatus({
                        tableId: table.id,
                        type: status.type,
                        message: status.message
                      });
                      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
                    }}
                  />
                )}
              </div>
            </div>
          </Card>
        ))
      )}
    </div>
  );
};

export default SchemaManagement;
