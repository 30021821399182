import React, { useState, useEffect, useRef } from 'react';
import { X, Trash2, Edit, Save, Plus, Info, AlertTriangle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const UserProfileModal = ({ isOpen, onClose, userEmail }) => {
  const { saveUserPreferences } = useAuth();
  const [formData, setFormData] = useState({
    englishName: '',
    role: '',
    preferences: '',
    enablePersonalization: true,
    enableBigBro: true
  });
  const [originalFormData, setOriginalFormData] = useState({
    englishName: '',
    role: '',
    preferences: '',
    enablePersonalization: true,
    enableBigBro: true
  });
  const [learnedPreferences, setLearnedPreferences] = useState([]);
  const [originalLearnedPreferences, setOriginalLearnedPreferences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [editingPreference, setEditingPreference] = useState(null);
  const [newPreference, setNewPreference] = useState({ preference: '', description: '' });
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', userEmail);
        const docSnap = await getDoc(userPrefsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const newFormData = {
            englishName: data.englishName || '',
            role: data.role || '',
            preferences: data.preferences || '',
            enablePersonalization: data.hasOwnProperty('enablePersonalization') ? data.enablePersonalization : true,
            enableBigBro: data.hasOwnProperty('enableBigBro') ? data.enableBigBro : true
          };
          
          setFormData(newFormData);
          setOriginalFormData(newFormData);
          setCharCount(data.preferences?.length || 0);
          
          // Load learned preferences
          if (data.learnings && Array.isArray(data.learnings)) {
            setLearnedPreferences(data.learnings);
            setOriginalLearnedPreferences(JSON.parse(JSON.stringify(data.learnings))); // Deep copy
          }
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    if (isOpen && userEmail) {
      fetchUserPreferences();
      setEditingPreference(null);
      setIsAddingNew(false);
      setNewPreference({ preference: '', description: '' });
      setShowConfirmDialog(false);
    }
  }, [isOpen, userEmail]);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseAttempt();
    }
  };

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        handleCloseAttempt();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, formData, learnedPreferences]);

  const hasUnsavedChanges = () => {
    // Check if form data has changed
    if (formData.englishName !== originalFormData.englishName ||
        formData.role !== originalFormData.role ||
        formData.preferences !== originalFormData.preferences ||
        formData.enablePersonalization !== originalFormData.enablePersonalization ||
        formData.enableBigBro !== originalFormData.enableBigBro) {
      return true;
    }
    
    // Check if learned preferences have changed
    if (learnedPreferences.length !== originalLearnedPreferences.length) {
      return true;
    }
    
    // Check if any preference has been edited
    for (let i = 0; i < learnedPreferences.length; i++) {
      if (i >= originalLearnedPreferences.length) {
        return true;
      }
      if (learnedPreferences[i].preference !== originalLearnedPreferences[i].preference ||
          learnedPreferences[i].description !== originalLearnedPreferences[i].description) {
        return true;
      }
    }
    
    // Check if we're in the middle of adding a new preference
    if (isAddingNew && (newPreference.preference.trim() || newPreference.description.trim())) {
      return true;
    }
    
    return false;
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges()) {
      setShowConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.englishName.trim()) {
      newErrors.englishName = 'English name is required';
    }
    
    const sanitizedRole = formData.role.trim();
    if (!sanitizedRole) {
      newErrors.role = 'Role is required';
    } else if (sanitizedRole.length < 3) {
      newErrors.role = 'Role must be at least 3 characters long';
    } else if (!/^[\p{L}\s"״״]+$/u.test(sanitizedRole)) {
      newErrors.role = 'Role can only contain letters, spaces, and quotation marks';
    }

    if (formData.preferences && formData.preferences.length > 300) {
      newErrors.preferences = 'Preferences must not exceed 300 characters';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePreferencesChange = (e) => {
    const text = e.target.value;
    if (text.length <= 300) {
      setFormData(prev => ({ ...prev, preferences: text }));
      setCharCount(text.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    
    if (validateForm()) {
      setIsLoading(true);
      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', userEmail);
        
        const docSnap = await getDoc(userPrefsRef);
        const currentData = docSnap.exists() ? docSnap.data() : {};

        const updateData = {
          ...currentData,
          englishName: formData.englishName.trim(),
          role: formData.role.trim(),
          preferences: formData.preferences?.trim() || '',
          enablePersonalization: formData.enablePersonalization,
          enableBigBro: formData.enableBigBro,
          learnings: learnedPreferences,
          lastUpdated: new Date()
        };

        await setDoc(userPrefsRef, updateData, { merge: true });
        await saveUserPreferences(userEmail, updateData);
        
        // Update original data to reflect the saved state
        setOriginalFormData({...formData});
        setOriginalLearnedPreferences(JSON.parse(JSON.stringify(learnedPreferences)));
        
        setSuccessMessage('Profile updated successfully!');
        setTimeout(() => {
          setSuccessMessage('');
          onClose();
        }, 2000);
      } catch (error) {
        console.error('Error saving preferences:', error);
        setErrors({ submit: 'Failed to update profile. Please try again.' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEditPreference = (index) => {
    setEditingPreference(index);
  };

  const handleUpdatePreference = (index, field, value) => {
    const updatedPreferences = [...learnedPreferences];
    updatedPreferences[index] = {
      ...updatedPreferences[index],
      [field]: value,
      manuallyEdited: true, // Mark as manually edited
      lastEditDate: new Date()
    };
    setLearnedPreferences(updatedPreferences);
  };

  const handleSavePreference = (index) => {
    setEditingPreference(null);
  };

  const handleRemovePreference = (index) => {
    const updatedPreferences = learnedPreferences.filter((_, i) => i !== index);
    setLearnedPreferences(updatedPreferences);
  };

  const handleAddPreference = () => {
    if (newPreference.preference.trim() && newPreference.description.trim()) {
      setLearnedPreferences([
        ...learnedPreferences,
        {
          preference: newPreference.preference.trim(),
          description: newPreference.description.trim(),
          manuallyAdded: true,
          discoveredAt: new Date(),
          source: 'manual_entry'
        }
      ]);
      setNewPreference({ preference: '', description: '' });
      setIsAddingNew(false);
    }
  };

  if (!isOpen) return null;
  
  const inputClassName = (fieldError) => `
    w-full p-2 border rounded-md 
    ${fieldError ? 'border-red-500' : 'border-gray-300'}
    ${!formData.enablePersonalization ? 'opacity-60 bg-gray-50' : ''}
  `;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-[60]">
          <div className="absolute inset-0 bg-black bg-opacity-60" onClick={() => setShowConfirmDialog(false)}></div>
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full relative z-10 mx-4">
            <div className="flex items-start mb-4">
              <div className="flex-shrink-0 text-amber-500">
                <AlertTriangle className="h-6 w-6" />
              </div>
              <div className="ml-3">
                <h3 className="text-lg font-medium text-gray-900">Unsaved Changes</h3>
                <p className="mt-1 text-sm text-gray-500">
                  You have unsaved changes. Are you sure you want to close this dialog? Your changes will be lost.
                </p>
              </div>
            </div>
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setShowConfirmDialog(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowConfirmDialog(false);
                  onClose();
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Discard Changes
              </button>
            </div>
          </div>
        </div>
      )}

      <div ref={modalRef} className="bg-white rounded-lg shadow-xl w-full max-w-md relative max-h-[90vh] overflow-auto">
        <button
          onClick={handleCloseAttempt}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="h-5 w-5" />
        </button>

        <div className="bg-gray-100 px-6 py-4 rounded-t-lg">
          <h2 className="text-xl font-semibold text-gray-800">Profile Settings</h2>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-gray-600">
              Update your personal information
            </p>
            <div className="inline-flex">
              <span className="text-sm px-2 py-0.5 bg-gray-200 text-gray-600 rounded-full">
                {userEmail}
              </span>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            {/* Personalization Toggle moved to top */}
            <div className="flex items-center justify-between mb-6 pb-4 border-b">
              <div>
                <label className="text-sm font-medium text-gray-700">
                  Enable Personalization
                </label>
                <p className="text-xs text-gray-500 mt-1">
                  Allow Tango to personalize the analysis
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.enablePersonalization}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    enablePersonalization: e.target.checked 
                  }))}
                  className="sr-only peer"
                />
                <div className={`w-11 h-6 bg-gray-200 rounded-full peer 
                  peer-checked:after:translate-x-full peer-checked:after:border-white 
                  after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                  after:bg-white after:border-gray-300 after:border after:rounded-full 
                  after:h-5 after:w-5 after:transition-all ${
                    formData.enablePersonalization ? 'bg-secondary' : ''
                  }`}>
                </div>
              </label>
            </div>

            {/* BigBro Toggle */}
            <div className="flex items-center justify-between mb-6 pb-4 border-b">
              <div>
                <label className="text-sm font-medium text-gray-700">
                  Enable Query Analysis
                </label>
                <p className="text-xs text-gray-500 mt-1">
                  Allow Tango to analyze your queries for potential improvements
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.enableBigBro}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    enableBigBro: e.target.checked 
                  }))}
                  className="sr-only peer"
                />
                <div className={`w-11 h-6 bg-gray-200 rounded-full peer 
                  peer-checked:after:translate-x-full peer-checked:after:border-white 
                  after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                  after:bg-white after:border-gray-300 after:border after:rounded-full 
                  after:h-5 after:w-5 after:transition-all ${
                    formData.enableBigBro ? 'bg-secondary' : ''
                  }`}>
                </div>
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name
              </label>
              <input
                type="text"
                value={formData.englishName}
                onChange={(e) => setFormData(prev => ({ ...prev, englishName: e.target.value }))}
                className={inputClassName(errors.englishName)}
              />
              {errors.englishName && (
                <p className="text-red-500 text-xs mt-1">{errors.englishName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Role
              </label>
              <input
                type="text"
                value={formData.role}
                onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
                placeholder="Enter your role"
                className={inputClassName(errors.role)}
              />
              {errors.role && (
                <p className="text-red-500 text-xs mt-1">{errors.role}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Preferences
              </label>
              <textarea
                value={formData.preferences}
                onChange={handlePreferencesChange}
                placeholder="Enter your preferences (optional)"
                className={`${inputClassName(errors.preferences)} h-24 resize-none`}
              />
              <div className="flex justify-between text-xs text-gray-500 mt-1">
                <span>{charCount}/300 characters</span>
                {errors.preferences && (
                  <span className="text-red-500">{errors.preferences}</span>
                )}
              </div>
            </div>

            {/* Learned Preferences Section */}
            <div className="mt-6 border-t pt-4">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-5 h-5 rounded-full bg-blue-50 text-primary mr-2">
                    <Info className="h-3 w-3" />
                  </div>
                  <h3 className="text-md font-medium text-gray-700">Learned Preferences</h3>
                </div>
                <button
                  type="button"
                  onClick={() => setIsAddingNew(true)}
                  className="text-xs py-1 px-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded flex items-center gap-1"
                  disabled={!formData.enablePersonalization}
                >
                  <Plus className="h-3 w-3" /> Add
                </button>
              </div>
              
              {formData.enablePersonalization ? (
                <>
                  {learnedPreferences.length === 0 && !isAddingNew && (
                    <p className="text-sm text-gray-500 italic">
                      No learned preferences yet. As you use the system, it will learn your preferences automatically.
                    </p>
                  )}
                  
                  <div className="space-y-3 mt-2">
                    {learnedPreferences.map((pref, index) => (
                      <div key={index} className="bg-gray-50 p-3 rounded-md border">
                        {editingPreference === index ? (
                          <div className="space-y-2">
                            <input
                              type="text"
                              value={pref.preference}
                              onChange={(e) => handleUpdatePreference(index, 'preference', e.target.value)}
                              className="w-full p-1.5 text-sm border rounded"
                              placeholder="Preference"
                            />
                            <textarea
                              value={pref.description}
                              onChange={(e) => handleUpdatePreference(index, 'description', e.target.value)}
                              className="w-full p-1.5 text-sm border rounded h-16 resize-none"
                              placeholder="Description"
                            />
                            <div className="flex justify-end gap-2">
                              <button
                                type="button"
                                onClick={() => handleSavePreference(index)}
                                className="text-xs py-1 px-2 bg-secondary text-text rounded flex items-center gap-1"
                              >
                                <Save className="h-3 w-3" /> Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="flex justify-between">
                              <div className="flex items-center">
                                <div className="flex items-center justify-center w-4 h-4 rounded-full bg-blue-50 text-primary mr-1.5">
                                  <Info className="h-2.5 w-2.5" />
                                </div>
                                <h4 className="font-medium text-sm">{pref.preference}</h4>
                              </div>
                              <div className="flex gap-1">
                                <button
                                  type="button"
                                  onClick={() => handleEditPreference(index)}
                                  className="text-gray-400 hover:text-gray-600"
                                >
                                  <Edit className="h-4 w-4" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleRemovePreference(index)}
                                  className="text-gray-400 hover:text-red-500"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            <p className="text-sm text-gray-600 mt-1">{pref.description}</p>
                            <div className="mt-2 flex flex-wrap gap-1">
                              <span className="text-xs px-2 py-0.5 bg-gray-200 text-gray-600 rounded-full">
                                {pref.source === 'manual_entry' ? 'Manual' : 'Auto-detected'}
                              </span>
                              {pref.discoveredAt && (
                                <span className="text-xs px-2 py-0.5 bg-gray-200 text-gray-600 rounded-full">
                                  {new Date(pref.discoveredAt.seconds * 1000).toLocaleDateString()}
                                </span>
                              )}
                              {pref.manuallyEdited && (
                                <span className="text-xs px-2 py-0.5 bg-gray-200 text-gray-600 rounded-full">
                                  Edited
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    
                    {isAddingNew && (
                      <div className="bg-gray-50 p-3 rounded-md border space-y-2">
                        <input
                          type="text"
                          value={newPreference.preference}
                          onChange={(e) => setNewPreference(prev => ({ ...prev, preference: e.target.value }))}
                          className="w-full p-1.5 text-sm border rounded"
                          placeholder="Preference (e.g., 'Always show data in NIS')"
                        />
                        <textarea
                          value={newPreference.description}
                          onChange={(e) => setNewPreference(prev => ({ ...prev, description: e.target.value }))}
                          className="w-full p-1.5 text-sm border rounded h-16 resize-none"
                          placeholder="Description (e.g., 'Convert all currency values to NIS in reports')"
                        />
                        <div className="flex justify-end gap-2">
                          <button
                            type="button"
                            onClick={() => setIsAddingNew(false)}
                            className="text-xs py-1 px-2 bg-gray-200 text-gray-700 rounded"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleAddPreference}
                            className="text-xs py-1 px-2 bg-secondary text-text rounded flex items-center gap-1"
                            disabled={!newPreference.preference.trim() || !newPreference.description.trim()}
                          >
                            <Save className="h-3 w-3" /> Add
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <p className="text-sm text-gray-500 italic">
                  Enable personalization to view and manage learned preferences.
                </p>
              )}
            </div>
          </div>

          {errors.submit && (
            <p className="text-red-500 text-sm mt-4">{errors.submit}</p>
          )}

          {successMessage && (
            <p className="text-green-500 text-sm mt-4">{successMessage}</p>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfileModal;